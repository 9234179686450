import React, {useEffect, useState} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import nookies, {destroyCookie} from 'nookies';
import {Box, Button} from '@material-ui/core';
import HomeIcon from '@mui/icons-material/Home';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Language} from '../context/LanguageContext';
import {Lintramax} from '../context/LintramaxContext';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import IotLintra from '../assets/images/IotLintraChart.png';
import iotLogo from '../assets/images/iotlogo.png';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

export const SearchKawasan = (props) => {
  const {language, newObj} = Language();
  const {startDate, endDate, setIsLimitIot} = Lintramax();
  const count = props.count;
  const cookies = nookies.get();
  const navigate = useNavigate();
  // let filteredArray = props.filteredArray;
  const selectedWilayah = props.selectedWilayah;
  const tokens_session = cookies.token;
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const displayIndex = props.displayIndex;
  const [searchText, setSearchText] = useState('');
  const [isError, setError] = useState();
  const [dataType, setDataType] = useState();
  const [locationClick, setLocationClick] = useState(false);
  const [displayedDataType, setDisplayedDataType] = useState('temperature');
  const [filteredArray, setFilteredArray] = useState([]);
  const isSearch = filteredArray[0]?.name ? true : false;
  const [selectDataType, setSelectDataType] = useState([]);
  const [isLoad, setLoad] = useState(true);
  const theme = createTheme({
    typography: {
      fontFamily: ['Montserrat'].join(','),
    },
  });

  useEffect(() => {
    if (displayIndex === 4) {
      getDataType('soil');
      setSelectDataType([]);
      if (props.isLintra) props.handleSetDataTypeIoT([]);
    } else if (displayIndex === 3) {
      getDataType('weather');
      setSelectDataType([]);
      if (props.isLintra) props.handleSetDataTypeIoT([]);
    }
  }, [displayIndex]);

  useEffect(() => {
    setFilteredArray(props.filteredArray);
  }, [props.filteredArray]);

  const getDeviceDetail = async (id) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/device/detail`,
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (res.data.status === 200) {
        getDataType(res.data.data.category);
      }
      if (res.data.status === 401) {
        destroyCookie(null, 'token');
        navigate('/login');
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getDeviceList = async (flag, value) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/device/list`,
        {
          flag: flag,
          value: value,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getDataType = async (device) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/data-type/list`,
        {
          category: device,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      if (res.data.status === 200) {
        if (device === 'weather')
          setDataType(res.data.data.filter((el) => el.label !== 'time'));
        else
          setDataType(
            res.data.data.filter(
              (el) => el.label !== 'time' && el.label !== 'locationName'
            )
          );
      }
      if (res.data.status === 401) {
        destroyCookie(null, 'token');
        navigate('/login');
      }
      setLoad(true);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const filterChosenDataType = (label, id) => {
    const res = selectDataType.find(
      (el) => el?.type === label && el?.deviceId === id
    );
    if (res) return true;
    else return false;
  };

  const onClickSearch = () => {
    if (searchText.length < 3) setError(true);
    else {
      props.handleSearch(searchText);
      setError(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className='searchLeftBox'>
        <Box
          style={{
            display: 'flex',
            padding: '10px',
            flex: '0 0 auto',
          }}
        >
          {count === 0 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginTop: 5,
                cursor: 'pointer',
              }}
              onClick={() => {
                props.emptyObject();
              }}
            >
              <LocationOnIcon style={{fontSize: '30px', marginX: '10px'}} />
              <Box
                style={{
                  color: '#2B5180',
                  fontWeight: '600',
                }}
              >
                {newObj.WilayahWord[language]}
              </Box>
            </div>
          ) : (
            <div style={{padding: '10px 10px 0 10px'}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <ChevronLeftIcon
                  style={{fontSize: '25px', cursor: 'pointer'}}
                  onClick={props.backClick}
                />
                <Box
                  style={{
                    color: '#2B5180',
                    fontWeight: '600',
                  }}
                >
                  {selectedWilayah[displayIndex].split('$')[0]}
                </Box>
              </div>

              <div
                style={{
                  display: 'flex',
                  gap: '3px',
                  marginTop: '10px',
                  alignItems: 'center',
                }}
              >
                <HomeIcon
                  onClick={props.emptyObject}
                  style={{cursor: 'pointer'}}
                />
                <ChevronRightIcon style={{color: 'gray'}} />

                {selectedWilayah.slice(1, 4).map((item, index) => (
                  <div
                    key={index}
                    style={{
                      gap: '3px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {index >= 1 && <ChevronRightIcon style={{color: 'gray'}} />}
                    <div
                      style={{fontSize: '10px', cursor: 'pointer'}}
                      onClick={() => {
                        props.returnClick(index);
                        setSelectDataType([]);
                      }}
                    >
                      {item.split('$')[0]}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Box>

        <Box
          style={{
            padding: '10px',
            backgroundColor: '#00000029',
            borderRadius: '5px',
            margin: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: '0 0 auto',
          }}
        >
          <input
            placeholder='Search'
            style={{
              width: 'auto',
              background: 'none',
              outline: 'none',
              flex: '1',
              boxShadow: 'none',
              borderColor: 'transparent',
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') onClickSearch();
            }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          ></input>

          <div
            style={{
              width: 'max-content',
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
            }}
            onClick={onClickSearch}
          >
            <SearchIcon />
          </div>
        </Box>
        {isError && (
          <span style={{color: 'red', padding: '0 17px', fontSize: '10px'}}>
            Please insert minimum 3 character
          </span>
        )}
        <div
          style={{
            overflowY: 'auto',
            flex: '1 1 auto',
          }}
        >
          {!props.isLoad && (
            <>
              {props.isLintra ? (
                <>
                  {count <= 3 ? (
                    <Box
                      sx={{
                        maxHeight: 250,
                      }}
                    >
                      {props.windowWidth < 900 ? (
                        <Box
                          style={{
                            // height: "250px",
                            maxHeight: '250px',
                          }}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              padding: '10px',
                              backgroundColor: '#00000029',
                              borderRadius: '5px',
                              margin: '10px',
                              cursor: 'pointer',
                              flex: '0 0 auto',
                            }}
                            onClick={() => {
                              setLocationClick(!locationClick);
                            }}
                          >
                            Location <ExpandMoreIcon />
                          </Box>

                          {locationClick && (
                            <Box
                              style={{
                                maxHeight: '200px',
                                overflowY: 'scroll',
                                padding: '10px',
                                backgroundColor: '#00000029',
                                borderRadius: '5px',
                                margin: '10px',
                                cursor: 'pointer',
                                flex: '0 0 auto',
                              }}
                            >
                              {filteredArray.map((item, index) => (
                                <div
                                  key={index}
                                  style={{
                                    borderBottom: '1px solid gray',
                                    padding: '10px',
                                  }}
                                >
                                  <div
                                    style={{
                                      padding: '8px',
                                      borderRadius: '5px',
                                      backgroundColor: 'transparent',
                                      fontSize: '12px',
                                      cursor: 'pointer',
                                    }}
                                    onMouseEnter={(e) => {
                                      e.currentTarget.style.backgroundColor =
                                        '#00000033';
                                    }}
                                    onMouseLeave={(e) => {
                                      e.currentTarget.style.backgroundColor =
                                        'transparent';
                                    }}
                                    onClick={(e) => {
                                      const id = item.code
                                        ? item.code
                                        : item.id;
                                      const id2 = item.pkCode
                                        ? item.pkCode
                                        : id;
                                      const id3 = item.kodProjek
                                        ? item.kodProjek
                                        : id2;
                                      const id4 =
                                        item.flag === 'wilayah' ? item.id : id3;
                                      props.onWilayahClick(
                                        e,
                                        id4,
                                        item.flag,
                                        item
                                      );
                                    }}
                                  >
                                    {'  '}
                                    {isSearch ? (
                                      <>
                                        <span
                                          style={{
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {item.code}{' '}
                                        </span>
                                        <span>{item.name}</span>
                                      </>
                                    ) : (
                                      <>
                                        <span
                                          style={{
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {/* {props.lintramax
                                    ? count >= 2 && "PK"
                                    : count === 2 && "PK"} */}

                                          {/* {!item.kodProjek && count >= 3 && "F"} */}
                                          {item.kodProjek ? (
                                            item.kodProjek
                                          ) : (
                                            <>
                                              {item.code && item.code}
                                              <span>
                                                {item.pkCode && item.pkCode}
                                              </span>
                                            </>
                                          )}
                                        </span>{' '}
                                        {count === 0 ? (
                                          <span>{item.wilayah}</span>
                                        ) : (
                                          <span>
                                            {count === 3 && item.namaProjek}
                                            {count === 2 && item.pkName}
                                            {count === 1 && item.negeri}
                                            {count === 0 && item.wilayah}
                                          </span>
                                        )}
                                        {count > 1 && <span>{item.title}</span>}
                                      </>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </Box>
                          )}

                          {props.DeviceIdByProjectCode && (
                            <>
                              {props.DeviceIdByProjectCode.length === 0 &&
                              displayIndex >= 3 ? (
                                <div
                                  style={{
                                    padding: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: '20px',
                                  }}
                                >
                                  <img
                                    src={IotLintra}
                                    alt={'iotlintra'}
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                      objectFit: 'contain',
                                    }}
                                  />

                                  <span
                                    style={{
                                      color: 'grey',
                                      width: '60%',
                                      textAlign: 'center',
                                    }}
                                  >
                                    No IoT Device found at this location
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {props.DeviceIdByProjectCode.map(
                                    (el, key) => {
                                      return (
                                        <>
                                          <div key={key}>
                                            <>
                                              <div
                                                style={{
                                                  padding: '15px',
                                                  display: 'flex',
                                                  justifyContent:
                                                    'space-between',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  gap: '20px',
                                                  backgroundColor:
                                                    'rgb(66, 66, 66)',
                                                  color: 'white',
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      width: '30px',
                                                      height: '30px',
                                                      borderRadius: '50%',
                                                      backgroundColor: 'grey',
                                                    }}
                                                  >
                                                    {' '}
                                                    <img
                                                      src={iotLogo}
                                                      width={'30px'}
                                                      alt='logo'
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: '11px',
                                                    }}
                                                  >
                                                    Device ID
                                                  </div>
                                                </div>

                                                <div
                                                  style={{
                                                    fontSize: '11px',
                                                  }}
                                                >
                                                  {el.title}
                                                </div>
                                              </div>
                                              {/* data type */}
                                              <div>
                                                {dataType && (
                                                  <div>
                                                    {dataType.map(
                                                      (item, key) => (
                                                        <div
                                                          style={{
                                                            padding: '5px',
                                                          }}
                                                        >
                                                          {' '}
                                                          <Button
                                                            disabled={
                                                              props.callIoTData
                                                            }
                                                            style={{
                                                              width: '100%',
                                                              padding: '10px',
                                                              display: 'flex',
                                                              alignItems:
                                                                'center',
                                                              borderRadius:
                                                                '10px',
                                                              textTransform:
                                                                'capitalize',
                                                              justifyContent:
                                                                'space-between',
                                                              ...(filterChosenDataType(
                                                                item.label,
                                                                el.id
                                                              ) && {
                                                                backgroundColor:
                                                                  'rgb(0, 61, 109)',
                                                                color: 'white',
                                                              }),
                                                            }}
                                                            onClick={() => {
                                                              const momentStart =
                                                                moment(
                                                                  startDate,
                                                                  'YYYY/MM/DD'
                                                                );
                                                              const momentEnd =
                                                                moment(
                                                                  endDate,
                                                                  'YYYY/MM/DD'
                                                                );
                                                              const diffDays =
                                                                momentEnd.diff(
                                                                  momentStart,
                                                                  'day'
                                                                );
                                                              if (
                                                                diffDays >= 31
                                                              ) {
                                                                setIsLimitIot(
                                                                  true
                                                                );
                                                              } else {
                                                                if (
                                                                  selectDataType.length <
                                                                    3 &&
                                                                  !selectDataType.find(
                                                                    (x) =>
                                                                      x.deviceId ===
                                                                        el.id &&
                                                                      item.label ===
                                                                        x.type
                                                                  )
                                                                ) {
                                                                  setSelectDataType(
                                                                    [
                                                                      ...selectDataType,
                                                                      {
                                                                        type: item.label,
                                                                        deviceId:
                                                                          el.id,
                                                                      },
                                                                    ]
                                                                  );
                                                                  props.handleSetDataTypeIoT(
                                                                    [
                                                                      ...selectDataType,
                                                                      {
                                                                        type: item.label,
                                                                        deviceId:
                                                                          el.id,
                                                                      },
                                                                    ]
                                                                  );
                                                                } else if (
                                                                  selectDataType.find(
                                                                    (x) =>
                                                                      x.deviceId ===
                                                                        el.id &&
                                                                      item.label ===
                                                                        x.type
                                                                  )
                                                                ) {
                                                                  const tempData =
                                                                    selectDataType.filter(
                                                                      (x) =>
                                                                        (x.deviceId ===
                                                                          el.id &&
                                                                          x.type !==
                                                                            item.label) ||
                                                                        x.deviceId !==
                                                                          el.id
                                                                    );
                                                                  setSelectDataType(
                                                                    tempData
                                                                  );
                                                                  props.handleSetDataTypeIoT(
                                                                    tempData
                                                                  );
                                                                }
                                                              }
                                                            }}
                                                            key={key}
                                                          >
                                                            <div
                                                              style={{
                                                                display: 'flex',
                                                                gap: 10,
                                                                alignItems:
                                                                  'center',
                                                              }}
                                                            >
                                                              {' '}
                                                              <div
                                                                style={{
                                                                  width: '30px',
                                                                  height:
                                                                    '30px',
                                                                  borderRadius:
                                                                    '50%',
                                                                  backgroundColor:
                                                                    'grey',
                                                                }}
                                                              >
                                                                {item.icon ===
                                                                '' ? (
                                                                  <div></div>
                                                                ) : (
                                                                  <img
                                                                    style={{
                                                                      width:
                                                                        '30px',
                                                                      height:
                                                                        '30px',
                                                                    }}
                                                                    src={
                                                                      item.icon
                                                                    }
                                                                    alt={
                                                                      item.name
                                                                    }
                                                                  />
                                                                )}
                                                              </div>
                                                              <div
                                                                style={{
                                                                  fontSize:
                                                                    '11px',
                                                                  fontWeight:
                                                                    'bold',
                                                                }}
                                                              >
                                                                {item.name}
                                                              </div>
                                                            </div>

                                                            {filterChosenDataType(
                                                              item.label,
                                                              el.id
                                                            ) === true && (
                                                              <div
                                                                className={`${item.label}Circle`}
                                                                style={{
                                                                  width: '15px',
                                                                  height:
                                                                    '15px',
                                                                  borderRadius:
                                                                    '3px',
                                                                  border:
                                                                    '1px solid white',
                                                                }}
                                                              />
                                                            )}
                                                          </Button>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            </>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Box>
                      ) : (
                        <>
                          {filteredArray.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                borderBottom: '1px solid gray',
                                padding: '10px',
                              }}
                            >
                              <div
                                style={{
                                  padding: '8px',
                                  borderRadius: '5px',
                                  backgroundColor: 'transparent',
                                  fontSize: '12px',
                                  cursor: 'pointer',
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    '#00000033';
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    'transparent';
                                }}
                                onClick={(e) => {
                                  const id = item.code ? item.code : item.id;
                                  const id2 = item.pkCode ? item.pkCode : id;
                                  const id3 = item.kodProjek
                                    ? item.kodProjek
                                    : id2;
                                  const id4 =
                                    item.flag === 'wilayah' ? item.id : id3;
                                  if (item.category) {
                                    props?.handleSelectDeviceIoT(item);
                                  } else
                                    props.onWilayahClick(
                                      e,
                                      id4,
                                      item.flag,
                                      item
                                    );
                                }}
                              >
                                {'  '}
                                {isSearch ? (
                                  <>
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {item.code}{' '}
                                    </span>
                                    <span>{item.name}</span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {/* {props.lintramax
                                    ? count >= 2 && "PK"
                                    : count === 2 && "PK"} */}

                                      {/* {!item.kodProjek && count >= 3 && "F"} */}
                                      {item.kodProjek ? (
                                        item.kodProjek
                                      ) : (
                                        <>
                                          {item.code && item.code}
                                          <span>
                                            {item.pkCode && item.pkCode}
                                          </span>
                                        </>
                                      )}
                                    </span>{' '}
                                    {count === 0 ? (
                                      <span>{item.wilayah}</span>
                                    ) : (
                                      <span>
                                        {count === 3 && item.namaProjek}
                                        {count === 2 && item.pkName}
                                        {count === 1 && item.negeri}
                                        {count === 0 && item.wilayah}
                                      </span>
                                    )}
                                    {count > 1 && <span>{item.title}</span>}
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                          {props.DeviceIdByProjectCode && (
                            <>
                              {props.DeviceIdByProjectCode.length === 0 &&
                              displayIndex >= 3 ? (
                                <div
                                  style={{
                                    padding: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: '20px',
                                  }}
                                >
                                  <img
                                    src={IotLintra}
                                    alt={'iotlintra'}
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                      objectFit: 'contain',
                                    }}
                                  />

                                  <span
                                    style={{
                                      color: 'grey',
                                      width: '60%',
                                      textAlign: 'center',
                                    }}
                                  >
                                    No IoT Device found at this location
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {props.DeviceIdByProjectCode.map(
                                    (el, key) => {
                                      return (
                                        <>
                                          <div key={key}>
                                            <>
                                              <div
                                                style={{
                                                  padding: '15px',
                                                  display: 'flex',
                                                  justifyContent:
                                                    'space-between',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  gap: '20px',
                                                  backgroundColor:
                                                    'rgb(66, 66, 66)',
                                                  color: 'white',
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      width: '30px',
                                                      height: '30px',
                                                      borderRadius: '50%',
                                                      backgroundColor: 'grey',
                                                    }}
                                                  >
                                                    {' '}
                                                    <img
                                                      src={iotLogo}
                                                      width={'30px'}
                                                      alt='logo'
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: '11px',
                                                    }}
                                                  >
                                                    Device ID
                                                  </div>
                                                </div>

                                                <div
                                                  style={{
                                                    fontSize: '11px',
                                                  }}
                                                >
                                                  {el.title}
                                                </div>
                                              </div>
                                              {/* data type */}
                                              <div>
                                                {dataType && (
                                                  <div>
                                                    {dataType.map(
                                                      (item, key) => (
                                                        <div
                                                          style={{
                                                            padding: '5px',
                                                          }}
                                                        >
                                                          {' '}
                                                          <Button
                                                            disabled={
                                                              props.callIoTData
                                                            }
                                                            style={{
                                                              width: '100%',
                                                              padding: '10px',
                                                              display: 'flex',
                                                              alignItems:
                                                                'center',
                                                              borderRadius:
                                                                '10px',
                                                              textTransform:
                                                                'capitalize',
                                                              justifyContent:
                                                                'space-between',
                                                              ...(filterChosenDataType(
                                                                item.label,
                                                                el.id
                                                              ) && {
                                                                backgroundColor:
                                                                  'rgb(0, 61, 109)',
                                                                color: 'white',
                                                              }),
                                                            }}
                                                            onClick={() => {
                                                              const momentStart =
                                                                moment(
                                                                  startDate,
                                                                  'YYYY/MM/DD'
                                                                );
                                                              const momentEnd =
                                                                moment(
                                                                  endDate,
                                                                  'YYYY/MM/DD'
                                                                );
                                                              const diffDays =
                                                                momentEnd.diff(
                                                                  momentStart,
                                                                  'day'
                                                                );
                                                              if (
                                                                diffDays >= 31
                                                              ) {
                                                                setIsLimitIot(
                                                                  true
                                                                );
                                                              } else {
                                                                if (
                                                                  selectDataType.length <
                                                                    3 &&
                                                                  !selectDataType.find(
                                                                    (x) =>
                                                                      x.deviceId ===
                                                                        el.id &&
                                                                      item.label ===
                                                                        x.type
                                                                  )
                                                                ) {
                                                                  setSelectDataType(
                                                                    [
                                                                      ...selectDataType,
                                                                      {
                                                                        type: item.label,
                                                                        deviceId:
                                                                          el.id,
                                                                      },
                                                                    ]
                                                                  );
                                                                  props.handleSetDataTypeIoT(
                                                                    [
                                                                      ...selectDataType,
                                                                      {
                                                                        type: item.label,
                                                                        deviceId:
                                                                          el.id,
                                                                      },
                                                                    ]
                                                                  );
                                                                } else if (
                                                                  selectDataType.find(
                                                                    (x) =>
                                                                      x.deviceId ===
                                                                        el.id &&
                                                                      item.label ===
                                                                        x.type
                                                                  )
                                                                ) {
                                                                  const tempData =
                                                                    selectDataType.filter(
                                                                      (x) =>
                                                                        (x.deviceId ===
                                                                          el.id &&
                                                                          x.type !==
                                                                            item.label) ||
                                                                        x.deviceId !==
                                                                          el.id
                                                                    );
                                                                  setSelectDataType(
                                                                    tempData
                                                                  );
                                                                  props.handleSetDataTypeIoT(
                                                                    tempData
                                                                  );
                                                                }
                                                              }
                                                            }}
                                                            key={key}
                                                          >
                                                            <div
                                                              style={{
                                                                display: 'flex',
                                                                gap: 10,
                                                                alignItems:
                                                                  'center',
                                                              }}
                                                            >
                                                              {' '}
                                                              <div
                                                                style={{
                                                                  width: '30px',
                                                                  height:
                                                                    '30px',
                                                                  borderRadius:
                                                                    '50%',
                                                                  backgroundColor:
                                                                    'grey',
                                                                }}
                                                              >
                                                                {item.icon ===
                                                                '' ? (
                                                                  <div></div>
                                                                ) : (
                                                                  <img
                                                                    style={{
                                                                      width:
                                                                        '30px',
                                                                      height:
                                                                        '30px',
                                                                    }}
                                                                    src={
                                                                      item.icon
                                                                    }
                                                                    alt={
                                                                      item.name
                                                                    }
                                                                  />
                                                                )}
                                                              </div>
                                                              <div
                                                                style={{
                                                                  fontSize:
                                                                    '11px',
                                                                  fontWeight:
                                                                    'bold',
                                                                }}
                                                              >
                                                                {item.name}
                                                              </div>
                                                            </div>

                                                            {filterChosenDataType(
                                                              item.label,
                                                              el.id
                                                            ) === true && (
                                                              <div
                                                                className={`${item.label}Circle`}
                                                                style={{
                                                                  width: '15px',
                                                                  height:
                                                                    '15px',
                                                                  borderRadius:
                                                                    '3px',
                                                                  border:
                                                                    '1px solid white',
                                                                }}
                                                              />
                                                            )}
                                                          </Button>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            </>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  ) : (
                    <>
                      {props.DeviceIdByProjectCode && (
                        <>
                          {props.DeviceIdByProjectCode.length === 0 ? (
                            <div
                              style={{
                                padding: '40px',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '20px',
                              }}
                            >
                              <img
                                src={IotLintra}
                                alt={'iotlintra'}
                                style={{
                                  height: '100%',
                                  width: '100%',
                                  objectFit: 'contain',
                                }}
                              />

                              <span
                                style={{
                                  color: 'grey',
                                  width: '60%',
                                  textAlign: 'center',
                                }}
                              >
                                No IoT Device found at this location
                              </span>
                            </div>
                          ) : (
                            <div
                              style={{
                                maxHeight: '250px',
                              }}
                            >
                              {props.DeviceIdByProjectCode.map((el, key) => {
                                return (
                                  <>
                                    <div key={key}>
                                      <>
                                        <div
                                          style={{
                                            padding: '15px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            gap: '20px',
                                            backgroundColor: 'rgb(66, 66, 66)',
                                            color: 'white',
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: '10px',
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: '30px',
                                                height: '30px',
                                                borderRadius: '50%',
                                                backgroundColor: 'grey',
                                              }}
                                            >
                                              {' '}
                                              <img
                                                src={iotLogo}
                                                width={'30px'}
                                                alt='logo'
                                              />
                                            </div>
                                            <div
                                              style={{
                                                fontSize: '11px',
                                              }}
                                            >
                                              Device ID
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              fontSize: '11px',
                                            }}
                                          >
                                            {el.title}
                                          </div>
                                        </div>
                                        {/* data type */}
                                        <div>
                                          {dataType && (
                                            <div>
                                              {dataType.map((item, key) => (
                                                <div style={{padding: '5px'}}>
                                                  {' '}
                                                  <Button
                                                    disabled={props.callIoTData}
                                                    style={{
                                                      width: '100%',
                                                      padding: '10px',
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      borderRadius: '10px',
                                                      textTransform:
                                                        'capitalize',
                                                      justifyContent:
                                                        'space-between',
                                                      ...(filterChosenDataType(
                                                        item.label,
                                                        el.id
                                                      ) && {
                                                        backgroundColor:
                                                          'rgb(0, 61, 109)',
                                                        color: 'white',
                                                      }),
                                                    }}
                                                    onClick={() => {
                                                      const momentStart =
                                                        moment(
                                                          startDate,
                                                          'YYYY/MM/DD'
                                                        );
                                                      const momentEnd = moment(
                                                        endDate,
                                                        'YYYY/MM/DD'
                                                      );
                                                      const diffDays =
                                                        momentEnd.diff(
                                                          momentStart,
                                                          'day'
                                                        );
                                                      if (diffDays >= 31) {
                                                        setIsLimitIot(true);
                                                      } else {
                                                        if (
                                                          selectDataType.length <
                                                            3 &&
                                                          !selectDataType.find(
                                                            (x) =>
                                                              x.deviceId ===
                                                                el.id &&
                                                              item.label ===
                                                                x.type
                                                          )
                                                        ) {
                                                          setSelectDataType([
                                                            ...selectDataType,
                                                            {
                                                              type: item.label,
                                                              deviceId: el.id,
                                                            },
                                                          ]);
                                                          props.handleSetDataTypeIoT(
                                                            [
                                                              ...selectDataType,
                                                              {
                                                                type: item.label,
                                                                deviceId: el.id,
                                                              },
                                                            ]
                                                          );
                                                        } else if (
                                                          selectDataType.find(
                                                            (x) =>
                                                              x.deviceId ===
                                                                el.id &&
                                                              item.label ===
                                                                x.type
                                                          )
                                                        ) {
                                                          const tempData =
                                                            selectDataType.filter(
                                                              (x) =>
                                                                (x.deviceId ===
                                                                  el.id &&
                                                                  x.type !==
                                                                    item.label) ||
                                                                x.deviceId !==
                                                                  el.id
                                                            );
                                                          setSelectDataType(
                                                            tempData
                                                          );
                                                          props.handleSetDataTypeIoT(
                                                            tempData
                                                          );
                                                        }
                                                      }
                                                    }}
                                                    key={key}
                                                  >
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        gap: 10,
                                                        alignItems: 'center',
                                                      }}
                                                    >
                                                      {' '}
                                                      <div
                                                        style={{
                                                          width: '30px',
                                                          height: '30px',
                                                          borderRadius: '50%',
                                                          backgroundColor:
                                                            'grey',
                                                        }}
                                                      >
                                                        {item.icon === '' ? (
                                                          <div></div>
                                                        ) : (
                                                          <img
                                                            style={{
                                                              width: '30px',
                                                              height: '30px',
                                                            }}
                                                            src={item.icon}
                                                            alt={item.name}
                                                          />
                                                        )}
                                                      </div>
                                                      <div
                                                        style={{
                                                          fontSize: '11px',
                                                          fontWeight: 'bold',
                                                        }}
                                                      >
                                                        {item.name}
                                                      </div>
                                                    </div>

                                                    {filterChosenDataType(
                                                      item.label,
                                                      el.id
                                                    ) === true && (
                                                      <div
                                                        className={`${item.label}Circle`}
                                                        style={{
                                                          width: '15px',
                                                          height: '15px',
                                                          borderRadius: '3px',
                                                          border:
                                                            '1px solid white',
                                                        }}
                                                      />
                                                    )}
                                                  </Button>
                                                </div>
                                              ))}
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {props.windowWidth < 900 ? (
                    <Box>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '10px',
                          backgroundColor: '#00000029',
                          borderRadius: '5px',
                          margin: '10px',
                          cursor: 'pointer',
                          flex: '0 0 auto',
                        }}
                        onClick={() => {
                          setLocationClick(!locationClick);
                        }}
                      >
                        Location <ExpandMoreIcon />
                      </Box>

                      {locationClick && (
                        <Box
                          style={{
                            maxHeight: '200px',
                            overflowY: 'scroll',
                            padding: '10px',
                            backgroundColor: '#00000029',
                            borderRadius: '5px',
                            margin: '10px',
                            cursor: 'pointer',
                            flex: '0 0 auto',
                          }}
                        >
                          {filteredArray.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                borderBottom: '1px solid gray',
                                padding: '10px',
                              }}
                            >
                              <div
                                style={{
                                  padding: '8px',
                                  borderRadius: '5px',
                                  backgroundColor: 'transparent',
                                  fontSize: '12px',
                                  cursor: 'pointer',
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    '#00000033';
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    'transparent';
                                }}
                                onClick={(e) => {
                                  const id = item.code ? item.code : item.id;
                                  const id2 = item.pkCode ? item.pkCode : id;
                                  const id3 = item.kodProjek
                                    ? item.kodProjek
                                    : id2;
                                  const id4 =
                                    item.flag === 'wilayah' ? item.id : id3;
                                  props.onWilayahClick(e, id4, item.flag, item);
                                }}
                              >
                                {'  '}
                                {isSearch ? (
                                  <>
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {item.code}{' '}
                                    </span>
                                    <span>{item.name}</span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {/* {props.lintramax
                                ? count >= 2 && "PK"
                                : count === 2 && "PK"} */}

                                      {/* {!item.kodProjek && count >= 3 && "F"} */}
                                      {item.kodProjek ? (
                                        item.kodProjek
                                      ) : (
                                        <>
                                          {item.code && item.code}
                                          <span>
                                            {item.pkCode && item.pkCode}
                                          </span>
                                        </>
                                      )}
                                    </span>{' '}
                                    {count === 0 ? (
                                      <span>{item.wilayah}</span>
                                    ) : (
                                      <span>
                                        {count === 3 && item.namaProjek}
                                        {count === 2 && item.pkName}
                                        {count === 1 && item.negeri}
                                        {count === 0 && item.wilayah}
                                      </span>
                                    )}
                                    {count > 1 && <span>{item.title}</span>}
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                        </Box>
                      )}
                    </Box>
                  ) : (
                    filteredArray.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          borderBottom: '1px solid gray',
                          padding: '10px',
                        }}
                      >
                        <div
                          style={{
                            padding: '8px',
                            borderRadius: '5px',
                            backgroundColor: 'transparent',
                            fontSize: '12px',
                            cursor: 'pointer',
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = '#00000033';
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor =
                              'transparent';
                          }}
                          onClick={(e) => {
                            const id = item.code ? item.code : item.id;
                            const id2 = item.pkCode ? item.pkCode : id;
                            const id3 = item.kodProjek ? item.kodProjek : id2;
                            const id4 = item.flag === 'wilayah' ? item.id : id3;
                            props.onWilayahClick(e, id4, item.flag, item);
                          }}
                        >
                          {'  '}
                          {isSearch ? (
                            <>
                              <span
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                {item.code}{' '}
                              </span>
                              <span>{item.name}</span>
                            </>
                          ) : (
                            <>
                              <span
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                {/* {props.lintramax
                                ? count >= 2 && "PK"
                                : count === 2 && "PK"} */}

                                {/* {!item.kodProjek && count >= 3 && "F"} */}
                                {item.kodProjek ? (
                                  item.kodProjek
                                ) : (
                                  <>
                                    {item.code && item.code}
                                    <span>{item.pkCode && item.pkCode}</span>
                                  </>
                                )}
                              </span>{' '}
                              {count === 0 ? (
                                <span>{item.wilayah}</span>
                              ) : (
                                <span>
                                  {count === 3 && item.namaProjek}
                                  {count === 2 && item.pkName}
                                  {count === 1 && item.negeri}
                                  {count === 0 && item.wilayah}
                                </span>
                              )}
                              {count > 1 && <span>{item.title}</span>}
                            </>
                          )}
                        </div>
                      </div>
                    ))
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Box>
    </ThemeProvider>
  );
};
