import React, {useState, useEffect} from 'react';
import {Loader} from '../../components/Loader';

import {useMediaQuery} from '@mui/material';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// import Link from "@mui/material/Link";
import {Link} from 'react-router-dom';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

import axios from 'axios';
import nookies, {destroyCookie} from 'nookies';
import moment from 'moment';
import dashboardLogo from '../../assets/images/dashboardLogo.png';
import macWP from '../../assets/images/macWP.jpg';
import digitalMap from '../../assets/images/mapBG.png';
import profile from '../../assets/images/profile.svg';
import admin from '../../assets/images/admin.svg';
import signsout from '../../assets/images/signout.svg';
import iotChart from '../../assets/images/iotChart.svg';
import Sawit from '../../assets/images/palmOil.svg';
import Padi from '../../assets/images/padi.svg';
import Getah from '../../assets/images/getah.svg';
import SawitBG from '../../assets/images/palmOilBG.png';
import PadiBG from '../../assets/images/padiBG.png';
import GetahBG from '../../assets/images/getahBG.png';
import mspoBg from '../../assets/images/MSPO_bg.jpg';
import powerBiBg from '../../assets/images/PowerBI_bg.jpg';
import transformasiBG from '../../assets/images/TRANSFORMASI_FELCRA_BERHAD.png';
import PPPLS from '../../assets/images/PPPLS.png';

import Doughnutchart from '../../components/Doughnutchart';
import LintramaxWidget from '../../components/LintramaxWidget';
import IoTWidget from '../../components/IoTWidget';
import Greeting from '../../components/Greeting';
import './index.scss';
import {Language} from '../../context/LanguageContext';
import {useTheme} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import {numberWithCommas} from '../../Helpers/stringFormat';

const Main = (props) => {
  const {language, newObj} = Language();
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const navigate = useNavigate();
  const role = props.role;
  const adminRole = [
    'lintramax_admin',
    'user_management_admin',
    'digital_mapping_admin',
    'iot_admin',
  ];

  const adminFlag = role?.some((value) => adminRole.includes(value));

  async function signout() {
    await destroyCookie(null, 'token', {
      path: '/',
    });
    navigate('/login');
  }

  const imageObj = [
    {code: 'S', src: Sawit, bg: SawitBG},
    {code: 'P', src: Padi, bg: PadiBG},
    {code: 'G', src: Getah, bg: GetahBG},
  ];

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [previewUrl, setPreviewUrl] = useState();
  const [widgetTanaman, setWidgetJenisTanaman] = useState();
  const [isLoad, setLoad] = useState(true);
  const [mapData, setMapData] = useState([]);
  const [lintramaxData, setLintramaxData] = useState();
  const [iotData, setIotData] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isSmScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const [doughnutData, setDoughnutData] = useState({
    labels: ['Luas Bertanam', 'Luas Berhasil'],
    datasets: [
      {
        label: 'Data',
        data: [1, 1],
        backgroundColor: ['rgba(226, 31, 38, 1)', 'rgba(43, 51, 125, 1)'],
        borderColor: ['rgba(226, 31, 38, 1)', 'rgba(43, 51, 125, 1)'],
        borderWidth: 1,
      },
    ],
  });

  const cookies = nookies.get();
  const tokens_session = cookies.token;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 0,
    aspectRatio: 1,
    width: '100%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // set this to false to disable the tooltip on hover
      },
    },
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const getMapData = async () => {
      try {
        const res = await axios.post(
          `${apiUrl}/api/mapping/widget`,
          {},
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
            },
          }
        );
        if (res.data.status === 200) {
          const data = res.data.data;

          setMapData(data);
        }
        if (res.data.status === 401) {
          destroyCookie(null, 'token');
          navigate('/login');
        }

        return res.data;
      } catch (e) {
        console.log(e);
      }
    };

    const getIoTData = async () => {
      try {
        const res = await axios.post(
          `${apiUrl}/api/iot/widget`,
          {},
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        if (res.data.status === 200) {
          setIotData(res.data.data);
        }
        if (res.data.status === 401) {
          destroyCookie(null, 'token');
          navigate('/login');
        }

        return res.data;
      } catch (e) {
        console.log(e);
      }
    };

    const getLintramaxData = async () => {
      try {
        const res = await axios.post(
          `${apiUrl}/api/lintramax/dashboard_widget`,
          {},
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (res.data.status === 200) {
          const data = res.data.data;
          setLintramaxData(data);
        }
        if (res.data.status === 401) {
          destroyCookie(null, 'token');
          navigate('/login');
        }
        return res.data;
      } catch (e) {
        console.log(e);
      }
    };

    const getWidgetJenisTanaman = async () => {
      try {
        const res = await axios.post(
          `${apiUrl}/api/mapping/widget-jenis-tanaman`,
          {},
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
            },
          }
        );
        if (res.status === 200) {
          const data = res.data.data;
          const mergedArray = data.map((obj) => {
            const matchingObj = imageObj.find((item) => item.code === obj.code);
            return {...obj, ...matchingObj};
          });

          setWidgetJenisTanaman(mergedArray);

          setLoad(false);
        }
        if (res.data.status === 401) {
          destroyCookie(null, 'token');
          navigate('/login');
        }
        return res.data;
      } catch (error) {
        console.error(error);
      }
    };
    const callProfile = async () => {
      try {
        const res = await axios.post(
          `${apiUrl}/api/profile`,
          {},
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
            },
          }
        );
        if (res.status === 200) {
          var data = res.data.data;
          setFirstName(data.firstname);
          setLastName(data.lastname);
          setEmail(data.username);
          setPreviewUrl(data.avatar);
        }
        if (res.data.status === 401) {
          destroyCookie(null, 'token');
          navigate('/login');
        }
        return res.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
    callProfile();
    getMapData();
    getIoTData();
    getWidgetJenisTanaman();
    getLintramaxData();
  }, [apiUrl, tokens_session]);

  useEffect(() => {
    if (mapData) {
      setDoughnutData({
        labels: ['Luas Berhasil', 'Luas Bertanam'],
        datasets: [
          {
            label: 'Data',
            data: [
              // mapData
              //   .reduce((sum, obj) => sum + obj.luas_bertanam, 0)
              //   .toFixed(3),
              // mapData
              //   .reduce((sum, obj) => sum + obj.luas_berhasil, 0)
              //   .toFixed(3),
              mapData?.luas_berhasil?.toFixed(3),
              mapData?.luas_bertanam?.toFixed(3),
            ],
            backgroundColor: ['rgba(43, 51, 125, 1)', 'rgba(226, 31, 38, 1)'],
            borderColor: ['rgba(43, 51, 125, 1)', 'rgba(226, 31, 38, 1)'],
            borderWidth: 1,
          },
        ],
      });
    }
  }, [mapData]);
  if (isLoad) {
    return <Loader />;
  } else
    return (
      <Box
        sx={{
          backgroundImage: `url(${macWP})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: isXsScreen || isSmScreen ? '10px 20px' : '20px',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            background: 'rgba(255, 255, 255, 0.6)',
            borderRadius: isXsScreen ? '0px' : isSmScreen ? '0px' : '10px',
            backdropFilter: 'blur(10px)',
            webkitBackdropFilter: 'blur(10px)',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            position: 'fixed',
            width: isXsScreen
              ? '100%'
              : isSmScreen
              ? '100%'
              : 'calc(100% - 40px)',
            zIndex: '100',
            height: isXsScreen ? '40px' : isSmScreen ? '40px' : '50px',
          }}
          alignItems='center'
          display='flex'
          py={1}
          px={2}
        >
          <Box display='flex'>
            <img
              src={dashboardLogo}
              alt='Dashboard Logo'
              width='auto'
              height={30}
            />
            <div
              style={{
                color: '#2B337D',
                display: 'flex',
                alignItems: 'center',
                marginLeft: '15px',
                fontWeight: 'bold',
              }}
            >
              {newObj.DashboardWord[language]}
            </div>
          </Box>
        </Box>

        <Grid
          container
          component='main'
          style={{paddingY: 2, marginTop: '50px'}}
          rowSpacing={{xs: 1, sm: 2, md: 2}}
          columnSpacing={{xs: 1, sm: 2, md: 2}}
        >
          <Grid xs={12} sm={6} lg={3} item sx={{height: 'max-content'}}>
            <Box
              sx={{
                width: '100%',
                height: 300,
                transition: '0.5s',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: '#CEB889',
                flexDirection: 'column',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
                textAlign: 'center',
                borderRadius: '20px',
                padding: '30px',
              }}
            >
              <Box>
                <Stack
                  direction='row'
                  spacing={2}
                  sx={{justifyContent: 'center'}}
                >
                  <Avatar
                    alt='Dummy Avatar'
                    src={previewUrl}
                    sx={{width: 80, height: 80}}
                  />
                </Stack>
                <Box
                  variant='subtitle1'
                  sx={{fontStyle: 'italic', margin: '10px 0'}}
                >
                  <Greeting />
                </Box>
                <Box variant='h5' sx={{fontWeight: 'bold'}}>
                  {firstName} {lastName}
                </Box>
                <Box variant='subtitle1'>{email}</Box>
              </Box>

              <Stack
                direction='row'
                spacing={2}
                sx={{justifyContent: 'center'}}
                mt={4}
              >
                <Link
                  to='/profile'
                  style={{
                    color: 'black',
                    textDecoration: 'none',
                    gap: '3px',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '12px',
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: 30,
                    }}
                  >
                    <img alt={'img'} src={profile} />
                  </ListItemAvatar>
                  {newObj.MyProfileWord[language]}
                </Link>

                {adminFlag && (
                  <Link
                    to='/adminpage'
                    style={{
                      color: 'black',
                      textDecoration: 'none',
                      gap: '3px',
                      display: 'flex',
                      flexDirection: 'column',
                      fontSize: '12px',
                    }}
                  >
                    <ListItemAvatar
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: 30,
                      }}
                    >
                      <img alt={'img'} src={admin} />
                    </ListItemAvatar>

                    {newObj.AdministratorWord[language]}
                  </Link>
                )}

                <Box
                  style={{
                    color: 'black',
                    textDecoration: 'none',
                    gap: '3px',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '12px',
                    cursor: 'pointer',
                  }}
                  onClick={() => signout()}
                >
                  <ListItemAvatar
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: 30,
                    }}
                  >
                    <img alt={'img'} src={signsout} />
                  </ListItemAvatar>
                  {newObj.SignOutWord[language]}
                </Box>
              </Stack>
            </Box>
          </Grid>
          {/* map */}
          <Grid xs={12} sm={6} lg={3} item sx={{height: 'max-content'}}>
            <Link
              to='/map'
              style={{
                textDecoration: 'none',
                height: 'fit-content',
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: 300,
                  transition: '0.5s',
                  backgroundColor: '#707070',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                  textAlign: 'left',
                  borderRadius: '20px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <Grid
                  container
                  component='main'
                  position='absolute'
                  justifyContent='start'
                  zIndex={10}
                  bottom='0'
                >
                  {' '}
                  <Box
                    style={{
                      position: 'absolute',
                      bottom: '-10px',
                      left: '15px',
                      zIndex: 100,
                      width: '60px',
                    }}
                  >
                    <Doughnutchart data={doughnutData} options={options} />
                  </Box>
                  <Grid
                    container
                    component='main'
                    mt={2}
                    sx={{
                      color: 'white',
                      display: 'flex',
                      alignItems: 'start',
                      marginLeft: '40px',
                      height: '50px',
                      width: '100%',
                    }}
                    className='digiMap'
                  >
                    <Grid item xs={2} px={1}>
                      {/* <Doughnutchart data={doughnutData} options={options} /> */}
                    </Grid>
                    <Grid item xs={3.5}>
                      <div style={{fontSize: '8px'}}>
                        {newObj.LuasLadangWord[language]} <br />
                        <span style={{fontSize: '8px'}}>
                          {numberWithCommas(mapData?.luas_ladang?.toFixed(2))}{' '}
                          ha
                        </span>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={0.5}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      <div
                        // className="border1 red"
                        style={{
                          width: '5px',
                          height: '20px',
                          borderRadius: '20px',
                          backgroundColor: '#e21f26',
                        }}
                      ></div>
                    </Grid>
                    <Grid item xs={3}>
                      <div variant='caption' style={{fontSize: '8px'}}>
                        {newObj.LuasBertanamWord[language]}
                        <br />
                        <span className='red' style={{fontSize: '8px'}}>
                          {numberWithCommas(mapData?.luas_bertanam?.toFixed(2))}{' '}
                          ha
                        </span>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={0.5}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      <div
                        // className="border1 blue"
                        style={{
                          backgroundColor: '#4081f9',
                          width: '5px',
                          height: '20px',
                          borderRadius: '20px',
                        }}
                      ></div>
                    </Grid>
                    <Grid item xs={2.5}>
                      <div variant='caption' style={{fontSize: '8px'}}>
                        {newObj.LuasBerhasilWord[language]} <br />
                        <span className='blue' style={{fontSize: '8px'}}>
                          {numberWithCommas(mapData?.luas_berhasil?.toFixed(2))}{' '}
                          ha
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  <Box
                    style={{
                      color: 'white',
                      backgroundColor: '#353535',
                      fontSize: '10px',
                      padding: '7px 15px',
                      width: '100%',
                    }}
                  >
                    {newObj.LastUpdatedWord[language]}:{' '}
                    {moment(mapData?.last_update).format('DD MMMM YYYY')}
                  </Box>
                </Grid>
                <Box
                  style={{
                    position: 'absolute',
                    background:
                      'linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0))',
                    top: '50%',
                    zIndex: '9',
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                />
                <Box
                  style={{
                    position: 'absolute',
                    background:
                      'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))',
                    top: 0,
                    zIndex: '9',
                    bottom: '80%',
                    left: 0,
                    right: 0,
                  }}
                />
                <Box
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <img
                    src={digitalMap}
                    alt='mspo'
                    style={{
                      objectFit: 'cover',
                      height: '300px',
                      width: '100%',
                    }}
                  />
                </Box>

                <Box
                  style={{
                    position: 'absolute',
                    padding: '20px',
                    top: 0,
                    left: 0,
                    color: 'white',
                    zIndex: 40,
                  }}
                >
                  {newObj.DigitalMappingWord[language]}
                </Box>
              </Box>
            </Link>
          </Grid>
          {/* iot */}
          <Grid xs={12} sm={6} lg={3} item sx={{height: 'max-content'}}>
            <Link
              to='/iot'
              style={{textDecoration: 'none', height: 'fit-content'}}
            >
              <Box
                sx={{
                  width: '100%',
                  height: 300,
                  transition: '0.5s',
                  backgroundColor: '#2a2a2a',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                  textAlign: 'left',
                  borderRadius: '20px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <Grid
                  container
                  component='main'
                  position='absolute'
                  justifyContent='end'
                  zIndex={10}
                  bottom='0'
                >
                  <Box
                    style={{
                      color: 'white',
                      fontSize: '12px',
                      padding: '7px 15px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <div style={{display: 'flex'}}>
                      {' '}
                      <img
                        alt='img'
                        src={Padi}
                        style={{width: '30px', marginRight: '10px'}}
                      />
                      <Box>
                        <div style={{fontSize: '10px'}}>
                          {/* {newObj.TemperatureWord[language]} */}
                          {iotData?.data_type?.name}
                        </div>
                        <div style={{fontSize: '10px'}}>{iotData?.kawasan}</div>
                      </Box>
                    </div>

                    <div>{iotData?.last_value}</div>
                  </Box>
                  <Box
                    style={{
                      color: 'white',
                      backgroundColor: '#353535',
                      fontSize: '10px',
                      padding: '7px 15px',
                      width: '100%',
                    }}
                  >
                    {newObj.LastUpdatedWord[language]}:{' '}
                    {iotData?.tarikh_kemaskini}
                  </Box>
                </Grid>
                <Box
                  style={{
                    position: 'absolute',
                    background:
                      'linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0))',
                    top: '50%',
                    zIndex: '9',
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                />
                <Box
                  style={{
                    position: 'absolute',
                    background:
                      'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))',
                    top: 0,
                    zIndex: '9',
                    bottom: '80%',
                    left: 0,
                    right: 0,
                  }}
                />
                <Box
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  {/* <img
                    src={iotChart}
                    alt="mspo"
                    style={{
                      objectFit: "contain",
                      height: "300px",
                      width: "100%",
                    }}
                  /> */}

                  <IoTWidget windowWidth={windowWidth} data={iotData} />
                </Box>

                <Box
                  style={{
                    position: 'absolute',
                    padding: '20px',
                    top: 0,
                    left: 0,
                    color: 'white',
                    zIndex: 40,
                  }}
                >
                  {newObj.IoTWord[language]}
                </Box>
              </Box>
            </Link>
          </Grid>
          {/* lintramax */}
          <Grid xs={12} sm={6} lg={3} item sx={{height: 'max-content'}}>
            <Link
              to='/graph'
              style={{textDecoration: 'none', height: 'fit-content'}}
            >
              <Box
                sx={{
                  width: '100%',
                  height: 300,
                  transition: '0.5s',
                  backgroundColor: '#2a2a2a',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                  textAlign: 'left',
                  borderRadius: '20px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <Grid
                  container
                  component='main'
                  position='absolute'
                  justifyContent='end'
                  zIndex={10}
                  bottom='0'
                >
                  <Box
                    style={{
                      color: 'white',
                      fontSize: '12px',
                      padding: '7px 15px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: 1,
                    }}
                  >
                    <Grid container columnSpacing={1} rowSpacing={1}>
                      {/* penjualan */}
                      <Grid item xs={6}>
                        <Box style={{borderBottom: '2px solid #FFAF02'}}>
                          <div style={{fontSize: '10px'}}>
                            {newObj.PenjualanWord[language]}
                          </div>
                          <div style={{fontSize: '10px', color: 'lightgrey'}}>
                            {newObj.NetWeightWord[language]}
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              gap: '5px',
                              alignItems: 'center',
                            }}
                          >
                            {/* <div
                              className="yellow"
                              style={{
                                border: "1px solid white",
                                borderRadius: "50%",
                                width: "9px",
                                height: "9px",
                              }}
                            /> */}
                            <span
                              style={{fontSize: '10px', fontWeight: 'bold'}}
                            >
                              {lintramaxData && (
                                <>{lintramaxData.data.penjualan}</>
                              )}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                      {/* penuaian */}
                      <Grid item xs={6}>
                        <Box style={{borderBottom: '2px solid #4FB320'}}>
                          <div style={{fontSize: '10px'}}>
                            {newObj.PenuaianWord[language]}
                          </div>
                          <div style={{fontSize: '10px', color: 'lightgrey'}}>
                            {newObj.FFBWeightNetWeightWord[language]}
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              gap: '5px',
                              alignItems: 'center',
                            }}
                          >
                            {/* <div
                              className="green"
                              style={{
                                border: "1px solid white",
                                borderRadius: "50%",
                                width: "9px",
                                height: "9px",
                              }}
                            /> */}
                            <span
                              style={{fontSize: '10px', fontWeight: 'bold'}}
                            >
                              {lintramaxData && (
                                <>{lintramaxData.data.penuaian_ffb}</>
                              )}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                      {/* Penuaian2 */}
                      <Grid item xs={6}>
                        <Box style={{borderBottom: '2px solid #E21BE9'}}>
                          <div style={{fontSize: '10px'}}>
                            {newObj.PenuaianWord[language]}
                          </div>
                          <div style={{fontSize: '10px', color: 'lightgrey'}}>
                            {newObj.MThaWord[language]}
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              gap: '5px',
                              alignItems: 'center',
                            }}
                          >
                            {/* <div
                              className="purple"
                              style={{
                                border: "1px solid white",
                                borderRadius: "50%",
                                width: "9px",
                                height: "9px",
                              }}
                            /> */}
                            <span
                              style={{fontSize: '10px', fontWeight: 'bold'}}
                            >
                              {lintramaxData && (
                                <>{lintramaxData.data.penuaian_mt}</>
                              )}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                      {/* total worker */}
                      <Grid item xs={6}>
                        <Box style={{borderBottom: '2px solid #499DF5'}}>
                          <div style={{fontSize: '10px'}}>
                            {' '}
                            {newObj.TotalWorkerWord[language]}
                          </div>
                          <div style={{fontSize: '10px', color: 'lightgrey'}}>
                            {newObj.MinMaxWord[language]}
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              gap: '5px',
                              alignItems: 'center',
                            }}
                          >
                            {/* <div
                              className="lightBlue"
                              style={{
                                border: "1px solid white",
                                borderRadius: "50%",
                                width: "9px",
                                height: "9px",
                              }}
                            /> */}
                            <span
                              style={{fontSize: '10px', fontWeight: 'bold'}}
                            >
                              {lintramaxData && (
                                <> {lintramaxData.data.total_worker}</>
                              )}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    style={{
                      color: 'white',
                      backgroundColor: '#353535',
                      fontSize: '10px',
                      padding: '7px 15px',
                      width: '100%',
                    }}
                  >
                    {newObj.LastUpdatedWord[language]}:{' '}
                    {lintramaxData &&
                      moment(lintramaxData.last_update).format('DD MMMM YYYY')}
                  </Box>
                </Grid>
                <Box
                  style={{
                    position: 'absolute',
                    background:
                      'linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0))',
                    top: '50%',
                    zIndex: '9',
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                />
                <Box
                  style={{
                    position: 'absolute',
                    background:
                      'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))',
                    top: 0,
                    zIndex: '9',
                    bottom: '80%',
                    left: 0,
                    right: 0,
                  }}
                />
                <Box
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '50%',
                  }}
                >
                  {lintramaxData && (
                    <LintramaxWidget
                      windowWidth={windowWidth}
                      data={lintramaxData}
                    />
                  )}
                </Box>

                <Box
                  style={{
                    position: 'absolute',
                    padding: '20px',
                    top: 0,
                    left: 0,
                    color: 'white',
                    zIndex: 40,
                  }}
                >
                  Lintramax
                </Box>
              </Box>
            </Link>
          </Grid>

          {/* mspo */}
          <Grid xs={12} sm={6} lg={3} item sx={{height: 'max-content'}}>
            <Link
              onClick={() =>
                window.open(
                  'https://sol2.felcra.com.my/eFelcra/dev/mspo/mspo_1/index.html'
                )
              }
              style={{
                textDecoration: 'none',
                height: 'fit-content',
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: 300,
                  transition: '0.5s',
                  backgroundColor: '#707070',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                  textAlign: 'left',
                  borderRadius: '20px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <Grid
                  container
                  component='main'
                  position='absolute'
                  zIndex={10}
                  bottom='0'
                  p={3}
                >
                  <Grid
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '10px',
                    }}
                    item
                  >
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      spacing={0}
                    >
                      <Box
                        variant='body1'
                        color='white'
                        style={{
                          fontWeight: 'bold',
                          width: '80%',
                          textAlign: 'center',
                          fontSize: '18px',
                        }}
                      >
                        Malaysian Sustainable Palm Oil
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
                <Box
                  style={{
                    position: 'absolute',
                    background:
                      'linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0))',
                    top: '50%',
                    zIndex: '9',
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                />
                <Box
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <img
                    src={mspoBg}
                    alt='mspo'
                    style={{
                      objectFit: 'cover',
                      height: '300px',
                      width: '100%',
                    }}
                  />
                </Box>

                <Box
                  style={{
                    position: 'absolute',
                    padding: '20px',
                    top: 0,
                    right: 0,
                  }}
                >
                  <Box
                    style={{
                      padding: '5px',
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      borderRadius: '100%',
                      height: 'max-content',
                      display: 'flex',
                    }}
                  >
                    <InsertLinkIcon style={{width: '20px', color: 'white'}} />
                  </Box>
                </Box>
              </Box>
            </Link>
          </Grid>
          {/* powerBi */}
          <Grid xs={12} sm={6} lg={3} item sx={{height: 'max-content'}}>
            <Link
              onClick={() =>
                window.open(
                  'https://sol2.felcra.com.my/eFelcra/dev/dbKPIFB/login.php'
                )
              }
              style={{
                textDecoration: 'none',
                height: 'fit-content',
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: 300,
                  transition: '0.5s',
                  backgroundColor: '#707070',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                  textAlign: 'left',
                  borderRadius: '20px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <Grid
                  container
                  component='main'
                  position='absolute'
                  zIndex={10}
                  bottom='0'
                  p={3}
                >
                  <Grid
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '10px',
                    }}
                    item={true}
                  >
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      spacing={0}
                    >
                      <Box
                        variant='body1'
                        color='white'
                        style={{
                          fontWeight: 'bold',
                          width: '80%',
                          textAlign: 'center',
                          fontSize: '18px',
                        }}
                      >
                        Analisa Prestasi KPI FELCRA Berhad
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
                <Box
                  style={{
                    position: 'absolute',
                    background:
                      'linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0))',
                    top: '50%',
                    zIndex: '9',
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                />
                <Box
                  style={{
                    position: 'absolute',
                    padding: '20px',
                    zIndex: '11',
                    top: 0,
                    right: 0,
                  }}
                >
                  <Box
                    style={{
                      padding: '5px',
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      borderRadius: '100%',
                      height: 'max-content',
                      display: 'flex',
                    }}
                  >
                    <InsertLinkIcon style={{width: '20px', color: 'white'}} />
                  </Box>
                </Box>
                <Box
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <img
                    src={powerBiBg}
                    alt='mspo'
                    style={{
                      objectFit: 'cover',
                      height: '300px',
                      width: '100%',
                    }}
                  />
                </Box>
              </Box>
            </Link>
          </Grid>
          {/* Transformasi FELCRA Berhad */}
          <Grid xs={12} sm={6} lg={3} item sx={{height: 'max-content'}}>
            <Link
              onClick={() =>
                window.open(
                  'https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fsol2.felcra.com.my%2FeFelcra%2Fdev%2Fblog-website-template-173%2Findex.html&data=05%7C01%7Cmuhd.asyraf%40felcra.com.my%7C9f007edd80b247ee682d08db7dbe2396%7Ca012cfa6fef84ebe9caf98077b5f03f8%7C1%7C0%7C638242029090983117%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=pljtzuEfMa2fCswm%2BTLIz6mnnzZXR1O3t8V7soLdwek%3D&reserved=0'
                )
              }
              style={{
                textDecoration: 'none',
                height: 'fit-content',
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: 300,
                  transition: '0.5s',
                  backgroundColor: '#707070',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                  textAlign: 'left',
                  borderRadius: '20px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <Grid
                  container
                  component='main'
                  position='absolute'
                  zIndex={10}
                  bottom='0'
                  p={3}
                >
                  <Grid
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '10px',
                    }}
                    item={true}
                  >
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      spacing={0}
                    >
                      <Box
                        variant='body1'
                        color='white'
                        style={{
                          fontWeight: 'bold',
                          width: '80%',
                          textAlign: 'center',
                          fontSize: '18px',
                        }}
                      >
                        Transformasi FELCRA Berhad
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
                <Box
                  style={{
                    position: 'absolute',
                    background:
                      'linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0))',
                    top: '50%',
                    zIndex: '9',
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                />
                <Box
                  style={{
                    position: 'absolute',
                    padding: '20px',
                    zIndex: '11',
                    top: 0,
                    right: 0,
                  }}
                >
                  <Box
                    style={{
                      padding: '5px',
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      borderRadius: '100%',
                      height: 'max-content',
                      display: 'flex',
                    }}
                  >
                    <InsertLinkIcon style={{width: '20px', color: 'white'}} />
                  </Box>
                </Box>
                <Box
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <img
                    src={transformasiBG}
                    alt='mspo'
                    style={{
                      objectFit: 'cover',
                      height: '300px',
                      width: '100%',
                    }}
                  />
                </Box>
              </Box>
            </Link>
          </Grid>
          <Grid xs={12} sm={6} lg={3} item sx={{height: 'max-content'}}>
            <Link
              onClick={() =>
                window.open(
                  'https://pppls.felcra.com.my/Default.aspx?ReturnUrl=%2f'
                )
              }
              style={{
                textDecoration: 'none',
                height: 'fit-content',
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: 300,
                  transition: '0.5s',
                  backgroundColor: '#707070',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                  textAlign: 'left',
                  borderRadius: '20px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <Grid
                  container
                  component='main'
                  position='absolute'
                  zIndex={10}
                  bottom='0'
                  p={3}
                >
                  <Grid
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '10px',
                    }}
                    item={true}
                  >
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      spacing={0}
                    >
                      <Box
                        variant='body1'
                        color='white'
                        style={{
                          fontWeight: 'bold',
                          width: '90%',
                          textAlign: 'center',
                          fontSize: '18px',
                        }}
                      >
                        Participant, Plantation Profile, Land & Survey (PPPLS)
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
                <Box
                  style={{
                    position: 'absolute',
                    background:
                      'linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0))',
                    top: '50%',
                    zIndex: '9',
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                />
                <Box
                  style={{
                    position: 'absolute',
                    padding: '20px',
                    zIndex: '11',
                    top: 0,
                    right: 0,
                  }}
                >
                  <Box
                    style={{
                      padding: '5px',
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      borderRadius: '100%',
                      height: 'max-content',
                      display: 'flex',
                    }}
                  >
                    <InsertLinkIcon style={{width: '20px', color: 'white'}} />
                  </Box>
                </Box>
                <Box
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <img
                    src={PPPLS}
                    alt='mspo'
                    style={{
                      objectFit: 'cover',
                      height: '300px',
                      width: '100%',
                    }}
                  />
                </Box>
              </Box>
            </Link>
          </Grid>

          <Grid
            xs={12}
            sm={6}
            lg={3}
            item
            sx={{height: 'max-content'}}
            // key={index}
          >
            <Box
              sx={{
                width: '100%',
                height: 300,
                transition: '0.5s',
                backgroundColor: '#323239',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
                textAlign: 'left',
                borderRadius: '20px',
                position: 'relative',
                overflow: 'hidden',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                // alignItems: "center",
                justifyContent: 'center',
              }}
            >
              <Box
                color='white'
                sx={{width: '100%', fontSize: '16px', margin: '5px'}}
              >
                Pengurusan Perladangan dan Pemulihan P&P Felcra Berhad
              </Box>
              {widgetTanaman.map((item, index) => (
                <Box
                  sx={{
                    position: 'relative',
                    margin: '2.5px 0px',
                  }}
                >
                  <Grid
                    container
                    component='main'
                    position='absolute'
                    zIndex={10}
                    bottom='0'
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                      height: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '10px',
                      }}
                    >
                      <Avatar alt='Dummy Avatar' src={item.src} />
                      <Box
                        sx={{
                          marginLeft: '10px',
                        }}
                      >
                        <Box variant='body1' color='white'>
                          {item.name}
                        </Box>
                        <Box variant='body1' color='white'>
                          <b style={{fontSize: '18px'}}>{item.description}</b>
                        </Box>
                      </Box>
                    </Box>
                    {/* <Grid item xs={12}>
                    </Grid> */}
                    {/* <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginTop: "10px",
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}
                      >
                   
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Box variant="body1" color="white">
                        <b style={{ fontSize: "18px" }}>{item.description}</b>
                      </Box>
                    </Grid> */}
                  </Grid>
                  {/* <Box
                    style={{
                      position: "absolute",
                      background:
                        "linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0))",
                      top: "50%",
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                  /> */}
                  <Box
                    style={
                      {
                        // position: "absolute",
                        // top: 0,
                        // bottom: 0,
                        // left: 0,
                        // right: 0,
                      }
                    }
                  >
                    <img
                      src={item.bg}
                      alt={item.name}
                      style={{
                        objectFit: 'cover',
                        height: '75px',
                        width: '100%',
                        borderRadius: '12px',
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
};

export default Main;
