import React, { useEffect, useState } from "react";
import nookies, { destroyCookie } from "nookies";
import axios from "axios";
import "./index.css";
import macWP from "../../assets/images/macWP.jpg";
import iotLogo from "../../assets/images/iotlogo.png";
import { Box, Grid, Modal } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PageNav from "../../components/PageNav";
import { Loader } from "../../components/Loader";
import { ModalUploadIcon } from "../../components/ModalUploadIcon";
import { Language } from "../../context/LanguageContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export const IconSettings = () => {
  const { language, newObj } = Language();
  const [dataType, setDataType] = useState();
  const [iconList, setIconList] = useState();
  const [isLoad, setLoad] = useState(true);
  const [openAddDeviceModal, setOpenAddDeviceModal] = useState(false);
  const [modalSave, setModalSave] = useState(false);
  const [clickData, setClickData] = useState();

  //toggle

  const [selectedPK, setSelectedPK] = useState();
  const [pkCode, setPkCode] = useState();
  const [projectCode, setProjectCode] = useState();

  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const navigate = useNavigate();

  let clickedIconData = iconList?.filter((item) => {
    return item.clicked === true;
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    backgroundColor: "lightgrey",
    boxShadow: 24,
    borderRadius: "10px",
  };
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
  });
  const getAllDataType = async (cat) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/data-type/list`,
        {
          category: cat,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.data.status === 200) {
        const data = res?.data?.data;
        const newData = data.map((item, index) => {
          if (index === 0) return { ...item, clicked: true };
          else return { ...item, clicked: false };
        });
        setDataType(newData);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }

      getAllIcon();
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getAllIcon = async () => {
    try {
      const res = await axios.get(`${apiUrl}/api/icon/list`, {
        headers: {
          Authorization: `Bearer ${tokens_session}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (res.data.status === 200) {
        const data = res?.data?.data || [];

        const newData = data.map((item, index) => {
          return { ...item, clicked: false };
        });
        setIconList(newData);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      setLoad(false);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getAllPkCode = async () => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/iot/pk-code`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      if (res.data.status === 200) {
        setPkCode(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getProjectCodeByPkCode = async (pkCode) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/iot/project-code`,
        { code: pkCode },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.data.status === 200) {
        setProjectCode(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      setLoad(false);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const setIconApi = async (dataTypeId, iconId) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/data-type/set-icon`,
        { id: dataTypeId, icon_id: iconId },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        Swal.fire({
          icon: "success",
          title: newObj.SuccessWord[language],
          text: newObj.IconHasBeenChangedWord[language],
        }).then(() => {
          window.location.reload();
        });
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleObjClick = (index) => {
    const newArr = dataType.map((item, currentIndex) =>
      currentIndex === index
        ? { ...item, clicked: true }
        : { ...item, clicked: false }
    );
    const newArrIcon = iconList.map((item) => ({
      ...item,
      clicked: false,
    }));
    setDataType(newArr);
    setIconList(newArrIcon);
  };

  const handleIconClick = (index) => {
    const newArr = iconList.map((item, currentIndex) =>
      currentIndex === index
        ? { ...item, clicked: true }
        : { ...item, clicked: false }
    );

    const newIcon = newArr[index].iconUrl;
    setClickData({ ...clickData, icon: newIcon });
    setIconList(newArr);
  };

  const handleSave = () => {
    const dataTypedId = clickData.id;
    const newIconId = clickedIconData[0].id;
    setIconApi(dataTypedId, newIconId);
  };

  useEffect(() => {
    if (!pkCode) getAllPkCode();
    getAllDataType("all");
  }, []);

  useEffect(() => {
    if (dataType) {
      const clickDataType = dataType?.filter((item) => {
        return item.clicked === true;
      });

      setClickData(clickDataType[0]);
    }
  }, [dataType]);

  useEffect(() => {
    if (selectedPK) {
      setLoad(true);
      getProjectCodeByPkCode(selectedPK?.code);
    }
  }, [selectedPK]);
  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  if (isLoad) return <Loader />;
  else
    return (
      <>
        <ThemeProvider theme={theme}>
          <Box
            style={{
              backgroundImage: `url(${macWP})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              justifyContent: "center",
              padding: isXsScreen || isSmScreen ? "10px" : "20px 10px 0px 10px",
              // height: "100vh",
            }}
          >
            <PageNav
              title={newObj.IconSettingWord[language]}
              href="/adminpage"
            />

            <Grid
              container
              style={{ paddingBottom: "10px", marginTop: "60px" }}
            >
              <Grid item md={3} xs={12} className="relativeParent">
                <Box className="sideMenu">
                  <div
                    className="removeScrollbar"
                    style={{
                      width: "100%",
                      overflowY: "auto",
                    }}
                  >
                    <div style={{ fontWeight: "bold", padding: " 20px" }}>
                      {newObj.DataTypesWord[language]}
                    </div>

                    <hr />
                    <Box>
                      {dataType.map((obj, index) => (
                        <div style={{ padding: "8px" }} key={obj.id}>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              ...(obj.clicked && {
                                backgroundColor: "#003d6d",
                              }),
                              cursor: "pointer",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                            onClick={() => handleObjClick(index)}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <img
                                src={obj.icon ? obj.icon : iotLogo}
                                alt={"title"}
                                style={{ width: "30px" }}
                              />

                              <div>
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                    ...(obj.clicked
                                      ? { color: "white" }
                                      : { color: "black" }),
                                  }}
                                >
                                  {obj.name}
                                </div>
                              </div>
                            </div>
                          </Box>
                        </div>
                      ))}
                    </Box>
                  </div>
                </Box>
              </Grid>

              <Grid
                item
                md={9}
                xs={12}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  // height: "100vh",
                  // overflow: "hidden",
                  // overflowY: "scroll",
                  // overflowX: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {/* content */}
                <div>
                  {/* top */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #d9d9d9",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        padding: "10px 20px",
                        alignItems: "center",
                        gap: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {newObj.IconWord[language]}
                    </div>

                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          padding: "20px",
                          display: "flex",
                          height: "100%",
                          color: "#468eee",
                        }}
                        onClick={() => setOpenAddDeviceModal(true)}
                      >
                        <AddIcon />
                        <span>{newObj.AddNewIconWord[language]}</span>
                      </div>
                    </div>
                  </div>
                  {/* middle */}
                  <div
                    style={{
                      padding: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {newObj.SelectedIconWord[language]}
                    </div>

                    <div
                      style={{
                        marginTop: "10px",
                        padding: "30px",
                        borderRadius: "10px",
                        backgroundColor: "#003d6d",
                        // width: "max-content",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                        color: "white",
                        gap: "10px",
                        width: "150px",
                      }}
                    >
                      <img
                        src={clickData?.icon ? clickData.icon : iotLogo}
                        alt="iot"
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />{" "}
                      <div
                        style={{
                          fontSize: "10px",
                        }}
                      >
                        {clickData?.name}
                      </div>
                    </div>
                  </div>
                  {/* bottom */}
                  <div
                    style={{
                      borderTop: "1px solid #d9d9d9",
                      padding: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {newObj.AvailableIconsWord[language]}
                    </div>

                    <div
                      style={{
                        marginTop: "10px",
                        // height: "40vh",
                        // overflowY: "scroll",
                      }}
                    >
                      <Grid container>
                        {iconList.map((item, index) => (
                          <Grid
                            item
                            xs={6}
                            md={2}
                            key={index}
                            style={{
                              width: "max-content",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "start",
                              fontWeight: "bold",
                            }}
                          >
                            <Box
                              onClick={() => handleIconClick(index)}
                              style={{
                                width: "70%",
                                ...(item.clicked && {
                                  backgroundColor: "#e1e1e1",
                                }),
                                borderRadius: "10px",
                                padding: "20px 0",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "10px",
                              }}
                            >
                              <img
                                src={item.iconUrl}
                                alt="iot"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                }}
                              />{" "}
                              <div
                                style={{
                                  fontSize: "10px",
                                }}
                              >
                                {item.iconName}
                              </div>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </div>
                </div>

                {/* save button */}
                <div
                  style={{
                    padding: "10px",
                    borderTop: "1px solid #d9d9d9",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <button
                    onClick={handleSave}
                    style={{
                      padding: "10px",
                      border: "black 1px solid",
                      backgroundColor: "transparent",
                      borderRadius: "10px",
                      width: "20%",
                      cursor: "pointer",
                      fontSize: "12px",
                    }}
                  >
                    {newObj.SaveWord[language]}
                  </button>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Modal
            open={openAddDeviceModal}
            onClose={() => setOpenAddDeviceModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div style={style}>
              <ModalUploadIcon
                setOpenAddDeviceModal={setOpenAddDeviceModal}
                modalSave={modalSave}
                setModalSave={setModalSave}
              />
            </div>
          </Modal>
        </ThemeProvider>
      </>
    );
};
