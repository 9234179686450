const LanguageObj = {
  // login page
  LoginWord: ["Login", "Login", "Log masuk"],
  PleaseinsertyourusenameandpasswordtologinWord: [
    "Please insert your usename and password to login",
    "Please insert your usename and password to login",
    "Sila masukkan nama pengguna dan kata laluan anda untuk log masuk",
  ],
  UsernameWord: ["Username", "Username", "Nama pengguna"],
  PasswordWord: ["Password", "Password", "Kata laluan"],
  CurrentPasswordWord: [
    "Current Password",
    "Current Password",
    "Kata Laluan Semasa",
  ],
  NewPasswordWord: ["New Password", "New Password", "Kata Laluan Laharu"],
  ConfirmNewPasswordWord: [
    "Confirm New Password",
    "Confirm New Password",
    "Sahkan Kata Laluan Baharu",
  ],
  NewPasswordMustMatchConfirmPasswordWord: [
    "New Password Must Match Confirm Password",
    "New Password Must Match Confirm Password",
    "Kata Laluan Baharu Mesti Sepadan Dengan Sahkan Kata Laluan",
  ],
  ErrorPasswordWord: [
    "Password must be at least 8 characters and contain at least one letter and one number.",
    "Password must be at least 8 characters and contain at least one letter and one number.",
    "Kata laluan mestilah sekurang-kurangnya 8 aksara dan mengandungi sekurang-kurangnya satu huruf dan satu nombor.",
  ],
  CannotAccessPageWord: [
    "You can't access this page",
    "You can't access this page",
    "Anda tidak boleh mengakses halaman ini",
  ],
  // dashboard
  DashboardWord: ["Dashboard", "Dashboard", "Dashboard"],
  AdministratorWord: ["Administrator", "Administrator", "Pentadbir"],
  GoodAfternoonWord: ["Good Afternoon", "Good Afternoon", "Selamat Petang"],
  GoodMorningWord: ["Good Morning", "Good Morning", "Selamat Pagi"],
  GoodEveningWord: ["Good Evening", "Good Evening", "Selamat Petang"],
  MyProfileWord: ["My Profile", "My Profile", "Profil"],
  SignOutWord: ["Sign Out", "Sign Out", "Log keluar"],
  DigitalMappingWord: [
    "Digital Mapping",
    "Digital Mapping",
    "Pemetaan Digital",
  ],
  LuasBerhasilWord: ["Luas Berhasil", "", "Luas Berhasil"],
  IoTWord: ["IoT", "IoT", "IoT"],
  TemperatureWord: ["Temperature (°C)", "Temperature (°C)", "Suhu (°C)"],
  LintraMaxWord: ["LintraMax", "LintraMax", "LintraMax"],
  PenjualanWord: ["Penjualan", "Sale", "Penjualan"],
  NetWeightWord: ["Net Weight (MT)", "Net Weight (MT)", "Berat Bersih (MT)"],

  PenuaianWord: ["Penuaian", "Harvest", "Penuaian"],
  MThaWord: ["MT/ha", "MT/ha", "MT/ha"],
  SumWord: ["Sum", "", ""],
  SawitWord: ["Sawit", "Palm", "Sawit"],
  PadiWord: ["Padi", "Paddy", "Padi"],
  GetahWord: ["Getah", "Rubber", "Getah"],

  //   profile
  ProfileWord: ["Profile", "Profile", "Profil"],
  ChangeAvatarWord: ["Change Avatar", "Change Avatar", "Tukar avatar"],
  FirstNameWord: ["First Name", "First Name", "Nama Pertama"],
  LastNameWord: ["Last Name", "Last Name", "Nama Akhir"],
  EmailWord: ["Email", "Email", "Emel"],
  ContactNumberWord: ["Contact Number", "Contact Number", "Nombor Telefon"],
  AddUserGroupWord: [
    "Add User Group",
    "Add User Group",
    "Tambah Kumpulan Pengguna",
  ],
  UserGroupWord: ["User Group", "User Group", "Kumpulan Pengguna"],
  ChangePasswordWord: [
    "Change Password",
    "Change Password",
    "Tukar kata laluan",
  ],
  SaveWord: ["Save", "Save", "Simpan"],

  //digital mapping
  MapFilesWord: ["Map Files", "Map Files", "Fail Peta"],
  JumlahKeluasanKawasanWord: [
    "Jumlah Keluasan Kawasan",
    "",
    "Jumlah Keluasan Kawasan",
  ],
  LuasLadangWord: ["Luas Keliling", "", "Luas Keliling"],
  LuasBertanamWord: ["Luas Bertanam", "", "Luas Bertanam"],

  //   lintramax
  WilayahWord: ["Wilayah", "", "Wilayah"],
  PBWWord: ["PBW", "PBW", "PBW"],
  StartDateWord: ["Start Date", "Start Date", "Tarikh Mula"],
  EndDateWord: ["End Date", "End Date", "Tarikh Tamat"],
  DateWord: ["Date", "Date", "Tarikh"],
  LastUpdatedWord: ["Last Updated at", "Last Updated at", "Tarikh Kemaskini"],
  PenuaianWord: ["Penuaian", "Harvest", "Penuaian"],
  FFBWeightNetWeightWord: [
    "FFB Weight / Net Weight",
    "FFB Weight / Net Weight",
    "",
  ],
  TotalWorkerWord: ["Total Worker", "Total Worker", "Jumlah Pekerja"],
  MinMaxWord: ["Min. Max", "Min. Max", ""],
  LokasiWord: ["Lokasi", "Location", "Lokasi"],
  PKKodWord: ["PK Kod", "", "PK Kod"],
  AllWilayahWord: ["All Wilayah", "", ""],
  AllPembeliWord: ["All Pembeli", "", ""],
  HistoricalWord: ["Historical", "Historical", "Bersejarah"],
  MonthlyWord: ["Monthly", "Monthly", "Bulanan"],
  QuarterlyWord: ["Quarterly", "Quarterly", "Suku tahunan"],
  HalfYearlyWord: ["Half Yearly", "Half Yearly", "Separuh tahunan"],
  YearlyWord: ["Yearly", "Yearly", "Tahunan"],
  DownloadReportWord: [
    "Download Report",
    "Download Report",
    "Muat turun Laporan",
  ],

  //   administrator
  DigitalMappingWord: [
    "Digital Mapping",
    "Digital Mapping",
    "Pemetaan Digital",
  ],
  MapFilesWord: ["Map Files", "Map Files", "Fail Peta"],
  UploadMapWord: ["Upload Map", "Upload Map", "Muat Naik Peta"],
  UploadDocumentReportWord: [
    "Upload Document / Report",
    "Upload Document / Report",
    "Muat Naik Dokumen/Laporan",
  ],
  DocumentReportWord: [
    "Document / Report",
    "Document / Report",
    " Dokumen/Laporan",
  ],
  IoTWord: ["IoT", "IoT", "IoT"],
  ManageDevicesWord: ["Manage Devices", "Manage Devices", "Urus Peranti"],
  UploadIoTDataWord: [
    "Upload IoT Data",
    "Upload IoT Data",
    "Muat Naik Data IoT",
  ],
  UploadIoTEventDataWord: [
    "Upload IoT Event Data",
    "Upload IoT Event Data",
    "Muat Naik Data Acara IoT",
  ],
  LintraMaxWord: ["LintraMax", "LintraMax", "LintraMax"],
  UploadPalmOilDataWord: [
    "Upload Palm Oil Data",
    "Upload Palm Oil Data",
    "Muat Naik Data Sawit",
  ],
  UploadPaddyDataWord: [
    "Upload Paddy Data",
    "Upload Paddy Data",
    "Muat Naik Data Padi",
  ],

  UploadRubberDataWord: [
    "Upload Rubber Data",
    "Upload Rubber Data",
    "Muat Naik Data Getah",
  ],
  LintraMaxArchiveFilesWord: [
    "LintraMax Archive Files",
    "LintraMax Archive Files",
    "Fail Arkib LintraMax",
  ],
  IoTArchiveFilesWord: [
    "IoT Archive Files",
    "IoT Archive Files",
    "Fail Arkib IoT",
  ],
  IoTDataWord: ["IoT Data", "IoT Data", "IoT Data"],
  EventDataWord: ["Event Data", "Event Data", "Peristiwa Data"],
  ArchiveWord: ["Archive", "Archive", "Arkib"],
  IconSettingWord: ["Icon Settings", "Icon Settings", "Tetapan Ikon"],
  IconWord: ["Icons", "Icons", "Ikon"],
  SelectedIconWord: ["Selected Icon", "Selected Icon", "Ikon Yang Dipilih"],
  AddNewIconWord: ["Add New Icon", "Add New Icon", "Tambah Ikon Baharu"],
  AvailableIconsWord: ["Available Icons", "Available Icons", "Ikon Tersedia"],
  MapFilesWord: ["Map Files", "Map Files", "Fail Peta"],
  AllMapsWord: ["All Maps", "All Maps", "Semua Peta"],
  PublishedMapWord: ["Published Map", "Published Map", "Peta Diterbitkan"],
  DraftMapWord: ["Draft Map", "Draft Map", "Peta draf"],
  ArchiveWord: ["Archive", "Archive", "Arkib"],
  SearchWord: ["Search", "Search", "Cari"],
  UploadMapWord: ["Upload Map", "Upload Map", "Muat Naik Peta"],
  UploadWord: ["Upload", "Upload", "Muat Naik"],
  ProcessingWord: ["Processing", "Processing", "Pemprosesan"],
  CompletedWord: ["Completed", "Completed", "Siap dibina"],
  DragdropsomefileshereorclicktoselectfilesWord: [
    "Drag and drop some files here, or click to select files",
    "Drag and drop some files here, or click to select files",
    "Seret dan lepaskan beberapa fail di sini atau klik untuk memilih fail",
  ],
  UploadDocumentReportWord: [
    "Upload Document / Report",
    "Upload Document / Report",
    "Muat Naik Dokumen/ Laporan",
  ],
  WilayahWord: ["Wilayah", "", "Wilayah"],
  LokasiWord: ["Lokasi", "Location", "Lokasi"],
  PKKodWord: ["PK Code", "PK Code", "PK Kod"],
  ProjekKodWord: ["Project Code", "Project Code", "Projek Kod"],
  AllWilayahWord: ["All Wilayah", "", ""],
  AllWord: ["All", "", ""],
  AddNewUserWord: ["Add New User", "Add New User", "Tambah Pengguna Baru"],
  EditUserWord: ["Edit User", "Edit User", "Ubah Pengguna"],
  AddNewUserGroupWord: [
    "Add New User Group",
    "Add New User Group",
    "Tambah Kumpulan Pengguna Baharu",
  ],
  EditUserGroupWord: [
    "Edit User Group",
    "Edit User Group",
    "Ubah Kumpulan Pengguna",
  ],
  UserGroupNameWord: [
    "User Group Name",
    "User Group Name",
    "Nama Kumpulan Pengguna",
  ],
  InputDescriptionWord: [
    "Input Description",
    "Input Description",
    "Penerangan Input",
  ],
  AdminAccessWord: ["Admin Access", "Admin Access", "Akses Pentadbir"],
  AssignUserGroupWord: [
    "Assign User Group",
    "Assign User Group",
    "Tetapkan Kumpulan Pengguna",
  ],
  UsersWord: ["Users", "Users", "Senarai Pengguna"],
  AccessWord: ["Access", "Access", "Akses"],
  YourFileWasSuccessfullyAddedWord: [
    "Your file was successfully added",
    "Your file was successfully added",
    "Fail anda telah berjaya ditambahkan",
  ],
  ViewTheChartUploadLintramaxWord: [
    "View the chart",
    "View the chart",
    "Lihat carta",
  ],
  ReturnToTheAdministratorPageWord: [
    "Return to the administrator page",
    "Return to the administrator page",
    "Kembali ke halaman pentadbir",
  ],
  ViewTheDocumentAndReportWord: [
    "View the documents and reports",
    "View the documents and reports",
    "Lihat dokumen dan laporan",
  ],
  YourMapWasSuccessfullyAddedWord: [
    "Your map was successfully added",
    "Your map was successfully added",
    "Peta anda telah berjaya ditambahkan",
  ],
  GoToDocumentsFiles: [
    "Go to documents files",
    "Go to documents files",
    "Pergi ke fail dokumen",
  ],
  PreviewThisMapWord: [
    "Preview this map",
    "Preview this map",
    "Pratonton peta ini",
  ],
  FileUploadedSuccessfully: [
    "File uploaded successfully",
    "File uploaded successfully",
    "Fail berjaya dimuat naik",
  ],
  ConfigureWord: ["Configure", "Configure", "Konfigurasi"],
  EditWord: ["Edit", "Edit", "Ubah"],
  DragAndDropOrBrowseYourFileWord: [
    "Drag and drop, or browse your file",
    "Drag and drop, or browse your file",
    "Sila masukkan fail pilihan anda di sini",
  ],
  DropHereWord: ["Drop here", "Drop here", "Turun sini"],
  NotFoundLocationLintramaxWord: [
    "The location you are looking for was not found. Make sure the location is available in Lintramax",
    "The location you are looking for was not found. Make sure the location is available in Lintramax",
    "Lokasi yang anda cari tidak ditemui. Pastikan lokasi tersedia di Lintramax",
  ],
  NotFoundLintramaxWord: ["Not Found", "Not Found", "Tidak Ditemui"],
  CloseWord: ["Close", "Close", "Menutup"],
  AddNewDeviceWord: [
    "Add New Device",
    "Add New Device",
    "Tambah Peranti Baharu",
  ],
  DeviceCategories: [
    "Device categories",
    "Device categories",
    "Kategori peranti",
  ],
  PleaseCompleteFormIoTWord: [
    "Please complete the form to add a new device",
    "Please complete the form to add a new device",
    "Sila lengkapkan borang untuk menambah peranti baharu",
  ],
  CancelWord: ["Cancel", "Cancel", "Batal"],
  ViewDataWord: ["View Data", "View Data", "Melihat Data"],
  UploadDataWord: ["Upload Data", "Upload Data", "Memuat Naik Data"],
  NewDeviceNameWord: [
    "New Device Name",
    "New Device Name",
    "Nama Peranti Baharu",
  ],
  NoDataWord: ["No Data", "No Data", "Tiada Data"],
  ManageIoTDeviceWord: [
    "Manage IoT Devices",
    "Manage IoT Devices",
    "Urus Peranti IoT",
  ],
  ChooseCategoryUploadIoTWord: [
    "Please specify whether the data file from the Weather Stations or Soil Station?",
    "Please specify whether the data file from the Weather Stations or Soil Station?",
    "Sila nyatakan sama ada fail data daripada Weather Stations atau Soil Station?",
  ],
  MuatTurunLaporanPenuhWord: [
    "Download the full report",
    "Download the full report",
    "Muat turun laporan penuh",
  ],
  DeviceTypeIoTWord: ["Device Type", "Device Type", "Jenis Peranti"],
  DataTerperinciWord: ["Detailed Data", "Detailed Data", "Data Terperinci"],
  DeviceWord: ["Device", "Device", "Peranti"],
  DataTypesWord: ["Data Types", "Data Types", "Jenis Data"],
  DeviceTypeWord: ["Device Types", "Device Types", "Jenis Peranti"],
  PasswordHasBeenChangedWord: [
    "Password has been changed",
    "Password has been changed",
    "Kata laluan telah ditukar",
  ],
  SuccessWord: ["Success", "Success", "Berjaya"],
  ErrorWord: ["Error", "Error", "Kesilapan"],
  UploadingWord: ["Uploading...", "Uploading...", "Memuat naik..."],
  InactiveAccountWord: [
    "This account is inactive, please contact your admin first",
    "This account is inactive, please contact your admin first",
    "Akaun ini tidak aktif, sila hubungi pentadbir anda terlebih dahulu",
  ],
  UserGroupCreatedWord: [
    "User group has been created!",
    "User group has been created!",
    "Kumpulan pengguna telah dibuat!",
  ],
  UserGroupUpatedWord: [
    "User group updated!",
    "User group updated!",
    "Kumpulan pengguna dikemas kini!",
  ],
  SelectDeviceWord: ["Select Device", "Select Device", "Pilih Peranti"],
  IconNameWord: ["Icon Name", "Icon Name", "Nama Ikon"],
  CreatedNewDeviceWord: ["Device Created", "Device Created", "Peranti Dicipta"],
  NewIconAddedWord: [
    "New Icon Added",
    "New Icon Added",
    "Ikon Baharu Ditambah",
  ],
  IconHasBeenChangedWord: [
    "Icon Has Been Changed",
    "Icon Has Been Changed",
    "Ikon Telah Berubah",
  ],
  PasswordNotMatch: [
    "Password Not Match",
    "Password Not Match",
    "Kata Laluan Tidak Padan",
  ],
  DeleteArchiveFileWord: [
    "Delete Archive Files",
    "Delete Archive Files",
    "Padamkan Fail Arkib",
  ],
  MapHasBeenDeleted: [
    "Map Has Been Deleted",
    "Map Has Been Deleted",
    "Peta Telah Dipadamkan",
  ],
  RememberMeWord: ["Remember me", "Remember me", "Ingat akses masuk"],
  ForgotPasswordWord: [
    "Forgot password",
    "Forgot password",
    "Lupa kata laluan",
  ],
  StatusWord: ["Status", "Status", "Status"],
  ExceedDaysIotWord: [
    "Sorry, total days cannot exceed 31 days",
    "Sorry, total days cannot exceed 31 days",
    "Maaf, jumlah hari tidak boleh melebihi 31 hari",
  ],
  WarningWord: ["Warning", "Warning", "Amaran"],
  LastRecordWord: ["Last Record", "Last Record", "Rekod Terakhir"],
  NameWord: ["Name", "Name", "Nama"],
  CreatedInWord: ["Created In", "Created In", "Dicipta Dalam"],
  LongitudeWord: ["Longitude", "Longitude", "Longitud"],
  LatitudeWord: ["Latitude", "Latitude", "Latitud"],
  TotalAreaWord: ["Total Area", "Total Area", "Jumlah Kawasan"],
  HasBeenPublishedWord: [
    "has been published",
    "has been published",
    "telah berjaya diterbit",
  ],
  DeleteWord: ["Delete", "Delete", "Padam"],
  DownloadWord: ["Download", "Download", "Muat Turun"],
  PublishMapWord: ["Publish Map", "Publish Map", "Terbitkan Peta"],
  TimeWord: ["Time", "Time", "Masa"],
  ActionWord: ["Action", "Action", "Tindakan"],
  NoRecordWord: ["No Record", "No Record", "Tiada Catatan"],
  DateAndTimeWord: ["Date And Time", "Date And Time", "Tarikh Dan Waktu"],
};

export default LanguageObj;
