import React, { useState } from "react";
import { Container, useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stepper from "../../components/Stepper";
import DocumentReportDropzone from "../../components/DocumentReportDropzone";
import PageNav from "../../components/PageNav";
import "./UploadPage.scss";
import macWP from "../../assets/images/macWP.jpg";
import docsReport from "../../assets/images/docsReport.png";
import useFindPath from "../../Helpers/useFindPath";
import { Language } from "../../context/LanguageContext";
import "./UploadPage.scss";

export const UploadDocReport = () => {
  const { language, newObj } = Language();
  const [steps, setSteps] = useState(0);
  const path = useFindPath();

  const handleChange = (newValue) => {
    setSteps(newValue);
  };
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
  });
  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundImage: `url(${macWP})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          paddingTop: isXsScreen || isSmScreen ? "10px" : "20px",
        }}
      >
        <PageNav
          title={newObj.UploadDocumentReportWord[language]}
          href="/adminpage"
        />
        <Grid
          sx={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              background: "#EFEFEF",
              backgroundImage: `url(${docsReport})`,
              borderRadius: "10px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "left",
              overflowX: "hidden",
            }}
            className="boxContainer"
          >
            <Box
              sx={{
                background: "#2B5180",
              }}
              className="boxUploadPage"
            >
              <Stepper active={steps} type={path.currentPath} totalSteps={4} />
              <DocumentReportDropzone
                onChange={handleChange}
                sx={{
                  borderRadius: "5px",
                  height: "80%",
                  width: "100%",
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};
