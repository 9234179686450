import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import nookies, { destroyCookie } from "nookies";
import Avatar from "@mui/material/Avatar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

// ICON LIST
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import uploadMapGenerated from "../../assets/images/uploadMapGenerated.svg";

import macWP from "../../assets/images/macWP.jpg";
import { light } from "@mui/material/styles/createPalette";
import { DocumentReportCard } from "../../components/DocumentReportCard";
import axios from "axios";
import { SearchKawasan } from "../../components/SearchKawasan";
import { Loader } from "../../components/Loader";
import { Language } from "../../context/LanguageContext";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

const DocumentReport = () => {
  const { language, newObj } = Language();
  const [doc, setDoc] = useState([]);
  const [PKCode, setPKCode] = useState("");
  const [wilayahKod, setWilayahKod] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoad, setLoad] = useState(true);
  const [projectCode, setProjectCode] = useState("");
  const [count, setCount] = useState(0);
  const [selectedWilayah, setSelectedWilayah] = useState(["All Wilayah"]);
  const [wilayahObj, setWilayahObject] = useState([]);
  const [deleteCount, setDeleteCount] = useState(0);
  const [isSearch, setSearch] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const cookies = nookies.get();
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const tokens_session = cookies.token;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [flagDM, setFlagDM] = useState(true);
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"].join(","),
    },
  });

  // data and function for searchKawasan
  const displayIndex = count > 3 ? 3 : count;

  // const filteredArray = wilayahObj.filter((item) =>
  //   item.title
  //     ? item.title.toLowerCase().includes(searchTerm.toLowerCase())
  //     : item.negeri
  //     ? item.negeri.toLowerCase().includes(searchTerm.toLowerCase())
  //     : item.wilayah.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const emptyObject = () => {
    if (isSearch) getWilayahObject();
    setCount(0);
    setSelectedWilayah(["All Wilayah"]);
    setWilayahKod("");
    setPKCode("");
    setProjectCode("");
    setSearchParams();
  };

  const returnClick = (index) => {
    const params = {};

    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    if (index + 1 === 1) {
      delete params.kodWilayah;
      delete params.kodProjek;
      delete params.kodKawasan;
      setPKCode("");
      setProjectCode("");
      setSearchParams(params);
    } else if (index + 1 === 2) {
      delete params.kodProjek;
      delete params.kodKawasan;
      setPKCode("");
      setProjectCode("");
      setSearchParams(params);
    } else {
      delete params.kodProjek;
      setProjectCode("");
      setSearchParams(params);
    }
    setSelectedWilayah(selectedWilayah.slice(0, index + 2));
    setCount(index + 1);
  };

  const backClick = () => {
    const returnFlag = count > 3 ? 3 : count;
    const params = {};

    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    if (returnFlag === 1) {
      delete params.kodWilayah;
      setSearchParams(params);
    } else if (count === 2) {
      delete params.kodKawasan;
      setSearchParams(params);
    } else {
      delete params.kodProjek;
      setSearchParams(params);
    }
    setWilayahObject([]);
    setSelectedWilayah(selectedWilayah.slice(0, returnFlag));
    setCount(returnFlag - 1);
  };

  const detailArea = async (flag, code) => {
    setLoad(true);
    try {
      const res = await axios.post(
        `${apiUrl}/api/area/detail-area`,
        { flag: flag, code: code },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.status === 200) {
        return res.data.data;
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  function handleSearch(event) {
    setSearchTerm(event);
    searchArea(event);
  }

  const searchArea = async (word) => {
    setLoad(true);
    try {
      const res = await axios.post(
        `${apiUrl}/api/area/search-area`,
        { keyword: word },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setLoad(false);
      if (res.data.status === 200) {
        setSelectedWilayah(["All Wilayah"]);
        setWilayahKod("");
        setPKCode("");
        setCount(0);
        setProjectCode("");
        setSearch(true);
        setWilayahObject(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const onWilayahClick = async (e, id, flag, item) => {
    const objFlag = ["", "negeri", "pk", "project"];
    const index = objFlag.indexOf(flag);
    let obj = e.target.textContent + "$" + id;
    if (isSearch) {
      let returnData = "";
      if (index !== -1) {
        returnData = await detailArea(flag, id);
        const tempSearchParams = {};
        const reversedData = returnData.reverse().map((obj, i) => {
          if (i === 0) tempSearchParams["idWilayah"] = obj?.id;
          else if (i === 1) {
            setWilayahKod(obj?.code);
            tempSearchParams["kodWilayah"] = obj?.code;
          } else if (i === 2) {
            setPKCode(obj?.code);
            tempSearchParams["kodKawasan"] = obj?.code;
          }
          if (obj.code === null) {
            return `${obj.name}$${obj.id}`;
          } else {
            return `${obj.name}$${obj.code}`;
          }
        });
        if (returnData.length === 1) {
          setWilayahKod(id);
          tempSearchParams["kodWilayah"] = id;
        } else if (returnData.length === 2) {
          setPKCode(id);
          tempSearchParams["kodKawasan"] = id;
        } else if (returnData.length === 3) {
          setProjectCode(id);
          tempSearchParams["kodProjek"] = id;
        }
        setSearchParams(tempSearchParams);
        setSelectedWilayah(["All Wilayah", ...reversedData, obj]);
        setCount(index + 1);
      } else {
        setCount(1);
        setSelectedWilayah(["All Wilayah", obj]);
      }
      setSearchTerm();
      setSearch(false);
    } else {
      const params = {};

      for (let [key, value] of searchParams.entries()) {
        params[key] = value;
      }

      let name = item.title;
      if (count === 0) {
        name = item.wilayah;
        setSearchParams({ ...params, idWilayah: id });
      } else if (count === 1) {
        name = item.negeri;
        setSearchParams({ ...params, kodWilayah: id });
      } else if (count === 2) {
        setSearchParams({ ...params, kodKawasan: id });
      } else {
        setSearchParams({ ...params, kodProjek: id });
      }
      let obj = name + "$" + id;

      setSelectedWilayah([...selectedWilayah, obj]);
      if (count < 3) {
        setWilayahObject([]);
      }
      setCount(count + 1);
    }
    setFlagDM(false);
  };

  const getWilayahObject = async () => {
    try {
      const res = await axios.get(
        `${apiUrl}/api/area/wilayah/get-mapping-wilayah`,
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      if (res.data.status === 200) {
        setWilayahObject(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getNegeriByWilayah = async (id) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/area/negeri/get-mapping-negeri-by-wilayah`,
        { wilayah_id: id },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        setWilayahObject(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getDataPKCodeByNegeri = async (id) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/mapping/pk-code/get-by-negeri-new`,
        { flag: "click", code: id, status: "publish" },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.data.status === 200) {
        setWilayahObject(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getDataProjectCodeByPKCode = async (id) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/mapping/project-code/get-by-pk-code-new`,
        { flag: "click", code: id, status: "publish" },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.data.status === 200) {
        setWilayahObject(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getDoc = async (kod_wilayah, pk_code, project_code) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/archive/report/list`,
        {
          pk_code: pk_code,
          kod_wilayah: kod_wilayah,
          project_code: project_code,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        setDoc(res?.data?.data);
      } else if (res.data.status === 500) {
        setDoc([]);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      setLoad(false);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    if (count === 0 && !isSearch) {
      getDoc("all", "all", "all");
      getWilayahObject();
      setPKCode("");
      setProjectCode("");
    } else {
      const id = selectedWilayah[count].split("$")[1];
      if (count === 1) {
        getNegeriByWilayah(id);
        setPKCode("");

        setProjectCode("");
      } else if (count === 2) {
        setWilayahKod(id);
        getDataPKCodeByNegeri(id);
      } else if (count === 3) {
        getDataProjectCodeByPKCode(id);

        setPKCode(id);
      } else if (count >= 4) {
        setProjectCode(id);
      }
    }
  }, [count]);

  useEffect(() => {
    if (
      searchParams.getAll("idWilayah")[0] &&
      searchParams.getAll("kodWilayah")[0] &&
      searchParams.getAll("kodKawasan")[0] &&
      searchParams.getAll("kodProjek")[0] &&
      flagDM === true
    ) {
      const fetchData = async () => {
        const res1 = await getWilayahObject();
        const res2 = await getNegeriByWilayah(
          searchParams.getAll("idWilayah")[0]
        );
        const res3 = await getDataPKCodeByNegeri(
          searchParams.getAll("kodWilayah")[0]
        );
        const res4 = await getDataProjectCodeByPKCode(
          searchParams.getAll("kodKawasan")[0]
        );
        const found1 = res1?.data?.find(
          (el) => el.id === searchParams.getAll("idWilayah")[0]
        );
        const found2 = res2?.data.find(
          (el) => el.code === searchParams.getAll("kodWilayah")[0]
        );
        const found3 = res3?.data.find(
          (el) => el.code === searchParams.getAll("kodKawasan")[0]
        );
        const found4 = res4?.data.find(
          (el) => el.code === searchParams.getAll("kodProjek")[0]
        );
        setSelectedWilayah([
          ...selectedWilayah,
          `${found1?.wilayah}$${found1?.id}`,
          `${found2?.negeri}$${found2?.code}`,
          `${found3?.title}$${found3?.code}`,
          `${found4?.title}$${found4?.code}`,
        ]);
        setCount(4);
        setPKCode(found3?.code);
        setProjectCode(found4?.code);

        setWilayahObject(res4?.data);
      };
      fetchData();
    }
  }, [
    searchParams.getAll("idWilayah")[0],
    searchParams.getAll("kodWilayah")[0],
    searchParams.getAll("kodKawasan")[0],
    searchParams.getAll("kodProjek")[0],
  ]);

  useEffect(() => {
    const w = wilayahKod ? wilayahKod : "all";
    const pk = PKCode ? PKCode : "all";
    const project = projectCode ? projectCode : "all";
    getDoc(w, pk, project);
  }, [PKCode, projectCode, wilayahKod]);

  useEffect(() => {
    getDoc("all", "all", "all");
  }, [deleteCount]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const data = [
    {
      title: "Lokasi",
      name: selectedWilayah[displayIndex]?.split("$")[0],
    },
    { title: "PK Kod", name: PKCode },
    { title: "Projek Kod", name: projectCode },
  ];

  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  // if (isLoad) {
  //   return <Loader />;
  // } else
  return (
    <div>
      {isLoad && (
        <div style={{ position: "absolute" }}>
          <Loader />
        </div>
      )}
      <ThemeProvider theme={theme}>
        {" "}
        <Box
          style={{
            backgroundImage: `url(${macWP})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            padding: isXsScreen || isSmScreen ? "10px" : "20px 10px",
          }}
        >
          {/* nav */}
          <Box className="headerWithButton">
            <Box display="flex">
              <Link
                to="/adminpage"
                style={{
                  textDecoration: "none",
                  alignItems: "center",
                  display: "flex",
                  padding: "8px 16px",
                }}
              >
                <ChevronLeftIcon sx={{ fontSize: "28px", color: "#2B337D" }} />
                <Box
                  sx={{
                    color: "#2B337D",
                    display: "flex",
                    alignItems: "center",
                    margin: "0 !important",
                    fontWeight: "bold !important",
                  }}
                  fontWeight="bold"
                  p={0}
                  ml={3}
                  mb={0}
                >
                  Laporan
                </Box>
              </Link>
            </Box>

            <Box>
              <Link
                to="/uploadreport"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "black !important",
                  marginRight: "10px !important",
                }}
              >
                <Avatar
                  alt="Map Files"
                  sx={{ width: 24, height: 24, marginRight: "10px" }}
                  src={uploadMapGenerated}
                />

                {windowWidth > 600 && newObj.UploadDocumentReportWord[language]}
              </Link>
            </Box>
          </Box>

          <Grid
            container
            columnSpacing={{ xs: 1, sm: 2, md: 1 }}
            style={{
              minHeight: "calc(100vh - 120px)",
              display: "flex",
              marginTop: "60px",
              justifyContent: "center",
            }}
          >
            <Grid item md={3} xs={12} style={{ position: "relative" }}>
              <SearchKawasan
                count={count}
                filteredArray={wilayahObj}
                displayIndex={displayIndex}
                selectedWilayah={selectedWilayah}
                returnClick={returnClick}
                onWilayahClick={onWilayahClick}
                handleSearch={handleSearch}
                backClick={backClick}
                emptyObject={emptyObject}
                windowWidth={windowWidth}
                isLintra={false}
              />
            </Grid>
            <Grid item md={9} xs={12} sx={{}}>
              <Box
                sx={{
                  backgroundColor: "rgba(255,255,255,0.8)",
                  borderRadius: "5px",
                  minHeight: "100vh",
                }}
              >
                <Box
                  sx={{
                    padding: "15px",
                    borderBottom: "1px solid gray",
                    display: "flex",
                    gap: "70px",
                  }}
                >
                  {data.map((item, index) => (
                    <div key={index}>
                      <Box
                        sx={{
                          color: "gray",
                          display: "flex",
                          fontSize: "10px",
                          fontStyle: light,
                        }}
                      >
                        {item.title}
                      </Box>

                      <Box
                        sx={{
                          color: "black",
                          display: "flex",
                          fontSize: "15px",
                          fontWeight: "normal !important",
                          alignItems: "center",
                        }}
                      >
                        {item.name !== "" || item.name ? item.name : "All"}
                      </Box>
                    </div>
                  ))}
                </Box>

                <Box
                  sx={{
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      color: "gray",
                      display: "flex",
                      fontSize: "10px",
                      fontStyle: light,
                      marginBottom: "10px",
                    }}
                  >
                    This Year
                  </Box>

                  <Grid container spacing={0}>
                    {doc.map((item, index) => (
                      <Grid
                        key={index}
                        item
                        xl={3}
                        lg={4}
                        md={6}
                        xs={12}
                        sx={{ padding: "5px" }}
                      >
                        <div key={index}>
                          <DocumentReportCard
                            item={item}
                            deleteCount={() => setDeleteCount(deleteCount + 1)}
                          />
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default DocumentReport;
