import React from "react";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Stack from "@mui/material/Stack";

import locationLogo from "../assets/images/location.svg";
import palmOil from "../assets/images/palmOil.svg";
import getah from "../assets/images/getah.svg";
import padi from "../assets/images/padi.svg";
import luasLadang from "../assets/images/luasLadang.png";
import luasBerhasil from "../assets/images/luasBerhasil.svg";
import luasBertanam from "../assets/images/luasBertanam.svg";
import { numberWithCommas } from "../Helpers/stringFormat";
import { Language } from "../context/LanguageContext";

const DetailData = (props) => {
  const { language, newObj } = Language();

  return (
    <>
      {props.data?.length > 0 && (
        <>
          {props.data.map((data) => (
            <Grid container component="main" key={data.id}>
              <Grid
                container
                mt={2}
                mx={2}
                borderRadius="20px 20px 0px 0px"
                sx={{
                  backgroundColor: "rgba(0,0,0,.2)",
                  height: "max-content",
                }}
              >
                <Grid
                  container
                  component="main"
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  px={2}
                  sx={{
                    paddingY: "10px",
                  }}
                >
                  <Grid
                    item
                    xs={7}
                    //  pb={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Avatar alt="Remy Sharp" src={locationLogo} />
                      <div variant="subtitle1">
                        {data.title} {data?.code}
                      </div>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <AvatarGroup max={4}>
                      {data.jenis_tanaman.sawit == true && (
                        <Avatar alt="Travis Howard" src={palmOil} />
                      )}
                      {data.jenis_tanaman.getah == true && (
                        <Avatar alt="Travis Howard" src={getah} />
                      )}
                      {data.jenis_tanaman.padi == true && (
                        <Avatar alt="Travis Howard" src={padi} />
                      )}
                    </AvatarGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                px={1}
                py={1}
                mx={2}
                sx={{
                  backgroundColor: "rgba(0,0,0,.1)",
                  borderRadius: "0px 0px 20px 20px",
                }}
              >
                <Grid container component="main" p={1} py={0}>
                  <Grid item xs={6} display="flex" alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar
                        alt="Luas Ladang"
                        src={luasLadang}
                        sx={{ width: 24, height: 24 }}
                      />
                      <div style={{ fontSize: "10.5px", padding: "2.5px" }}>
                        {newObj.LuasLadangWord[language]}
                      </div>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} display="flex" alignItems="center">
                    <Stack direction="row" spacing={2} alignItems="center">
                      <div
                        style={{
                          fontSize: "10.5px",
                          padding: "2.5px",
                          fontWeight: "bold",
                        }}
                      >
                        {numberWithCommas(data?.luas_ladang_ha)} ha /{" "}
                        {numberWithCommas(data?.luas_ladang_ac)} ac
                      </div>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container component="main" p={1} py={0}>
                  <Grid item xs={6} display="flex" alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar
                        alt="Luas Bertanam"
                        src={luasBertanam}
                        sx={{ width: 24, height: 24 }}
                      />
                      <div style={{ fontSize: "10.5px", padding: "2.5px" }}>
                        Luas Bertanam
                      </div>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} display="flex" alignItems="center">
                    <Stack direction="row" spacing={2} alignItems="center">
                      <div
                        style={{
                          fontSize: "10.5px",
                          padding: "2.5px",
                          fontWeight: "bold",
                        }}
                      >
                        {numberWithCommas(data?.luas_bertanam)} ha
                      </div>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container component="main" p={1} py={0}>
                  <Grid item xs={6} display="flex" alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar
                        alt="Luas Berhasil"
                        src={luasBerhasil}
                        sx={{ width: 24, height: 24 }}
                      />
                      <div style={{ fontSize: "10.5px", padding: "2.5px" }}>
                        Luas Berhasil
                      </div>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} display="flex" alignItems="center">
                    <Stack direction="row" spacing={2} alignItems="center">
                      <div
                        style={{
                          fontSize: "10.5px",
                          padding: "2.5px",
                          fontWeight: "bold",
                        }}
                      >
                        {numberWithCommas(data?.luas_berhasil)} ha
                      </div>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </>
  );
};

export default DetailData;
