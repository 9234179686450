import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Stepper from "../../components/Stepper";
import MyDropzone from "../../components/IoTDropzone";
import PageNav from "../../components/PageNav";
import "./UploadPage.scss";
import macWP from "../../assets/images/macWP.jpg";
import bgIot from "../../assets/images/Upload-IoT-Data.jpg";
import useFindPath from "../../Helpers/useFindPath";
import { Language } from "../../context/LanguageContext";

export const UploadIoT = () => {
  const { language, newObj } = Language();
  const bgSize = "cover";
  const [steps, setSteps] = useState(0);
  const path = useFindPath();
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
  });
  const handleChange = (newValue) => {
    setSteps(newValue);
  };

  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundImage: `url(${macWP})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingTop: isXsScreen || isSmScreen ? "10px" : "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <PageNav title={newObj.UploadIoTDataWord[language]} href="/adminpage" />
        <Grid
          component="main"
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Box
            sx={{
              background: "#EFEFEF",
              backgroundImage: `url(${bgIot})`,
              borderRadius: "10px",
              backgroundRepeat: "no-repeat",
              backgroundSize: `${bgSize}`,
              backgroundPosition: "left",
              // minHeight: "100vh",
              // marginTop: "60px",
            }}
            className="boxContainer"
          >
            <Box
              sx={{
                background: "#2B5180",
              }}
              className="boxUploadPage"
            >
              <Stepper active={steps} type={path.currentPath} />
              <MyDropzone
                onChange={handleChange}
                sx={{
                  borderRadius: "5px",
                  height: "80%",
                  width: "100%",
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};
