import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useFindPath from "../../Helpers/useFindPath";
import Stepper from "../../components/Stepper";
import MyDropzone from "../../components/Dropzone";
import PageNav from "../../components/PageNav";
import "./UploadPage.scss";

import macWP from "../../assets/images/macWP.jpg";
import mapUpload from "../../assets/images/mapUpload.png";
import lintraDocs from "../../assets/images/lintraDocs.png";
import docsReport from "../../assets/images/docsReport.png";
import bgIot from "../../assets/images/Upload-IoT-Event-Data.jpg";
import { Language } from "../../context/LanguageContext";

const UploadPage = () => {
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
  });
  const { language, newObj } = Language();
  const [title, setTitle] = useState(null);
  const [imageSelected, setImageSelected] = useState(null);
  const [bgSize, setBgSize] = useState(null);
  const [steps, setSteps] = useState(0);
  const path = useFindPath();

  useEffect(() => {
    if (path.currentPath === "/uploadmap") {
      setTitle(newObj.UploadMapWord[language]);
      setImageSelected(mapUpload);
      setBgSize("cover");
    } else if (path.currentPath === "/uploadreport") {
      setTitle(newObj.UploadDocumentReportWord[language]);
      setImageSelected(docsReport);
      setBgSize("contain");
    } else if (path.currentPath === "/uploadpalm") {
      setTitle(newObj.UploadPalmOilDataWord[language]);
      setBgSize("contain");
      setImageSelected(lintraDocs);
    } else if (path.currentPath === "/uploadpaddy") {
      setTitle(newObj.UploadPaddyDataWord[language]);
      setImageSelected(lintraDocs);
      setBgSize("contain");
    } else if (path.currentPath === "/uploadrubber") {
      setTitle(newObj.UploadRubberDataWord[language]);
      setImageSelected(lintraDocs);
      setBgSize("contain");
    } else if (path.currentPath === "/uploadiotevent") {
      setImageSelected(bgIot);
      setBgSize("cover");
      setTitle(newObj.UploadIoTEventDataWord[language]);
    }
  }, [path.currentPath, language]);

  const handleChange = (newValue) => {
    setSteps(newValue);
  };

  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundImage: `url(${macWP})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingTop: isSmScreen || isXsScreen ? "10px" : "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <PageNav title={title} href="/adminpage" />
        <Grid
          component="main"
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Box
            sx={{
              background: "#EFEFEF",
              backgroundImage: `url(${imageSelected})`,
              borderRadius: "10px",
              backgroundRepeat: "no-repeat",
              backgroundSize: `${bgSize}`,
              backgroundPosition: "left",
            }}
            className="boxContainer"
          >
            <Box
              sx={{
                background: "#2B5180",
              }}
              className="boxUploadPage"
            >
              <Stepper active={steps} type={path.currentPath} />
              <MyDropzone
                onChange={handleChange}
                sx={{
                  borderRadius: "5px",
                  height: "80%",
                  width: "100%",
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default UploadPage;
