import React, { useEffect, useRef, useState } from "react";
import nookies, { destroyCookie } from "nookies";
import * as d3 from "d3";
import axios from "axios";
import { Backdrop, Box, CircularProgress, Switch } from "@material-ui/core";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import btmChartDrag from "../assets/images/btmChartDrag.png";
import "./Components.scss";
import moment, { lang } from "moment";
import { numberWithCommas } from "../Helpers/stringFormat";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Language } from "../context/LanguageContext";
import { createTheme, styled, ThemeProvider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DigMap } from "../context/DigMapContext";
import { Lintramax } from "../context/LintramaxContext";
const LintraChart = (props) => {
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"].join(","),
      color: "white",
    },
  });
  const MaterialUISwitch = styled(Switch)(({ checked }) => ({
    "& .MuiSwitch-thumb": {
      color: checked ? "#68aaff" : "dimgray",
    },
    "& .Mui-checked": {
      color: checked ? "#5a7ba6 !important" : "dimgray",
    },
    "& .MuiSwitch-track": {
      backgroundColor: checked ? "#5a7ba6 !important" : "dimgray",
    },
  }));

  const { language, newObj } = Language();
  const { date, setDate, setPlantType } = DigMap();
  const {
    setHasData,
    fromDigitalMap,
    setFromDigitalMap,
    setIsLimitIot,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = Lintramax();
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const windowWidth = props.windowWidth;
  const navigate = useNavigate();
  const [isInit, setIsInit] = useState(false);
  const [isIoT, setIsIoT] = useState(false);
  const [dataTemp, setDataTemp] = useState([]);
  const [dataTop, setDataTop] = useState([]);
  const [dataBot, setDataBot] = useState([]);
  const [openTo, setOpenTo] = useState("day");
  // const [isCalled, setIsCalled] = useState(false);
  const isCalled = useRef();
  const [isLimit, setIsLimit] = useState({ status: false, isFirstTime: false });
  const dataObject = [
    {
      title: newObj.LokasiWord[language],
      name: props.selectedWilayah[props.displayIndex]?.split("$")[0],
    },
    { title: newObj.PKKodWord[language], name: props.PKCode },
    { title: newObj.ProjekKodWord[language], name: props.projectCode },
  ];

  const periodeObject = [
    { name: "Historical", value: "all", id: 1 },
    { name: "Monthly", value: "monthly", id: 2 },
    { name: "Quarterly", value: "quarterly", id: 3 },
    { name: "Half yearly", value: "halfyearly", id: 4 },
    { name: "Yearly", value: "yearly", id: 5 },
  ];

  const [periodeState, setPeriodeState] = useState(periodeObject[0]);
  const [periodClick, setPeriodClick] = useState(false);
  const [isLoad, setLoad] = useState(true);

  const [pembeli, setPembeli] = useState([]);
  const [pembeliClick, setPembeliClick] = useState(false);

  const [prevDate, setPrevDate] = useState({
    prevStartDate: "",
    prevEndDate: "",
  });

  const [startBottomDate, setStartBottomDate] = useState();
  const [endBottomDate, setEndBottomDate] = useState();
  const [selectedPembeli, setSelectedPembeli] = useState({
    name: newObj.AllPembeliWord[language],
    id: "all",
  });
  const [topData, setTopData] = useState([]);
  const chartRef = useRef(null);
  const [iotData, setIotData] = useState({});

  const [line1Visible, setLine1Visible] = useState(true);
  const [line2Visible, setLine2Visible] = useState(true);
  const [line3Visible, setLine3Visible] = useState(true);
  const [line4Visible, setLine4Visible] = useState(true);
  const [line6Visible, setLine6Visible] = useState(true);
  const [line7Visible, setLine7Visible] = useState(true);
  const [line8Visible, setLine8Visible] = useState(true);
  const [toggleStatus, setToggleStatus] = useState({
    toggle1: true,
    toggle2: "checked",
    toggle3: "checked",
    toggle4: "checked",
  });
  const toggleLine1 = () => {
    setToggleStatus({ ...toggleStatus, toggle1: !toggleStatus.toggle1 });
    setLine1Visible(!line1Visible);
  };
  const toggleLine2 = () => {
    setToggleStatus({ ...toggleStatus, toggle2: !toggleStatus.toggle2 });
    setLine2Visible(!line2Visible);
  };
  const toggleLine3 = () => {
    setToggleStatus({ ...toggleStatus, toggle3: !toggleStatus.toggle3 });
    setLine3Visible(!line3Visible);
  };
  const toggleLine4 = () => {
    setToggleStatus({ ...toggleStatus, toggle4: !toggleStatus.toggle4 });
    setLine4Visible(!line4Visible);
  };

  const callDataGraph = async (data) => {
    const formData = new FormData();
    const options = {
      headers: {
        Authorization: `Bearer ${tokens_session}`,
        "Content-Type": "multipart/form-data",
      },
    };
    formData.append("pembeli", selectedPembeli.id);
    formData.append(
      "pk_code",
      fromDigitalMap.status
        ? fromDigitalMap.code.pkCode
        : props.PKCode === ""
        ? "all"
        : props.PKCode
    );
    formData.append(
      "kod_projek",
      fromDigitalMap.status
        ? fromDigitalMap.code.projectCode
        : props.projectCode === ""
        ? "all"
        : props.projectCode
    );
    formData.append(
      "kod_negeri",
      fromDigitalMap.status
        ? fromDigitalMap.code.kodNegeri
        : props.kodNegeri === ""
        ? "all"
        : props.kodNegeri
    );
    formData.append(
      "kod_wilayah",
      fromDigitalMap.status
        ? fromDigitalMap.code.kodWilayah
        : props.wilayahKod === ""
        ? "all"
        : props.wilayahKod
    );
    // formData.append("pk_code", "all");
    // formData.append("kod_projek", "all");
    // formData.append("kod_wilayah", "all");
    formData.append(
      "jenis_tanaman",
      props.jenisTanaman
        .filter((item) => item.selected)
        .map((item) => item.value)
        .join(", ")
    );
    formData.append(
      "account",
      props.account
        .filter((item) => item.selected)
        .map((item) => item.value)
        .join(", ")
    );
    if (periodeState?.value === "all") {
      setLoad(true);
      formData.append("screen", "pc");
      formData.append(
        "firstLoading",
        fromDigitalMap.status ? false : isCalled.current ? false : true
      );
      formData.append("start_date", startDate);
      formData.append("end_date", endDate);
      axios
        .post(
          `${apiUrl}/api/dashboard-lintramax/get-data-graph`,
          formData,
          options
        )
        .then((response) => {
          var responses = response.data.data;
          setDataTop(responses?.graph ? responses?.graph : []);
          const sortedPembeli = responses.pembeli.sort((a, b) =>
            a.namaPembeli > b.namaPembeli
              ? 1
              : b.namaPembeli > a.namaPembeli
              ? -1
              : 0
          );
          setPembeli([
            {
              namaPembeli: "All Pembeli",
              id: "all",
              kodPembeli: "all",
            },
            ...sortedPembeli,
          ]);
          if (responses.start_date) setStartDate(responses?.start_date);
          if (responses.end_date) setEndDate(responses?.end_date);
          if (!isInit)
            setPrevDate({
              ...prevDate,
              prevStartDate: responses?.start_date,
              prevEndDate: responses?.end_date,
            });

          setTopData([
            responses.penjualan,
            responses.penuaian_ffb,
            responses.penuaian_mt,
            responses.total_worker,
          ]);
          setIsInit(true);
          setHasData(true);
          setDate({ startDate: "", endDate: "" });
          setPlantType({});
          setLoad(false);
          if (response.data.status === 401) {
            setHasData(false);
            destroyCookie(null, "token");
            navigate("/login");
          }
        })
        .catch((error) => {
          setDataTop();
          setHasData(false);
          setLoad(false);
          console.error(error);
        });
    } else {
      setLoad(true);
      await handleFilterDateFormat([startDate, endDate]);
      formData.append("historical", periodeState.value);
      // formData.append("start_year", startYear);
      // formData.append("end_year", endYear);
      formData.append("start_date", startDate);
      formData.append("end_date", endDate);
      axios
        .post(
          `${apiUrl}/api/dashboard-lintramax/get-data-graph-using-year`,
          formData,
          options
        )
        .then((response) => {
          var responses = response.data.data;
          setDataTop(responses.graph);
          setTopData([
            responses.penjualan,
            responses.penuaian_ffb,
            responses.penuaian_mt,
            responses.total_worker,
          ]);
          setIsInit(true);
          setHasData(true);
          setLoad(false);
          if (response.data.status === 401) {
            setHasData(false);
            destroyCookie(null, "token");
            navigate("/login");
          }
        })
        .catch((error) => {
          setDataTop();
          setHasData(false);
          console.error(error);
          setLoad(false);
        });
    }
  };

  const callDataGraphBottom = async () => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/lintramax/bottom_chart`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      // setIsCalled(true);
      isCalled.current = true;
      if (res.data.status === 200) {
        setDataBot(res?.data?.data?.graphList);
        setStartBottomDate(res?.data?.data?.start_date.split("-").join("/"));
        setEndBottomDate(res?.data?.data?.end_date.split("-").join("/"));
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      setDataBot();
      throw error;
    }
  };

  const handleDateChange = (date, type) => {
    const dataReceived = date.format("YYYY/MM/DD");
    const momentStart = moment(
      type === "start" ? dataReceived : startDate,
      "YYYY/MM/DD"
    );
    const momentEnd = moment(
      type === "end" ? dataReceived : endDate,
      "YYYY/MM/DD"
    );
    const diffDays = momentEnd.diff(momentStart, "day");
    if (fromDigitalMap.status)
      setFromDigitalMap({
        ...fromDigitalMap,
        status: false,
      });
    if (props.dataTypeIoT.length > 0 && diffDays >= 31) {
      setIsLimitIot(true);
    } else {
      if (type === "start") {
        setPrevDate({ ...prevDate, prevStartDate: startDate });
        setStartDate(dataReceived);
      } else {
        setPrevDate({ ...prevDate, prevEndDate: endDate });
        setEndDate(dataReceived);
      }
      if (diffDays >= 365) {
        if (!isLimit.isFirstTime) {
          setIsLimit({ status: true, isFirstTime: true });
          setPeriodeState(periodeObject[1]);
        } else setIsLimit({ ...isLimit, status: true });
      } else {
        setIsLimitIot(false);
        setIsLimit({ status: false, isFirstTime: false });
      }
    }
  };
  const handleFilterDateChange = (date, type) => {
    const dataReceived = date.format("YYYY/MM/DD");
    const splitDate = dataReceived.split("/");
    const lastDate = function (year, month) {
      return new Date(year, Number(month), 0).getDate();
    };
    if (type === "start") {
      setPrevDate({ ...prevDate, prevStartDate: startDate });
      setStartDate(`${splitDate[0]}/${splitDate[1]}/01`);
    } else {
      setPrevDate({ ...prevDate, prevEndDate: endDate });
      setEndDate(
        `${splitDate[0]}/${splitDate[1]}/${lastDate(
          splitDate[0],
          splitDate[1]
        )}`
      );
    }
  };

  const handleFilterDateFormat = async (date) => {
    const splitDateStart = date[0].split("/");
    const splitDateEnd = date[1].split("/");
    const lastDate = function (year, month) {
      return new Date(year, Number(month), 0).getDate();
    };
    setStartDate(`${splitDateStart[0]}/${splitDateStart[1]}/01`);
    setEndDate(
      `${splitDateEnd[0]}/${splitDateEnd[1]}/${lastDate(
        splitDateEnd[0],
        splitDateEnd[1]
      )}`
    );
  };

  const dateAndTime = (d, t) => {
    var date = moment.utc(d + " " + t);
    var result =
      date.year() +
      "-" +
      `${date.month() + 1}` +
      "-" +
      `${date.date()}` +
      " " +
      `${date.hours()}:${date.minutes()}`;
    return result;
  };

  const dateAndTimeIoT = (time) => {
    var date = new Date(time);
    var result =
      date.getFullYear() +
      "-" +
      `${date.getMonth() + 1}` +
      "-" +
      `${date.getDate()}` +
      " " +
      `${date.getHours()}:${date.getMinutes()}`;
    return result;
  };

  const callIoTDataGraph = async (id, category) => {
    try {
      setLoad(true);
      const res = await axios.post(
        `${apiUrl}/api/iot/get-data-grafik`,
        {
          device_id: id,
          category: category,
          first_load: false,
          start: startDate,
          end: endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.data.status === 200) {
        setDataTemp(res?.data?.data?.data);
        setLoad(false);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (date.startDate && date.endDate) {
      isCalled.current = true;
      setStartDate(date.startDate);
      setEndDate(date.endDate);
      callDataGraphBottom();
      callDataGraph();
      props.dataReturn({
        startDate,
        endDate,
        selectedPembeli,
        periodeState,
        isLoad,
        isIoT,
      });
    } else if (!isCalled.current) {
      callDataGraphBottom();
      callDataGraph();
      props.dataReturn({
        startDate,
        endDate,
        selectedPembeli,
        periodeState,
        isLoad,
        isIoT,
      });
    }
  }, [isCalled.current, date.startDate, date.endDate]);

  useEffect(() => {
    if (props.dataTypeIoT.length > 0) {
      props.handleCallIoTData(true);
      const obj = { ...iotData };
      props.dataTypeIoT.map(async (el, i) => {
        if (
          prevDate.prevStartDate !== startDate ||
          prevDate.prevEndDate !== endDate
        )
          delete obj[el.deviceId];

        if (!obj[el.deviceId]) {
          const res = await callIoTDataGraph(
            el.deviceId,
            props.displayIndex === 3 ? "weather" : "soil"
          );
          obj[el.deviceId] = {
            data: res,
            isLoad: true,
          };
          setPrevDate({
            ...prevDate,
            prevStartDate: startDate,
            prevEndDate: endDate,
          });
        }

        if (i === props.dataTypeIoT.length - 1) {
          await props.handleCallIoTData(false);
          await setIotData(obj);
        }
      });
    } else {
      setIsIoT(false);
      setIotData({});
    }
  }, [props.dataTypeIoT, startDate, endDate]);

  useEffect(() => {
    if (props.displayIndex < 4) {
      setDataTemp([]);
    }
  }, [props.displayIndex]);

  useEffect(() => {
    props.handleLoadingChart(isLoad);
  }, [isLoad]);

  // useEffect(() => {
  // callDataGraph();
  // props.dataReturn({
  //   startDate,
  //   endDate,
  //   startYear,
  //   endYear,
  //   selectedPembeli,
  //   periodeState,
  //   isLoad,
  //   hasData: dataTop.length > 0 ? true : false,
  //   isIoT,
  // });
  // }, []);

  useEffect(() => {
    if (isInit) {
      callDataGraph();
      props.dataReturn({
        startDate,
        endDate,
        selectedPembeli,
        periodeState,
        isLoad,
        // hasData: dataTop.length > 0 ? true : false,
        isIoT,
      });
    }
  }, [
    startDate,
    endDate,
    selectedPembeli,
    props.displayIndex,
    props.account,
    props.jenisTanaman,
    periodeState,
  ]);

  // MAP
  useEffect(() => {
    if (dataTop && dataBot) {
      d3.select(chartRef.current).selectAll("*").remove();
      // Parse the date/time
      // const parseTimeTop = isIoT
      //   ? d3.timeParse("%Y-%m-%d %H:%M")
      //   : d3.timeParse("%Y-%m-%d");
      const parseTimeTop = d3.timeParse("%Y-%m-%d %H:%M");
      const parseTime = d3.timeParse("%Y-%m-%d");
      const svg = d3.select(chartRef.current);
      // Set the dimensions and margins of the graph
      const margin = { top: 20, right: 0, bottom: 110, left: 0 };
      const margin2 = { top: 420, right: 0, bottom: 0, left: 0 };
      const width =
        svg.node().getBoundingClientRect().width - margin.left - margin.right;
      const height = 500 - margin.top - margin.bottom;
      svg.attr("width", width + margin.left + margin.right);
      svg.attr("height", height + margin.top + margin.bottom);
      const height2 = 500 - margin2.top - margin2.bottom;
      // Append the svg object to the chartRef.current
      const parentHeight =
        chartRef.current.parentNode.getBoundingClientRect().height;
      svg.attr("height", parentHeight);
      const parentWidth =
        chartRef.current.parentNode.getBoundingClientRect().width;
      svg.attr("width", parentWidth);
      const longFormat = d3.timeFormat("%b");
      const shortFormat = d3.timeFormat("%d");
      const yearFormat = d3.timeFormat("%Y");
      // Set the ranges
      const x = d3
        .scaleTime()
        .domain(
          d3.extent(dataTop, (d) =>
            parseTimeTop(dateAndTime(d?.date, "00:00:00"))
          )
        )
        .range([0, width]);

      x.ticks(windowWidth < 600 ? 5 : 10);

      const x2 = d3
        .scaleTime()
        .domain(d3.extent(dataBot, (d) => parseTime(d.date)))
        .range([0, width]);

      // x2.ticks(windowWidth < 600 ? 6 : 10);

      const yScales = Array.from({ length: 4 }, () =>
        d3
          .scaleLinear()
          .domain([0, d3.max(dataTop, (d) => d.value)])
          .range([height, 0])
      );

      const [
        y,
        y2,
        y3,
        y4,
        //  y5
      ] = yScales;

      let y6, y7, y8;

      if (props.dataTypeIoT[0]?.deviceId) {
        y6 = d3
          .scaleLinear()
          .domain([
            0,
            d3.max(
              iotData[props.dataTypeIoT[0]?.deviceId]?.data,
              (d) => d?.value
            ),
          ])
          .range([height, 0]);
      }

      if (props.dataTypeIoT[1]?.deviceId)
        y7 = d3
          .scaleLinear()
          .domain([
            0,
            d3.max(
              iotData[props.dataTypeIoT[1]?.deviceId]?.data,
              (d) => d.value
            ),
          ])
          .range([height, 0]);

      if (props.dataTypeIoT[2]?.deviceId)
        y8 = d3
          .scaleLinear()
          .domain([
            0,
            d3.max(
              iotData[props.dataTypeIoT[2]?.deviceId]?.data,
              (d) => d.value
            ),
          ])
          .range([height, 0]);

      const ybScales = Array.from({ length: 4 }, () =>
        d3
          .scaleLinear()
          .domain([0, d3.max(dataBot, (d) => d.value)])
          .range([height2, 0])
      );

      // Accessing the scales
      const [
        yb,
        yb2,
        yb3,
        yb4,

        // yb5
      ] = ybScales;

      // Define the line
      const line1 = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(parseTimeTop(dateAndTime(d?.date, "00:00:00"))))
        .y((d) => y(d.netWeightInMt));

      const line2 = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(parseTimeTop(dateAndTime(d?.date, "00:00:00"))))
        .y((d) => y2(d.ffbWeightInMt));

      const line3 = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(parseTimeTop(dateAndTime(d?.date, "00:00:00"))))
        .y((d) => {
          if (d.ffbWeightInMt === 0 && d.areaHarvestInHa === 0) return y3(0);
          return y3(d.ffbWeightInMt / d.areaHarvestInHa);
        });

      const line4 = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(parseTimeTop(dateAndTime(d?.date, "00:00:00"))))
        .y((d) => y4(d.totalWorker));

      // const line5 = d3
      //   .line()
      //   .curve(d3.curveMonotoneX)
      //   .x((d) => x(parseTimeTop(dateAndTime(d?.date, "00:00:00"))))
      //   .y((d) => y5(d.maxWorker));

      let line6, line7, line8;

      if (props.dataTypeIoT[0]?.deviceId)
        line6 = d3
          .line()
          .curve(d3.curveMonotoneX)
          .x((d) => {
            return x(parseTimeTop(dateAndTimeIoT(d?.time)));
          })
          .y((d) => {
            return y6(d[props.dataTypeIoT[0]?.type]);
          });

      if (props.dataTypeIoT[1]?.deviceId)
        line7 = d3
          .line()
          .curve(d3.curveMonotoneX)
          .x((d) => {
            return x(parseTimeTop(dateAndTimeIoT(d?.time)));
          })
          .y((d) => {
            return y7(d[props.dataTypeIoT[1]?.type]);
          });

      if (props.dataTypeIoT[2]?.deviceId)
        line8 = d3
          .line()
          .curve(d3.curveMonotoneX)
          .x((d) => {
            return x(parseTimeTop(dateAndTimeIoT(d?.time)));
          })
          .y((d) => {
            return y8(d[props.dataTypeIoT[2]?.type]);
          });

      // B LINE

      const line1b = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x2(parseTime(d.date)))
        .y((d) => yb(d.netWeightInMt));

      const line2b = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x2(parseTime(d.date)))
        .y((d) => yb2(d.ffbWeightInMt));

      const line3b = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x2(parseTime(d.date)))
        .y((d) => {
          if (d.ffbWeightInMt === 0 && d.areaHarvestInHa === 0) return yb3(0);
          return yb3(d.ffbWeightInMt / d.areaHarvestInHa);
        });

      const line4b = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x2(parseTime(d.date)))
        .y((d) => yb4(d.totalWorker));

      // const line5b = d3
      //   .line()
      //   .curve(d3.curveMonotoneX)

      //   .x((d) => x2(parseTime(d.date)))
      //   .y((d) => yb5(d.maxWorker));

      // Add the clip path
      svg
        .append("defs")
        .append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("width", width)
        .attr("height", height);

      // Add the main chart area
      const chart = svg
        .append("g")
        .classed("plot", true)
        .attr("class", "chart");

      // Add the brush chart area
      const context = svg
        .append("g")
        .attr("transform", `translate(${margin2.left},${margin2.top})`)
        .attr("class", "context");

      x.domain(
        d3.extent(dataTop, (d) => {
          return parseTimeTop(dateAndTime(d?.date, "00:00:00"));
        })
      );

      const maxY = d3.max(dataTop, (d) => {
        return d.netWeightInMt;
      });
      const maxY2 = d3.max(dataTop, (d) => {
        return d.ffbWeightInMt;
      });
      const maxY3 = d3.max(dataTop, (d) => {
        if (d.ffbWeightInMt === 0 && d.areaHarvestInHa === 0) return 0;
        return d.ffbWeightInMt / d.areaHarvestInHa;
      });
      const maxY4 = d3.max(dataTop, (d) => {
        return d.totalWorker;
      });
      // const maxY5 = d3.max(dataTop, (d) => {
      //   return d.maxWorker;
      // });

      y.domain([0, maxY * 1.1]);
      y2.domain([0, maxY2 * 1.1]);
      y3.domain([0, maxY3 * 1.1]);
      y4.domain([0, maxY4 * 1.1]);
      // y5.domain([0, maxY5 * 1.1]);

      // if (isIoT) {
      let maxY6, maxY7, maxY8;
      let minY6, minY7, minY8;
      if (props.dataTypeIoT[0]?.deviceId) {
        maxY6 = d3.max(iotData[props.dataTypeIoT[0]?.deviceId]?.data, (d) => {
          return d[props?.dataTypeIoT[0]?.type];
        });
        minY6 = d3.min(iotData[props.dataTypeIoT[0]?.deviceId]?.data, (d) => {
          return d[props?.dataTypeIoT[0]?.type];
        });
        y6.domain([minY6, maxY6 * 1.1]);
      }

      if (props.dataTypeIoT[1]?.deviceId) {
        maxY7 = d3.max(iotData[props.dataTypeIoT[1]?.deviceId]?.data, (d) => {
          return d[props?.dataTypeIoT[1]?.type];
        });
        minY7 = d3.min(iotData[props.dataTypeIoT[1]?.deviceId]?.data, (d) => {
          return d[props?.dataTypeIoT[1]?.type];
        });
        y7.domain([minY7, maxY7 * 1.1]);
      }

      if (props.dataTypeIoT[2]?.deviceId) {
        maxY8 = d3.max(iotData[props.dataTypeIoT[2]?.deviceId]?.data, (d) => {
          return d[props?.dataTypeIoT[2]?.type];
        });
        minY8 = d3.min(iotData[props.dataTypeIoT[2]?.deviceId]?.data, (d) => {
          return d[props?.dataTypeIoT[2]?.type];
        });
        y8.domain([minY8, maxY8 * 1.1]);
      }
      // y6.domain(d3.extent((d) => d[props?.dataTypeIoT?.type]));
      // }
      // Batasi rentang sumbu y5 dengan nilai 110% dari maxY

      // Set the domains
      x2.domain(
        d3.extent(dataBot, (d) => {
          return parseTime(d.date);
        })
      );
      yb.domain(d3.extent(dataBot, (d) => d.netWeightInMt));
      yb2.domain(d3.extent(dataBot, (d) => d.ffbWeightInMt));
      yb3.domain(
        d3.extent(dataBot, (d) => {
          if (d.ffbWeightInMt === 0 && d.areaHarvestInHa === 0) return 0;
          return d.ffbWeightInMt / d.areaHarvestInHa;
        })
      );
      yb4.domain(d3.extent(dataBot, (d) => d.totalWorker));
      // yb5.domain(d3.extent(dataBot, (d) => d.maxWorker));

      // BACKGROUND
      // Add a background to the X-axis based on date
      chart
        .append("rect")
        .attr("class", "x-axis-background")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height)
        .style("fill", "#3E3E3E");

      // Add a vertical line for each date on the X-axis
      chart
        .selectAll(".vertical-line-group")
        .data(dataTop)
        .enter()
        .append("g")
        .attr("class", "vertical-line-group")
        .attr(
          "transform",
          (d) =>
            `translate(${x(parseTimeTop(dateAndTime(d?.date, "00:00:00")))},0)`
        )
        .append("line")
        .attr("class", "vertical-line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", 0)
        .attr("y2", height)
        .style("stroke", "#707070")
        .style("stroke-width", 0.3);

      // Add a background to the X-axis based on date
      context
        .append("rect")
        .attr("class", "x-axis-background")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height2)
        .style("fill", "#2A2A2A");

      const visibilities = [
        line1Visible,
        line2Visible,
        line3Visible,
        line4Visible,
        // line4Visible,
      ];
      const lines = [
        line1,
        line2,
        line3,
        line4,
        //  line5
      ];

      lines.forEach((line, i) => {
        chart
          .append("path")
          .datum(dataTop)
          .attr("class", `line line${i + 1}`)
          .attr("d", line)
          .style("display", visibilities[i] ? "block" : "none")
          .on("mousemove", (event) => {
            showData(event);
          })
          .on("mouseout", () => d3.selectAll(".data-label").remove());
      });
      chart
        .append("path")
        .datum(iotData[props.dataTypeIoT[0]?.deviceId]?.data)
        .attr("class", `line line6 ${props?.dataTypeIoT[0]?.type}`)
        .attr("d", line6)
        .style("display", props?.dataTypeIoT[0]?.deviceId ? "block" : "none")
        .on("mousemove", (event) => {
          showData(event);
        })
        .on("mouseout", () => d3.selectAll(".data-label").remove());
      chart
        .append("path")
        .datum(iotData[props.dataTypeIoT[1]?.deviceId]?.data)
        .attr("class", `line line7 ${props?.dataTypeIoT[1]?.type}`)
        .attr("d", line7)
        .style("display", props?.dataTypeIoT[1]?.deviceId ? "block" : "none")
        .on("mousemove", (event) => {
          showData(event);
        })
        .on("mouseout", () => d3.selectAll(".data-label").remove());
      chart
        .append("path")
        .datum(iotData[props.dataTypeIoT[2]?.deviceId]?.data)
        .attr("class", `line line8 ${props?.dataTypeIoT[2]?.type}`)
        .attr("d", line8)
        .style("display", props?.dataTypeIoT[2]?.deviceId ? "block" : "none")
        .on("mousemove", (event) => {
          showData(event);
        })
        .on("mouseout", () => d3.selectAll(".data-label").remove());
      // Add the x axis
      chart
        .append("g")
        .attr("class", "x axis")
        .attr("transform", `translate(0, ${height})`)
        .attr("color", "white")
        .call(
          d3
            .axisBottom(x)
            .tickFormat((d, i) => {
              const ticks = d3.axisBottom(x).scale().ticks();

              if (i > 0 && ticks[i - 1].getMonth() === d.getMonth()) {
                return shortFormat(d);
              } else if (
                i > 0 &&
                ticks[i - 1].getFullYear() !== d.getFullYear()
              ) {
                return yearFormat(d);
              } else {
                return longFormat(d);
              }
            })
            .ticks(windowWidth < 600 ? 5 : 10)
        );

      // Add the y axis
      // Add the y axes to the chart area
      const yAxes = [
        y,
        y2,
        y3,
        y4,
        //  y5
      ];

      yAxes.forEach((yAxis) => {
        chart
          .append("g")
          .attr("class", "y axis")
          .attr("transform", "translate(" + width + ", 0)")
          .call(d3.axisRight(yAxis));
      });
      // Add the area to the brush chart area

      const linesB = [
        line1b,
        line2b,
        line3b,
        line4b,
        //  line5b
      ];

      linesB.forEach((line, i) => {
        context
          .append("path")
          .datum(dataBot)
          .attr("class", `area line${i + 1}b`)
          .attr("d", line)
          .style("fill", "transparent");
      });

      // Finish

      // Add the brush
      const brush = d3
        .brushX()
        .extent([
          [0, 0],
          [width, height2],
        ])
        .on("end", brushedEnd)
        .on("brush", brushed);

      // Add the zoom
      const zoom = d3
        .zoom()
        .scaleExtent([1, Infinity])
        .translateExtent([
          [0, 0],
          [width, height],
        ])
        .extent([
          [0, 0],
          [width, height],
        ])
        .on("zoom", zoomed);

      // Add the x axis to the brush chart area
      context
        .append("g")
        .attr("class", "x axis")
        .attr("transform", `translate(0, ${height2})`)
        .attr("color", "white")
        .call(
          d3
            .axisBottom(x2)
            .tickFormat((d, i) => {
              const ticks = d3.axisBottom(x2).scale().ticks();

              if (i > 0 && ticks[i - 1].getMonth() === d.getMonth()) {
                return shortFormat(d);
              } else if (
                i > 0 &&
                ticks[i - 1].getFullYear() !== d.getFullYear()
              ) {
                return yearFormat(d);
              } else {
                return longFormat(d);
              }
            })
            .ticks(windowWidth < 600 ? 5 : 10)
        );

      // Add the y axis to the brush chart area
      context.append("g").attr("class", "y axis").call(d3.axisLeft(yb));

      // Add the brush to the brush chart area

      // context
      // .append("g")
      // .attr("class", "brush")
      // .call(brush)
      // .call(brush.move, x2.range());
      const startDateBrush =
        parseTime(startDate.split("/").join("-")) <
        d3.min(dataBot, (d) => parseTime(d.date))
          ? d3.min(dataBot, (d) => parseTime(d.date))
          : parseTime(startDate.split("/").join("-"));
      const endDateBrush =
        parseTime(endDate.split("/").join("-")) >
        d3.max(dataBot, (d) => parseTime(d.date))
          ? d3.max(dataBot, (d) => parseTime(d.date))
          : parseTime(endDate.split("/").join("-"));

      context
        .append("g")
        .attr("class", "brush")
        .call(brush)
        .call(brush.move, [x2(startDateBrush), x2(endDateBrush)]);

      context
        .select(".brush")
        .append("image")
        .attr("href", btmChartDrag)
        .attr("class", "leftMarker")
        .attr("x", x2(startDateBrush) - 10) // Adjust the positioning as needed
        .attr("y", 30)
        .attr("width", 20)
        .attr("height", 20)
        .attr("fill", "blue")
        .attr("cursor", "ew-resize");

      context
        .select(".brush")
        .append("image")
        .attr("href", btmChartDrag)
        .attr("class", "rightMarker")
        .attr("x", x2(endDateBrush) - 10) // Adjust the positioning as needed
        .attr("y", 30)
        .attr("width", 20)
        .attr("height", 20)
        .attr("fill", "blue")
        .attr("cursor", "ew-resize");
      context
        .select(".selection")
        // .attr("fill", "red")
        .attr("fill", "rgba(255, 255, 255, 0.8)");

      context.selectAll(".handle").attr("width", 15).raise();
      context.select(".handle--w").attr("x", x2(startDateBrush) - 8);
      context.select(".handle--e").attr("x", x2(endDateBrush) - 8);
      // // Add the zoom to the chart area
      // svg
      //   .append("rect")
      //   .attr("class", "zoom")
      //   .attr("width", width)
      //   .attr("height", height)
      //   .attr("transform", `translate(${margin.left},${margin.top})`)
      //   .style("fill", "transparent")
      //   .call(zoom);

      // hover element
      const lineHover = chart.append("line").style("stroke", "white");

      const circleOrange = chart
        .append("circle")
        .attr("r", 0)
        .attr("fill", "#FFAF02")
        .style("stroke", "white")
        .attr("opacity", 1)
        .style("pointer-events", "none");

      const circlePurple = chart
        .append("circle")
        .attr("r", 0)
        .attr("fill", "#E21BE9")
        .style("stroke", "white")
        .attr("opacity", 1)
        .style("pointer-events", "none");

      const circleGreen = chart
        .append("circle")
        .attr("r", 0)
        .attr("fill", "#50B720")
        .style("stroke", "white")
        .attr("opacity", 1)
        .style("pointer-events", "none");

      const circeBlue = chart
        .append("circle")
        .attr("r", 0)
        .attr("fill", "#95C0ED")
        .style("stroke", "white")
        .attr("opacity", 1)
        .style("pointer-events", "none");

      const circeBlue2 = chart
        .append("circle")
        .attr("r", 0)
        .attr("fill", "#1083fc")
        .style("stroke", "white")
        .attr("opacity", 1)
        .style("pointer-events", "none");

      const circleIotOne = chart
        .append("circle")
        .attr("r", 0)
        // .attr("fill", "#ff0000")
        .attr("class", `${props?.dataTypeIoT[0]?.type}Circle`)
        .style("stroke", "white")
        .attr("opacity", 1)
        .style("pointer-events", "none");

      const circleIotTwo = chart
        .append("circle")
        .attr("r", 0)
        // .attr("fill", "#ff55bb")
        .attr("class", `${props?.dataTypeIoT[1]?.type}Circle`)
        .style("stroke", "white")
        .attr("opacity", 1)
        .style("pointer-events", "none");

      const circleIotThree = chart
        .append("circle")
        .attr("r", 0)
        // .attr("fill", "#f6fa70")
        .attr("class", `${props?.dataTypeIoT[2]?.type}Circle`)
        .style("stroke", "white")
        .attr("opacity", 1)
        .style("pointer-events", "none");

      function showData(event) {
        const x0 = x.invert(d3.pointer(event, this)[0]);
        const bisectDate = d3.bisector((d) =>
          parseTimeTop(dateAndTime(d?.date, "00:00:00"))
        ).left;
        const i = bisectDate(dataTop, x0, 1);
        const d0 = dataTop[i - 1];
        const d1 = dataTop[i];
        const d =
          x0 - parseTimeTop(dateAndTime(d0?.date, "00:00:00")) >
          parseTimeTop(dateAndTime(d1?.date, "00:00:00")) - x0
            ? d1
            : d0;
        const bisectDateIoT = d3.bisector((d) =>
          parseTimeTop(dateAndTimeIoT(d?.time))
        ).left;

        const iIoT = bisectDateIoT(
          iotData[props.dataTypeIoT[0]?.deviceId]?.data.length > 0
            ? iotData[props.dataTypeIoT[0]?.deviceId]?.data
            : [],
          x0,
          1
        );
        const d0IoT = iotData[props.dataTypeIoT[0]?.deviceId]?.data[iIoT - 1];
        const d1IoT = iotData[props.dataTypeIoT[0]?.deviceId]?.data[iIoT];

        const iIoT2 = bisectDateIoT(
          iotData[props.dataTypeIoT[1]?.deviceId]?.data.length > 0
            ? iotData[props.dataTypeIoT[1]?.deviceId]?.data
            : [],
          x0,
          1
        );
        const d0IoT2 = iotData[props.dataTypeIoT[1]?.deviceId]?.data[iIoT2 - 1];
        const d1IoT2 = iotData[props.dataTypeIoT[1]?.deviceId]?.data[iIoT2];

        const iIoT3 = bisectDateIoT(
          iotData[props.dataTypeIoT[2]?.deviceId]?.data.length > 0
            ? iotData[props.dataTypeIoT[2]?.deviceId]?.data
            : [],
          x0,
          1
        );
        const d0IoT3 = iotData[props.dataTypeIoT[2]?.deviceId]?.data[iIoT3 - 1];
        const d1IoT3 = iotData[props.dataTypeIoT[2]?.deviceId]?.data[iIoT3];

        const dIoT =
          x0 - parseTimeTop(dateAndTimeIoT(d0IoT?.time)) >
          parseTimeTop(dateAndTimeIoT(d1IoT?.time)) - x0
            ? d1IoT
            : d0IoT;

        const dIoT2 =
          x0 - parseTimeTop(dateAndTimeIoT(d0IoT2?.time)) >
          parseTimeTop(dateAndTimeIoT(d1IoT2?.time)) - x0
            ? d1IoT2
            : d0IoT2;

        const dIoT3 =
          x0 - parseTimeTop(dateAndTimeIoT(d0IoT3?.time)) >
          parseTimeTop(dateAndTimeIoT(d1IoT3?.time)) - x0
            ? d1IoT3
            : d0IoT3;

        const xPosition = x(parseTimeTop(dateAndTime(d?.date, "00:00:00")));
        const xPositionIoT = x(parseTimeTop(dateAndTimeIoT(dIoT?.time)));
        const xPositionIoT2 = x(parseTimeTop(dateAndTimeIoT(dIoT2?.time)));
        const xPositionIoT3 = x(parseTimeTop(dateAndTimeIoT(dIoT3?.time)));
        const yPosition = y(d.netWeightInMt);
        const yPositionPurple =
          d.ffbWeightInMt === 0 && d.areaHarvestInHa === 0
            ? y3(0)
            : y3(d.ffbWeightInMt / d.areaHarvestInHa);
        const yPositionGreen = y2(d.ffbWeightInMt);
        // const yPositionBlue = y5(d.maxWorker);
        const yPositionBlue2 = y4(d.totalWorker);
        let yPositionIotOne;
        let yPositionIotTwo;
        let yPositionIotThree;
        if (dIoT) yPositionIotOne = y6(dIoT[props?.dataTypeIoT[0]?.type]);
        if (dIoT2) yPositionIotTwo = y7(dIoT2[props?.dataTypeIoT[1]?.type]);
        if (dIoT3) yPositionIotThree = y8(dIoT3[props?.dataTypeIoT[2]?.type]);

        const halfWidth = width / 2;
        // Remove any existing data labels
        d3.selectAll(".data-label").remove();
        // Add a new data label

        const yMaxPosition = setMaxValue(
          yPosition,
          // yPositionBlue,
          yPositionPurple,
          yPositionGreen,
          yPositionBlue2
          // yPositionIotOne,
          // yPositionIotTwo,
          // yPositionIotThree
        );

        function setMaxValue(
          const1,
          const2,
          const3,
          const4
          // const5
          // const6,
          // const7,
          // const8
        ) {
          const maxValue = Math.min(
            const1,
            const2,
            const3,
            const4
            // const5
            // const6,
            // const7,
            // const8
          );
          if (maxValue === 370) return maxValue / 2;
          else return maxValue;
        }

        chart
          .append("rect")
          .attr("class", "data-label")
          .attr("x", xPosition < halfWidth ? xPosition + 20 : xPosition - 170) // set the x position of the rectangle
          .attr("y", yMaxPosition - 40) // set the y position of the rectangle
          .attr("width", 150) // set the width of the rectangle
          .attr("height", dIoT ? 260 : 140) // set the height of the rectangle
          .attr("fill", "rgba(255, 255, 255, 0.7)") // set the fill color of the rectangle
          .attr("rx", 5) // set the x-axis radius of the rectangle
          .attr("ry", 5)
          .raise(); // set the y-axis radius of the rectangle

        let formattedDate;
        if (periodeState.value === "all")
          formattedDate = moment.utc(d.date).format("D MMM YYYY");
        else if (periodeState.value === "monthly")
          formattedDate = moment.utc(d.date).format("MMM YYYY");
        else if (periodeState.value === "quarterly")
          formattedDate = `${moment.utc(d.date).format("MMM")} - ${moment
            .utc(d.date)
            .add(2, "M")
            .format("MMM")} ${moment.utc(d.date).year()}`;
        else if (periodeState.value === "halfyearly")
          formattedDate = `${moment.utc(d.date).format("MMM")} - ${moment
            .utc(d.date)
            .add(5, "M")
            .format("MMM")} ${moment.utc(d.date).year()}`;
        else if (periodeState.value === "yearly")
          formattedDate = moment.utc(d.date).format("YYYY");
        const dateFormatOption = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        };
        const dateFormatLabel = parseTimeTop(
          dateAndTime(d.date, d.time ? d.time : "00:00:00")
        ).toLocaleDateString("en-GB", dateFormatOption);
        let dateFormatLabelIoT;
        let dateFormatLabelIoT2;
        let dateFormatLabelIoT3;

        if (dIoT)
          dateFormatLabelIoT = parseTimeTop(
            dateAndTimeIoT(dIoT.time)
          ).toLocaleDateString("en-GB", dateFormatOption);

        if (dIoT2)
          dateFormatLabelIoT2 = parseTimeTop(
            dateAndTimeIoT(dIoT2.time)
          ).toLocaleDateString("en-GB", dateFormatOption);

        if (dIoT3)
          dateFormatLabelIoT3 = parseTimeTop(
            dateAndTimeIoT(dIoT3.time)
          ).toLocaleDateString("en-GB", dateFormatOption);
        chart
          .append("text")
          .attr("class", "data-label")
          .attr(
            "x",
            xPosition < halfWidth ? xPosition + 120 - 90 : xPosition - 160
          )
          .attr("y", yMaxPosition - 20)
          .attr("text-align", "start")
          .attr("font-size", "20px")
          .attr("font-weight", "bold")
          .attr("fill", "black").text(`${dIoT ? dateFormatLabel : formattedDate}
            `);

        lineHover
          .attr("x1", xPosition)
          .attr("y1", height)
          .attr("x2", xPosition)
          .attr("y2", 0);

        circleOrange.attr("cx", xPosition).attr("cy", yPosition);
        circlePurple.attr("cx", xPosition).attr("cy", yPositionPurple);
        circleGreen.attr("cx", xPosition).attr("cy", yPositionGreen);
        // circeBlue.attr("cx", xPosition).attr("cy", yPositionBlue);
        circeBlue2.attr("cx", xPosition).attr("cy", yPositionBlue2);

        if (dIoT) {
          circleIotOne
            .attr("cx", xPositionIoT)
            .attr("cy", yPositionIotOne)
            .style(
              "display",
              props?.dataTypeIoT[0]?.deviceId ? "block" : "none"
            );
        }

        if (dIoT2) {
          circleIotTwo
            .attr("cx", xPositionIoT2)
            .attr("cy", yPositionIotTwo)
            .style(
              "display",
              props?.dataTypeIoT[1]?.deviceId ? "block" : "none"
            );
        }

        if (dIoT3) {
          circleIotThree
            .attr("cx", xPositionIoT3)
            .attr("cy", yPositionIotThree)
            .style(
              "display",
              props?.dataTypeIoT[2]?.deviceId ? "block" : "none"
            );
        }

        chart
          .append("rect")
          .attr("class", "data-label")
          .attr(
            "x",
            xPosition < halfWidth ? xPosition + 120 - 90 : xPosition - 160
          )
          .attr("y", yMaxPosition - 5)
          .attr("width", 10)
          .attr("height", 10)
          .attr("fill", "#FFAF02")
          .attr("stroke", "white")
          .attr("rx", 5)
          .attr("ry", 5);

        chart
          .append("text")
          .attr("class", "data-label")
          .attr(
            "x",
            xPosition < halfWidth ? xPosition + 120 - 75 : xPosition - 145
          )
          .attr("y", yMaxPosition + 5)
          .attr("text-align", "start")
          .style("font-size", "11px")
          .attr("fill", "black")
          .text(` ${numberWithCommas(d.netWeightInMt.toFixed(2))} MT`);

        chart
          .append("rect")
          .attr("class", "data-label")
          .attr(
            "x",
            xPosition < halfWidth ? xPosition + 120 - 90 : xPosition - 160
          )
          .attr("y", yMaxPosition + 15)
          .attr("width", 10)
          .attr("height", 10)
          .attr("fill", "#50B720")
          .attr("stroke", "white")
          .attr("rx", 5)
          .attr("ry", 5);

        chart
          .append("text")
          .attr("class", "data-label")
          .attr(
            "x",
            xPosition < halfWidth ? xPosition + 120 - 75 : xPosition - 145
          )
          .attr("y", yMaxPosition + 25)
          .attr("text-align", "start")
          .style("font-size", "11px")
          .attr("fill", "black")
          .text(`${numberWithCommas(d.ffbWeightInMt.toFixed(2))} MT`);

        // // dot on the line
        // chart
        //   .append("rect")
        //   .attr("class", "data-label")
        //   .attr("x", xPosition)
        //   .attr("y", y3(d.areaHarvestInHa + 5))
        //   .attr("width", 10)
        //   .attr("height", 10)
        //   .attr("fill", "purple")
        //   .attr("stroke", "white")
        //   .attr("rx", 5)
        //   .attr("ry", 5);

        chart
          .append("rect")
          .attr("class", "data-label")
          .attr(
            "x",
            xPosition < halfWidth ? xPosition + 120 - 90 : xPosition - 160
          )
          .attr("y", yMaxPosition + 35)
          .attr("width", 10)
          .attr("height", 10)
          .attr("fill", "#E21BE9")
          .attr("stroke", "white")
          .attr("rx", 5)
          .attr("ry", 5);
        chart
          .append("text")
          .attr("class", "data-label")
          .attr(
            "x",
            xPosition < halfWidth ? xPosition + 120 - 75 : xPosition - 145
          )
          .attr("y", yMaxPosition + 45)
          .attr("text-align", "start")
          .style("font-size", "11px")
          .attr("fill", "black")
          .text(
            d.ffbWeightInMt === 0 && d.areaHarvestInHa === 0
              ? `0.0 MT/ha`
              : `${numberWithCommas(
                  (d.ffbWeightInMt / d.areaHarvestInHa).toFixed(2)
                )} MT/ha`
          );

        chart
          .append("rect")
          .attr("class", "data-label")
          .attr(
            "x",
            xPosition < halfWidth ? xPosition + 120 - 90 : xPosition - 160
          )
          .attr("y", yMaxPosition + 55)
          .attr("width", 10)
          .attr("height", 10)
          .attr("fill", "#1083fc")
          .attr("stroke", "white")
          .attr("rx", 5)
          .attr("ry", 5);
        chart
          .append("text")
          .attr("class", "data-label")
          .attr(
            "x",
            xPosition < halfWidth ? xPosition + 120 - 75 : xPosition - 145
          )
          .attr("y", yMaxPosition + 65)
          .attr("text-align", "start")
          .style("font-size", "11px")
          .attr("fill", "black")
          .text(`${numberWithCommas(d.totalWorker)} Total Worker`);

        // chart
        //   .append("rect")
        //   .attr("class", "data-label")
        //   .attr(
        //     "x",
        //     xPosition < halfWidth ? xPosition + 120 - 90 : xPosition - 160
        //   )
        //   .attr("y", yMaxPosition + 75)
        //   .attr("width", 10)
        //   .attr("height", 10)
        //   .attr("fill", "#95C0ED")
        //   .attr("stroke", "white")
        //   .attr("rx", 5)
        //   .attr("ry", 5);
        // chart
        //   .append("text")
        //   .attr("class", "data-label")
        //   .attr(
        //     "x",
        //     xPosition < halfWidth ? xPosition + 120 - 75 : xPosition - 145
        //   )
        //   .attr("y", yMaxPosition + 85)
        //   .attr("text-align", "start")
        //   .style("font-size", "11px")
        //   .attr("fill", "black")
        //   .text(`${numberWithCommas(d.totalWorker)} Min`);

        if (dIoT) {
          chart
            .append("text")
            .attr("class", "data-label")
            .attr(
              "x",
              xPosition < halfWidth ? xPosition + 120 - 90 : xPosition - 160
            )
            .attr("y", yMaxPosition + 105)
            .attr("text-align", "start")
            .attr("font-size", "20px")
            .attr("font-weight", "bold")
            .attr("fill", "black").text(`${
            // isIoT
            // ?
            dateFormatLabelIoT
            // : formattedDate
          }
            `);

          chart
            .append("rect")
            .attr("class", `data-label  ${props?.dataTypeIoT[0]?.type}Circle`)
            .attr(
              "x",
              xPosition < halfWidth ? xPosition + 120 - 90 : xPosition - 160
            )
            .attr("y", yMaxPosition + 115)
            .attr("width", 10)
            .attr("height", 10)
            // .attr("fill", "#ff0000")
            .attr("stroke", "white")
            .attr("rx", 5)
            .attr("ry", 5);

          chart
            .append("text")
            .attr("class", "data-label")
            .attr(
              "x",
              xPosition < halfWidth ? xPosition + 120 - 75 : xPosition - 145
            )
            .attr("y", yMaxPosition + 125)
            .attr("text-align", "start")
            .style("font-size", "11px")
            .attr("fill", "black")
            .text(`${dIoT[props.dataTypeIoT[0]?.type]}`);
        }
        if (dIoT2) {
          chart
            .append("text")
            .attr("class", "data-label")
            .attr(
              "x",
              xPosition < halfWidth ? xPosition + 120 - 90 : xPosition - 160
            )
            .attr("y", yMaxPosition + 145)
            .attr("text-align", "start")
            .attr("font-size", "20px")
            .attr("font-weight", "bold")
            .attr("fill", "black").text(`${dateFormatLabelIoT2}
          `);

          chart
            .append("rect")
            .attr("class", `data-label  ${props?.dataTypeIoT[1]?.type}Circle`)
            .attr(
              "x",
              xPosition < halfWidth ? xPosition + 120 - 90 : xPosition - 160
            )
            .attr("y", yMaxPosition + 155)
            .attr("width", 10)
            .attr("height", 10)
            // .attr("fill", "#ff55bb")
            .attr("stroke", "white")
            .attr("rx", 5)
            .attr("ry", 5);

          chart
            .append("text")
            .attr("class", "data-label")
            .attr(
              "x",
              xPosition < halfWidth ? xPosition + 120 - 75 : xPosition - 145
            )
            .attr("y", yMaxPosition + 165)
            .attr("text-align", "start")
            .style("font-size", "11px")
            .attr("fill", "black")
            .text(`${dIoT2[props.dataTypeIoT[1]?.type]}`);
        }
        if (dIoT3) {
          chart
            .append("text")
            .attr("class", "data-label")
            .attr(
              "x",
              xPosition < halfWidth ? xPosition + 120 - 90 : xPosition - 160
            )
            .attr("y", yMaxPosition + 185)
            .attr("text-align", "start")
            .attr("font-size", "20px")
            .attr("font-weight", "bold")
            .attr("fill", "black").text(`${dateFormatLabelIoT3}
        `);

          chart
            .append("rect")
            .attr("class", `data-label  ${props?.dataTypeIoT[2]?.type}Circle`)
            .attr(
              "x",
              xPosition < halfWidth ? xPosition + 120 - 90 : xPosition - 160
            )
            .attr("y", yMaxPosition + 195)
            .attr("width", 10)
            .attr("height", 10)
            // .attr("fill", "#f6fa70")
            .attr("stroke", "white")
            .attr("rx", 5)
            .attr("ry", 5);

          chart
            .append("text")
            .attr("class", "data-label")
            .attr(
              "x",
              xPosition < halfWidth ? xPosition + 120 - 75 : xPosition - 145
            )
            .attr("y", yMaxPosition + 205)
            .attr("text-align", "start")
            .style("font-size", "11px")
            .attr("fill", "black")
            .text(`${dIoT3[props.dataTypeIoT[2]?.type]}`);
        }
      }
      // Add hover effect to lines and chart
      chart.on("mousemove", function (event) {
        lineHover.style("stroke-width", 1);
        circleOrange.attr("r", 5);
        circlePurple.attr("r", 5);
        circleGreen.attr("r", 5);
        circeBlue.attr("r", 5);
        circeBlue2.attr("r", 5);
        // if (isIoT)
        circleIotOne.attr("r", 5);
        circleIotTwo.attr("r", 5);
        circleIotThree.attr("r", 5);
        showData(event);
      });
      chart.on("mouseout", () => {
        d3.selectAll(".data-label").remove();
        lineHover.style("stroke-width", 0);
        circleOrange.attr("r", 0);
        circlePurple.attr("r", 0);
        circleGreen.attr("r", 0);
        circeBlue.attr("r", 0);
        circeBlue2.attr("r", 0);
        // if (isIoT)
        circleIotOne.attr("r", 0);
        circleIotTwo.attr("r", 0);
        circleIotThree.attr("r", 0);
      });

      function brushed(event) {
        const selection = event.selection;
        if (selection) {
          var selectedDates = selection.map(x2.invert);
          const startTempDate = new Date(selectedDates[0]);
          const endTempDate = new Date(selectedDates[1]);

          context
            .select(".leftMarker")
            .attr(
              "x",
              x2(
                parseTime(
                  `${startTempDate.getFullYear()}-${String(
                    startTempDate.getMonth() + 1
                  ).padStart(2, "0")}-${String(
                    startTempDate.getDate()
                  ).padStart(2, "0")}`
                )
              ) - 10
            )
            .attr("y", 30);
          context
            .select(".rightMarker")
            .attr(
              "x",
              x2(
                parseTime(
                  `${endTempDate.getFullYear()}-${String(
                    endTempDate.getMonth() + 1
                  ).padStart(2, "0")}-${String(endTempDate.getDate()).padStart(
                    2,
                    "0"
                  )}`
                )
              ) - 10
            )
            .attr("y", 30);
        }
      }

      function brushedEnd(event) {
        if (!event || !event.sourceEvent || !event.sourceEvent.type === "zoom")
          return;
        // const s = event.selection || x2.range();
        // x.domain(s.map(x2.invert, x2));
        // chart.select(".line1").attr("d", line1);
        // chart.select(".line2").attr("d", line2);
        // chart.select(".line3").attr("d", line3);
        // chart.select(".line4").attr("d", line4);
        // chart.select(".line5").attr("d", line5);
        // chart.select(".line1b").attr("d", line1b);
        // chart.select(".line2b").attr("d", line2b);
        // chart.select(".line3b").attr("d", line3b);
        // chart.select(".line4b").attr("d", line4b);
        // chart.select(".line5b").attr("d", line5b);
        // chart.select(".x.axis").call(d3.axisBottom(x));

        const selection = event.selection;
        // console.log(selection, "selection");
        // setBrushWidth(selection);
        // context
        //   .select(".selection")
        //   .attr("width", brushWidth[1] - brushWidth[0]);
        // let widthBrush = selection[1] - selection[0];
        // brushGroup.select(".selection").attr("width", widthBrush);

        // Get the brush element
        // const brushElement = d3.select(".brush");

        // Get the current brush selection coordinates
        // const brushSelection = d3.brushSelection(brushElement.node());

        // Extract individual coordinates
        // const [x0, y0, x1, y1] = brushSelection;
        // console.log(x0, y0, x1, y1, "coordinate");
        if (selection) {
          var selectedDates = selection.map(x2.invert);
          let newObjDate = [];
          selectedDates.map((item, index) => {
            // Create a new Date object from the original date string
            const dateObj = new Date(item);

            // Extract the individual components of the date
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, "0");
            const day = String(dateObj.getDate()).padStart(2, "0");

            // Create the desired date string in the format "MM/DD/YYYY"
            const newDate = `${year}/${month}/${day}`;

            newObjDate[index] = newDate;
            // Log the selected dates
          });
          const momentStart = moment(newObjDate[0], "YYYY/MM/DD");
          const momentEnd = moment(newObjDate[1], "YYYY/MM/DD");
          const diffDays = momentEnd.diff(momentStart, "day");

          if (props.dataTypeIoT.length > 0 && diffDays >= 31) {
            setIsLimitIot(true);
          } else if (diffDays >= 365 && periodeState?.value === "all") {
            if (!isLimit.isFirstTime) {
              setIsLimit({ status: true, isFirstTime: true });
              setPeriodeState(periodeObject[1]);
            } else setIsLimit({ ...isLimit, status: true });
          } else {
            setIsLimitIot(false);
            setIsLimit({ status: false, isFirstTime: false });
          }

          if (props.dataTypeIoT.length > 0 && diffDays >= 31) {
            context.select(".brush").remove();
            context
              .append("g")
              .attr("class", "brush")
              .call(brush)
              .call(brush.move, [x2(startDateBrush), x2(endDateBrush)]);

            context
              .select(".brush")
              .append("image")
              .attr("href", btmChartDrag)
              .attr("class", "leftMarker")
              .attr("x", x2(startDateBrush) - 10) // Adjust the positioning as needed
              .attr("y", 30)
              .attr("width", 20)
              .attr("height", 20)
              .attr("fill", "blue")
              .style("cursor", "ew-resize");

            context
              .select(".brush")
              .append("image")
              .attr("href", btmChartDrag)
              .attr("class", "rightMarker")
              .attr("x", x2(endDateBrush) - 10) // Adjust the positioning as needed
              .attr("y", 30)
              .attr("width", 20)
              .attr("height", 20)
              .attr("fill", "blue")
              .style("cursor", "ew-resize");

            context
              .select(".selection")
              // .attr("fill", "red")
              .attr("fill", "rgba(255, 255, 255, 0.8)");

            context.selectAll(".handle").attr("width", 15).raise();
            context.select(".handle--w").attr("x", x2(startDateBrush) - 8);
            context.select(".handle--e").attr("x", x2(endDateBrush) - 8);
          } else if (periodeState?.value !== "all" || diffDays >= 365) {
            setPrevDate({
              ...prevDate,
              prevStartDate: startDate,
              prevEndDate: endDate,
            });
            handleFilterDateFormat(newObjDate);
          } else {
            setPrevDate({
              ...prevDate,
              prevStartDate: startDate,
              prevEndDate: endDate,
            });
            setStartDate(newObjDate[0]);
            setEndDate(newObjDate[1]);
          }
          if (fromDigitalMap.status)
            setFromDigitalMap({
              ...fromDigitalMap,
              status: false,
            });
        }
      }

      function zoomed(event) {
        if (!event || !event.sourceEvent || !event.sourceEvent.type === "brush")
          return;
        const t = event.transform;
        x.domain(t.rescaleX(x2).domain());
        chart.select(".line1").attr("d", line1);
        chart.select(".line2").attr("d", line2);
        chart.select(".line3").attr("d", line3);
        chart.select(".line4").attr("d", line4);
        // chart.select(".line5").attr("d", line5);
        chart.select(".line1b").attr("d", line1b);
        chart.select(".line2b").attr("d", line2b);
        chart.select(".line3b").attr("d", line3b);
        chart.select(".line4b").attr("d", line4b);
        // chart.select(".line5b").attr("d", line5b);
        chart.select(".x.axis").call(d3.axisBottom(x));
        context.select(".brush").call(brush.move, x.range().map(t.invertX, t));

        // Update the position of the vertical lines based on the new x scale
        chart
          .selectAll(".vertical-line-group")
          .attr("transform", (d) => `translate(${x(parseTime(d.date))},0)`);
      }
      // setLoad(false);
    }
  }, [
    line1Visible,
    line2Visible,
    line3Visible,
    line4Visible,
    iotData,
    dataTop,
    windowWidth,
  ]);

  return (
    <ThemeProvider theme={theme} style={{ position: "relative" }}>
      <div className="chart-container">
        {" "}
        <div>
          <Box
            style={{
              display: "flex",
              ...(windowWidth > 1000
                ? {
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }
                : { flexDirection: "column" }),
              backgroundColor: "#4D4D4D",
              borderBottom: "white solid 1px",
            }}
          >
            {/* date */}
            <div
              style={{
                padding: "10px 15px 10px 15px",
                display: "flex",
                alignItems: "center",
                color: "white",
                ...(windowWidth > 1000 ? { width: "40%" } : { width: "100%" }),
              }}
            >
              <CalendarMonthIcon />

              <div
                style={{
                  display: "flex",
                  ...(windowWidth >= 1000
                    ? { justifyContent: "space-between" }
                    : { justifyContent: "start" }),
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  sx={{ border: "1px solid white" }}
                >
                  <MobileDatePicker
                    autoFocus={"true"}
                    views={
                      periodeState?.value === "all"
                        ? ["day", "month", "year"]
                        : periodeState?.value === "yearly"
                        ? ["year"]
                        : ["month", "year"]
                    }
                    label="Start Date"
                    onAccept={(date) => {
                      periodeState.value === "all"
                        ? handleDateChange(date, "start")
                        : handleFilterDateChange(date, "start");
                    }}
                    value={moment(startDate, "YYYY/MM/DD")}
                    minDate={moment(startBottomDate, "YYYY/MM/DD")}
                    maxDate={moment(endBottomDate, "YYYY/MM/DD")}
                    onViewChange={(view) => {
                      setOpenTo(view);
                    }}
                    onClose={() => {
                      periodeState.value === "all"
                        ? setOpenTo("day")
                        : periodeState.value === "yearly"
                        ? setOpenTo("year")
                        : setOpenTo("month");
                    }}
                    openTo={openTo}
                    onYearChange={(date) => {
                      periodeState.value === "all"
                        ? handleDateChange(date, "start")
                        : handleFilterDateChange(date, "start");
                    }}
                    closeOnSelect={false}
                    format={
                      periodeState?.value === "all"
                        ? "MM/DD/YYYY"
                        : periodeState?.value === "monthly" ||
                          periodeState?.value === "quarterly" ||
                          periodeState?.value === "halfyearly"
                        ? "MMM YYYY"
                        : "YYYY"
                    }
                  />
                </LocalizationProvider>
                <span
                  style={{
                    margin: "0 5px",
                    color: "white",
                    fontWeight: "600",
                  }}
                >
                  -
                </span>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  sx={{ border: "1px solid white" }}
                >
                  <MobileDatePicker
                    autoFocus="true"
                    label="End Date"
                    views={
                      periodeState?.value === "all"
                        ? ["day", "month", "year"]
                        : periodeState?.value === "yearly"
                        ? ["year"]
                        : ["month", "year"]
                    }
                    onAccept={(date) =>
                      periodeState.value === "all"
                        ? handleDateChange(date, "end")
                        : handleFilterDateChange(date, "end")
                    }
                    onClose={() => {
                      periodeState.value === "all"
                        ? setOpenTo("day")
                        : periodeState.value === "yearly"
                        ? setOpenTo("year")
                        : setOpenTo("month");
                    }}
                    openTo={openTo}
                    onYearChange={(date) => {
                      periodeState.value === "all"
                        ? handleDateChange(date, "end")
                        : handleFilterDateChange(date, "end");
                    }}
                    value={moment(endDate, "YYYY/MM/DD")}
                    minDate={moment(startBottomDate, "YYYY/MM/DD")}
                    maxDate={moment(endBottomDate, "YYYY/MM/DD")}
                    format={
                      periodeState?.value === "all"
                        ? "MM/DD/YYYY"
                        : periodeState?.value === "monthly" ||
                          periodeState?.value === "quarterly" ||
                          periodeState?.value === "halfyearly"
                        ? "MMM YYYY"
                        : "YYYY"
                    }
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                ...(windowWidth < 900
                  ? { width: "100%", flexDirection: "column" }
                  : windowWidth > 900 && windowWidth <= 1000
                  ? { width: "100%", flexDirection: "row" }
                  : { flexDirection: "row", width: "60%" }),
              }}
            >
              <div style={{ display: "flex", width: "100%" }}>
                {/* penjualan net weight mt */}
                <Box
                  onClick={toggleLine1}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "grey solid 1px",
                    ...(windowWidth < 900
                      ? { width: "50%" }
                      : // : windowWidth >= 960 && windowWidth <= 1000
                        // ? { width: "100%" }
                        { width: "100%" }),
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px  0 0 10px",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ color: "white" }}>
                      <div
                        style={{
                          ...(windowWidth > 1536
                            ? { fontSize: "12px" }
                            : { fontSize: "9px" }),
                          fontWeight: "bold",
                        }}
                      >
                        {newObj.PenjualanWord[language]}
                      </div>
                      <div
                        style={{
                          color: "lightgrey",
                          ...(windowWidth > 1536
                            ? { fontSize: "12px" }
                            : { fontSize: "9px" }),
                        }}
                      >
                        {newObj.NetWeightWord[language]}
                      </div>
                      <div
                        style={{
                          ...(windowWidth > 1536
                            ? { fontSize: "12px" }
                            : { fontSize: "9px" }),
                          fontWeight: "bold",
                        }}
                      >
                        {topData && <>{topData[0]}</>}
                      </div>
                    </div>

                    <div
                      style={{
                        rotate: "270deg",
                        scale: "80%",
                      }}
                    >
                      <MaterialUISwitch checked={toggleStatus.toggle1} />
                    </div>
                  </div>
                  <div style={{ padding: "2px", width: "100%" }}>
                    <hr size="5px" width="100%" color="#ffaf02" />
                  </div>
                </Box>
                {/* penuaian ffb weight/net weight */}
                <Box
                  onClick={toggleLine2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "grey solid 1px",
                    ...(windowWidth < 900
                      ? { width: "50%" }
                      : // : windowWidth >= 960 && windowWidth <= 1000
                        // ? { width: "50%" }
                        { width: "100%" }),
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px  0 0 10px",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ color: "white" }}>
                      <div
                        style={{
                          ...(windowWidth > 1536
                            ? { fontSize: "12px" }
                            : { fontSize: "9px" }),
                          fontWeight: "bold",
                        }}
                      >
                        {newObj.PenuaianWord[language]}
                      </div>
                      <div
                        style={{
                          color: "lightgrey",
                          ...(windowWidth > 1536
                            ? { fontSize: "12px" }
                            : { fontSize: "9px" }),
                        }}
                      >
                        {newObj.FFBWeightNetWeightWord[language]}
                      </div>
                      <div
                        style={{
                          ...(windowWidth > 1536
                            ? { fontSize: "12px" }
                            : { fontSize: "9px" }),
                          fontWeight: "bold",
                        }}
                      >
                        {topData && <>{topData[1]}</>}
                      </div>
                    </div>

                    <div
                      style={{
                        rotate: "270deg",
                        scale: "80%",
                      }}
                    >
                      <MaterialUISwitch checked={toggleStatus.toggle2} />
                    </div>
                  </div>
                  <div style={{ padding: "2px", width: "100%" }}>
                    <hr height="5px" width="100%" color="#50b720" />
                  </div>
                </Box>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                {/* penjualan m1/ha */}
                <Box
                  onClick={toggleLine3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "grey solid 1px",
                    ...(windowWidth < 900
                      ? { width: "50%" }
                      : // : windowWidth >= 960 && windowWidth <= 1000
                        // ? { width: "50%" }
                        { width: "100%" }),
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px  0 0 10px",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ color: "white" }}>
                      <div
                        style={{
                          ...(windowWidth > 1536
                            ? { fontSize: "12px" }
                            : { fontSize: "9px" }),
                          fontWeight: "bold",
                        }}
                      >
                        {newObj.PenuaianWord[language]}
                      </div>
                      <div
                        style={{
                          color: "lightgrey",
                          ...(windowWidth > 1536
                            ? { fontSize: "12px" }
                            : { fontSize: "9px" }),
                        }}
                      >
                        {newObj.MThaWord[language]}
                      </div>
                      <div
                        style={{
                          ...(windowWidth > 1536
                            ? { fontSize: "12px" }
                            : { fontSize: "9px" }),
                          fontWeight: "bold",
                        }}
                      >
                        {topData && <>{topData[2]}</>}
                      </div>
                    </div>

                    <div
                      style={{
                        rotate: "270deg",
                        scale: "80%",
                      }}
                    >
                      <MaterialUISwitch checked={toggleStatus.toggle3} />
                    </div>
                  </div>
                  <div style={{ padding: "2px", width: "100%" }}>
                    <hr height="5px" width="100%" color="#E21BE9" />
                  </div>
                </Box>
                {/* total worker */}
                <Box
                  onClick={toggleLine4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "grey solid 1px",
                    ...(windowWidth < 900
                      ? { width: "50%" }
                      : // : windowWidth >= 960 && windowWidth <= 1000
                        // ? { width: "50%" }
                        { width: "100%" }),
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px  0 0 10px",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ color: "white" }}>
                      <div
                        style={{
                          ...(windowWidth > 1536
                            ? { fontSize: "12px" }
                            : { fontSize: "9px" }),
                          fontWeight: "bold",
                        }}
                      >
                        {newObj.TotalWorkerWord[language]}
                      </div>
                      <div
                        style={{
                          color: "lightgrey",
                          ...(windowWidth > 1536
                            ? { fontSize: "12px" }
                            : { fontSize: "9px" }),
                        }}
                      >
                        {newObj.SumWord[language]}
                      </div>
                      <div
                        style={{
                          ...(windowWidth > 1536
                            ? { fontSize: "12px" }
                            : { fontSize: "9px" }),
                          fontWeight: "bold",
                        }}
                      >
                        {topData && <>{topData[3]}</>}
                      </div>
                    </div>

                    <div
                      style={{
                        rotate: "270deg",
                        scale: "80%",
                      }}
                    >
                      <MaterialUISwitch checked={toggleStatus.toggle4} />
                    </div>
                  </div>
                  <div style={{ padding: "2px", width: "100%" }}>
                    <hr height="5px" width="100%" color="#1083FC" />
                  </div>
                </Box>
              </div>
            </div>
          </Box>

          <Box
            style={{
              display: "flex",
              ...(windowWidth >= 900
                ? { justifyContent: "space-between", flexDirection: "row" }
                : { flexDirection: "column" }),
              backgroundColor: "#424242",
              alignItems: "center",
              padding: "15px",
            }}
          >
            <Box
              style={{
                display: "flex",
                gap: "70px",
                color: "white",
                ...(windowWidth <= 900 && { padding: "10px", width: "100%" }),
              }}
            >
              {dataObject.map((item, index) => (
                <div key={index}>
                  <Box
                    style={{
                      display: "flex",
                      fontSize: "10px",
                      fontStyle: "light",
                    }}
                  >
                    {item.title}
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "normal !important",
                      alignItems: "center",
                    }}
                  >
                    {item.name !== "" || item.name ? item.name : "All"}
                  </Box>
                </div>
              ))}
            </Box>

            <Box
              style={{
                display: "flex",
                gap: "10px",
                position: "relative",
                ...(windowWidth < 900 && { padding: "0 10px", width: "100%" }),
              }}
            >
              <Box
                style={{
                  minWidth: "200px",
                  backgroundColor: "rgb(79, 79, 79)",
                  borderRadius: "5px",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setPembeliClick(!pembeliClick)}
              >
                {selectedPembeli.name}
                {selectedPembeli.id === "all" ? "" : `(${selectedPembeli?.id})`}
                <ExpandMoreIcon style={{ width: "20px" }} />
              </Box>
              {pembeliClick && (
                <Box
                  style={{
                    position: "absolute",
                    borderRadius: "5px",
                    backgroundColor: "rgb(79, 79, 79)",
                    zIndex: "1000",
                    ...(windowWidth <= 900 ? { left: "10px" } : { right: "0" }),
                    height: "500px",
                    overflowX: "scroll",
                    top: "60px",
                    padding: "10px",
                  }}
                >
                  {pembeli.map((obj, index) => (
                    <div
                      key={index}
                      id={obj.id}
                      style={{
                        display: "flex",
                        color: "white",
                        padding: "6px",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setPembeliClick(!pembeliClick);
                        setSelectedPembeli({
                          name: obj.namaPembeli,
                          id: obj.id,
                        });
                      }}
                    >
                      {obj.namaPembeli}{" "}
                      {obj?.id === "all" ? "" : `(${obj?.id})`}
                    </div>
                  ))}
                </Box>
              )}

              <Box
                style={{
                  backgroundColor: "rgb(79, 79, 79)",
                  borderRadius: "5px",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setPeriodClick(!periodClick)}
              >
                {periodeState.name}
                <ExpandMoreIcon style={{ width: "20px" }} />
              </Box>
              {periodClick && (
                <Box
                  style={{
                    position: "absolute",
                    borderRadius: "5px",
                    backgroundColor: "rgb(79, 79, 79)",
                    zIndex: "1000",
                    ...(windowWidth <= 900
                      ? { left: "220px" }
                      : { right: "0" }),
                    top: "60px",
                    padding: "10px",
                  }}
                >
                  {periodeObject.map((obj, index) => (
                    <div
                      key={index}
                      id={index}
                      style={{
                        display: "flex",
                        color: "white",
                        padding: "6px",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setPeriodClick(!periodClick);
                        setPeriodeState(periodeObject[e.target.id]);
                      }}
                    >
                      {obj.name}
                    </div>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </div>
        <div>
          {isLoad === true && (
            <>
              <div
                style={{
                  position: "absolute",
                  zIndex: "1000",
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  width: "100%",
                  height: "530px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            </>
          )}
          {dataTop ? (
            <>
              <svg
                ref={chartRef}
                className="asuchart"
                style={{
                  height: "530px",
                  backgroundColor: "#3E3E3E",
                  width: "100%",
                }}
              ></svg>
            </>
          ) : (
            <div
              style={{
                height: "530px",
                backgroundColor: "#3E3E3E",
                width: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
              }}
            >
              {newObj.NoDataWord[language]}
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default LintraChart;
