import { useEffect, useState } from "react";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Avatar,
  Grid,
  Checkbox,
} from "@material-ui/core";
import zip from "../assets/images/qgisZip.png";
import word from "../assets/images/word.png";
import excel from "../assets/images/excel.png";
import acrobat from "../assets/images/Icon.png";
import csv from "../assets/images/csv.png";
import CircleIcon from "@mui/icons-material/Circle";

function MapFilesSelection(props) {
  const [data, setData] = useState(props.data);

  const filteredArray = data.filter((item) =>
    item.name.toLowerCase().includes(props.search.toLowerCase())
  );
  const setImage = (src) => {
    if (src === "zip") {
      return zip;
    } else if (src === "docx" || src === "doc") {
      return word;
    } else if (src === "xlsx" || src === "xls") {
      return excel;
    } else if (src === "pdf ") {
      return acrobat;
    } else if (src === "csv") {
      return csv;
    } else return zip;
  };

  const handleChange = (event) => {
    const { id } = event.target;

    setData(
      data.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  useEffect(() => {
    props.onSelectionChange(data);
  }, [data]);

  return (
    <Grid container spacing={2}>
      {filteredArray.map((option) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={option.label}>
          <div
            style={{
              background: "#4D4D4D",
              color: " white",
              width: "100%",
              margin: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <img
                src={setImage(option.fileExt)}
                alt={option.name}
                style={{ height: "60px", aspectRatio: 1 }}
              />
              <div
                className="textWrapper"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "center",
                  textAlign: "start",
                }}
              >
                <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                  {option.name}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {option.createdAt}
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#6b6b6b",
                padding: "14px 7px",
                borderRadius: "0 10px 10px  0 ",
              }}
            >
              <Checkbox
                checked={option.checked}
                onClick={(e) => handleChange(e)}
                id={option.id}
                icon={
                  <CircleIcon
                    style={{ color: "white", width: "20px", height: "20px" }}
                  />
                }
                checkedIcon={
                  <CircleIcon
                    style={{
                      color: "blue",
                      border: "white 5px solid",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                }
                color="primary"
                shape="circle"
              />
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

export default MapFilesSelection;
