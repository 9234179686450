import React, { useEffect, useRef, useState } from "react";
import nookies from "nookies";
import * as d3 from "d3";
import axios from "axios";
const IoTWidget = (props) => {
  const chartRef = useRef(null);
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const data = props?.data?.chart?.graphList;
  const [line1Visible, setLine1Visible] = useState(true);
  const [line2Visible, setLine2Visible] = useState(true);
  const [line3Visible, setLine3Visible] = useState(true);
  const [line4Visible, setLine4Visible] = useState(true);

  const dateAndTime = (time) => {
    var date = new Date(time);
    var result =
      date.getFullYear() +
      "-" +
      `${date.getMonth() + 1}` +
      "-" +
      `${date.getDate()}`;
    //  +
    // " " +
    // `${date.getHours()}:${date.getMinutes()}`;
    return result;
  };
  useEffect(() => {
    if (data) {
      d3.select(chartRef.current).selectAll("*").remove();
      // Parse the date/time
      const parseTime = d3.timeParse("%Y-%m-%d");
      const svg = d3.select(chartRef.current);
      // Set the dimensions and margins of the graph
      const margin = { top: 20, right: 0, bottom: 110, left: 0 };
      const width =
        svg.node().getBoundingClientRect().width - margin.left - margin.right;
      const height = 320 - margin.top - margin.bottom;
      svg.attr("width", width + margin.left + margin.right);
      svg.attr("height", height + margin.top + margin.bottom);
      // Append the svg object to the chartRef.current
      const parentHeight =
        chartRef.current.parentNode.getBoundingClientRect().height;
      svg.attr("height", parentHeight);
      const parentWidth =
        chartRef.current.parentNode.getBoundingClientRect().width;
      svg.attr("width", parentWidth);

      // Set the ranges
      const x = d3
        .scaleTime()
        .domain(d3.extent(data, (d) => parseTime(dateAndTime(d.date))))
        .range([0, width]);

      const y = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d.temperature)])
        .range([height, 0]);

      const line1 = d3
        .line()
        .curve(d3.curveLinear)
        .x((d) => x(parseTime(dateAndTime(d.date))))
        .y((d) => y(d.temperature));

      // Add the clip path
      svg
        .append("defs")
        .append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("width", width)
        .attr("height", height);

      // Add the main chart area
      const chart = svg
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`)
        .attr("class", "chart");

      x.domain(d3.extent(data, (d) => parseTime(dateAndTime(d.date))));
      y.domain(d3.extent(data, (d) => d.temperature));

      // BACKGROUND
      // Add a background to the X-axis based on date
      chart
        .append("rect")
        .attr("class", "x-axis-background")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height)
        .style("fill", "#3E3E3E");

      // Add a vertical line for each date on the X-axis
      chart
        .selectAll(".vertical-line-group")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "vertical-line-group")
        .attr(
          "transform",
          (d) => `translate(${x(parseTime(dateAndTime(d.date)))},0)`
        )
        .append("line")
        .attr("class", "vertical-line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", 0)
        .attr("y2", height)
        .style("stroke", "#707070")
        .style("opacity", "0.5")
        .style("stroke-width", 0.5);

      chart
        .append("path")
        .datum(data)
        .attr("class", `line line1`)
        .attr("d", line1)
        .style("display", "block");

      // chart
      //   .append("g")
      //   .attr("class", "x axis")
      //   .attr("transform", `translate(0, ${height})`)
      //   .call(d3.axisBottom(x));

      chart
        .append("g")
        .attr("class", "y axis")
        .attr("transform", "translate(" + width + ", 0)")
        .call(d3.axisRight(y));
    }
  }, [data, props.windowWidth]);
  return (
    <div className="chart-container">
      <svg
        ref={chartRef}
        style={{ height: "fit-content", width: "100%" }}
      ></svg>
    </div>
  );
};
export default IoTWidget;
