import React from "react";

import Logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import IconProfile from "./IconProfile";
import IconLanguage from "./IconLanguage";
import { Lintramax } from "../context/LintramaxContext";
import { DigMap } from "../context/DigMapContext";
import { IoT } from "../context/IoTContext";

const Header = () => {
  const { resetLintramaxCtx } = Lintramax();
  const { resetIoTCtx } = IoT();
  const { resetDigMapCtx } = DigMap();
  const resetContext = () => {
    resetLintramaxCtx();
    resetIoTCtx();
    resetDigMapCtx();
  };
  return (
    <Box
      alignItems="center"
      style={{
        borderBottom: "4px solid #2B337D",
        marginLeft: "0",
        position: "fixed",
        background: "white",
        display: "flex",
        width: "100%",
        top: "0",
        height: "60px",
        zIndex: "10000",
        padding: "0px 20px",
        justifyContent: "space-between",
      }}
      className="headerFelcra"
    >
      <Box>
        <Link to="/" onClick={() => resetContext()}>
          <img src={Logo} alt="Logo" />
        </Link>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        {/* <IconButton sx={{ margin: "0px 20px" }}>
          <SearchIcon sx={{ fontSize: "32px" }} />
        </IconButton> */}
        <IconLanguage />
        <IconProfile />
      </Box>
    </Box>
  );
};

export default Header;
