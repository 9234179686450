import { createContext, useContext, useState } from "react";

const DigMapContext = createContext({
  plantType: {},
  date: {
    startDate: "",
    endDate: "",
  },
  setPlantType: () => {},
  setDate: () => {},
  resetDigMapCtx: () => {},
});

export function DigMapContextProvider(props) {
  const [plantType, setPlantType] = useState({});
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const resetDigMapCtx = () => {
    setPlantType({});
    setDate({
      startDate: "",
      endDate: "",
    });
  };
  const context = {
    plantType: plantType,
    setPlantType: setPlantType,
    date: date,
    setDate: setDate,
    resetDigMapCtx: resetDigMapCtx,
  };

  return (
    <DigMapContext.Provider value={context}>
      {props.children}
    </DigMapContext.Provider>
  );
}

export function DigMap() {
  return useContext(DigMapContext);
}
