import React, { useState, useEffect } from "react";
import word from "../assets/images/word.png";
import excel from "../assets/images/excel.png";
import acrobat from "../assets/images/Icon.png";
import zip from "../assets/images/qgisZip.png";
import axios from "axios";
import nookies, { destroyCookie } from "nookies";
import { Link, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Language } from "../context/LanguageContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";

export default function EditDocReportCard(props) {
  const { language, newObj } = Language();
  const [progress, setProgress] = useState(0);
  const [steps, setSteps] = useState(0);
  const [doc, setDoc] = useState([]);
  const [center, setCenter] = useState();
  const [pkCodeClick, setPKCodeClick] = useState(false);
  const [selectedPK, setSelectedPK] = useState();
  const [pkCode, setPkCode] = useState();
  const [projectCodeClick, setProjectCodeClick] = useState(false);
  const [selectedProjectCode, setSelectedProjectCode] = useState();
  const [projectCode, setProjectCode] = useState();
  const [isLoad, setLoad] = useState(true);
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  let apiUrl = process.env.REACT_APP_MAIN_URL;
  const navigate = useNavigate();

  const getUrlParam = () => {
    const url = window.location.href;
    const paramIndex = url.lastIndexOf("="); // Find the index of the '=' character
    const paramString = url.substring(paramIndex + 1); // Extract the string after '='
    return paramString;
  };
  const setFileImg = (file) => {
    if (file === "docx" || file === "doc") {
      return word;
    } else if (file === "xlsx" || file === "xls") {
      return excel;
    } else if (file === "pdf") {
      return acrobat;
    } else {
      return zip;
    }
  };

  const getDoc = async (id) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/archive/report/get`,
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        const data = res.data.data;
        setDoc(data);

        getAllPkCode();
      } else if (res.data.status === 500) {
        setDoc();
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getAllPkCode = async () => {
    try {
      const res = await axios.get(`${apiUrl}/api/mapping/pk_code/get-all`, {
        headers: {
          Authorization: `Bearer ${tokens_session}`,
        },
      });
      if (res.data.status === 200) {
        setPkCode(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getProjectCodeByPkCode = async (pkCode) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/mapping/project_code/get-all-by-pk-code`,
        { pk_code: pkCode },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        setProjectCode(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const updateReport = async (id, pk_code, projek_kod, negeri_kod) => {
    setLoad(true);
    try {
      const res = await axios.post(
        `${apiUrl}/api/archive/report/update`,
        {
          id: id,
          pk_kod: pk_code,
          projek_kod: projek_kod,
          negeri_kod: negeri_kod,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        setSteps(1);
        props.onChange(1);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      setLoad(false);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const selectPKCodeHandler = () => {
    console.log(doc, pkCode);
    const selectedPKCode = pkCode.find((item) => item.kodKawasa === doc.pkKod);
    setSelectedPK({
      kodKawasa: selectedPKCode.kodKawasa,
      namaKawasa: selectedPKCode.namaKawasa,
    });
  };

  const selectProjectCodeHandler = () => {
    const selectedProjectCode = projectCode.find(
      (item) => "F" + item.kodProjek === doc.projekKod
    );

    const selectedProjectCode2 = projectCode.find(
      (item) => item.kodProjek === doc.projekKod
    );
    const selectedProject = selectedProjectCode
      ? selectedProjectCode
      : selectedProjectCode2;
    setSelectedProjectCode({
      kodProjek: selectedProject.kodProjek,
      namaProjek: selectedProject.namaProjek,
    });

    setLoad(false);
  };

  const saveUpdateHandler = () => {
    const negeriKod = selectedPK.kodKawasa.slice(0, 2);
    updateReport(
      doc.id,
      selectedPK.kodKawasa,
      selectedProjectCode.kodProjek,
      negeriKod
    );
  };
  useEffect(() => {
    if (pkCode) selectPKCodeHandler();
  }, [pkCode]);

  useEffect(() => {
    if (projectCode) selectProjectCodeHandler();
  }, [projectCode]);

  useEffect(() => {
    if (selectedPK) {
      setLoad(true);
      getProjectCodeByPkCode(selectedPK?.kodKawasa);
    }
  }, [selectedPK]);

  useEffect(() => {
    const id = getUrlParam();
    getDoc(id);
  }, []);

  if (!isLoad)
    return (
      <>
        <div
          style={{
            height: "100%",
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            {steps === 1 ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: "15px",
                }}
              >
                <div
                  style={{
                    marginBottom: "50px",
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                    marginBottom: "100px",
                  }}
                >
                  <CheckCircleOutlineIcon
                    style={{ color: "lightgreen", fontSize: "100px" }}
                  />
                  Your file was successfully updated
                </div>

                <Link to="/documentreport" style={{ width: "100%" }}>
                  <button
                    style={{
                      color: "black",
                      backgroundColor: "white",
                      border: "1px solid white",
                      padding: "15px",
                      width: "100%",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  >
                    View the documents and reports
                  </button>
                </Link>

                <Link to="/adminpage">
                  {" "}
                  <div
                    style={{
                      color: "white",
                      textUnderlineOffset: "2px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Return to the administrator page
                  </div>
                </Link>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "end",
                  height: "100%",
                  gap: "20px",
                }}
              >
                <div style={{ width: "100%", marginBottom: "100px" }}>
                  {" "}
                  {/* file */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src={setFileImg(doc.fileExt)}
                      alt="file"
                      style={{ width: "50px" }}
                    />
                    <div>
                      <div style={{ color: "white", fontWeight: "bold" }}>
                        {doc.name}
                      </div>
                      <div style={{ color: "white", fontSize: "12px" }}>
                        {doc.createdAt}
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: "10px 0 5px 0", color: "white" }}>
                    Please fill up information below
                  </div>
                  {/* select pk code */}
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    {" "}
                    <Box
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px 20px",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        position: "relative",
                      }}
                      onClick={() => {
                        setPKCodeClick(!pkCodeClick);
                      }}
                    >
                      {selectedPK ? (
                        // pk code name
                        <span>
                          <span
                            style={{
                              fontWeight: "bold",
                              marginRight: "10px",
                              fontSize: "12px",
                            }}
                          >
                            PK{selectedPK.kodKawasa}
                          </span>
                          {selectedPK.namaKawasa}
                        </span>
                      ) : (
                        <span>PK Code</span>
                      )}
                      <ExpandMoreIcon style={{ width: "20px" }} />
                    </Box>
                    {pkCodeClick && (
                      <Box
                        style={{
                          padding: "3% 0",
                          position: "absolute",
                          width: "100%",
                          zIndex: 100,
                        }}
                      >
                        <Box
                          className="removeScrollbar"
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "white",
                            zIndex: "1000",
                            right: "0",
                            maxHeight: "200px",
                            overflowY: "auto",
                            bottom: "-25%",
                          }}
                        >
                          {pkCode.map((obj, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                borderBottom: "1px solid lightgray",
                                padding: "10px 20px",
                                alignItems: "center",
                                cursor: "pointer",
                                fontSize: "12px",
                              }}
                              onClick={() => {
                                setPKCodeClick(!pkCodeClick);
                                setSelectedPK({
                                  kodKawasa: obj.kodKawasa,
                                  namaKawasa: obj.namaKawasa,
                                });
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "10px",
                                  fontSize: "12px",
                                }}
                              >
                                PK{obj.kodKawasa}
                              </span>
                              {obj.namaKawasa}
                            </div>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </div>
                  {/* select project code */}
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <Box
                      style={{
                        borderRadius: "5px",
                        display: "flex",
                        marginTop: "10px",
                        alignItems: "center",
                        padding: "10px 20px",
                        justifyContent: "space-between",

                        ...(!selectedPK
                          ? {
                              backgroundColor: "grey",
                            }
                          : {
                              backgroundColor: "white",
                            }),
                        ...(!selectedPK
                          ? { cursor: "not-allowed" }
                          : { cursor: "pointer" }),
                      }}
                      onClick={() =>
                        selectedPK && setProjectCodeClick(!projectCodeClick)
                      }
                    >
                      {selectedProjectCode ? (
                        // pk code name
                        <span>
                          <span
                            style={{
                              fontWeight: "bold",
                              marginRight: "10px",
                              fontSize: "12px",
                            }}
                          >
                            F{selectedProjectCode.kodProjek}
                          </span>
                          {selectedProjectCode.namaProjek}
                        </span>
                      ) : (
                        <span>Project Code</span>
                      )}
                      <ExpandMoreIcon style={{ width: "20px" }} />
                    </Box>
                    {projectCodeClick && (
                      <Box
                        style={{
                          padding: "3% 0",
                          position: "absolute",
                          width: "100%",
                        }}
                      >
                        <Box
                          className="removeScrollbar"
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "white",
                            zIndex: "1000",
                            right: "0",
                            maxHeight: "200px",
                            overflowY: "scroll",
                            bottom: "-25%",
                          }}
                        >
                          {projectCode.map((obj, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                borderBottom: "1px solid lightgray",
                                padding: "10px 20px",
                                alignItems: "center",
                                cursor: "pointer",
                                fontSize: "12px",
                              }}
                              onClick={() => {
                                setProjectCodeClick(!projectCodeClick);
                                setSelectedProjectCode({
                                  kodProjek: obj.kodProjek,
                                  namaProjek: obj.namaProjek,
                                });
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "10px",
                                  fontSize: "12px",
                                }}
                              >
                                F{obj.kodProjek}
                              </span>
                              {obj.namaProjek}
                            </div>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </div>
                </div>

                <button
                  onClick={saveUpdateHandler}
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    border: "1px solid white",
                    padding: "15px",
                    width: "100%",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                >
                  Save Update
                </button>
              </div>
            )}
          </div>
        </div>
      </>
    );
}
