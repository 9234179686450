import React, { useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Language } from "../context/LanguageContext";

export default function SimpleStepper(data) {
  const { language, newObj } = Language();
  function getSteps() {
    if (data.totalSteps === 4)
      return [
        newObj.UploadWord[language],
        newObj.ConfigureWord[language],
        newObj.ProcessingWord[language],
        newObj.CompletedWord[language],
      ];
    else if (data.totalSteps === 2)
      return [newObj.EditWord[language], newObj.CompletedWord[language]];
    return [
      newObj.UploadWord[language],
      newObj.ProcessingWord[language],
      newObj.CompletedWord[language],
    ];
  }
  const steps = getSteps();
  return (
    <div>
      <Stepper activeStep={data.active} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            key={index}
            sx={{
              "& .MuiStepLabel-root .Mui-completed": {
                color: "common.white", // circle color (COMPLETED)
              },
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                {
                  color: "grey.500", // Just text label (COMPLETED)
                },
              "& .MuiStepLabel-root .Mui-active": {
                color: "common.white", // circle color (ACTIVE)
              },
              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                {
                  color: "common.white", // Just text label (ACTIVE)
                },
              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                fill: "black", // circle's number (ACTIVE)
              },
            }}
          >
            <StepLabel style={{ color: "white" }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
