import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import axios from "axios";
import nookies from "nookies";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useMediaQuery, Box } from "@mui/material";
import Logo from "../../assets/images/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import macWP from "../../assets/images/macWP.jpg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Language } from "../../context/LanguageContext";
import "./index.scss";
import Swal from "sweetalert2";

const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
});

const ResetPassword = () => {
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const cookies = nookies.get();
  const url = window.location.href;
  const parts = url.split("/");
  const lastString = parts[parts.length - 1];
  const tokens_session = cookies.token;
  const { language, newObj } = Language();
  const navigate = useNavigate();
  // FUNCTION TO SHOW PASSWORD
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleClickShowPassword3 = () => setShowPassword3((show) => !show);
  const [firstPass, setFirstPass] = useState("");
  const [secondPass, setSecondPass] = useState("");
  const [thirdPass, setThirdPass] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);

  const [isTokenValid, setTokenValid] = useState();
  useEffect(() => {
    const checkTokenStringFromUrl = async () => {
      try {
        const res = await axios.post(
          `${apiUrl}/api/auth/check-token-forget-password`,
          { user_token: lastString },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.data.status === 200) {
          setTokenValid(true);
        } else {
          navigate("/login");
          setTokenValid(false);
        }
        return res.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    checkTokenStringFromUrl();
  }, []);
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword3 = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (event) => {
    // const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    const regex = /^(?=.*[A-Za-z])(?=.*\d)^.{8,}$/;
    if (regex.test(event.target.value) || event.target.value === "") {
      setSecondPass(event.target.value);
      setError(false);
      setPasswordsMatch(event.target.value === thirdPass);
    } else {
      setError(true);
      setPasswordsMatch(false);
    }
  };

  const handlePasswordChange2 = (event) => {
    // const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    const regex = /^(?=.*[A-Za-z])(?=.*\d)^.{8,}$/;
    if (regex.test(event.target.value) || event.target.value === "") {
      setThirdPass(event.target.value);
      setError2(false);
      setPasswordsMatch(event.target.value === secondPass);
    } else {
      setError2(true);
      setPasswordsMatch(false);
    }
  };
  const handleSubmitPass = async (event) => {
    event.preventDefault();

    const bodyFormData = new FormData();
    bodyFormData.append("user_token", lastString);
    bodyFormData.append("password", secondPass);

    if (passwordsMatch) {
      try {
        const res = await axios.post(
          `${apiUrl}/api/auth/reset-password`,
          bodyFormData,
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
            },
          }
        );
        if (res.data.status === 200) {
          Swal.fire({
            icon: "success",
            title: newObj.SuccessWord[language],
            text: res.data.message,
          });
          setFirstPass();
          setSecondPass();
          setThirdPass();
          navigate("/login");
        }
        if (res.status === 500) {
          Swal.fire({
            icon: "error",
            title: newObj.ErrorWord[language],
            text: res.data.message,
          });
        }
        return res.data;
      } catch (e) {
        console.log(e);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: newObj.ErrorWord[language],
        text: "Password not match",
      });
    }
  };

  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isTokenValid ? (
        <div>
          <Box
            alignItems="center"
            style={{
              borderBottom: "4px solid #2B337D",
              marginLeft: "0",
              position: "fixed",
              background: "white",
              display: "flex",
              width: "100%",
              top: "0",
              height: "60px",
              zIndex: "10000",
              padding: "0px 20px",
              justifyContent: "space-between",
            }}
            className="headerFelcra"
          >
            <Box>
              <Link to="/login">
                <img src={Logo} alt="Logo" />
              </Link>
            </Box>
          </Box>
          <Container
            maxWidth="100%"
            sx={{
              backgroundImage: `url(${macWP})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
              padding: isXsScreen
                ? "1px !important"
                : isSmScreen
                ? "1px !important"
                : "10px !important",
              marginTop: "60px",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // padding: "30px",
            }}
          >
            <Box className="BoxReset">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                marginBottom={"20px"}
              >
                Reset Password
              </Typography>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "100%" },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmitPass}
              >
                <FormControl sx={{ my: 1, width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="newPass2">New Password</InputLabel>
                  <OutlinedInput
                    id="newPass2"
                    type={showPassword2 ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                          edge="end"
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    onChange={handlePasswordChange}
                  />
                  {error && (
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                      {newObj.ErrorPasswordWord[language]}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ my: 1, width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="newPass3">
                    Confirm New Password
                  </InputLabel>
                  <OutlinedInput
                    id="newPass3"
                    type={showPassword3 ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword3}
                          onMouseDown={handleMouseDownPassword3}
                          edge="end"
                        >
                          {showPassword3 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    onChange={handlePasswordChange2}
                  />
                  {error2 && (
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                      {newObj.ErrorPasswordWord[language]}
                    </Typography>
                  )}
                </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  style={{
                    marginTop: 30,
                    mb: 2,
                    width: "100%",
                    padding: 15,
                    ...(passwordsMatch
                      ? {
                          backgroundColor: "rgb(43, 51, 125)",
                          cursor: "pointer",
                        }
                      : {
                          backgroundColor: "grey",
                          cursor: "not-allowed",
                        }),
                    color: "white",
                    borderRadius: "10px",
                  }}
                >
                  {newObj.ChangePasswordWord[language]}
                </Button>
              </Box>
            </Box>
          </Container>
        </div>
      ) : (
        <div>No Token</div>
      )}
    </ThemeProvider>
  );
};

export default ResetPassword;
