import { createContext, useContext, useEffect, useState } from "react";
import LanguageObj from "./LanguageWord.js";
import { setCookie, destroyCookie } from "nookies";
import nookies from "nookies";
const LanguageContext = createContext();

export const LanguangeContextProvider = ({ children }) => {
  const cookies = nookies.get();
  const defaultLang = cookies.languageCookie ? cookies.languageCookie : 1;
  const [language, setLang] = useState(defaultLang); //0 Default, 1 ENG, 2 MY
  function changeLanguage(input) {
    setLang(input);
  }

  useEffect(() => {
    destroyCookie(null, "languageCookie");
    setCookie(null, "languageCookie", language);
  }, [language]);

  let newObj = { ...LanguageObj };

  Object.keys(newObj).forEach((key) => {
    let arr = newObj[key];

    if (arr[1] === "") {
      arr[1] = arr[0];
    }

    if (arr[2] === "") {
      arr[2] = arr[0];
    }

    newObj[key] = arr;
  });

  return (
    <div>
      <LanguageContext.Provider value={{ changeLanguage, language, newObj }}>
        {children}
      </LanguageContext.Provider>
    </div>
  );
};

export function Language() {
  return useContext(LanguageContext);
}
