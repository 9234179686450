import React from "react";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import PageNav from "../../components/PageNav";

// ICON LIST
import iotArchived from "../../assets/images/iotarchived.png";
import manageDevices from "../../assets/images/managedevice.png";
import uploadIot from "../../assets/images/uploadiot.png";
import mapFiles from "../../assets/images/mapFiles.svg";
import uploadMap from "../../assets/images/uploadMap.svg";
import uploadDoc from "../../assets/images/uploadDocument.svg";
import uploadPaddy from "../../assets/images/uploadPaddy.svg";
import uploadRubber from "../../assets/images/uploadRubber.svg";
import lintraMax from "../../assets/images/lintraMax.svg";
import addNewUser from "../../assets/images/addNewUser.svg";
import users from "../../assets/images/users.svg";

import macWP from "../../assets/images/macWP.jpg";

import "./index.scss";
import { Language } from "../../context/LanguageContext";

const AdminPage = (props) => {
  const { language, newObj } = Language();
  const role = props.user.authority;
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
  });

  const digitalMapAdminRole = role.includes("digital_mapping_admin");
  const lintramaxAdminRole = role.includes("lintramax_admin");
  const iotAdminRole = role.includes("iot_admin");
  const userManagementRole = role.includes("user_management_admin");

  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMdScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLgScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        maxWidth="100%"
        sx={{
          backgroundImage: `url(${macWP})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          padding: isXsScreen
            ? "0px 1px 1px 1px !important"
            : isSmScreen
            ? "0px 1px 1px 1px !important"
            : "10px !important",
          marginTop: "60px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          // height:  "100%",
        }}
        className="adminOuterContainer"
      >
        <PageNav title={newObj.AdministratorWord[language]} href="/" />
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Grid
            container
            component="main"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="containerAdmin"
          >
            {digitalMapAdminRole && (
              <Grid
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box className="boxAdmin">
                  <Box
                    variant="body1"
                    sx={{
                      color: "white",
                      backgroundColor: "#505559",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                    fontWeight="bold"
                    py={2}
                    pl={3}
                  >
                    {newObj.DigitalMappingWord[language]}
                  </Box>
                  <List sx={{ width: "100%", maxWidth: 300 }}>
                    <Link
                      to="/mapfiles"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={mapFiles}
                            sx={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.MapFilesWord[language]}
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to="/uploadmap"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={uploadMap}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.UploadMapWord[language]}
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to="/uploadreport"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={uploadDoc}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.UploadDocumentReportWord[language]}
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to="/documentreport"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={uploadMap}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.DocumentReportWord[language]}
                        />
                      </ListItem>
                    </Link>
                  </List>
                </Box>
              </Grid>
            )}

            {iotAdminRole && (
              <Grid
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box className="boxAdmin">
                  <Box
                    variant="body1"
                    sx={{
                      color: "white",
                      backgroundColor: "#505559",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                    fontWeight="bold"
                    py={2}
                    pl={3}
                  >
                    {newObj.IoTWord[language]}
                  </Box>
                  <List sx={{ width: "100%", maxWidth: 300 }}>
                    <Link
                      to="/manageiotdevices"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={manageDevices}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.ManageDevicesWord[language]}
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to="/uploadiot"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={uploadIot}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.UploadIoTDataWord[language]}
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to="/uploadiotevent"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={uploadIot}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.UploadIoTEventDataWord[language]}
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to="/iotarchivefiles"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={iotArchived}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.IoTArchiveFilesWord[language]}
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to="/iconsettings"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={lintraMax}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.IconSettingWord[language]}
                        />
                      </ListItem>
                    </Link>
                  </List>
                </Box>
              </Grid>
            )}

            {lintramaxAdminRole && (
              <Grid
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box className="boxAdmin">
                  <Box
                    variant="body1"
                    sx={{
                      color: "white",
                      backgroundColor: "#505559",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                    fontWeight="bold"
                    py={2}
                    pl={3}
                  >
                    {newObj.LintraMaxWord[language]}
                  </Box>
                  <List sx={{ width: "100%", maxWidth: 300 }}>
                    <Link
                      to="/uploadpalm"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={uploadMap}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.UploadPalmOilDataWord[language]}
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to="/uploadpaddy"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={uploadPaddy}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.UploadPaddyDataWord[language]}
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to="/uploadrubber"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={uploadRubber}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.UploadRubberDataWord[language]}
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to="/lintramaxarchivefiles"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={lintraMax}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.LintraMaxArchiveFilesWord[language]}
                        />
                      </ListItem>
                    </Link>
                  </List>
                </Box>
              </Grid>
            )}

            {userManagementRole && (
              <Grid
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box className="boxAdmin">
                  <Box
                    variant="body1"
                    sx={{
                      color: "white",
                      backgroundColor: "#505559",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                    fontWeight="bold"
                    py={2}
                    pl={3}
                  >
                    {newObj.AccessWord[language]}
                  </Box>
                  <List sx={{ width: "100%", maxWidth: 300 }}>
                    <Link
                      to="/addnewuser"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={addNewUser}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.AddNewUserWord[language]}
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to="/userlist"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={addNewUser}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.UsersWord[language]}
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to="/addusergroup"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={users}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.AddUserGroupWord[language]}
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to="/usergroup"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem divider>
                        <ListItemAvatar>
                          <Avatar
                            src={users}
                            style={{
                              background: "transparent",
                              borderRadius: 0,
                              color: "white",
                              objectFit: "contain",
                              width: "fit-content",
                              filter: "invert(1)",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "white" }}
                          primary={newObj.UserGroupWord[language]}
                        />
                      </ListItem>
                    </Link>
                  </List>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Container>
    </ThemeProvider>
  );
};

export default AdminPage;
