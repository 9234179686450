import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import "./App.css";
import Main from "./pages/Main";
import MapGenerated from "./pages/MapGenerated";
import IoT from "./pages/IoT";
import D3Graph from "./pages/D3Graph";
import NoPage from "./pages/NoPage";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import AdminPage from "./pages/Admin";
import AddNewUser from "./pages/AddNewUser";
import AddUserGroup from "./pages/AddUserGroup";
import UserList from "./pages/UserList";
import UserGroup from "./pages/UserGroup";
import UploadPage from "./pages/UploadPage";
import MapFiles from "./pages/MapFiles";
import DocumentReport from "./pages/DocumentReport";
import nookies, { setCookie, destroyCookie } from "nookies";
import axios from "axios";

import Header from "./components/Header";

import { createTheme, ThemeProvider } from "@mui/material";
import "../src/assets/styles/globals.css";
import { Loader } from "./components/Loader";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { IoTGraph } from "./pages/IoTGraph";
import { UploadIoT } from "./pages/UploadIoT/UploadIoT";
import { UploadDocReport } from "./pages/UploadDocReport";
import { ManageIoTDevices } from "./pages/ManageIoTDevices";
import LintramaxArchiveFiles from "./pages/LintramaxArchiveFiles";
import { IconSettings } from "./pages/IconSettings";
import MapPreview from "./pages/MapPreview";
import ResetPassword from "./pages/ResetPassword";
import IotArchiveFiles from "./pages/IoT Archive Files";
import { EditDocReport } from "./pages/EditDocReport";
import { IOTGraphDataDetail } from "./pages/IOTGraphDataDetail";

function App() {
  const [user, setUser] = useState();
  const [role, setRole] = useState();
  const [isLoad, setLoad] = useState(true);
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"].join(","),
    },
  });
  const location = useLocation();
  const navigate = useNavigate();

  const cookies = nookies.get();
  const tokens_session = cookies.token;

  const apiUrl = process.env.REACT_APP_MAIN_URL;

  const checkValid = async () => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/profile`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      if (res.data.status === 200) {
        setUser(res.data.data);
      } else if (res.data.status === 401) {
        destroyCookie(null, "token");
        if (location.pathname.split("/")[1] !== "resetpassword")
          navigate("/login");
      } else {
        console.log(res.data.message);
      }
      setLoad(false);
      return res.data;
    } catch (e) {
      console.log("error", e);
      navigate("/login");
    }
  };

  useEffect(() => {
    if (!tokens_session || tokens_session === "undefined") {
      setLoad(false);
      if (location.pathname.split("/")[1] !== "resetpassword")
        navigate("/login");
    } else {
      setCookie(null, "token", tokens_session, {
        maxAge: 3600,
        path: "/",
      });
    }
  }, []);

  useEffect(() => {
    checkValid();
  }, [tokens_session]);

  useEffect(() => {
    if (user) {
      setRole(user.authority);

      setLoad(false);
    }
  }, [user]);

  if (isLoad) {
    return <Loader />;
  } else
    return (
      <ThemeProvider theme={theme}>
        {user !== null && (
          <div className="App" style={{ marginTop: "50px" }}>
            {tokens_session && <Header />}
            <Routes>
              <Route index element={<Main role={role} />} />
              <Route
                path="adminpage"
                element={
                  <ProtectedRoute
                    permission={[
                      "digital_mapping_admin",
                      "iot_admin",
                      "lintramax_admin",
                    ]}
                    user={user}
                  >
                    <AdminPage user={user} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="addnewuser"
                element={
                  <ProtectedRoute
                    permission={["user_management_admin"]}
                    user={user}
                  >
                    <AddNewUser />
                  </ProtectedRoute>
                }
              />
              <Route
                path="addusergroup"
                element={
                  <ProtectedRoute
                    permission={["user_management_admin"]}
                    user={user}
                  >
                    <AddUserGroup />
                  </ProtectedRoute>
                }
              />
              <Route
                path="graph"
                element={
                  <ProtectedRoute
                    permission={["lintramax_viewer", "lintramax_admin"]}
                    user={user}
                  >
                    <D3Graph />
                  </ProtectedRoute>
                }
              />
              <Route path="login" element={<Login />} />
              <Route
                path="map"
                element={
                  <ProtectedRoute
                    permission={[
                      "digital_mapping_admin",
                      "digital_mapping_viewer",
                    ]}
                    user={user}
                  >
                    <MapGenerated role={role} />
                  </ProtectedRoute>
                }
              />

              <Route
                path="mappreview"
                element={
                  <ProtectedRoute
                    permission={["digital_mapping_admin"]}
                    user={user}
                  >
                    <MapPreview role={role} />
                  </ProtectedRoute>
                }
              />

              <Route
                path="editdocreport/:data"
                element={
                  <ProtectedRoute
                    permission={["digital_mapping_admin"]}
                    user={user}
                  >
                    <EditDocReport role={role} />
                  </ProtectedRoute>
                }
              />

              <Route
                path="iot"
                element={
                  <ProtectedRoute
                    permission={["iot_admin", "iot_viewer"]}
                    user={user}
                  >
                    <IoT role={role} />
                  </ProtectedRoute>
                }
              />

              <Route
                path="iotgraph"
                element={
                  <ProtectedRoute
                    permission={["iot_admin", "iot_viewer"]}
                    user={user}
                  >
                    <IoTGraph role={role} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="iotgraph/detail"
                element={
                  <ProtectedRoute
                    permission={["iot_admin", "iot_viewer"]}
                    user={user}
                  >
                    <IOTGraphDataDetail role={role} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="mapfiles"
                element={
                  <ProtectedRoute
                    permission={["digital_mapping_admin"]}
                    user={user}
                  >
                    <MapFiles />
                  </ProtectedRoute>
                }
              />

              <Route
                path="iotarchivefiles"
                element={
                  <ProtectedRoute permission={["iot_admin"]} user={user}>
                    <IotArchiveFiles />
                  </ProtectedRoute>
                }
              />
              <Route
                path="lintramaxarchivefiles"
                element={
                  <ProtectedRoute permission={["lintramax_admin"]} user={user}>
                    <LintramaxArchiveFiles />
                  </ProtectedRoute>
                }
              />
              <Route path="profile" element={<Profile />} />

              <Route path="resetpassword/:token" element={<ResetPassword />} />
              <Route
                path="userlist"
                element={
                  <ProtectedRoute
                    permission={["user_management_admin"]}
                    user={user}
                  >
                    <UserList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="userlist/:userId"
                element={
                  <ProtectedRoute
                    permission={["user_management_admin"]}
                    user={user}
                  >
                    <AddNewUser />
                  </ProtectedRoute>
                }
              />
              <Route
                path="usergroup"
                element={
                  <ProtectedRoute
                    permission={["user_management_admin"]}
                    user={user}
                  >
                    <UserGroup />
                  </ProtectedRoute>
                }
              />
              <Route
                path="usergroup/:userId"
                element={
                  <ProtectedRoute
                    permission={["user_management_admin"]}
                    user={user}
                  >
                    <AddUserGroup />
                  </ProtectedRoute>
                }
              />
              <Route
                path="uploadmap"
                element={
                  <ProtectedRoute
                    permission={["digital_mapping_admin"]}
                    user={user}
                  >
                    <UploadPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="uploadiotevent"
                element={
                  <ProtectedRoute permission={["iot_admin"]} user={user}>
                    <UploadPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="uploadreport"
                element={
                  <ProtectedRoute
                    permission={["digital_mapping_admin"]}
                    user={user}
                  >
                    <UploadDocReport />
                  </ProtectedRoute>
                }
              />
              <Route
                path="uploadiot"
                element={
                  <ProtectedRoute permission={["iot_admin"]} user={user}>
                    <UploadIoT />
                  </ProtectedRoute>
                }
              />
              <Route
                path="manageiotdevices"
                element={
                  <ProtectedRoute permission={["iot_admin"]} user={user}>
                    <ManageIoTDevices />
                  </ProtectedRoute>
                }
              />
              <Route
                path="iconsettings"
                element={
                  <ProtectedRoute permission={["iot_admin"]} user={user}>
                    <IconSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="uploadreport"
                element={
                  <ProtectedRoute
                    permission={["digital_mapping_admin"]}
                    user={user}
                  >
                    <UploadPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="uploadpalm"
                element={
                  <ProtectedRoute permission={["lintramax_admin"]} user={user}>
                    <UploadPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="uploadpaddy"
                element={
                  <ProtectedRoute permission={["lintramax_admin"]} user={user}>
                    <UploadPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="uploadrubber"
                element={
                  <ProtectedRoute permission={["lintramax_admin"]} user={user}>
                    <UploadPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="documentreport"
                element={
                  <ProtectedRoute
                    permission={["digital_mapping_admin"]}
                    user={user}
                  >
                    <DocumentReport />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<NoPage />} />
            </Routes>
          </div>
        )}
      </ThemeProvider>
    );
}

export default App;
