import React from "react";
import Grid from "@mui/material/Grid";
import publishedMap from "../assets/images/publishedMap.svg";
import draftMap from "../assets/images/draftmap.png";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { Checkbox } from "@mui/material";

const MapFiles = (props) => {
  const centerLat = props.file.latitude;
  const centerLong = props.file.longitude;
  const projekCode = props.file.kodKawasa;
  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Box
          sx={{
            background: `${props.isPublished ? "#2B5180" : "white"}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            borderRadius: "10px",
            textAlign: "start",
          }}
        >
          {props.isPublished ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                fontSize: "12px",
                color: `${
                  props.isPublished ? "white !important" : "black !important"
                }`,
              }}
            >
              <Avatar
                src={props.isPublished ? publishedMap : draftMap}
                style={{
                  background: "transparent",
                  borderRadius: 0,
                  color: "white",
                  objectFit: "contain",
                  width: "fit-content",
                  height: "60px",
                  aspectRatio: 1,
                  marginRight: 5,
                }}
              />
              {props.title}
            </Box>
          ) : (
            <Link to={`/mappreview?center=${centerLat + "&" + centerLong}`}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  cursor: "pointer",
                  fontSize: "12px",
                  color: `${
                    props.isPublished ? "white !important" : "black !important"
                  }`,
                }}
              >
                <Avatar
                  src={props.isPublished ? publishedMap : draftMap}
                  style={{
                    background: "transparent",
                    borderRadius: 0,
                    color: "white",
                    objectFit: "contain",
                    width: "fit-content",
                    height: "60px",
                    aspectRatio: 1,
                    marginRight: 5,
                  }}
                />
                {props.title}
              </Box>
            </Link>
          )}

          <div
            style={{
              backgroundColor: "rgb(0,0,0,0.3)",
              padding: "20px",
              borderRadius: "0 10px 10px  0 ",
            }}
          >
            <Checkbox
              // checked={option.checked}
              // onClick={(e) => handleChange(e)}
              // id={option.id}
              id={props.id}
              checked={props.checked}
              onClick={(e) => props.onSelectionChange(e)}
              icon={
                <CircleIcon
                  style={{ color: "white", width: "20px", height: "20px" }}
                />
              }
              checkedIcon={
                <CircleIcon
                  style={{
                    color: "blue",
                    border: "white 5px solid",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                  }}
                />
              }
              color="primary"
              shape="circle"
            />
          </div>
        </Box>
      </Grid>
    </>
  );
};

export default MapFiles;
