import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import nookies, { destroyCookie } from "nookies";
import dragndrop from "../assets/images/dragndrop.png";
import { Language } from "../context/LanguageContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
export const ModalUploadIcon = (props) => {
  const { language, newObj } = Language();
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState();
  const [fileImg, setFileImg] = useState();
  const [newIconName, setNewIconName] = useState();
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const navigate = useNavigate();
  const uploadFile = async (file) => {
    const formData = new FormData();
    const options = {
      headers: {
        Authorization: `Bearer ${tokens_session}`,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        setProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    };
    formData.append("file", file);
    setProgress(100);
    setFileImg(URL.createObjectURL(file));
    setFile(file);

    // axios
    //   .post(
    //     `${apiUrl}/api/icon/new`,
    //     { file: file, name: newIconName },
    //     options
    //   )
    //   .then((response) => {})
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };
  const newIconApi = async (file, name) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/icon/new`,
        { file: file, name: name },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        props.setOpenAddDeviceModal(false);
        Swal.fire({
          icon: "success",
          title: newObj.SuccessWord[language],
          text: newObj.NewIconAddedWord[language],
        }).then(() => {
          window.location.reload();
        });
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    if (progress === 100) props.setModalSave(true);
  }, [progress]);

  const onDrop = useCallback((acceptedFiles) => {
    // Handle the dropped files here
    acceptedFiles.forEach((file) => {
      uploadFile(file);
    });
  }, []);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    rejectedFiles,
    isDragReject,
  } = useDropzone({
    onDrop,
    multiple: false,
    minSize: 0,
    accept: {
      "image/png": [".png"],
      "image/svg": [".svg"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
  });
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <input {...getInputProps()} />
      <div style={{ width: "100%", height: "90%" }}>
        <div>
          <div
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              padding: "15px",
            }}
          >
            {newObj.AddNewIconWord[language]}
          </div>

          <hr />

          {/* input field */}
          <div
            style={{
              padding: "15px",
            }}
          >
            <div>
              <input
                onChange={(event) => setNewIconName(event.target.value)}
                style={{
                  padding: "15px",
                  border: "transparent",
                  marginTop: "10px",
                  borderRadius: "10px",
                  width: "100%",
                }}
                value={newIconName}
                placeholder={newObj.IconNameWord[language]}
              />
            </div>
            <div
              {...(progress !== 100 && getRootProps())}
              style={{
                ...(progress !== 100 && { cursor: "pointer" }),
              }}
            >
              {progress ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    padding: "30px 0",
                    gap: "10px",
                  }}
                >
                  <img src={fileImg} alt={"drag"} style={{ width: "200px" }} />
                  <b style={{ fontSize: "13px" }}>{file.name}</b>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    padding: "30px 0",
                    gap: "10px",
                  }}
                >
                  <img
                    src={dragndrop}
                    alt={"drag"}
                    style={{ width: "200px" }}
                  />
                  <b style={{ fontSize: "13px" }}>
                    {
                      newObj.DragdropsomefileshereorclicktoselectfilesWord[
                        language
                      ]
                    }
                  </b>
                  <span
                    style={{
                      fontStyle: "italic",
                      width: "80%",
                      fontSize: "10px",
                    }}
                  >
                    Please upload an image size in 400x400 px and in PNG, JPG,
                    and SVG formats
                  </span>
                </div>
              )}
            </div>

            <div
              style={{
                padding: "15px 0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={() => props.setOpenAddDeviceModal(false)}
                style={{
                  padding: "15px",
                  color: "red",
                  cursor: "pointer",
                  border: "red 1px solid",
                  backgroundColor: "transparent",
                  borderRadius: "10px",
                  width: "40%",
                }}
              >
                {newObj.CancelWord[language]}
              </button>
              <button
                onClick={() =>
                  file && newIconName && newIconApi(file, newIconName)
                }
                style={{
                  padding: "15px",
                  color: "white",
                  border: "none",
                  borderRadius: "10px",
                  width: "58%",
                  ...(file && newIconName
                    ? { cursor: "pointer", backgroundColor: "#2B5180" }
                    : { cursor: "not-allowed", backgroundColor: "grey" }),
                }}
              >
                {newObj.AddNewIconWord[language]}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
