import React, { useEffect, useRef, useState } from "react";
import nookies from "nookies";
import * as d3 from "d3";
import axios from "axios";
import { Box, Switch } from "@material-ui/core";

import moment from "moment";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Language } from "../context/LanguageContext";
import { createTheme, ThemeProvider } from "@mui/material";

const LintramaxWidget = (props) => {
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"].join(","),
      color: "white",
    },

    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          border: "none",
        },
      },
    },
    MuiInputBase: {
      root: {
        color: "white",
      },
    },
  });

  const chartRef = useRef(null);
  const { language, newObj } = Language();
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const data = props.data.data.graphList;

  const periodeObject = [
    { name: "Historical", value: "all", id: 1 },
    { name: "Monthly", value: "monthly", id: 2 },
    { name: "Quarterly", value: "quarterly", id: 3 },
    { name: "Half yearly", value: "halfyearly", id: 4 },
    { name: "Yearly", value: "yearly", id: 5 },
  ];

  const [periodeState, setPeriodeState] = useState(periodeObject[0]);

  const [pembeli, setPembeli] = useState([]);

  // COUNT DAY
  const today = moment().format("YYYY/MM/DD");
  const todayYear = moment(today, "YYYY/MM/DD").year();
  const date90DaysAgo = moment(today, "YYYY/MM/DD")
    .subtract(90, "days")
    .format("YYYY/MM/DD");

  const [startDate, setStartDate] = useState(date90DaysAgo);
  const [endDate, setEndDate] = useState(today);

  const [startYear, setStartYear] = useState(todayYear);
  const [endYear, setEndYear] = useState(todayYear);

  const [selectedPembeli, setSelectedPembeli] = useState({
    name: newObj.AllPembeliWord[language],
    id: "all",
  });
  const [topData, setTopData] = useState([]);
  const [line1Visible, setLine1Visible] = useState(true);
  const [line2Visible, setLine2Visible] = useState(true);
  const [line3Visible, setLine3Visible] = useState(true);
  const [line4Visible, setLine4Visible] = useState(true);

  const toggleLine1 = () => setLine1Visible(!line1Visible);
  const toggleLine2 = () => setLine2Visible(!line2Visible);
  const toggleLine3 = () => setLine3Visible(!line3Visible);
  const toggleLine4 = () => setLine4Visible(!line4Visible);

  // const callDataGraph = async (data) => {
  //   const formData = new FormData();
  //   const options = {
  //     headers: {
  //       Authorization: `Bearer ${tokens_session}`,
  //       "Content-Type": "multipart/form-data",
  //     },
  //   };
  //   formData.append("pembeli", "all");
  //   formData.append("pembeli", "all");
  //   formData.append("pk_code", "all");
  //   formData.append("kod_projek", "all");
  //   formData.append("kod_wilayah", "all");
  //   formData.append("jenis_tanaman", "Sawit, Padi, Getah");
  //   formData.append("account", "FB, JV");

  //   if (periodeState?.value === "all") {
  //     formData.append("start_date", startDate);
  //     formData.append("end_date", endDate);
  //     axios
  //       .post(
  //         `${apiUrl}/api/dashboard-lintramax/get-data-graph`,
  //         formData,
  //         options
  //       )
  //       .then((response) => {
  //         var responses = response.data.data;
  //         setData(responses.graph);
  //         setTopData([
  //           responses.penjualan,
  //           responses.penuaian_ffb,
  //           responses.penuaian_mt,
  //           responses.total_worker,
  //         ]);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   } else {
  //     formData.append("historical", periodeState.value);
  //     formData.append("start_year", startYear);
  //     formData.append("end_year", endYear);
  //     axios
  //       .post(
  //         `${apiUrl}/api/dashboard-lintramax/get-data-graph-using-year`,
  //         formData,
  //         options
  //       )
  //       .then((response) => {
  //         var responses = response.data.data;
  //         setData(responses.graph);
  //         setTopData([
  //           responses.penjualan,
  //           responses.penuaian_ffb,
  //           responses.penuaian_mt,
  //           responses.total_worker,
  //         ]);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   }
  // };

  const getPembeli = async () => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/lintramax/pembeli/get-data`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      if (res.data.status === 200) {
        setPembeli([
          { namaPembeli: "All Pembeli", id: "all" },
          ...res?.data?.data,
        ]);
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  // useEffect(() => {
  //   getPembeli();
  //   callDataGraph();
  // }, []);

  // useEffect(() => {
  //   callDataGraph();
  // }, [startDate, endDate, startYear, endYear, selectedPembeli, periodeState]);

  useEffect(() => {
    if (data) {
      d3.select(chartRef.current).selectAll("*").remove();
      // Parse the date/time
      const parseTime = d3.timeParse("%Y-%m-%d");
      const svg = d3.select(chartRef.current);
      // Set the dimensions and margins of the graph
      const margin = { top: 20, right: 0, bottom: 110, left: 0 };
      const margin2 = { top: 420, right: 0, bottom: 0, left: 0 };
      const width =
        svg.node().getBoundingClientRect().width - margin.left - margin.right;
      const height = 270 - margin.top - margin.bottom;
      svg.attr("width", width + margin.left + margin.right);
      svg.attr("height", height + margin.top + margin.bottom);
      const height2 = 270 - margin2.top - margin2.bottom;
      // Append the svg object to the chartRef.current
      const parentHeight =
        chartRef.current.parentNode.getBoundingClientRect().height;
      svg.attr("height", parentHeight);
      const parentWidth =
        chartRef.current.parentNode.getBoundingClientRect().width;
      svg.attr("width", parentWidth);

      // Set the ranges
      const x = d3
        .scaleTime()
        .domain(d3.extent(data, (d) => new Date(d.date)))
        .range([0, width]);
      const x2 = d3
        .scaleTime()
        .domain(d3.extent(data, (d) => new Date(d.date)))
        .range([0, width]);

      const yScales = Array.from({ length: 5 }, () =>
        d3
          .scaleLinear()
          .domain([0, d3.max(data, (d) => d.value)])
          .range([height, 0])
      );

      const [y, y2, y3, y4, y5] = yScales;

      const ybScales = Array.from({ length: 5 }, () =>
        d3
          .scaleLinear()
          .domain([0, d3.max(data, (d) => d.value)])
          .range([height2, 0])
      );

      // Accessing the scales
      const [yb, yb2, yb3, yb4, yb5] = ybScales;

      // Define the line
      const line1 = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(parseTime(d.date)))
        .y((d) => y(d.netWeightInMt));

      const line2 = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(parseTime(d.date)))
        .y((d) => y2(d.ffbWeightInMt));

      const line3 = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(parseTime(d.date)))
        .y((d) => y3(d.ffbWeightInMt / d.areaHarvestInHa));

      const line4 = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(parseTime(d.date)))
        .y((d) => y4(d.minWorker));

      const line5 = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(parseTime(d.date)))
        .y((d) => y5(d.maxWorker));

      // B LINE

      const line1b = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(parseTime(d.date)))
        .y((d) => yb(d.netWeightInMt));

      const line2b = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(parseTime(d.date)))
        .y((d) => yb2(d.ffbWeightInMt));

      const line3b = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(parseTime(d.date)))
        .y((d) => yb3(d.ffbWeightInMt / d.areaHarvestInHa));

      const line4b = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(parseTime(d.date)))
        .y((d) => yb4(d.minWorker));

      const line5b = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(parseTime(d.date)))
        .y((d) => yb5(d.maxWorker));

      // Add the clip path
      svg
        .append("defs")
        .append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("width", width)
        .attr("height", height);

      // Add the main chart area
      const chart = svg
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`)
        .attr("class", "chart");

      // Add the brush chart area
      const context = svg
        .append("g")
        .attr("transform", `translate(${margin2.left},${margin2.top})`)
        .attr("class", "context");

      x.domain(
        d3.extent(data, (d) => {
          return parseTime(d.date);
        })
      );
      const maxY = d3.max(data, (d) => {
        return d.netWeightInMt;
      });
      const maxY2 = d3.max(data, (d) => {
        return d.ffbWeightInMt;
      });
      const maxY3 = d3.max(data, (d) => {
        return d.ffbWeightInMt / d.areaHarvestInHa;
      });
      const maxY4 = d3.max(data, (d) => {
        return d.minWorker;
      });
      const maxY5 = d3.max(data, (d) => {
        return d.maxWorker;
      });
      y.domain([0, maxY * 1.1]);
      y2.domain([0, maxY2 * 1.1]);
      y3.domain([0, maxY3 * 1.1]);
      y4.domain([0, maxY4 * 1.1]);
      y5.domain([0, maxY5 * 1.1]);

      // Batasi rentang sumbu y5 dengan nilai 110% dari maxY

      // Set the domains
      x2.domain(
        d3.extent(data, (d) => {
          return parseTime(d.date);
        })
      );
      yb.domain(d3.extent(data, (d) => d.netWeightInMt));
      yb2.domain(d3.extent(data, (d) => d.ffbWeightInMt));
      yb3.domain(d3.extent(data, (d) => d.ffbWeightInMt / d.areaHarvestInHa));
      yb4.domain(d3.extent(data, (d) => d.minWorker));
      yb5.domain(d3.extent(data, (d) => d.maxWorker));

      // BACKGROUND
      // Add a background to the X-axis based on date
      chart
        .append("rect")
        .attr("class", "x-axis-background")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height)
        .style("fill", "#3E3E3E");

      // Add a vertical line for each date on the X-axis
      // chart
      //   .selectAll(".vertical-line-group")
      //   .data(data)
      //   .enter()
      //   .append("g")
      //   .attr("class", "vertical-line-group")
      //   .attr("transform", (d) => `translate(${x(parseTime(d.date))},0)`)
      //   .append("line")
      //   .attr("class", "vertical-line")
      //   .attr("x1", 0)
      //   .attr("y1", 0)
      //   .attr("x2", 0)
      //   .attr("y2", height)
      //   .style("stroke", "#707070")
      //   .style("stroke-width", 0.5);

      // Add a background to the X-axis based on date
      context
        .append("rect")
        .attr("class", "x-axis-background")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height2)
        .style("fill", "#3E3E3E");

      const visibilities = [
        line1Visible,
        line2Visible,
        line3Visible,
        line4Visible,
        line4Visible,
      ];
      const lines = [line1, line2, line3, line4, line5];

      lines.forEach((line, i) => {
        chart
          .append("path")
          .datum(data)
          .attr("class", `line line${i + 1}`)
          .attr("d", line)
          .style("display", visibilities[i] ? "block" : "none")
          .on("mousemove", (event, d) => {
            showData(event, d);
          })
          .on("mouseout", () => d3.selectAll(".data-label").remove());
      });

      // Add the x axis
      // chart
      //   .append("g")
      //   .attr("class", "x axis")
      //   .attr("transform", `translate(0, ${height})`)
      //   .call(d3.axisBottom(x));

      // Add the y axis
      // Add the y axes to the chart area
      const yAxes = [y, y2, y3, y4, y5];

      yAxes.forEach((yAxis) => {
        chart
          .append("g")
          .attr("class", "y axis")
          .attr("transform", "translate(" + width + ", 0)")
          .call(d3.axisRight(yAxis));
      });
      // Add the area to the brush chart area

      const linesB = [line1b, line2b, line3b, line4b, line5b];

      linesB.forEach((line, i) => {
        context
          .append("path")
          .datum(data)
          .attr("class", `area line${i + 1}b`)
          .attr("d", line)
          .style("fill", "transparent");
      });

      // Finish

      // Add the brush
      const brush = d3
        .brushX()
        .extent([
          [0, 0],
          [width, height2],
        ])
        .on("brush end", brushed);

      // Add the zoom
      const zoom = d3
        .zoom()
        .scaleExtent([1, Infinity])
        .translateExtent([
          [0, 0],
          [width, height],
        ])
        .extent([
          [0, 0],
          [width, height],
        ])
        .on("zoom", zoomed);

      // Add the x axis to the brush chart area
      context
        .append("g")
        .attr("class", "x axis")
        .attr("transform", `translate(0, ${height2})`)
        .call(d3.axisBottom(x2));

      // Add the y axis to the brush chart area
      context.append("g").attr("class", "y axis").call(d3.axisLeft(yb));

      // Add the brush to the brush chart area
      context
        .append("g")
        .attr("class", "brush")
        .call(brush)
        .call(brush.move, x.range());

      // Add the zoom to the chart area
      svg
        .append("rect")
        .attr("class", "zoom")
        .attr("width", width)
        .attr("height", height)
        .attr("transform", `translate(${margin.left},${margin.top})`)
        .style("fill", "transparent")
        .call(zoom);

      function showData(event) {
        console.log("showData called with data:", data);
        const x0 = x.invert(d3.pointer(event, this)[0]);
        const bisectDate = d3.bisector((d) => new Date(d.date)).left;
        const i = bisectDate(data, x0, 1);
        const d0 = data[i - 1];
        const d1 = data[i];
        const d = x0 - new Date(d0.date) > new Date(d1.date) - x0 ? d1 : d0;
        const xPosition = x(new Date(d.date));
        const yPosition = y(d.netWeightInMt);

        // Remove any existing data labels
        d3.selectAll(".data-label").remove();

        // Add a new data label
        chart
          .append("rect")
          .attr("class", "data-label")
          .attr("x", xPosition - 105) // set the x position of the rectangle
          .attr("y", yPosition - 40) // set the y position of the rectangle
          .attr("width", 210) // set the width of the rectangle
          .attr("height", 100) // set the height of the rectangle
          .attr("fill", "black") // set the fill color of the rectangle
          .attr("rx", 5) // set the x-axis radius of the rectangle
          .attr("ry", 5); // set the y-axis radius of the rectangle

        chart
          .append("text")
          .attr("class", "data-label")
          .attr("x", xPosition)
          .attr("y", yPosition - 20)
          .attr("text-anchor", "middle")
          .attr("font-size", "12px")
          .attr("fill", "white")
          .text(`${d.date}`);

        chart
          .append("text")
          .attr("class", "data-label")
          .attr("x", xPosition)
          .attr("y", yPosition - 5)
          .attr("text-anchor", "middle")
          .attr("font-size", "12px")
          .attr("fill", "white")
          .text(`Penuaian: ${d.ffbWeightInMt.toFixed(1)} MT`);

        chart
          .append("text")
          .attr("class", "data-label")
          .attr("x", xPosition)
          .attr("y", yPosition + 10)
          .attr("text-anchor", "middle")
          .attr("font-size", "12px")
          .attr("fill", "white")
          .text(`Penjualan: ${d.netWeightInMt.toFixed(1)} MT`);

        chart
          .append("text")
          .attr("class", "data-label")
          .attr("x", xPosition)
          .attr("y", yPosition + 25)
          .attr("text-anchor", "middle")
          .attr("font-size", "12px")
          .attr("fill", "white")
          .text(
            `Penuaian: ${d.ffbWeightInMt / d.areaHarvestInHa.toFixed(1)} MT/ha`
          );

        chart
          .append("text")
          .attr("class", "data-label")
          .attr("x", xPosition)
          .attr("y", yPosition + 40)
          .attr("text-anchor", "middle")
          .attr("font-size", "12px")
          .attr("fill", "white")
          .text(`Min Worker: ${d.minWorker.toFixed(1)}`);

        chart
          .append("text")
          .attr("class", "data-label")
          .attr("x", xPosition)
          .attr("y", yPosition + 55)
          .attr("text-anchor", "middle")
          .attr("font-size", "12px")
          .attr("fill", "white")
          .text(`Max Worker: ${d.maxWorker.toFixed(1)}`);
      }
      // Add hover effect to lines and chart
      chart
        .selectAll(".zoom")
        .on("mousemove", function (event, d) {
          showData(event, d);
        })
        .on("mouseout", () => d3.selectAll(".data-label").remove());

      function brushed(event) {
        if (!event || !event.sourceEvent || !event.sourceEvent.type === "zoom")
          return;
        const s = event.selection || x2.range();
        x.domain(s.map(x2.invert, x2));
        chart.select(".line1").attr("d", line1);
        chart.select(".line2").attr("d", line2);
        chart.select(".line3").attr("d", line3);
        chart.select(".line4").attr("d", line4);
        chart.select(".line5").attr("d", line5);
        chart.select(".line1b").attr("d", line1b);
        chart.select(".line2b").attr("d", line2b);
        chart.select(".line3b").attr("d", line3b);
        chart.select(".line4b").attr("d", line4b);
        chart.select(".line5b").attr("d", line5b);
        chart.select(".x.axis").call(d3.axisBottom(x));
        svg
          .select(".zoom")
          .call(
            zoom.transform,
            d3.zoomIdentity.scale(width / (s[1] - s[0])).translate(-s[0], 0)
          );

        // Update the position of the vertical lines based on the new x scale
        chart
          .selectAll(".vertical-line-group")
          .attr("transform", (d) => `translate(${x(parseTime(d.date))},0)`);
      }

      function zoomed(event) {
        if (!event || !event.sourceEvent || !event.sourceEvent.type === "brush")
          return;
        const t = event.transform;
        x.domain(t.rescaleX(x2).domain());
        chart.select(".line1").attr("d", line1);
        chart.select(".line2").attr("d", line2);
        chart.select(".line3").attr("d", line3);
        chart.select(".line4").attr("d", line4);
        chart.select(".line5").attr("d", line5);
        chart.select(".line1b").attr("d", line1b);
        chart.select(".line2b").attr("d", line2b);
        chart.select(".line3b").attr("d", line3b);
        chart.select(".line4b").attr("d", line4b);
        chart.select(".line5b").attr("d", line5b);
        chart.select(".x.axis").call(d3.axisBottom(x));
        context.select(".brush").call(brush.move, x.range().map(t.invertX, t));

        // Update the position of the vertical lines based on the new x scale
        chart
          .selectAll(".vertical-line-group")
          .attr("transform", (d) => `translate(${x(parseTime(d.date))},0)`);
      }
    }
  }, [
    line1Visible,
    line2Visible,
    line3Visible,
    line4Visible,
    data,
    props.windowWidth,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <div className="chart-container">
        <svg
          ref={chartRef}
          style={{ height: "fit-content", width: "100%" }}
        ></svg>
      </div>
    </ThemeProvider>
  );
};

export default LintramaxWidget;
