import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import axios from "axios";
import nookies, { setCookie, destroyCookie } from "nookies";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Modal,
  OutlinedInput,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";

import loginBG from "../../assets/images/loginBG.jpg";
import loginBGMobile from "../../assets/images/LoginMobile.jpg";
import logoFelcra from "../../assets/images/felcraLogo.png";
import logoFelcraMobile from "../../assets/images/felcraLogoMobile.png";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Language } from "../../context/LanguageContext";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
});

export default function SignInSide() {
  const [alertSuccess, setAlertSuccess] = useState("");
  const [alertFailed, setAlertFailed] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [rememberToken, setRememberToken] = useState("");
  const [email, setEmail] = useState("");
  const [cookied, setCookied] = useState();
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [emailForgotPswd, setEmailForgotPswd] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const userAgent = navigator.userAgent;
  const strSplit = "12cookie12";
  const { language, newObj } = Language();
  const handleSubmit = async (event) => {
    let tokenn = rememberToken;
    if (rememberToken === "") {
      tokenn = "";
    } else {
      const emaill = cookied.split(strSplit)[1];
      const decoded = decodeURIComponent(emaill);

      if (decoded !== email) {
        tokenn = "";
      }
    }

    event.preventDefault();
    try {
      const res = await axios.post(
        `${apiUrl}/api/auth/login`,
        {
          username: email,
          password: password,
          user_agent: userAgent,
          remember_status: false,
          token: "",
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.status === 200) {
        const encrypted = await CryptoJS.AES.encrypt(
          JSON.stringify({ email, password }),
          secretKey
        );
        destroyCookie(null, "rememberMe");
        if (rememberMe) {
          // setCookie(
          //   null,
          //   "rememberMe",
          //   res.data.remember_token + strSplit + res.data.username,
          //   {
          //     maxAge: 7 * 24 * 60 * 60,
          //   }
          // );
          setCookie(null, "rememberMe", encrypted, {
            maxAge: 7 * 24 * 60 * 60,
          });
        }
        setCookie(null, "token", res.data.token, {
          maxAge: 1800,
          path: "/",
        });
        navigate("/");
      } else {
        setAlertFailed(res.data.message);
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const getCookie = (name) => {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + "=")) {
          return cookie.substring(name.length + 1);
        }
      }
      return null;
    };

    const myCookie = getCookie("rememberMe");
    setCookied(myCookie);
  }, []);

  useEffect(() => {
    if (cookied) {
      // const email = cookied.split(strSplit)[1];
      // const decoded = decodeURIComponent(email);
      const remToken = cookied.split(strSplit)[0];
      const cookieVal = nookies.get();
      const decrypted = CryptoJS.AES.decrypt(cookieVal.rememberMe, secretKey);
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
      const parsedData = JSON.parse(decryptedData);
      setEmail(parsedData.email);
      setPassword(parsedData.password);
      setRememberToken(decodeURIComponent(remToken));
      setRememberMe(true);
    } else setRememberMe(false);
  }, [cookied]);

  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMdScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLgScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleSubmitModal = async () => {
    try {
      setDisableButton(true);
      const res = await axios.post(
        `${apiUrl}/api/auth/forget-password`,
        {
          email: emailForgotPswd,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        setShowModal(false);
        setDisableButton(false);
        setEmailForgotPswd("");
        Swal.fire({
          icon: "success",
          title: newObj.SuccessWord[language],
          text: res.data.message,
        });
      }
    } catch (error) {
      setDisableButton(false);
      setEmailForgotPswd("");
      console.log(error);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isXsScreen ? 300 : 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    p: 4,
    borderRadius: "12px",
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sm={12}
        sx={{
          minHeight: "100vh",
          marginTop: "-50px",
          backgroundImage: isXsScreen
            ? `url(${loginBGMobile})`
            : isSmScreen
            ? `url(${loginBGMobile})`
            : `url(${loginBG})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          square
          sx={{ position: "relative", height: "100%" }}
        >
          <Box
            alignItems={isXsScreen ? "center" : isSmScreen ? "center" : "left"}
            sx={{
              my: 16,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              margin: isXsScreen ? "10px" : "25px",
              height: isXsScreen ? "calc(100vh - 20px)" : "calc(100vh - 50px)",
              //alignItems: "flex-start",
            }}
          >
            <img
              src={
                isXsScreen
                  ? logoFelcraMobile
                  : isSmScreen
                  ? logoFelcraMobile
                  : logoFelcra
              }
              alt="Logo Felcra"
              style={{
                width: "100%",
                maxWidth: "150px",
                position: isXsScreen
                  ? "initial"
                  : isSmScreen
                  ? "initial"
                  : "absolute",
                top: "5%",
                left: isMdScreen ? "calc(25% - 75px)" : "calc(25% - 100px)",
              }}
            />

            <div
              style={{
                TextAlign: isXsScreen ? "center" : "left",
                position: "absolute",
                bottom: isXsScreen
                  ? "25px"
                  : isSmScreen
                  ? "50px"
                  : "calc(50vh - 250px)",
                left: isXsScreen
                  ? "auto"
                  : isSmScreen
                  ? "auto"
                  : isMdScreen
                  ? "calc(25% - 75px)"
                  : "calc(25% - 100px)",
                width: isXsScreen
                  ? "calc(100% - 25px)"
                  : isSmScreen
                  ? "100%"
                  : isLgScreen
                  ? "100%"
                  : isXlScreen
                  ? "65%"
                  : "max-content",
              }}
            >
              <Typography
                component="h1"
                variant="h1"
                mt={7}
                mb={1}
                sx={{
                  fontFamily: "'Montserrat', sans-serif;",
                  fontSize: "24pt",
                  color: "#2A3384",
                  fontWeight: "bold",
                }}
              >
                {newObj.LoginWord[language]}
              </Typography>
              <Typography component="p" variant="p" mt={1} mb={1}>
                {newObj.PleaseinsertyourusenameandpasswordtologinWord[language]}
              </Typography>

              {alertSuccess && (
                <Alert variant="outlined" severity="success">
                  {alertSuccess}
                </Alert>
              )}
              {alertFailed && (
                <Alert variant="outlined" severity="warning">
                  {alertFailed}
                </Alert>
              )}

              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    border: "1px solid #ccc",
                    borderRadius: "10px 10px 0px 0px",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0px 10px",
                    marginBottom: "2px",
                    height: "60px",
                  }}
                >
                  <AccountCircleOutlinedIcon
                    sx={{
                      color: "action.active",
                      mr: 1,
                      my: 0.5,
                      fontSize: 32,
                    }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="username"
                    label=""
                    value={email}
                    placeholder={newObj.UsernameWord[language]}
                    name="username"
                    onChange={(e) => setEmail(e.target.value)}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      style: { fontFamily: "Montserrat, sans-serif " },
                    }}
                    InputLabelProps={{ shrink: false }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    border: "1px solid #ccc",
                    borderRadius: "0px 0px 10px 10px",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0px 10px",
                    height: "60px",
                  }}
                >
                  <LockOutlinedIcon
                    sx={{
                      color: "action.active",
                      mr: 1,
                      my: 0.5,
                      fontSize: 32,
                    }}
                  />
                  <TextField
                    required
                    fullWidth
                    value={password}
                    id="password"
                    name="password"
                    label=""
                    placeholder={newObj.PasswordWord[language]}
                    type="password"
                    variant="standard"
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                      style: { fontFamily: "Montserrat, sans-serif " },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rememberMe}
                        color="primary"
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                    }
                    label={newObj.RememberMeWord[language]}
                  />

                  <Link
                    component={"button"}
                    type="button"
                    underline={"hover"}
                    onClick={handleOpen}
                  >
                    {newObj.ForgotPasswordWord[language]}?
                  </Link>
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{
                    marginTop: "16px",
                    // marginBottom: 0,
                    height: "60px",
                    color: "#FFFFFF",
                    background: "#2B337D",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                    fontSize: "14pt",
                    "&:hover": { background: "#2B337D" },
                  }}
                >
                  {newObj.LoginWord[language]}
                </Button>
              </Box>
            </div>
          </Box>
        </Grid>
        <Grid item xs={false} sm={4} md={7} />
      </Grid>
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ marginBottom: "20px" }}
          >
            {"Forgot Password"}
          </Typography>
          <IconButton
            sx={{ position: "absolute", top: "10px", right: "15px" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              flexDirection: "column",
            }}
            noValidate
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitModal();
            }}
          >
            <Box
              sx={{
                textAlign: "justify",
              }}
            >
              Make sure you enter a registered email address. After this, we'll
              send a confirmation email to that address with further
              instructions regarding reset password.
            </Box>
            <FormControl sx={{ my: 1, width: "100%" }} variant="outlined">
              <InputLabel>Email</InputLabel>
              <OutlinedInput
                type={"email"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      // onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    ></IconButton>
                  </InputAdornment>
                }
                label="Email"
                onChange={(e) => setEmailForgotPswd(e.target.value)}
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              disabled={disableButton}
              style={{
                width: "100%",
                padding: 15,
                backgroundColor: "rgb(43, 51, 125)",
                cursor: "pointer",
                color: "white",
                marginTop: "20px",
                borderRadius: "10px",
              }}
            >
              {"Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
