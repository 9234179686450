import React, { useEffect, useState } from "react";
import { Container, useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import axios from "axios";
import nookies, { destroyCookie } from "nookies";

import SearchInput from "../../components/SearchInput";
import MapFilesSelection from "../../components/MapFilesSelection";

import "./index.scss";

import macWP from "../../assets/images/macWP.jpg";
import iconDelete from "../../assets/images/icon-delete.svg";
import iconDownload from "../../assets/images/download.svg";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import PageNav from "../../components/PageNav";
import { Language } from "../../context/LanguageContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const LintramaxArchiveFiles = () => {
  const cookies = nookies.get();
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const tokens_session = cookies.token;
  const navigate = useNavigate();
  const { language, newObj } = Language();

  const [mapFileData, setMapFileData] = useState([]);
  const [archiveData, setArchiveData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState(0);
  const [tableToggle, setTableToggle] = useState(0); //0 = Weather, 1 = Soil
  const [isLoad, setLoad] = useState(true);
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
  });
  useEffect(() => {
    const countCheckedObjects = archiveData.reduce(
      (acc, obj) => (obj.checked ? acc + 1 : acc),
      0
    );

    setSelectedOption(countCheckedObjects);
  }, [archiveData]);

  const getAllMapArchive = async () => {
    try {
      const res = await axios.get(`${apiUrl}/api/archive/lintramax/list`, {
        headers: {
          Authorization: `Bearer ${tokens_session}`,
        },
      });
      if (res.data.status === 200) {
        const data = res?.data?.data;
        const dataWithCheck = data.map((item) => ({
          ...item,
          checked: false,
        }));
        setArchiveData(dataWithCheck);
      } else if (res.data.status === 500) {
        setArchiveData([]);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      setLoad(false);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const postArchivedDownload = async (files, filename, fileExt) => {
    let extType = "";
    if (fileExt === "zip") {
      extType = "application/zip";
    } else if (fileExt === "xlsx" || fileExt === "xls") {
      if (fileExt === "xls") extType = "application/vnd.ms-excel";
      else
        extType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    } else if (fileExt === "pdf") {
      extType = "application/pdf";
    } else if (fileExt === "docx" || fileExt === "doc") {
      if (fileExt === "doc") extType = "application/msword";
      else
        extType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    }
    const fetchFile = await axios
      .post(
        `${apiUrl}/api/archive/lintramax/download`,
        {
          filename: files,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer",
        }
      )
      .then(({ data }) => {
        const fileName = filename;
        const uint8Array = new Uint8Array(data);
        const blob = new Blob([uint8Array], { type: extType });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.setAttribute("target", "_blank");
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch(() => null);
  };

  const postArchivedDelete = async (files) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/archive/lintramax/delete`,
        {
          files,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      if (res.data.status === 200) {
        Swal.fire({
          icon: "success",
          title: newObj.SuccessWord[language],
          text: newObj.DeleteArchiveFileWord[language],
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            window.location.reload();
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Delete Archive Files",
        });
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      setLoad(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
      throw error;
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDownload = () => {
    const filteredObjects = archiveData
      .filter((obj) => obj.checked)
      .map((el) => el);

    // console.log(filteredObjects[0]);
    filteredObjects.forEach((el, i) => {
      postArchivedDownload(
        filteredObjects[i].key,
        filteredObjects[i].name,
        filteredObjects[i].fileExt
      );
    });
  };

  const handleDeleteArchive = () => {
    const filteredObjects = archiveData
      .filter((obj) => obj.checked)
      .map(({ id, key }) => ({ filename: key, id }));

    console.log(filteredObjects);
    postArchivedDelete(filteredObjects);
  };

  const handleSelectionChange = (data) => {
    setArchiveData(data);
  };

  useEffect(() => {
    getAllMapArchive();
  }, [tableToggle]);

  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  if (!isLoad)
    return (
      <ThemeProvider theme={theme}>
        <Box
          style={{
            backgroundImage: `url(${macWP})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            height: "100vh",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            padding: isXsScreen || isSmScreen ? "10px" : "20px 10px",
          }}
        >
          {/* nav */}
          <PageNav
            title={newObj.LintraMaxArchiveFilesWord[language]}
            href="/adminpage"
          />

          <Box
            style={{
              minHeight: "78vh",
              borderRadius: "5px",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              marginTop: "70px",
              width: "100%",
            }}
          >
            <Box style={{ width: "100%" }}>
              <Box
                style={{
                  borderBottom: "1px solid lightgrey",
                  padding: "0 15px",
                  borderColor: "divider",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* tabs */}
                  <div style={{ display: "flex" }}>
                    <Box
                      style={{
                        padding: "10px 20px 10px 20px",
                        borderRadius: "5px",
                        backgroundColor: "#2B5180",
                        cursor: "pointer",
                        marginLeft: "10px",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        width: "max-content",
                      }}
                      onClick={() => setTableToggle(3)}
                    >
                      <FolderCopyOutlinedIcon />
                      <span className="archiveText">Archive</span>
                    </Box>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "5px",
                    }}
                  >
                    <SearchInput
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <div
                      className="buttonContainer"
                      style={{
                        marginLeft: "20px",
                        borderLeft: "1px solid lightgrey",
                        display: "flex",
                        alignItems: "center",
                        padding: "20px",
                        gap: "20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: "8px",
                        }}
                        onClick={handleDeleteArchive}
                      >
                        <img src={iconDelete} alt="download" width={"20px"} />
                        <span className="archiveText">Delete</span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor:
                            // selectedOption > 1 ? "not-allowed" :
                            "pointer",
                          gap: "8px",
                        }}
                        onClick={() => {
                          // if (selectedOption === 1) {
                          handleDownload();
                          // }
                        }}
                      >
                        <img src={iconDownload} alt="download" width={"20px"} />
                        <span className="archiveText">Download</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
              <div style={{ padding: "15px" }}>
                <Grid
                  container
                  spacing={0}
                  display="flex"
                  alignItems="center"
                  textAlign="center"
                  padding="0"
                >
                  <MapFilesSelection
                    data={archiveData}
                    search={searchTerm}
                    onSelectionChange={handleSelectionChange}
                  />
                </Grid>
              </div>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
};

export default LintramaxArchiveFiles;
