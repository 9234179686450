import "./index.scss";
import { Box, Grid } from "@mui/material";
import PageNav from "../../components/PageNav";
import { useState, useEffect, useRef } from "react";
import { Language } from "../../context/LanguageContext";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import iotLogo from "../../assets/images/iotlogo.png";
import stationBg from "../../assets/images/iot_Station_bg.jpg";
import portableBg from "../../assets/images/iot_portable_bg.jpg";
import locationLogo from "../../assets/images/location.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Loader } from "../../components/Loader";
import axios from "axios";
import nookies, { destroyCookie } from "nookies";
import L, { map } from "leaflet";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  ZoomControl,
  useMapEvents,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";

export const IOTGraphDataDetail = (props) => {
  let apiUrl = process.env.REACT_APP_MAIN_URL;

  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const navigate = useNavigate();
  const { language, newObj } = Language();
  const { data } = useParams();
  const [deviceDetail, setDeviceDetail] = useState();
  const [weatherObject, setWeatherObject] = useState([]);
  const [isLoad, setIsLoad] = useState([]);
  const [displayCode, setDisplayCode] = useState(false);
  const [geoJsonLayer, setGeoJsonLayer] = useState(null);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const mapRef = useRef();
  const lat = Number(searchParams.get("lat"));
  const long = Number(searchParams.get("long"));
  const locName = searchParams.get("locName");
  useEffect(() => {
    const getDeviceDetail = async (id) => {
      try {
        const res = await axios.post(
          `${apiUrl}/api/device/detail`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.data.status === 200) {
          setDeviceDetail(res.data.data);
        }
        if (res.data.status === 401) {
          destroyCookie(null, "token");
          navigate("/login");
        }
        return res.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    if (id) {
      getDeviceDetail(id);
    }
  }, [id]);

  useEffect(() => {
    const getDataTypeList = async () => {
      try {
        const res = await axios.post(
          `${apiUrl}/api/data-type/list`,
          {
            category: deviceDetail.category,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.data.status === 200) {
          let dataTypeList = res.data.data.map((el) => {
            if (
              !el.checked &&
              el.label === "temperature"
              // || el.label === "time"
            )
              el.checked = true;
            else if (!el.checked) el.checked = false;

            if (el.label === "time") {
              el.headerName = "Masa";
              el.width = 80;
            } else {
              el.headerName = el.name;
              el.width = 130;
            }
            el.field = el.label;
            return el;
          });

          setWeatherObject(dataTypeList);
          setIsLoad(false);
        }
        if (res.data.status === 401) {
          destroyCookie(null, "token");
          navigate("/login");
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (deviceDetail) getDataTypeList();
  }, [deviceDetail]);

  useEffect(() => {
    const dummyData = [
      {
        // luas_bertanam: 8141.6,
        // jenis_tanaman: {
        //   padi: false,
        //   sawit: true,
        //   getah: true,
        // },
        // luas_ladang_ac: 15507.4,
        latitude: lat,
        // luas_berhasil: 5498.8,
        // id: "63dbcea04ea68c709e262a5a",
        title: locName,
        // luas_ladang_ha: 6275.6,
        longitude: long,
      },
    ];

    const geoJsonFeatures = dummyData.map((item) => ({
      type: "Feature",
      properties: {
        name: item.title,
        // id: item.id,
        latitude: item.latitude,
        longitude: item.longitude,
        // code: item.code,
        // wilayah_id: item.wilayah_id,
      },
      geometry: {
        type: "Point",
        coordinates: [item.longitude, item.latitude],
      },
    }));
    const geoJson = {
      type: "FeatureCollection",
      features: geoJsonFeatures,
    };
    setGeoJsonLayer(geoJson);
  }, []);

  const mapMarginTop = document.getElementsByClassName("headerFelcra")[0]
    ? document.getElementsByClassName("headerFelcra")[0].getBoundingClientRect()
        .height
    : 60;
  const pointToLayer = (feature, latlng) => {
    const displayTooltipCode =
      displayCode === true ? feature.properties.code : "";
    const icon = L.icon({
      iconUrl: locationLogo,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [10, -18],
    });
    const tooltip = L.tooltip({
      direction: "right",
      permanent: "true",
      opacity: 1,
      arrow: false,
      interactive: true,
      className: "custom-tooltip",
      riseOffset: 800,
      riseOnHover: true,
    }).setContent(displayTooltipCode + " " + feature.properties.name);
    const marker = L.marker(latlng, {
      icon,
      className: "custom-tooltip",
      riseOnHover: true,
    }).bindTooltip(tooltip);

    return marker;
  };
  if (isLoad) return <Loader />;
  return (
    <div>
      <div
        className="mapGeneratedMainIoT"
        style={{
          height: `calc(100vh - ${mapMarginTop})`,
        }}
      >
        <MapContainer
          center={[lat, long]}
          className="mapContainerIoT"
          zoomControl={false}
          zoom={16}
          ref={mapRef}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <div className="mapWrapperIoT">
            <Box className="topHeaderIoT">
              <PageNav title={newObj.IoTWord[language]} href="/iot" />
            </Box>
          </div>
          <div>
            <ZoomControl position={"bottomright"} />
          </div>

          {geoJsonLayer && (
            <GeoJSON
              data={geoJsonLayer}
              pointToLayer={pointToLayer}
              // onEachFeature={OnFeature}
            ></GeoJSON>
          )}
        </MapContainer>
      </div>
      <Grid className="dataTypeContainerIoT">
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignCenter: "center",
            height: "100%",
            width: "100%",
            flexDirection: "column",
            background: "rgba(255, 255, 255, 0.6)",
          }}
        >
          <div
            style={{
              width: "100%",
              borderBottom: "1px solid grey",
              flex: "0 0 auto",
            }}
          >
            {/* image */}
            <div style={{ height: "150px", position: "relative" }}>
              <img
                src={deviceDetail.type === "portable" ? portableBg : stationBg}
                alt="imageIot"
                style={{
                  backgroundColor: "gray",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  padding: "10px",
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
                }}
              >
                <Link to="/iot">
                  <ChevronLeftIcon style={{ fontSize: "30px" }} />
                </Link>
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  padding: "10px 20px",
                  ...(deviceDetail.type !== "portable"
                    ? { backgroundColor: "rgba(255,255,255,0.3)" }
                    : { backgroundColor: "rgba(0,0,0,0.6)" }),
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  gap: "10px",
                  ...(deviceDetail.type === "portable"
                    ? { color: "white" }
                    : { color: "black" }),
                }}
              >
                <img src={iotLogo} width={"30px"} alt="logo" />
                {deviceDetail.name}
              </div>
            </div>

            <div
              style={{
                padding: "20px 20px",
              }}
            >
              <Grid container>
                <Grid xs={6}>Device Type</Grid>
                <Grid xs={6}>{deviceDetail.category}</Grid>
              </Grid>
              {deviceDetail.pkCode && (
                <>
                  {" "}
                  <Grid container style={{ margin: "20px 0" }}>
                    <Grid xs={6}>{newObj.PKKodWord[language]}</Grid>
                    <Grid xs={6}>PK{deviceDetail.pkCode}</Grid>
                  </Grid>
                  <Grid container>
                    <Grid xs={6}>{newObj.ProjekKodWord[language]}</Grid>
                    <Grid xs={6}>{deviceDetail.projectCode}</Grid>
                  </Grid>
                </>
              )}
            </div>
          </div>

          <div
            style={{
              overflowY: "auto",
              flex: "1 1 auto",
            }}
          >
            <div style={{ fontWeight: "bold", padding: "10px 20px" }}>
              Data Type
            </div>
            <Box>
              {weatherObject.map((obj, index) => (
                <div style={{ padding: "8px" }} key={obj.id}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // ...(obj.checked && { backgroundColor: "#003d6d" }),
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <img
                        src={obj.icon ? obj.icon : iotLogo}
                        alt={"title"}
                        style={{ width: "30px" }}
                      />

                      <span
                        style={{
                          fontWeight: "bold",
                          // ...(obj.checked
                          //   ? { color: "white" }
                          //   : { color: "black" }),
                        }}
                      >
                        {obj.name}
                      </span>
                    </div>

                    {/* <input
                      type="checkbox"
                      disabled={checkedCount >= 3 && !obj.checked}
                      id={"check" + obj.id}
                      checked={obj.checked}
                      onChange={(e) => handleChecked(e, index)}
                    /> */}
                  </Box>
                </div>
              ))}
            </Box>
          </div>
        </Box>
      </Grid>
    </div>
  );
};
