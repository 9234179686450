import React, { useState, useEffect } from "react";
import { Box, createTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import nookies from "nookies";

import PageNav from "../../components/PageNav";

import macWP from "../../assets/images/macWP.jpg";
import { Language } from "../../context/LanguageContext";

const UserList = () => {
  const apiUrl = process.env.REACT_APP_MAIN_URL;

  const { language, newObj } = Language();
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const navigate = useNavigate();
  const [userListData, setUserListData] = useState([]);
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
  });
  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const columns = [
    { field: "id", headerName: "ID", width: 350 },
    {
      field: "username",
      headerName: newObj.UsernameWord[language],
      width: 200,
    },
    {
      field: "firstname",
      headerName: newObj.FirstNameWord[language],
      width: 200,
    },
    {
      field: "lastname",
      headerName: newObj.LastNameWord[language],
      width: 200,
    },
    {
      field: "phone",
      headerName: newObj.ContactNumberWord[language],
      width: 200,
    },
    {
      field: "status",
      headerName: newObj.StatusWord[language],
      width: 200,
      valueGetter: (params) => (params.row.status ? "Active" : "Inactive"),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      options: {
        filter: false,
        sort: false,
      },
      getActions: (params) => [
        <GridActionsCellItem
          disabled={params.row.username === "system" ? true : false}
          icon={<EditIcon />}
          label="Edit"
          onClick={() => navigate(`/userlist/${params.id}`, { replace: false })}
        />,
      ],
    },
  ];

  useEffect(() => {
    const getDataParam = async () => {
      try {
        const res = await axios.post(
          `${apiUrl}/api/admin/user/list`,
          {},
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
            },
          }
        );
        if (res.status === 200) {
          setUserListData(res.data.data);
        }
        return res.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
    getDataParam();
  }, [apiUrl, tokens_session]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundImage: `url(${macWP})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingTop: isXsScreen || isSmScreen ? "10px" : "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <PageNav title="Users" href="/adminpage" />

        <Grid
          sx={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            padding: "10px",
            paddingTop: "60px",
            width: "100%",
          }}
        >
          <div
            style={{
              height: userListData.length > 0 ? "auto" : "100vh",
              width: "100%",
            }}
          >
            <DataGrid
              className="custom-table"
              columns={columns}
              rows={userListData}
              pageSize={10}
              rowsPerPageOptions={[10]}
              autoHeight={userListData.length > 0 ? true : false}
              localeText={{ noRowsLabel: newObj.NoDataWord[language] }}
            />
          </div>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default UserList;
