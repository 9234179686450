import React, {useEffect, useRef, useState} from 'react';
import nookies, {destroyCookie} from 'nookies';
import * as d3 from 'd3';
import axios from 'axios';
import {CircularProgress} from '@material-ui/core';

import moment from 'moment';
import close from '../assets/images/failed.png';
import eventDataLogo from '../assets/images/Events.png';
import eventDataLogoRBg from '../assets/images/events-removebg.png';
import btmChartDrag from '../assets/images/btmChartDrag.png';

import {Language} from '../context/LanguageContext';
import {useNavigate} from 'react-router-dom';
import {IoT} from '../context/IoTContext';

const IOTChart = (props) => {
  const {language, newObj} = Language();
  const {startDate, setStartDate, endDate, setEndDate, isLoad, setIsLoad} =
    IoT();
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  // const [isLoad, setIsLoad] = useState(true);
  const today = moment().format('YYYY/MM/DD');
  const date90DaysAgo = moment(today, 'YYYY/MM/DD')
    .subtract(90, 'days')
    .format('YYYY/MM/DD');
  const [data, setData] = useState([]);
  const [dataBot, setDataBot] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [isInit, setIsInit] = useState(false);
  // const [startDate, setStartDate] = useState(today);
  // const [endDate, setEndDate] = useState(date90DaysAgo);
  const chartRef = useRef(null);
  const [count, setCount] = useState(0);
  const [line1Visible, setLine1Visible] = useState(false);
  const [line2Visible, setLine2Visible] = useState(false);
  const [line3Visible, setLine3Visible] = useState(false);

  const [lineOne, setLineOne] = useState();
  const [lineTwo, setLineTwo] = useState();
  const [lineThree, setLineThree] = useState();

  const dateAndTime = (time) => {
    var date = new Date(time);
    var result =
      date.getFullYear() +
      '-' +
      `${date.getMonth() + 1}` +
      '-' +
      `${date.getDate()}` +
      ' ' +
      `${date.getHours()}:${date.getMinutes()}`;
    return result;
  };

  const dateAndTimeEvent = (time) => {
    var date = new Date(time);
    var result =
      date.getFullYear() +
      '-' +
      `${date.getMonth() + 1}` +
      '-' +
      `${date.getDate()}` +
      ' ' +
      `${date.getHours()}:${date.getMinutes()}`;

    return result;
  };

  const getDataChart = async () => {
    try {
      setIsLoad(true);
      const res = await axios.post(
        `${apiUrl}/api/iot/get-data-grafik`,
        {
          device_id: props.deviceDetail.id,
          category: props.deviceDetail.category,
          first_load: isInit ? false : true,
          // start: `${moment(startDate).year()}/${
          //   moment(startDate).month() + 1
          // }/${moment(startDate).date()}`,
          // end: `${moment(endDate).year()}/${
          //   moment(endDate).month() + 1
          // }/${moment(endDate).date()}`,
          start: startDate,
          end: endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      if (res.data.status === 200) {
        const data = res.data.data.data;
        const eventData = res.data.data.event;
        // setStartDate(
        //   `${moment(data[0].time).year()}/${
        //     moment(data[0].time).month() + 1
        //   }/${moment(data[0].time).date()}`
        // );
        // setEndDate(
        //   `${moment(data[data.length - 1].time).year()}/${
        //     moment(data[data.length - 1].time).month() + 1
        //   }/${moment(data[data.length - 1].time).date()}`
        // );
        setData(data);
        setEventData(eventData);
        setStartDate(
          `${moment(data[0]?.time).year()}/${
            moment(data[0]?.time).month() + 1
          }/${moment(data[0]?.time).date()} ${moment(
            data[0]?.time
          ).hour()}:${moment(data[0]?.time).minute()}:${moment(
            data[0]?.time
          ).second()}`
        );
        setEndDate(
          `${moment(data[data.length - 1]?.time).year()}/${
            moment(data[data.length - 1]?.time).month() + 1
          }/${moment(data[data.length - 1]?.time).date()} ${moment(
            data[data.length - 1]?.time
          ).hour()}:${moment(data[data.length - 1]?.time).minute()}:${moment(
            data[data.length - 1]?.time
          ).second()}`
        );
        setIsLoad(false);
      }
      if (res.data.status === 401) {
        destroyCookie(null, 'token');
        navigate('/login');
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getDataBottomChart = async () => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/iot/get-bottom-chart`,
        {
          device_id: props.deviceDetail.id,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      if (res.data.status === 200) {
        setDataBot(res.data?.data?.graphList);
        setIsInit(true);
      }
      if (res.data.status === 401) {
        destroyCookie(null, 'token');
        navigate('/login');
      }
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isInit) getDataBottomChart();
  }, [isInit]);

  useEffect(() => {
    if (startDate && endDate) getDataChart();
    // props.dataReturn({
    //   startDate,
    //   endDate,
    // });
  }, [startDate, endDate]);

  useEffect(() => {
    setLineOne();
    setLineTwo();
    setLineThree();
    props.checkedWeather.forEach((element, index) => {
      if (element.checked === true) {
        if (index === 0) {
          setLineOne(element);
        } else if (index === 1) {
          setLineTwo(element);
        } else if (index === 2) {
          setLineThree(element);
        }
      }
    });
  }, [props]);

  // MAP
  useEffect(() => {
    if (data && dataBot) {
      // console.log(data, "data");
      d3.select(chartRef.current).selectAll('*').remove();
      // Parse the date/time
      const parseTime = d3.timeParse('%Y-%m-%d %H:%M');
      const svg = d3.select(chartRef.current);
      // Set the dimensions and margins of the graph
      const margin = {top: 20, right: 0, bottom: 110, left: 0};
      const margin2 = {top: 420, right: 0, bottom: 0, left: 0};
      const width =
        svg?.node()?.getBoundingClientRect()?.width -
        margin.left -
        margin.right;
      const height = 500 - margin.top - margin.bottom;
      svg.attr('width', width + margin.left + margin.right);
      svg.attr('height', height + margin.top + margin.bottom);
      const height2 = 500 - margin2.top - margin2.bottom;
      // Append the svg object to the chartRef.current
      const parentHeight =
        chartRef?.current?.parentNode?.getBoundingClientRect()?.height;
      svg.attr('height', parentHeight + 40);
      const parentWidth =
        chartRef?.current?.parentNode?.getBoundingClientRect()?.width;
      svg.attr('width', parentWidth);
      const longFormat = d3.timeFormat('%b');
      const shortFormat = d3.timeFormat('%d');
      const yearFormat = d3.timeFormat('%Y');
      // Set the ranges
      const x = d3
        .scaleTime()
        .domain(d3.extent(data, (d) => new Date(dateAndTime(d.time))))
        .range([0, width]);
      const x2 = d3
        .scaleTime()
        .domain(d3.extent(dataBot, (d) => new Date(dateAndTime(d.date))))
        .range([0, width]);

      const yScales = Array.from({length: 3}, () =>
        d3
          .scaleLinear()
          .domain([0, d3.max(data, (d) => d.value)])
          .range([height, 0])
      );

      const [y, y2, y3] = yScales;

      const ybScales = Array.from({length: 3}, () =>
        d3
          .scaleLinear()
          .domain([0, d3.max(dataBot, (d) => d.value)])
          .range([height2, 0])
      );

      // Accessing the scales
      const [yb, yb2, yb3] = ybScales;

      // Define the line
      const line1 = d3
        .line()
        .curve(d3.curveLinear)
        .x((d, i) => {
          return x(parseTime(dateAndTime(d.time)));
        })
        .y((d) => y(d[lineOne?.label]));

      const line2 = d3
        .line()
        .curve(d3.curveLinear)
        .x((d) => x(parseTime(dateAndTime(d.time))))
        .y((d) => y2(d[lineTwo?.label]));

      const line3 = d3
        .line()
        .curve(d3.curveLinear)
        .x((d) => x(parseTime(dateAndTime(d.time))))
        .y((d) => y3(d[lineThree?.label]));

      // B LINE
      const line1b = d3
        .line()
        .curve(d3.curveLinear)
        .x((d) => x2(parseTime(dateAndTime(d.date))))
        .y((d) => yb(d[lineOne?.label]));

      const line2b = d3
        .line()
        .curve(d3.curveLinear)
        .x((d) => x2(parseTime(dateAndTime(d.date))))
        .y((d) => yb2(d[lineTwo?.label]));

      const line3b = d3
        .line()
        .curve(d3.curveLinear)
        .x((d) => x2(parseTime(dateAndTime(d.date))))
        .y((d) => yb3(d[lineThree?.label]));

      // Add the clip path
      svg
        .append('defs')
        .append('clipPath')
        .attr('id', 'clip')
        .append('rect')
        .attr('width', width)
        .attr('height', height);

      // Add the main chart area
      const chart = svg
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)
        .attr('class', 'chart');

      // Add the brush chart area
      const context = svg
        .append('g')
        .attr('transform', `translate(${margin2.left},${margin2.top})`)
        .attr('class', 'context');

      x.domain(d3.extent(data, (d) => parseTime(dateAndTime(d.time))));
      y.domain(d3.extent(data, (d) => d[lineOne?.label]));
      y2.domain(d3.extent(data, (d) => d[lineTwo?.label]));
      y3.domain(d3.extent(data, (d) => d[lineThree?.label]));

      // Set the domains
      x2.domain(d3.extent(dataBot, (d) => parseTime(dateAndTime(d.date))));
      yb.domain(d3.extent(dataBot, (d) => d[lineOne?.label]));
      yb2.domain(d3.extent(dataBot, (d) => d[lineTwo?.label]));
      yb3.domain(d3.extent(dataBot, (d) => d[lineThree?.label]));

      // BACKGROUND
      // Add a background to the X-axis based on date
      chart
        .append('rect')
        .attr('class', 'x-axis-background')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', width)
        .attr('height', height)
        .style('fill', '#3E3E3E');

      // Add a vertical line for each date on the X-axis
      chart
        .selectAll('.vertical-line-group')
        .data(data)
        .enter()
        .append('g')
        .attr('class', 'vertical-line-group')
        .attr(
          'transform',
          (d) => `translate(${x(parseTime(dateAndTime(d.time)))},0)`
        )
        .append('line')
        .attr('class', 'vertical-line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', height)
        .style('stroke', '#707070')
        .style('opacity', '0.5')
        .style('stroke-width', 0.5);

      // Add a background to the X-axis based on date
      context
        .append('rect')
        .attr('class', 'x-axis-background')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', width)
        .attr('height', height2)
        .style('fill', '#3E3E3E');

      // const visibilities = [
      //   line1Visible,
      //   line2Visible,
      //   line3Visible,
      // ];

      const lines = [line1, line2, line3];
      if (props.windowWidth < 960)
        lines.forEach((line, i) => {
          chart
            .append('path')
            .datum(data)
            .attr(
              'class',
              `line line${i + 1} ${
                i === 0
                  ? lineOne?.label
                  : i === 1
                  ? lineTwo?.label
                  : lineThree?.label
              }`
            )
            .attr('d', line)
            .on('click', async (event, d) => {
              await showData(event, d);
              chart.selectAll('.eventData').raise();
            });
          // .on("mouseout", () => {
          //   d3.selectAll(".data-label").remove();
          // });
        });
      else
        lines.forEach((line, i) => {
          chart
            .append('path')
            .datum(data)
            .attr(
              'class',
              `line line${i + 1} ${
                i === 0
                  ? lineOne?.label
                  : i === 1
                  ? lineTwo?.label
                  : lineThree?.label
              }`
            )
            .attr('d', line)
            .on('mousemove', async (event, d) => {
              await showData(event, d);
              chart.selectAll('.eventData').raise();
            })
            .on('mouseout', () => {
              d3.selectAll('.data-label').remove();
            });
        });
      // Add the x axis
      chart
        .append('g')
        .attr('class', 'x axis')
        .attr('transform', `translate(0, ${height})`)
        .call(
          d3
            .axisBottom(x)
            .tickFormat((d, i) => {
              const ticks = d3.axisBottom(x).scale().ticks();

              if (i > 0 && ticks[i - 1].getMonth() === d.getMonth()) {
                return shortFormat(d);
              } else if (
                i > 0 &&
                ticks[i - 1].getFullYear() !== d.getFullYear()
              ) {
                return yearFormat(d);
              } else {
                return longFormat(d);
              }
            })
            .ticks(props.windowWidth < 600 ? 5 : 10)
        );

      // Add the y axis
      // Add the y axes to the chart area
      const yAxes = [y, y2, y3];

      yAxes.forEach((yAxis) => {
        chart
          .append('g')
          .attr('class', 'y axis')
          .attr('transform', 'translate(' + width + ', 0)')
          .call(d3.axisRight(yAxis));
      });
      // Add the area to the brush chart area

      const linesB = [line1b, line2b, line3b];

      linesB.forEach((line, i) => {
        context
          .append('path')
          .datum(dataBot)
          .attr(
            'class',
            `area line${i + 1}b ${
              i === 0
                ? lineOne?.label
                : i === 1
                ? lineTwo?.label
                : lineThree?.label
            }`
          )
          .attr('d', line)
          // .style("display", visibilities[i] ? "block" : "none")
          .style('fill', 'transparent');
      });
      const dateAndTimeOption = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      };

      eventData.forEach((event, i) => {
        chart
          .append('image')
          .attr('href', eventDataLogo)
          .style('cursor', 'pointer')
          .on('click', function () {
            chart.select(`.eventDataLogo${i}`).attr('display', 'none');

            chart
              .append('rect')
              .attr('class', `eventData${i} eventData`)
              .attr('x', x(parseTime(dateAndTime(event.fulldate))) - 80)
              .attr('y', 220)
              .attr('width', 0)
              .attr('height', 0)
              .transition()
              .delay(100)
              .duration(100)
              .attr('width', 200)
              .attr('height', 125)
              .attr('fill', 'white')
              .attr('rx', 0)
              .attr('ry', 0);

            chart
              .append('image')
              .attr('href', eventDataLogoRBg)
              .attr('width', 0)
              .attr('height', 0)
              .attr('class', `eventDataLogoSm${i} eventData`)
              .attr('x', x(parseTime(dateAndTime(event.fulldate))) - 85)
              .attr('y', 220)
              .transition()
              .delay(100)
              .duration(100)
              .attr('width', 35)
              .attr('height', 35);

            const foreignObject = chart
              .append('foreignObject')
              .attr('class', `eventDataTitle${i} eventData`)
              .attr('x', x(parseTime(dateAndTime(event.fulldate))) - 52.5)
              .attr('y', 230)
              .attr('width', 150) // Set the width to limit text width
              .attr('height', 25);

            const div = foreignObject
              .append('xhtml:div')
              .attr('class', `eventDataTitle${i} eventData`)
              .style('width', '100%')
              .style('height', '100%')
              .style('display', 'flex');
            // .style("align-items", "center")
            // .style("justify-content", "center");

            const text = div
              .append('p')
              .style('margin', '0')
              .attr('class', `eventDataTitle${i} eventData`)
              .attr('font-size', '0px')
              .transition()
              .delay(100)
              .duration(100)
              .style('font-size', '14px')
              .style('color', 'black')
              .style('font-weight', 'bold')
              .style('text-align', 'start')
              .style('max-width', '140px')
              .style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .text(`${event.title}`);

            const foreignObjectDesc = chart
              .append('foreignObject')
              .attr('class', `eventDataDescription${i} eventData`)
              .attr('x', x(parseTime(dateAndTime(event.fulldate))) - 61)
              .attr('y', 260)
              .attr('width', 180) // Set the width to limit text width
              .attr('height', 50);

            const divDesc = foreignObjectDesc
              .append('xhtml:div')
              .attr('class', `eventDataDescription${i} eventData`)
              .style('width', '100%')
              .style('height', '100%')
              .style('display', 'flex');
            // .style("align-items", "center")
            // .style("justify-content", "center");

            const textDesc = divDesc
              .append('p')
              .style('margin', '0')
              .attr('class', `eventDataDescription${i} eventData`)
              .attr('font-size', '0px')
              .transition()
              .delay(100)
              .duration(100)
              .style('font-size', '14px')
              .style('color', 'black')
              .style('font-weight', 'normal')
              .style('text-align', 'start')
              .style('width', '180px')
              .style('max-width', '180px')
              .style('word-wrap', 'break-word')
              .style('overflow-y', 'scroll')
              .text(`${event.description}`);

            chart
              .append('rect')
              .attr('x', x(parseTime(dateAndTime(event.fulldate))) - 80)
              .attr('y', 255)
              .attr('width', 0)
              .attr('height', 0)
              .transition()
              .delay(100)
              .duration(100)
              .attr('width', 200)
              .attr('height', 1)
              .attr('fill', '#7E7E7E')
              .attr('class', `eventDataHorizontalLine${i} eventData`);

            chart
              .append('text')
              .attr('x', x(parseTime(dateAndTime(event.fulldate))) - 60)
              .attr('y', 335)
              .attr('width', 0)
              .attr('height', 0)
              .transition()
              .delay(100)
              .duration(100)
              .attr('width', 200)
              .attr('height', 20)
              .attr('fill', '#7E7E7E')
              .attr('class', `eventDataDateTime${i} eventData`)
              .style('font-style', 'italic')
              .text(
                parseTime(dateAndTime(event.fulldate)).toLocaleDateString(
                  'en-GB',
                  dateAndTimeOption
                )
              );

            chart
              .append('image')
              .attr('href', close)
              .style('cursor', 'pointer')
              .on('click', () => {
                chart.selectAll(`.eventDataClose${i}`).remove();
                chart.selectAll(`.eventDataTitle${i}`).remove();
                chart.selectAll(`.eventDataDescription${i}`).remove();
                chart.selectAll(`.eventDataHorizontalLine${i}`).remove();
                chart.selectAll(`.eventData${i}`).remove();
                chart.selectAll(`.eventDataLogoSm${i}`).remove();
                chart.selectAll(`.eventDataDateTime${i}`).remove();
                chart.select(`.eventDataLogo${i}`).attr('display', 'block');
              })
              .attr('width', 0)
              .attr('height', 0)
              .attr('class', `eventDataClose${i} eventData`)
              .attr('x', x(parseTime(dateAndTime(event.fulldate))) + 90)
              .attr('y', 225)
              .transition()
              .delay(100)
              .duration(100)
              .attr('width', 25)
              .attr('height', 25);
          })
          .attr('width', 0)
          .attr('height', 0)
          .attr('class', `eventDataLogo${i} eventData`)
          .attr('x', x(parseTime(dateAndTimeEvent(event.fulldate))) - 12)
          .attr('y', 340)
          .transition()
          .delay(100)
          .duration(100)
          .attr('width', 25)
          .attr('height', 25);

        // .attr("opacity", 0.7);
      });

      // Finish

      // Add the brush
      const brush = d3
        .brushX()
        .extent([
          [0, 0],
          [width, height2],
        ])
        .on('end', brushedEnd)
        .on('brush', brushed);

      // Add the zoom
      const zoom = d3
        .zoom()
        .scaleExtent([1, Infinity])
        .translateExtent([
          [0, 0],
          [width, height],
        ])
        .extent([
          [0, 0],
          [width, height],
        ])
        .on('zoom', zoomed);

      // Add the x axis to the brush chart area
      context
        .append('g')
        .attr('class', 'x axis')
        .attr('transform', `translate(0, ${height2})`)
        .call(
          d3
            .axisBottom(x2)
            .tickFormat((d, i) => {
              const ticks = d3.axisBottom(x2).scale().ticks();

              if (i > 0 && ticks[i - 1].getMonth() === d.getMonth()) {
                return shortFormat(d);
              } else if (
                i > 0 &&
                ticks[i - 1].getFullYear() !== d.getFullYear()
              ) {
                return yearFormat(d);
              } else {
                return longFormat(d);
              }
            })
            .ticks(props.windowWidth < 600 ? 5 : 10)
        );

      // Add the y axis to the brush chart area
      context.append('g').attr('class', 'y axis').call(d3.axisLeft(yb));

      // Add the brush to the brush chart area
      const startDateBrush =
        d3.min(data, (d) => parseTime(dateAndTime(d.time))) <
        d3.min(dataBot, (d) => parseTime(dateAndTime(d.date)))
          ? d3.min(dataBot, (d) => parseTime(dateAndTime(d.date)))
          : d3.min(data, (d) => parseTime(dateAndTime(d.time)));
      const endDateBrush =
        d3.max(data, (d) => parseTime(dateAndTime(d.time))) >
        d3.max(dataBot, (d) => parseTime(dateAndTime(d.date)))
          ? d3.max(dataBot, (d) => parseTime(dateAndTime(d.date)))
          : d3.max(data, (d) => parseTime(dateAndTime(d.time)));
      // const startDateBrush = parseTime(dateAndTime(startDate));
      // const endDateBrush = parseTime(dateAndTime(endDate));
      // const startDateBrush = d3.min(data, (d) =>
      //   parseTime(dateAndTime(d.time))
      // );
      // const endDateBrush = d3.max(data, (d) => parseTime(dateAndTime(d.time)));

      context
        .append('g')
        .attr('class', 'brush')
        .call(brush)
        .call(brush.move, [x2(startDateBrush), x2(endDateBrush)]);

      context
        .select('.brush')
        .append('image')
        .attr('href', btmChartDrag)
        .attr('class', 'leftMarker')
        .attr('x', x2(startDateBrush) - 10) // Adjust the positioning as needed
        .attr('y', 30)
        .attr('width', 20)
        .attr('height', 20)
        .attr('fill', 'blue')
        .style('cursor', 'ew-resize');

      context
        .select('.brush')
        .append('image')
        .attr('href', btmChartDrag)
        .attr('class', 'rightMarker')
        .attr('x', x2(endDateBrush) - 10) // Adjust the positioning as needed
        .attr('y', 30)
        .attr('width', 20)
        .attr('height', 20)
        .attr('fill', 'blue')
        .style('cursor', 'ew-resize');

      context
        .select('.selection')
        // .attr("fill", "red")
        .attr('fill', 'rgba(255, 255, 255, 0.8)');

      context.selectAll('.handle').attr('width', 15).raise();
      context.select('.handle--w').attr('x', x2(startDateBrush) - 8);
      context.select('.handle--e').attr('x', x2(endDateBrush) - 8);

      // Add the zoom to the chart area
      // svg
      //   .append("rect")
      //   .attr("class", "zoom")
      //   .attr("width", width)
      //   .attr("height", height)
      //   .attr("transform", `translate(${margin.left},${margin.top})`)
      //   .style("fill", "transparent")
      //   .call(zoom);

      const lineHover = chart
        .append('line')
        .style('stroke', 'white')
        .attr('class', 'line-hover');

      const circle = chart
        .append('circle')
        .attr('class', `${lineOne?.label}Circle`)
        .attr('r', 0)
        // .attr("fill", "orange")
        .style('stroke', 'white')
        .attr('opacity', 0.7)
        .style('pointer-events', 'none');

      const circle2 = chart
        .append('circle')
        .attr('class', `${lineTwo?.label}Circle`)
        .attr('r', 0)
        // .attr("fill", "green")
        .style('stroke', 'white')
        .attr('opacity', 0.7)
        .style('pointer-events', 'none');

      const circle3 = chart
        .append('circle')
        .attr('class', `${lineThree?.label}Circle`)
        .attr('r', 0)
        // .attr("fill", "purple")
        .style('stroke', 'white')
        .attr('opacity', 0.7)
        .style('pointer-events', 'none');

      const circle4 = chart
        .append('circle')
        .attr('r', 0)
        .attr('fill', '#2196f3')
        .style('stroke', 'white')
        .attr('opacity', 0.7)
        .style('pointer-events', 'none');

      const circle5 = chart
        .append('circle')
        .attr('r', 0)
        .attr('fill', '#95c0ed')
        .style('stroke', 'white')
        .attr('opacity', 0.7)
        .style('pointer-events', 'none');

      const circle6 = chart
        .append('circle')
        .attr('r', 0)
        .attr('fill', '#d61355')
        .style('stroke', 'white')
        .attr('opacity', 0.7)
        .style('pointer-events', 'none');

      const circle7 = chart
        .append('circle')
        .attr('r', 0)
        .attr('fill', '#ff55bb')
        .style('stroke', 'white')
        .attr('opacity', 0.7)
        .style('pointer-events', 'none');

      const circle8 = chart
        .append('circle')
        .attr('r', 0)
        .attr('fill', '#f6fa70')
        .style('stroke', 'white')
        .attr('opacity', 0.7)
        .style('pointer-events', 'none');

      const circle9 = chart
        .append('circle')
        .attr('r', 0)
        .attr('fill', '#fc4f00')
        .style('stroke', 'white')
        .attr('opacity', 0.7)
        .style('pointer-events', 'none');

      const circle10 = chart
        .append('circle')
        .attr('r', 0)
        .attr('fill', '#e8d2a6')
        .style('stroke', 'white')
        .attr('opacity', 0.7)
        .style('pointer-events', 'none');

      function showData(event) {
        // console.log("showData called with data:", data);
        const x0 = x.invert(d3.pointer(event, this)[0]);
        const bisectDate = d3.bisector((d) =>
          parseTime(dateAndTime(d.time))
        ).left;
        const i = bisectDate(data, x0, 1);
        const d0 = data[i - 1];
        const d1 = data[i];
        const d =
          x0 - parseTime(dateAndTime(d0?.time)) >
          parseTime(dateAndTime(d1?.time)) - x0
            ? d1
            : d0;
        const xPosition = x(parseTime(dateAndTime(d?.time)));
        const yPosition = y(d[lineOne?.label]);
        const yPosition2 = y2(d[lineTwo?.label]);
        const yPosition3 = y3(d[lineThree?.label]);
        const showDataMap = {
          1: {
            circle: circle,
            lineVisible: line1Visible,
            color: 'orange',
            yPos: yPosition,
            value: lineOne?.label,
            unit:
              lineOne?.name.split('(').length > 1
                ? lineOne?.name.split('(')[1].slice(0, -1)
                : '',
          },

          2: {
            circle: circle2,
            lineVisible: line2Visible,
            color: 'green',
            yPos: yPosition2,
            value: lineTwo?.label,
            unit:
              lineTwo?.name.split('(').length > 1
                ? lineTwo?.name.split('(')[1].slice(0, -1)
                : '',
          },

          3: {
            circle: circle3,
            lineVisible: line3Visible,
            color: 'purple',
            yPos: yPosition3,
            value: lineThree?.label,
            unit:
              lineThree?.name.split('(').length > 1
                ? lineThree?.name.split('(')[1].slice(0, -1)
                : '',
          },
        };

        const arrYpos = props.checkedWeather.map((el, i) => {
          return showDataMap[i + 1].yPos;
        });
        const maxYPosition =
          Math.min(...arrYpos) > 30
            ? Math.min(...arrYpos)
            : Math.min(...arrYpos) + 30;
        const checkedWeatherLength = props.checkedWeather.length;
        const halfWidth = width / 2;
        const dateAndTimeOption = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        };
        const dateAndTimeLabel = parseTime(
          dateAndTime(d.time)
        ).toLocaleDateString('en-GB', dateAndTimeOption);
        // Remove any existing data labels
        d3.selectAll('.data-label').remove();

        // Add a new data label
        chart
          .append('rect')
          .attr('class', 'data-label')
          .attr('x', xPosition < halfWidth ? xPosition + 20 : xPosition - 170) // set the x position of the rectangle
          .attr('y', maxYPosition - 40) // set the y position of the rectangle
          .attr('width', 150) // set the width of the rectangle
          .attr(
            'height',
            checkedWeatherLength === 1
              ? 60
              : checkedWeatherLength === 2
              ? 75
              : 95
          ) // set the height of the rectangle
          .attr('fill', 'rgba(255, 255, 255, 0.7)') // set the fill color of the rectangle
          .attr('rx', 5) // set the x-axis radius of the rectangle
          .attr('ry', 5); // set the y-axis radius of the rectangle

        chart
          .append('text')
          .attr('class', 'data-label')
          .attr(
            'x',
            xPosition < halfWidth ? xPosition + 120 - 90 : xPosition - 160
          )
          .attr('y', maxYPosition - 20)
          .attr('text-align', 'start')
          .attr('font-size', '20px')
          .attr('font-weight', 'bold')
          .attr('fill', 'black')
          .text(`${dateAndTimeLabel}`);

        lineHover
          .attr('x1', xPosition)
          .attr('y1', height)
          .attr('x2', xPosition)
          .attr('y2', 0);

        props.checkedWeather.forEach((el, i) => {
          // Add circle on the line
          showDataMap[i + 1].circle
            .attr('cx', xPosition)
            .attr('cy', showDataMap[i + 1].yPos)
            .style('display', 'block');

          // Add circle beside for each text data
          chart
            .append('rect')
            .attr('class', `data-label ${showDataMap[i + 1].value}Circle`)
            .attr(
              'x',
              xPosition < halfWidth ? xPosition + 120 - 90 : xPosition - 160
            )
            .attr(
              'y',
              i === 0
                ? maxYPosition - 5
                : i === 1
                ? maxYPosition + 15
                : maxYPosition + 35
            )
            .attr('width', 10)
            .attr('height', 10)
            // .attr("fill", showDataMap[i + 1].color)
            .attr('stroke', 'white')
            .attr('rx', 5)
            .attr('ry', 5);
          // .style(
          //   "display",
          //   showDataMap[i + 1].lineVisible ? "block" : "none"
          // );

          // Add text data
          chart
            .append('text')
            .attr('class', 'data-label')
            .attr(
              'x',
              xPosition < halfWidth ? xPosition + 120 - 75 : xPosition - 145
            )
            .attr(
              'y',
              i === 0
                ? maxYPosition + 5
                : i === 1
                ? maxYPosition + 25
                : maxYPosition + 45
            )
            .attr('text-align', 'start')
            .style('font-size', '11px')
            .attr('fill', 'black')
            .text(`${d[showDataMap[i + 1].value]} ${showDataMap[i + 1].unit}`);
        });
      }
      circle.transition().duration(50).attr('r', 5).style('display', 'none');
      circle2.transition().duration(50).attr('r', 5).style('display', 'none');
      circle3.transition().duration(50).attr('r', 5).style('display', 'none');
      circle4.transition().duration(50).attr('r', 5).style('display', 'none');
      circle5.transition().duration(50).attr('r', 5).style('display', 'none');
      circle6.transition().duration(50).attr('r', 5).style('display', 'none');
      circle7.transition().duration(50).attr('r', 5).style('display', 'none');
      circle8.transition().duration(50).attr('r', 5).style('display', 'none');
      circle9.transition().duration(50).attr('r', 5).style('display', 'none');
      circle10.transition().duration(50).attr('r', 5).style('display', 'none');

      // Add hover effect to lines and chart
      // chart
      //   .selectAll(".zoom")
      //   .on("mousemove", function (event, d) {
      //     showData(event, d);
      //   })
      //   .on("mouseout", () => d3.selectAll(".data-label").remove());
      if (props.windowWidth < 960)
        chart.on('click', async function (event, d) {
          lineHover.style('stroke-width', 1);
          await circle.attr('r', 5);
          await circle2.attr('r', 5);
          await circle3.attr('r', 5);

          await showData(event, d);
          await chart.selectAll('.eventData').raise();
        });
      else
        chart
          .on('mousemove', async function (event, d) {
            lineHover.style('stroke-width', 1);
            await circle.attr('r', 5);
            await circle2.attr('r', 5);
            await circle3.attr('r', 5);

            await showData(event, d);
            await chart.selectAll('.eventData').raise();
          })
          .on('mouseout', () => {
            lineHover.style('stroke-width', 0);
            circle.attr('r', 0);
            circle2.attr('r', 0);
            circle3.attr('r', 0);

            d3.selectAll('.data-label').remove();
          });

      function brushed(event) {
        const selection = event.selection;
        if (selection) {
          var selectedDates = selection.map(x2.invert);
          const startTempDate = new Date(selectedDates[0]);
          const endTempDate = new Date(selectedDates[1]);

          context
            .select('.leftMarker')
            .attr(
              'x',
              x2(
                parseTime(
                  `${startTempDate.getFullYear()}-${String(
                    startTempDate.getMonth() + 1
                  ).padStart(2, '0')}-${String(
                    startTempDate.getDate()
                  ).padStart(
                    2,
                    '0'
                  )} ${startTempDate.getHours()}:${startTempDate.getMinutes()}`
                )
              ) - 10
            )
            .attr('y', 30);
          context
            .select('.rightMarker')
            .attr(
              'x',
              x2(
                parseTime(
                  `${endTempDate.getFullYear()}-${String(
                    endTempDate.getMonth() + 1
                  ).padStart(2, '0')}-${String(endTempDate.getDate()).padStart(
                    2,
                    '0'
                  )} ${endTempDate.getHours()}:${endTempDate.getMinutes()}`
                )
              ) - 10
            )
            .attr('y', 30);
        }
      }

      function brushedEnd(event) {
        if (!event || !event.sourceEvent || !event.sourceEvent.type === 'zoom')
          return;
        // const s = event.selection || x2.range();

        // x.domain(s.map(x2.invert, x2));
        // chart.select(".line1").attr("d", line1);
        // chart.select(".line2").attr("d", line2);
        // chart.select(".line3").attr("d", line3);
        // chart.select(".line4").attr("d", line4);
        // chart.select(".line5").attr("d", line5);
        // chart.select(".line6").attr("d", line6);
        // chart.select(".line7").attr("d", line7);
        // chart.select(".line8").attr("d", line8);
        // chart.select(".line9").attr("d", line9);
        // chart.select(".line10").attr("d", line10);
        // chart.select(".line1b").attr("d", line1b);
        // chart.select(".line2b").attr("d", line2b);
        // chart.select(".line3b").attr("d", line3b);
        // chart.select(".line4b").attr("d", line4b);
        // chart.select(".line5b").attr("d", line5b);
        // chart.select(".line6b").attr("d", line6b);
        // chart.select(".line7b").attr("d", line7b);
        // chart.select(".line8b").attr("d", line8b);
        // chart.select(".line9b").attr("d", line9b);
        // chart.select(".line10b").attr("d", line10b);
        // chart.select(".x.axis").call(d3.axisBottom(x));
        // svg
        //   .select(".zoom")
        //   .call(
        //     zoom.transform,
        //     d3.zoomIdentity.scale(width / (s[1] - s[0])).translate(-s[0], 0)
        //   );

        // Update the position of the vertical lines based on the new x scale
        // chart
        //   .selectAll(".vertical-line-group")
        //   .attr(
        //     "transform",
        //     (d) => `translate(${x(parseTime(dateAndTime(d.time)))},0)`
        //   );

        const selection = event.selection;
        if (selection) {
          var selectedDates = selection.map(x2.invert);
          let newObjDate = [];
          selectedDates.map((item, index) => {
            // Create a new Date object from the original date string
            const dateObj = new Date(item);

            // Extract the individual components of the date
            const year = dateObj.getUTCFullYear();
            const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getUTCDate()).padStart(2, '0');
            const hour = dateObj.getUTCHours();
            const minute = dateObj.getUTCMinutes();
            const second = dateObj.getUTCSeconds();

            // Create the desired date string in the format "MM/DD/YYYY"
            let newDate = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
            // const newDate = `${year}/${month}/${day} ${
            //   index == 0 ? "00:00:00" : "23:59:59"
            // }`;

            if (
              index === 1 &&
              dataBot[dataBot.length - 1].date === `${year}-${month}-${day}`
            ) {
              newDate = `${year}/${month}/${day} 23:59:59`;
            }

            newObjDate[index] = newDate;
          });

          const momentStart = moment(newObjDate[0], 'YYYY/MM/DD');
          const momentEnd = moment(newObjDate[1], 'YYYY/MM/DD');
          const diffDays = momentEnd.diff(momentStart, 'day');

          if (diffDays >= 31) {
            context.select('.brush').remove();
            context
              .append('g')
              .attr('class', 'brush')
              .call(brush)
              .call(brush.move, [x2(startDateBrush), x2(endDateBrush)]);

            context
              .select('.brush')
              .append('image')
              .attr('href', btmChartDrag)
              .attr('class', 'leftMarker')
              .attr('x', x2(startDateBrush) - 10) // Adjust the positioning as needed
              .attr('y', 30)
              .attr('width', 20)
              .attr('height', 20)
              .attr('fill', 'blue')
              .style('cursor', 'ew-resize');

            context
              .select('.brush')
              .append('image')
              .attr('href', btmChartDrag)
              .attr('class', 'rightMarker')
              .attr('x', x2(endDateBrush) - 10) // Adjust the positioning as needed
              .attr('y', 30)
              .attr('width', 20)
              .attr('height', 20)
              .attr('fill', 'blue')
              .style('cursor', 'ew-resize');

            context
              .select('.selection')
              // .attr("fill", "red")
              .attr('fill', 'rgba(255, 255, 255, 0.8)');

            context.selectAll('.handle').attr('width', 15).raise();
            context.select('.handle--w').attr('x', x2(startDateBrush) - 8);
            context.select('.handle--e').attr('x', x2(endDateBrush) - 8);
            props.handleOpen();
          } else {
            setStartDate(newObjDate[0]);
            setEndDate(newObjDate[1]);
          }
        }
      }

      function zoomed(event) {
        if (!event || !event.sourceEvent || !event.sourceEvent.type === 'brush')
          return;
        const t = event.transform;
        x.domain(t.rescaleX(x2).domain());
        chart.select('.line1').attr('d', line1);
        chart.select('.line2').attr('d', line2);
        chart.select('.line3').attr('d', line3);
        chart.select('.line1b').attr('d', line1b);
        chart.select('.line2b').attr('d', line2b);
        chart.select('.line3b').attr('d', line3b);
        chart.select('.x.axis').call(d3.axisBottom(x));
        context.select('.brush').call(brush.move, x.range().map(t.invertX, t));

        // Update the position of the vertical lines based on the new x scale
        chart
          .selectAll('.vertical-line-group')
          .attr(
            'transform',
            (d) => `translate(${x(parseTime(dateAndTime(d.time)))},0)`
          );
      }
    }
  }, [lineOne, lineTwo, lineThree, data, props.windowWidth]);

  return (
    <div className='chart-container'>
      {data.length > 0 || isLoad ? (
        <>
          {!lineOne && !lineTwo && !lineThree && !isLoad ? (
            <>
              <div
                style={{
                  height: '530px',
                  backgroundColor: '#3E3E3E',
                  width: '100%',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                }}
              >
                {newObj.NoDataWord[language]}
              </div>
            </>
          ) : (
            <>
              {isLoad && (
                <div
                  style={{
                    position: 'absolute',
                    zIndex: '1000',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress color='primary' />
                </div>
              )}
              <svg
                ref={chartRef}
                style={{height: '530px', width: '100%'}}
              ></svg>
            </>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              height: '530px',
              backgroundColor: '#3E3E3E',
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            {newObj.NoDataWord[language]}
          </div>
        </>
      )}
    </div>
  );
};

export default IOTChart;
