import React, { useState, useEffect } from "react";
import { Avatar, IconButton, Link, Menu, MenuItem } from "@material-ui/core";
import uploadMapGenerated from "../assets/images/uploadMapGenerated.svg";
import mapFiles from "../assets/images/mapFiles.svg";

import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Box } from "@mui/material";

function IconMenuDigitalMapping(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    function handleResize() {
      if (window.matchMedia("(min-width: 576px)").matches) {
        // Your function logic here for screens wider than sm breakpoint
        handleClose();
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <MoreHorizOutlinedIcon style={{ rotate: "90deg" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        style={{ marginTop: "70px" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem>
          <div>
            <Link
              href="/uploadmap"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "black !important",
                margin: "0 16px",
              }}
            >
              <Avatar
                alt="Upload Map"
                style={{
                  width: 24,
                  height: 24,
                  marginRight: "10px",
                }}
                src={uploadMapGenerated}
              />
              Upload Map
            </Link>
          </div>
        </MenuItem>
        <MenuItem>
          <div>
            <Link
              href="/mapfiles"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "black !important",
                margin: "0 16px",
              }}
            >
              <img
                alt="Map Files"
                style={{
                  width: 24,
                  height: 24,
                  marginRight: "10px",
                }}
                src={mapFiles}
              />
              Map Files
            </Link>
          </div>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default IconMenuDigitalMapping;
