import React from "react";
// import Link from "@mui/material/Link";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Lintramax } from "../context/LintramaxContext";
import { IoT } from "../context/IoTContext";
import { DigMap } from "../context/DigMapContext";
const PageNav = (props) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMdScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLgScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const { resetLintramaxCtx } = Lintramax();
  const { resetIoTCtx } = IoT();
  const { resetDigMapCtx } = DigMap();
  const resetContext = () => {
    resetLintramaxCtx();
    resetIoTCtx();
    resetDigMapCtx();
  };
  return (
    <>
      <Box
        sx={{
          background: "rgba(255, 255, 255, 0.6)",
          borderRadius: isXsScreen ? "0px" : isSmScreen ? "0px" : "10px",
          backdropFilter: "blur(10px)",
          webkitBackdropFilter: "blur(10px)",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          position: "fixed",
          width: isXsScreen
            ? "100%"
            : isSmScreen
            ? "100%"
            : "calc(100% - 20px)",
          zIndex: "100",
          height: isXsScreen ? "40px" : isSmScreen ? "40px" : "50px",
        }}
        alignItems="center"
        display="flex"
        py={1}
        px={2}
      >
        <Link
          // href={props.href}
          to={props.href}
          variant="body2"
          style={{
            textDecoration: "none",
            alignItems: "center",
            display: "flex",
          }}
          onClick={() => resetContext()}
        >
          <Box display="flex">
            <ChevronLeftIcon sx={{ fontSize: "28px", color: "#2B337D" }} />
            <Box
              variant="body1"
              sx={{
                color: "#2B337D",
                display: "flex",
                alignItems: "center",
                fontFamily: "Montserrat, sans-serif ",
              }}
              fontWeight="bold"
              p={0}
              ml={3}
              mb={0}
            >
              {props.title}
            </Box>
          </Box>
        </Link>
      </Box>
    </>
  );
};

export default PageNav;
