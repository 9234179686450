import { Box, Grid, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Docs from "../assets/images/docs.svg";
import LintramaxLogo from "../assets/images/lintramaxLogo.svg";
import IoT from "../assets/images/iot.svg";
import download from "../assets/images/download.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axios from "axios";
import nookies, { destroyCookie } from "nookies";
import { Language } from "../context/LanguageContext";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { DigMap } from "../context/DigMapContext";
import { Lintramax } from "../context/LintramaxContext";
export const LowerComponent = (props) => {
  const { language, newObj } = Language();
  const { setStartDate, setEndDate, setFromDigitalMap } = Lintramax();
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("default", { month: "long" });
  const currentYear = currentDate.getFullYear();
  const apiUrl = process.env.REACT_APP_MAIN_URL;

  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const navigate = useNavigate();
  const projekKod = props.rightData?.detail?.kodProjek;
  const projectName = props.rightData?.detail?.namaProjek;
  const pkkod = props.rightData?.detail?.kodKawasa;
  const [lintramaxData, getLintramaxData] = useState();
  const [isLoad, setLoad] = useState(true);
  const [latestFile, setLatestFile] = useState();
  const [iotData, setIotData] = useState();
  const [DeviceIdByProjectCode, setDeviceIdByProject] = useState([]);

  const { setDate, setPlantType } = DigMap();

  useEffect(() => {
    getDataLintramax(pkkod, projekKod, projectName);
    mappingIot(pkkod, projekKod);
    getDeviceIdByProjectCode(props?.rightData?.detail.kodKawasa);
  }, [pkkod, projekKod, projectName]);

  useEffect(() => {
    postReportDownload(props?.kawasan, projekKod);
  }, [props.kawasan]);

  const getDeviceIdByProjectCode = async (pk_code) => {
    try {
      setLoad(true);
      const res = await axios.post(
        `${apiUrl}/api/device/list`,
        { value: pk_code, flag: "pk_code" },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        setDeviceIdByProject(res.data.data);
        setLoad(false);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getDataLintramax = async (pkCode, projectCode, projectName) => {
    try {
      setLoad(true);
      const res = await axios.post(
        `${apiUrl}/api/mapping/get-data-lintramax`,
        {
          pk_code: pkCode,
          project_code: projectCode,
          project_name: projectName,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.status === 200) {
        getLintramaxData(res?.data?.data);
        setLoad(false);
      }

      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  const mappingIot = async (pkCode, projectCode) => {
    try {
      setLoad(true);
      const res = await axios.post(
        `${apiUrl}/api/mapping/iot`,
        {
          pk_code: pkCode,
          project_code: projectCode,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.data.status === 200) {
        setIotData(res.data.data);
        setLoad(false);
      } else setIotData([]);

      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  const postReportDownload = async (id, projectCode) => {
    try {
      setLoad(true);
      const res = await axios.post(
        `${apiUrl}/api/archive/report/get-latest`,
        {
          pk_code: id,
          projek_code: projectCode,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 200) {
        const fileObj = {
          key: res.data.data.key,
          name: res.data.data.name,
          fileExt: res.data.data.fileExt,
        };
        setLatestFile(fileObj);
        setLoad(false);
      }

      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  const postArchivedDownload = async (fileExt, files, filename) => {
    let fileExtension = "";
    if (fileExt === "csv") fileExtension = `text/csv;charset=utf-8`;
    else if (fileExt === "xlsx") fileExtension = `application/vnd.ms-excel`;
    else if (fileExt === "pdf") fileExtension = `application/pdf`;
    const fetchFile = await axios
      .post(
        `${apiUrl}/api/archive/report/download`,
        {
          filename: files,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer",
        }
      )
      .then(({ data }) => {
        const fileName = filename;
        const uint8Array = new Uint8Array(data);
        const blob = new Blob([uint8Array], { type: fileExtension });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.setAttribute("target", "_blank");
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoad(false);
      })
      .catch(() => null);
  };
  // if (!isLoad)
  return (
    <Box style={{ width: "100%" }}>
      <Box style={{ padding: "10px", width: "100%" }}>
        {/* download */}
        {(props.adminDigitalMapFlag || props.viewerDigitalMapFlag) && (
          <Box
            style={{
              backgroundColor: "#EFEBCD",
              borderRadius: "10px",
              width: "100%",
            }}
          >
            <Box
              onClick={() => {
                if (props.adminDigitalMapFlag) {
                  navigate(
                    `/documentreport?idWilayah=${props?.rightData?.detail?.idWilaya}&kodWilayah=${props?.rightData?.detail?.kodWilaya}&kodKawasan=${props?.rightData?.detail?.kodKawasa}&kodProjek=${props?.rightData?.detail?.kodProjek}`
                  );
                } else {
                  Swal.fire({
                    icon: "error",
                    title: newObj.ErrorWord[language],
                    text: newObj.CannotAccessPageWord[language],
                  });
                }
              }}
              style={{
                cursor: "pointer",
              }}
            >
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={Docs} style={{ marginRight: 10, width: "20px" }} />{" "}
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {newObj.MuatTurunLaporanPenuhWord[language]}
                  </span>
                </div>

                <div>
                  <ChevronRightIcon style={{ cursor: "pointer" }} />
                </div>
              </div>
            </Box>

            <hr />

            {latestFile && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {latestFile.name || newObj.NoDataWord[language]}
                </div>

                <div
                  onClick={() =>
                    postArchivedDownload(
                      latestFile.fileExt,
                      latestFile.key,
                      latestFile.name
                    )
                  }
                >
                  <img
                    src={download}
                    style={{ width: "20px", cursor: "pointer" }}
                  />
                </div>
              </div>
            )}
          </Box>
        )}

        {/* lintramax */}
        {(props.adminLintramaxFlag ||
          props.viewerDigitalMapFlag ||
          props.adminDigitalMapFlag) &&
        lintramaxData ? (
          <Box
            style={{
              backgroundColor: "#A7D1BC",
              borderRadius: "10px",
              margin: "10px 0",
              width: "100%",
            }}
          >
            <Box
              onClick={() => {
                if (props.adminLintramaxFlag) {
                  const lastDate = function (year, month) {
                    return new Date(year, Number(month), 0).getDate();
                  };
                  setPlantType(props.data.janis_tanaman);
                  setStartDate(
                    `${moment(lintramaxData.date_detail).year()}/${
                      moment(lintramaxData.date_detail).month() + 1
                    }/01`
                  );
                  setEndDate(
                    `${moment(lintramaxData.date_detail).year()}/${
                      moment(lintramaxData.date_detail).month() + 1
                    }/${lastDate(
                      moment(lintramaxData.date_detail).year(),
                      moment(lintramaxData.date_detail).month() + 1
                    )}`
                  );
                  setFromDigitalMap({
                    status: true,
                    code: {
                      projectCode: props?.rightData?.detail?.kodProjek,
                      pkCode: props?.rightData?.detail?.kodKawasa,
                      kodNegeri: props?.rightData?.detail?.kodWilaya,
                      kodWilayah: props?.rightData?.detail?.idWilaya,
                    },
                  });
                  navigate(
                    `/graph?idWilayah=${props?.rightData?.detail?.idWilaya}&kodWilayah=${props?.rightData?.detail?.kodWilaya}&kodKawasan=${props?.rightData?.detail?.kodKawasa}&kodProjek=${props?.rightData?.detail?.kodProjek}`
                  );
                } else {
                  Swal.fire({
                    icon: "error",
                    title: newObj.ErrorWord[language],
                    text: newObj.CannotAccessPageWord[language],
                  });
                }
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={LintramaxLogo}
                    style={{ marginRight: 10, width: "20px" }}
                  />{" "}
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {newObj.LintraMaxWord[language]}
                  </span>
                </div>

                <div>
                  <ChevronRightIcon style={{ cursor: "pointer" }} />
                </div>
              </div>
            </Box>

            <hr />

            <div
              style={{
                padding: "10px",
              }}
            >
              <div
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  backgroundColor: "#71A189",
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "white ",
                }}
              >
                {moment(lintramaxData.date_detail).format("MMMM YYYY")}
              </div>
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  {newObj.PenjualanWord[language]}
                </div>
                <div style={{ fontSize: "12px" }}>
                  {newObj.NetWeightWord[language]}
                  <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {lintramaxData.net_weight_in_mt}
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginTop: "15px",
                    marginBottom: "5px",
                  }}
                >
                  {newObj.PenuaianWord[language]}
                </div>
                <Grid container>
                  <Grid item xs={6} style={{ fontSize: "12px" }}>
                    {newObj.FFBWeightNetWeightWord[language]}
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                      {lintramaxData.ffb_weight_in_mt}
                    </div>
                  </Grid>
                  <Grid item xs={6} style={{ fontSize: "12px" }}>
                    {newObj.TotalWorkerWord[language]}
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                      {lintramaxData.total_worker}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Box>
        ) : (
          <>
            <Box
              style={{
                backgroundColor: "#A7D1BC",
                borderRadius: "10px",
                margin: "10px 0",
                width: "100%",
              }}
            >
              <Box
                onClick={() => {}}
                style={{
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={LintramaxLogo}
                      style={{ marginRight: 10, width: "20px" }}
                    />{" "}
                    <span
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {newObj.LintraMaxWord[language]}
                    </span>
                  </div>

                  <div>
                    <ChevronRightIcon style={{ cursor: "pointer" }} />
                  </div>
                </div>
              </Box>

              <hr />

              <div
                style={{
                  padding: "10px",
                }}
              >
                <div style={{ padding: "10px", textAlign: "center" }}>
                  {newObj.NoDataWord[language]}
                </div>
              </div>
            </Box>
          </>
        )}

        {/* IoT */}
        {(props.adminIotFlag ||
          // props.viewerIotFlag ||
          props.viewerDigitalMapFlag ||
          props.adminDigitalMapFlag) &&
        (iotData?.weather.length > 0 || iotData?.soil.length > 0) ? (
          <>
            {iotData?.weather.map((el) => {
              return (
                <>
                  <Box
                    style={{
                      backgroundColor: "#B7C3D0",
                      borderRadius: "10px",
                      margin: "10px 0",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (props.adminIotFlag) {
                          navigate(
                            `/iotgraph?id=${el.device_detail.device_id}`
                          );
                        } else {
                          Swal.fire({
                            icon: "error",
                            title: newObj.ErrorWord[language],
                            text: newObj.CannotAccessPageWord[language],
                          });
                        }
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={IoT}
                          style={{ marginRight: 10, width: "20px" }}
                        />{" "}
                        <span
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {newObj.IoTWord[language]} -{" "}
                          {el?.device_detail?.device_nama}
                        </span>
                      </div>

                      <div>
                        <ChevronRightIcon style={{ cursor: "pointer" }} />
                      </div>
                    </div>

                    <hr />

                    <div
                      style={{
                        padding: "10px",
                      }}
                    >
                      <div>
                        <Grid container>
                          {el.device_property.map((item, key) => (
                            <Grid
                              item
                              xs={4}
                              key={key}
                              style={{ paddingBottom: "5px" }}
                            >
                              <Tooltip title={item.name} placement="bottom">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      backgroundColor: "grey",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    {item.icon && (
                                      <img
                                        alt={item.name}
                                        src={item.icon}
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          backgroundColor: "grey",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    )}
                                  </div>

                                  <div style={{ fontSize: "10px" }}>
                                    {item.value}
                                  </div>
                                </div>
                              </Tooltip>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    </div>
                  </Box>
                </>
              );
            })}
            {iotData?.soil.map((el) => {
              return (
                <>
                  <Box
                    style={{
                      backgroundColor: "#B7C3D0",
                      borderRadius: "10px",
                      margin: "10px 0",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={IoT}
                          style={{ marginRight: 10, width: "20px" }}
                        />{" "}
                        <span
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {newObj.IoTWord[language]} -{" "}
                          {el?.device_detail?.device_nama}
                        </span>
                      </div>

                      <div>
                        <Link
                          style={{
                            borderRadius: "12px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          to={`/iotgraph?id=${el.device_detail.device_id}`}
                        >
                          <ChevronRightIcon style={{ cursor: "pointer" }} />
                        </Link>
                      </div>
                    </div>

                    <hr />

                    <div
                      style={{
                        padding: "10px",
                      }}
                    >
                      <div>
                        <Grid container>
                          {el.device_property.map((item, key) => (
                            <Grid
                              item
                              xs={4}
                              key={key}
                              style={{ paddingBottom: "5px" }}
                            >
                              <Tooltip title={item.name} placement="bottom">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      backgroundColor: "grey",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    {item.icon && (
                                      <img
                                        alt={item.name}
                                        src={item.icon}
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          backgroundColor: "grey",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    )}
                                  </div>

                                  <div style={{ fontSize: "10px" }}>
                                    {item.value}
                                  </div>
                                </div>
                              </Tooltip>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    </div>
                  </Box>
                </>
              );
            })}
          </>
        ) : (
          <>
            <Box
              style={{
                backgroundColor: "#B7C3D0",
                borderRadius: "10px",
                margin: "10px 0",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (props.adminIotFlag) {
                    navigate(`/iot`);
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: newObj.ErrorWord[language],
                      text: newObj.CannotAccessPageWord[language],
                    });
                  }
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={IoT} style={{ marginRight: 10, width: "20px" }} />
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {newObj.IoTWord[language]}
                  </span>
                </div>

                <div>
                  {/* <Link
                    style={{
                      borderRadius: "12px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    to={`/iot`}
                  > */}
                  <ChevronRightIcon style={{ cursor: "pointer" }} />
                  {/* </Link> */}
                </div>
              </div>

              <hr />

              <div
                style={{
                  padding: "10px",
                }}
              >
                <div style={{ padding: "10px", textAlign: "center" }}>
                  {newObj.NoDataWord[language]}
                </div>
              </div>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
