import React, { useEffect, useRef, useState } from "react";
import nookies, { destroyCookie } from "nookies";
import axios from "axios";
import "./index.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import macWP from "../../assets/images/macWP.jpg";
import iotLogo from "../../assets/images/iotlogo.png";
import {
  Box,
  createTheme,
  ThemeProvider,
  Grid,
  TextField,
} from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Link } from "react-router-dom";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Loader } from "../../components/Loader";
import { Language } from "../../context/LanguageContext";
import { ModalAddIotDevice } from "../../components/ModalAddIotDevice";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const ManageIoTDevices = () => {
  const { language, newObj } = Language();
  const [weatherObject, setWeatherObject] = useState();
  const [tableToggle, setTableToggle] = useState(0); //0 = Weather, 1 = Soil
  const [isLoad, setLoad] = useState(true);
  const [deviceDetail, setDeviceDetail] = useState();
  const [dataType, setDataType] = useState({
    weather: {
      data: [],
      alreadyLoadStatus: false,
    },
    soil: {
      data: [],
      alreadyLoadStatus: false,
    },
  });
  //toggle

  const [pkCodeClick, setPKCodeClick] = useState(false);
  const [selectedPK, setSelectedPK] = useState();
  const [pkCode, setPkCode] = useState();
  const [projectCodeClick, setProjectCodeClick] = useState(false);
  const [selectedProjectCode, setSelectedProjectCode] = useState();
  const [projectCode, setProjectCode] = useState();
  const [openAddDeviceModal, setOpenAddDeviceModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [longlatha, setLongLatHa] = useState({
    longitude: "",
    latitude: "",
    ha: 40,
  });
  const lang = useRef(language);
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const navigate = useNavigate();
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"].join(","),
    },
  });

  const clickedWeather = weatherObject?.filter((item) => {
    return item.clicked === true;
  });

  const getAllDevice = async (device) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/device/list`,
        {
          flag: "category",
          value: device,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        if (tableToggle === 0) {
          const data = res?.data?.data;
          const newData = data.map((item, index) => {
            if (index === 0) return { ...item, clicked: true };
            else return { ...item, clicked: false };
          });
          setWeatherObject(newData);
        } else {
          const dataStation = res?.data?.data?.station;
          const dataPortable = res?.data?.data?.portable;
          const dataStationNew = dataStation.map((item, index) => {
            if (index === 0) return { ...item, clicked: true };
            else return { ...item, clicked: false };
          });
          const dataPortableNew = dataPortable.map((item) => {
            return { ...item, clicked: false };
          });
          setWeatherObject([...dataStationNew, ...dataPortableNew]);
        }
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      setLoad(false);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getDeviceDetail = async (id) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/device/detail`,
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        setDeviceDetail(res.data.data);
        setSelectedPK({
          name: res.data.data.pkName,
          code: res.data.data.pkCode,
        });
        setLongLatHa({
          longitude: res.data.data.longitude,
          latitude: res.data.data.latitude,
          ha: res.data.data.total_area_ha,
        });
        setSelectedProjectCode({
          name: res.data.data.projectName,
          code: res.data.data.projectCode,
        });
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getAllPkCode = async () => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/iot/pk-code`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      if (res.data.status === 200) {
        setPkCode(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getProjectCodeByPkCode = async (pkCode) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/iot/project-code`,
        { code: pkCode },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.data.status === 200) {
        setProjectCode(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      setLoad(false);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const updateDeviceData = async (
    name,
    category,
    pkCode,
    projectCode,
    id,
    longitude,
    latitude,
    totalAreaHa
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/device/update`,
        {
          name: name,
          category: category,
          pk_code: pkCode,
          project_code: projectCode,
          id: id,
          longitude: longitude,
          latitude: latitude,
          total_area_ha: totalAreaHa,
          language: lang.current == 1 ? "en" : "my",
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        Swal.fire({
          icon: "success",
          title: newObj.SuccessWord[language],
          text: res.data.message,
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            window.location.href = "/adminpage";
          }
        });
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getDataTypeList = async (category) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/data-type/list`,
        {
          category: category,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        if (category === "weather")
          setDataType({
            ...dataType,
            weather: { alreadyLoadStatus: true, data: res.data.data },
          });
        else
          setDataType({
            ...dataType,
            soil: { alreadyLoadStatus: true, data: res.data.data },
          });
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleObjClick = (index) => {
    const newArr = weatherObject.map((item, currentIndex) =>
      currentIndex === index
        ? { ...item, clicked: true }
        : { ...item, clicked: false }
    );
    setWeatherObject(newArr);
    setSelectedProjectCode();
    setSelectedPK();
  };

  const handleSave = () => {
    const category = tableToggle === 0 ? "weather" : "soil";
    updateDeviceData(
      deviceDetail.name,
      category,
      selectedPK?.code,
      selectedProjectCode?.code
        ? selectedProjectCode.code
        : deviceDetail.projectCode,
      deviceDetail.id,
      longlatha.longitude,
      longlatha.latitude,
      longlatha.ha
    );
  };

  useEffect(() => {
    const device = tableToggle === 0 ? "weather" : "soil";
    getAllDevice(device);
    if (
      (dataType.weather.alreadyLoadStatus === false && tableToggle === 0) ||
      (dataType.soil.alreadyLoadStatus === false && tableToggle === 1)
    ) {
      getDataTypeList(device);
    }
  }, [tableToggle]);

  useEffect(() => {
    if (clickedWeather) {
      const id = clickedWeather[0]?.id;
      getDeviceDetail(id);
    }
  }, [weatherObject]);

  useEffect(() => {
    if (!pkCode) getAllPkCode();
  }, []);

  useEffect(() => {
    lang.current = language;
  }, [language]);

  useEffect(() => {
    if (selectedPK) {
      setLoad(true);
      getProjectCodeByPkCode(selectedPK?.code);
    }
  }, [selectedPK]);

  useEffect(() => {
    setSelectedProjectCode();
  }, [selectedPK]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  if (isLoad) return <Loader />;
  else
    return (
      <ThemeProvider theme={theme}>
        <Box
          style={{
            backgroundImage: `url(${macWP})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            padding: isXsScreen || isSmScreen ? " 10 px 10px" : "15px 10px",
          }}
        >
          {/* nav */}
          <Box className="headerWithButton">
            <Link
              to="/adminpage"
              py={1}
              px={2}
              style={{
                textDecoration: "none",
                alignItems: "center",
                display: "flex",
              }}
            >
              <ChevronLeftIcon style={{ fontSize: "28px", color: "#2B337D" }} />
              <div
                variant="body1"
                style={{
                  color: "#2B337D",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
                p={0}
                ml={3}
                mb={0}
              >
                {newObj.ManageIoTDeviceWord[language]}
              </div>
            </Link>
            <Box
              style={{
                height: "100%",
              }}
            >
              <Box
                onClick={() => setOpenAddDeviceModal(!openAddDeviceModal)}
                style={{
                  borderLeft: "1px solid lightgrey",
                  display: "flex",
                  padding: "10px 20px",
                  height: "100%",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                  color: "black",
                }}
              >
                <AddCircleOutlineOutlinedIcon />
                <div
                  style={{
                    fontWeight: "500",
                  }}
                >
                  {newObj.AddNewDeviceWord[language]}
                </div>
              </Box>
            </Box>
          </Box>
          <Grid
            container
            columnSpacing={{ xs: 1, sm: 2, md: 1 }}
            style={{ marginTop: "60px" }}
          >
            <Grid item xs={12} md={3} className="relativeParent">
              <Box className="sideMenu">
                <div
                  className="removeScrollbar"
                  style={{
                    width: "100%",
                    overflowY: "auto",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      padding: "10px 20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      style={{
                        backgroundColor: "lightgray",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        style={{
                          padding: "10px 20px 10px 20px",
                          borderRadius: "5px",
                          backgroundColor: tableToggle === 0 && "#2B5180",
                          cursor: "pointer",
                          color: tableToggle === 0 && "white",
                          width: "max-content",
                        }}
                        onClick={() => setTableToggle(0)}
                      >
                        Weather Stations
                      </Box>
                      <Box
                        style={{
                          padding: "10px 20px 10px 20px",
                          borderRadius: "5px",
                          backgroundColor: tableToggle === 1 && "#2B5180",
                          cursor: "pointer",
                          color: tableToggle === 1 && "white",
                          width: "max-content",
                        }}
                        onClick={() => setTableToggle(1)}
                      >
                        Soil Sensors
                      </Box>
                    </Box>
                  </div>

                  <hr />
                  <Box>
                    {weatherObject.map((obj, index) => (
                      <div style={{ padding: "8px" }} key={obj.id}>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            ...(obj.clicked && {
                              backgroundColor: "#003d6d",
                            }),
                            cursor: "pointer",
                            borderRadius: "10px",
                            padding: "10px",
                          }}
                          onClick={() => handleObjClick(index)}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <img
                              src={iotLogo}
                              alt={"title"}
                              style={{ width: "30px" }}
                            />

                            <div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                  ...(obj.clicked
                                    ? { color: "white" }
                                    : { color: "black" }),
                                }}
                              >
                                {obj.title}
                              </div>
                              <div
                                style={{
                                  fontSize: "11px",
                                  ...(obj.clicked
                                    ? { color: "white" }
                                    : { color: "black" }),
                                }}
                              >
                                {newObj.LastRecordWord[language]}:{" "}
                                {obj.last_data_updated
                                  ? moment(obj.last_data_updated).format(
                                      "MMM D YYYY, h:mm a"
                                    )
                                  : newObj.NoRecordWord[language]}
                              </div>
                            </div>
                          </div>
                        </Box>
                      </div>
                    ))}
                  </Box>
                </div>
              </Box>
            </Grid>

            <Grid
              item
              md={9}
              xs={12}
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                // height: "81vh",
                // overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {/* content */}
              <div>
                {/* top */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #d9d9d9",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      padding: "10px 20px",
                      alignItems: "center",
                      gap: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    <img src={iotLogo} alt="logo" style={{ width: "30px" }} />
                    {clickedWeather[0]?.title}
                  </div>

                  <div style={{ display: "flex" }}>
                    <Link to="/iconsettings">
                      <div
                        style={{
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          borderLeft: "1px solid #d9d9d9",
                          padding: "10px",
                          display: "flex",
                          height: "100%",
                          color: "black",
                        }}
                      >
                        <SettingsOutlinedIcon />
                        {windowWidth > 900 && (
                          <span>{newObj.IconSettingWord[language]}</span>
                        )}
                      </div>
                    </Link>
                    <Link to="/uploadiot">
                      <div
                        style={{
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          borderLeft: "1px solid #d9d9d9",
                          padding: "10px",
                          display: "flex",
                          color: "black",
                          height: "100%",
                        }}
                      >
                        <FileUploadOutlinedIcon />
                        {windowWidth > 900 && (
                          <span>{newObj.UploadDataWord[language]}</span>
                        )}
                      </div>
                    </Link>

                    <Link to={`/iotgraph?id=${deviceDetail?.id}`}>
                      <div
                        style={{
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "black",
                          gap: "5px",
                          borderLeft: "1px solid #d9d9d9",
                          padding: "10px",
                          display: "flex",
                          height: "100%",
                        }}
                      >
                        <SourceOutlinedIcon />
                        {windowWidth > 900 && (
                          <span>{newObj.ViewDataWord[language]}</span>
                        )}
                      </div>
                    </Link>
                  </div>
                </div>
                {/* middle */}
                {deviceDetail && deviceDetail.type === "station" ? (
                  <Grid container style={{ padding: "15px" }}>
                    <Grid
                      md={6}
                      xs={12}
                      style={{
                        gap: "20px",
                        display: "flex",
                        flexDirection: "column",
                        padding: "0 10px",
                      }}
                    >
                      <div>
                        <TextField
                          id="outlined-read-only-input"
                          label={newObj.NameWord[language]}
                          defaultValue="as"
                          value={deviceDetail.name}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          disabled
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-read-only-input"
                          label={newObj.CreatedInWord[language]}
                          value={deviceDetail.createdAt}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          disabled
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-read-only-input"
                          label={newObj.DeviceTypeWord[language]}
                          value={deviceDetail.type}
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          disabled
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid
                      md={6}
                      xs={12}
                      style={{
                        gap: "20px",
                        display: "flex",
                        flexDirection: "column",
                        padding: "0 10px",
                      }}
                    >
                      {/* select pk code */}
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        {" "}
                        {/* select pk code */}
                        <Box
                          style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            display: "flex",
                            border: "1px solid #d9d9d9",
                            alignItems: "center",
                            padding: "10px 20px",
                            justifyContent: "space-between",
                            cursor: "pointer",
                          }}
                          onClick={() => setPKCodeClick(!pkCodeClick)}
                        >
                          {selectedPK ? (
                            // pk code name
                            <span>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "10px",
                                  fontSize: "12px",
                                }}
                              >
                                {selectedPK.code}
                              </span>
                              {selectedPK.name}
                            </span>
                          ) : (
                            // <span>{newObj.PKKodWord[language]}</span>
                            <span>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "10px",
                                  fontSize: "12px",
                                }}
                              >
                                {deviceDetail.pkCode}
                              </span>
                              {deviceDetail.pkName}
                            </span>
                          )}
                          <ExpandMoreIcon style={{ width: "20px" }} />
                        </Box>
                        <div style={{}} />
                        {pkCodeClick && (
                          <Box
                            style={{
                              position: "absolute",
                              width: "100%",
                              zIndex: "100",
                            }}
                          >
                            <Box
                              className="removeScrollbar"
                              style={{
                                borderRadius: "5px",
                                backgroundColor: "white",
                                border: "1px solid #d9d9d9",
                                // zIndex: "1000",
                                // right: "0",
                                maxHeight: "200px",
                                overflowY: "scroll",
                              }}
                            >
                              {pkCode.map((obj, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    borderBottom: "1px solid lightgray",
                                    padding: "10px 20px",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    fontSize: "12px",
                                  }}
                                  onClick={(e) => {
                                    setPKCodeClick(!pkCodeClick);
                                    setSelectedPK({
                                      code: obj.code,
                                      name: obj.name,
                                    });
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      marginRight: "10px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {obj.code}
                                  </span>
                                  {obj.name}
                                </div>
                              ))}
                            </Box>
                          </Box>
                        )}
                        {/* select project code */}
                        {deviceDetail.category === "soil" &&
                          deviceDetail.type === "station" && (
                            <>
                              <Box
                                style={{
                                  borderRadius: "5px",
                                  display: "flex",
                                  marginTop: "10px",
                                  alignItems: "center",
                                  padding: "10px 20px",
                                  justifyContent: "space-between",
                                  border: "1px solid #d9d9d9",

                                  ...(!selectedPK
                                    ? {
                                        backgroundColor: "grey",
                                      }
                                    : {
                                        backgroundColor: "white",
                                      }),
                                  ...(!selectedPK
                                    ? { cursor: "not-allowed" }
                                    : { cursor: "pointer" }),
                                }}
                                onClick={() =>
                                  selectedPK &&
                                  setProjectCodeClick(!projectCodeClick)
                                }
                              >
                                {selectedProjectCode ? (
                                  // pk code name
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "10px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {selectedProjectCode.code}
                                    </span>
                                    {selectedProjectCode.name}
                                  </span>
                                ) : (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "10px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {deviceDetail.projectCode}
                                    </span>
                                    {deviceDetail.projectName}
                                  </span>
                                )}
                                <ExpandMoreIcon style={{ width: "20px" }} />
                              </Box>
                              <div
                                style={{
                                  position: "relative",
                                }}
                              />
                              {projectCodeClick && (
                                <Box
                                  style={{
                                    position: "absolute",
                                    width: "100%",
                                    zIndex: "100",
                                  }}
                                >
                                  <Box
                                    className="removeScrollbar"
                                    style={{
                                      borderRadius: "5px",
                                      backgroundColor: "white",
                                      border: "1px solid #d9d9d9",
                                      zIndex: "1000",
                                      maxHeight: "200px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    {projectCode.map((obj, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          borderBottom: "1px solid lightgray",
                                          padding: "10px 20px",
                                          alignItems: "center",
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          setProjectCodeClick(
                                            !projectCodeClick
                                          );
                                          setSelectedProjectCode({
                                            code: obj.code,
                                            name: obj.name,
                                          });
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            marginRight: "10px",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {obj.code}
                                        </span>
                                        {obj.name}
                                      </div>
                                    ))}
                                  </Box>
                                </Box>
                              )}
                            </>
                          )}
                        <Grid
                          container
                          spacing={1}
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                            // style={{ paddingBottom: "0px" }}
                          >
                            <TextField
                              // id="outlined-read-only-input"
                              label={newObj.LongitudeWord[language]}
                              value={longlatha.longitude}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => {
                                setLongLatHa({
                                  ...longlatha,
                                  longitude: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                            // style={{ paddingBottom: "0px" }}
                          >
                            <TextField
                              // id="outlined-read-only-input"
                              label={newObj.LatitudeWord[language]}
                              value={longlatha.latitude}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              style={{
                                width: "100%",
                              }}
                              onChange={(e) => {
                                setLongLatHa({
                                  ...longlatha,
                                  latitude: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                            // style={{ paddingBottom: "0px" }}
                          >
                            <TextField
                              // id="outlined-read-only-input"
                              label={newObj.TotalAreaWord[language]}
                              value={longlatha.ha}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              style={{
                                width: "100%",
                              }}
                              onChange={(e) => {
                                setLongLatHa({
                                  ...longlatha,
                                  ha: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container style={{ padding: "15px" }}>
                    <Grid
                      md={6}
                      xs={12}
                      style={{
                        gap: "20px",
                        display: "flex",
                        flexDirection: "column",
                        padding: "0 10px",
                      }}
                    >
                      <div>
                        <TextField
                          id="outlined-read-only-input"
                          label="Name"
                          value={deviceDetail?.name}
                          variant="outlined"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-read-only-input"
                          label="Created in"
                          value={deviceDetail?.createdAt}
                          variant="outlined"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      md={6}
                      xs={12}
                      style={{
                        gap: "20px",
                        display: "flex",
                        flexDirection: "column",
                        padding: "0 10px",
                      }}
                    >
                      <div>
                        <TextField
                          id="outlined-read-only-input"
                          label="Device Type"
                          value={deviceDetail?.type}
                          variant="outlined"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}

                {/* bottom */}
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #d9d9d9",
                    borderTop: "1px solid #d9d9d9",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      padding: "10px 20px",
                      alignItems: "center",
                      gap: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {newObj.DataTypesWord[language]}
                  </div>
                </div>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {dataType[tableToggle === 0 ? "weather" : "soil"].data.map(
                    (item) => {
                      return (
                        <Box
                          style={{
                            width: "25%",
                            borderRadius: "10px",
                            padding: "20px 0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <img
                            src={item.icon ? item.icon : iotLogo}
                            alt={`icon_${item.label}`}
                            style={{
                              width: "40px",
                              height: "40px",
                            }}
                          />
                          <Box
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            {item.name}
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </Box>
              </div>
              {deviceDetail && deviceDetail.type === "station" && (
                <div
                  style={{
                    padding: "10px",
                    borderTop: "1px solid #d9d9d9",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {/* save button */}
                  <button
                    onClick={handleSave}
                    style={{
                      padding: "10px",
                      border: "black 1px solid",
                      backgroundColor: "transparent",
                      borderRadius: "10px",
                      width: "20%",
                      cursor: "pointer",
                      fontSize: "12px",
                    }}
                  >
                    {newObj.SaveWord[language]}
                  </button>
                </div>
              )}
            </Grid>
          </Grid>
        </Box>

        <ModalAddIotDevice
          openAddDeviceModal={openAddDeviceModal}
          setOpenAddDeviceModal={setOpenAddDeviceModal}
        />
      </ThemeProvider>
    );
};
