import React, { useEffect, useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import iconDelete from "../assets/images/icon-delete.svg";
import iconEdit from "../assets/images/icon-edit.svg";
import iconDownload from "../assets/images/download.svg";
import word from "../assets/images/word.png";
import excel from "../assets/images/excel.png";
import acrobat from "../assets/images/Icon.png";
import zip from "../assets/images/qgisZip.png";
import axios from "axios";
import nookies, { destroyCookie } from "nookies";
import { Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

export const DocumentReportCard = (props) => {
  const [click, setClick] = useState(false);
  const src = props.item.fileExt;
  let image = "";
  let srcType = "";
  const date = props.item.createdAt;
  const year = date.substring(date.length - 4);
  const title = props.item.name;
  const id = props.item.id;
  const cookies = nookies.get();
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const tokens_session = cookies.token;
  const navigate = useNavigate();
  if (src === "docx" || src === "doc") {
    image = word;
    if (src === "doc") srcType = "msword";
    else
      srcType = "vnd.openxmlformats-officedocument.wordprocessingml.document";
  } else if (src === "xlsx" || src === "xls") {
    image = excel;
    if (src === "xls") srcType = "vnd.ms-excel";
    else srcType = "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  } else if (src === "pdf") {
    image = acrobat;
    srcType = "pdf";
  } else {
    image = zip;
    srcType = "zip";
  }

  const ref = useRef();

  const postReportDelete = async (id, key) => {
    try {
      const fetchFile = await axios.post(
        `${apiUrl}/api/archive/report/delete`,
        {
          id: id,
          filename: key,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (fetchFile.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }

      if (fetchFile.data.status === 200) {
        props.deleteCount();
      }
      return fetchFile.data;
    } catch (error) {
      console.log(error);
    }
  };

  const postReportDownload = async (key, filename) => {
    const fetchFile = await axios
      .post(
        `${apiUrl}/api/archive/report/download`,
        {
          filename: key,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer",
        }
      )
      .then(({ data }) => {
        const fileName = filename;
        const uint8Array = new Uint8Array(data);
        const blob = new Blob([uint8Array], { type: `application/${srcType}` });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.setAttribute("target", "_blank");
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch(() => null);
  };

  useEffect(() => {
    // add event listener to detect clicks outside the div
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setClick(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // remove event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <Box
      sx={{
        padding: "5px",
        backgroundColor: "#2B5180",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <img src={image} alt={title} width={"80px"} />

      <div>
        <Box sx={{ color: "white", fontSize: "12px" }}>{title}</Box>

        <Box sx={{ color: "white", fontSize: "12px" }}>Terkini {year}</Box>
      </div>

      <Box
        sx={{
          padding: "5px",
          right: "0",
          backgroundColor: "#2E609D",
          color: "white",
          display: "flex",
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "0",
          bottom: "0",
          cursor: "pointer",
        }}
        onClick={() => setClick(!click)}
      >
        <MoreVertIcon
          style={{
            position: "relative",
          }}
        />

        {click && (
          <Box
            ref={ref}
            sx={{
              borderRadius: "5px",
              backgroundColor: "#E6E6E6",
              right: "0",
              bottom: "-50px",
              position: "absolute",
              padding: "8px",
              width: "220px",
              zIndex: "10",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 2,
              }}
              onClick={() =>
                postReportDownload(props.item.key, props.item.name)
              }
            >
              <Box sx={{ color: "black", fontSize: "13px" }}>Download</Box>

              <div>
                <img src={iconDownload} style={{ width: "18px" }} />
              </div>
            </div>

            <Link to={`/editdocreport/id=${id}`}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 2,
                }}
              >
                <Box sx={{ color: "black", fontSize: "13px" }}>Edit</Box>

                <div>
                  <img src={iconEdit} style={{ width: "18px" }} />
                </div>
              </div>
            </Link>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 2,
              }}
              onClick={() => postReportDelete(props.item.id, props.item.key)}
            >
              <Box sx={{ color: "black", fontSize: "13px" }}>Delete</Box>

              <div>
                <img src={iconDelete} style={{ width: "18px" }} />
              </div>
            </div>
          </Box>
        )}
      </Box>
    </Box>
  );
};
