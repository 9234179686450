import React, { useState, useEffect } from "react";
import { Container, FormLabel, Typography, useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import nookies, { destroyCookie } from "nookies";
import { useNavigate } from "react-router-dom";
import PageNav from "../../components/PageNav";
import macWP from "../../assets/images/macWP.jpg";
import { Language } from "../../context/LanguageContext";
import "./index.scss";
import Swal from "sweetalert2";

const AddUserGroup = () => {
  const { language, newObj } = Language();
  const [title, setTitle] = useState("");
  const [userGroupname, setUserGroupname] = useState("");
  const [description, setDescription] = useState("");
  const [tempInputVal, setTempInputVal] = useState([]);
  const [finalAuth, setFinalAuth] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [titleFlag, setTitleFlag] = useState("");
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
  });

  let apiUrl = process.env.REACT_APP_MAIN_URL;

  const navigate = useNavigate();
  const location = useLocation();
  let { userId } = useParams();

  useEffect(() => {
    setTitleFlag(location.pathname.split("/")[1]);
  }, []);
  const getExistedData = async (id) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/admin/usergroup/details`,
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      if (res.data.status === 200) {
        setUserGroupname(res.data.data.name);
        setDescription(res.data.data.description);
        setTempInputVal(res.data.data.authority);
        setTitle(res.data.data.name);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  const accessLevel = [
    {
      value: "user_management_admin",
      label: "User Management Admin",
    },
    {
      value: "iot_admin",
      label: "IoT Admin",
    },
    {
      value: "digital_mapping_admin",
      label: "Digital Mapping Admin",
    },
    {
      value: "iot_viewer",
      label: "IoT Viewer",
    },
    {
      value: "digital_mapping_viewer",
      label: "Digital Mapping Viewer",
    },
    {
      value: "lintramax_admin",
      label: "Lintramax Admin",
    },
    {
      value: "lintramax_viewer",
      label: "Lintramax Viewer",
    },
  ];

  const handleInputChange = (event) => {
    event.persist();
    const { value, checked } = event.target;

    if (checked) {
      setInputs((inputs) => [...inputs, event.target.value]);
      setTempInputVal((tempInputVal) => [...tempInputVal, event.target.value]);
    } else {
      setInputs(inputs.filter((val) => val !== value));
      setTempInputVal(tempInputVal.filter((val) => val !== value));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (userId) {
        const res = await axios.post(
          `${apiUrl}/api/admin/usergroup/update`,
          {
            id: userId,
            name: userGroupname,
            description: description,
            authority: finalAuth,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
            },
          }
        );
        if (res.data.status === 200) {
          Swal.fire({
            icon: "success",
            title: newObj.SuccessWord[language],
            text: newObj.UserGroupUpatedWord[language],
          }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate("/adminpage");
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: newObj.ErrorWord[language],
            text: res.data.message,
          });
        }
        if (res.data.status === 401) {
          destroyCookie(null, "token");
          navigate("/login");
        }
        return res.data;
      } else {
        const res = await axios.post(
          `${apiUrl}/api/admin/usergroup/new`,
          {
            name: userGroupname,
            description: description,
            authority: finalAuth,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
            },
          }
        );
        if (res.data.status === 200) {
          Swal.fire({
            icon: "success",
            title: newObj.SuccessWord[language],
            text: newObj.UserGroupCreatedWord[language],
          }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate("/adminpage");
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: newObj.ErrorWord[language],
            text: res.data.message,
          });
        }
        if (res.data.status === 401) {
          destroyCookie(null, "token");
          navigate("/login");
        }
        return res.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (userId) {
      getExistedData(userId);
    }
  }, []);

  useEffect(() => {
    setFinalAuth(inputs);
  }, [inputs]);
  useEffect(() => {
    setFinalAuth(tempInputVal);
  }, [tempInputVal]);
  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundImage: `url(${macWP})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: isXsScreen || isSmScreen ? "10px" : "20px 10px",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        <PageNav
          title={
            titleFlag === "addusergroup"
              ? newObj.AddNewUserGroupWord[language]
              : `${newObj.EditUserGroupWord[language] + " " + title}`
          }
          href="/adminpage"
        />
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            padding: "60px 0",
            width: "100%",
          }}
        >
          <Box
            sx={{
              background: "white",
              height: "100%",
              borderRadius: "10px",
              width: "100%",
            }}
            component="form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Grid container component="main">
              <Grid item xs={12} lg={8} className="boxUser">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  marginBottom={"20px"}
                >
                  {newObj.EditUserGroupWord[language] + " " + title}
                </Typography>
                <TextField
                  margin="dense"
                  required
                  id="userGroupname"
                  label={newObj.UserGroupNameWord[language]}
                  value={userGroupname}
                  onChange={(event) => setUserGroupname(event.target.value)}
                  fullWidth
                />
                <TextField
                  multiline
                  rows={5}
                  placeholder={newObj.InputDescriptionWord[language]}
                  value={description}
                  autoFocus={true}
                  style={{
                    padding: "16.5px 0 14px 0",
                    fontFamily: "inherit",
                    width: "100%",
                  }}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
                sx={{ textAlign: "start", padding: "20px" }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {newObj.AdminAccessWord[language]}
                </Typography>
                <FormGroup
                  aria-label="position"
                  row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "5%",
                    height: 400,
                    overflow: "hidden",
                    overflowY: "auto",
                  }}
                  onChange={handleInputChange}
                >
                  {accessLevel
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((level) => (
                      <Grid
                        key={level.value}
                        xs={12}
                        sx={{ marginLeft: "10px" }}
                      >
                        <FormControlLabel
                          control={<Checkbox />}
                          label={level.label}
                          value={level.value}
                          checked={tempInputVal.includes(level.value)}
                          labelPlacement="right"
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                    ))}
                </FormGroup>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTop: "2px solid #b6b6b6",
                p: 4,
              }}
            >
              <Button
                type="submit"
                fullWidth
                style={{
                  width: "50%",
                  padding: 15,
                  backgroundColor: "rgb(43, 51, 125)",
                  cursor: "pointer",
                  color: "white",
                  borderRadius: "10px",
                }}
              >
                {newObj.SaveWord[language]}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AddUserGroup;
