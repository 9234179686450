import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { Input } from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import nookies, { destroyCookie } from "nookies";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Swal from "sweetalert2";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useMediaQuery } from "@mui/material";
import "./index.scss";
import PageNav from "../../components/PageNav";

import macWP from "../../assets/images/macWP.jpg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Language } from "../../context/LanguageContext";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
});

const Profile = () => {
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const navigate = useNavigate();
  const { language, newObj } = Language();

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [lastNameTextField, setLastNameTextField] = useState({
    value: "",
    isError: false,
    errorMsg: "Last name is required",
  });
  const [firstNameTextField, setFirstNameTextField] = useState({
    value: "",
    isError: false,
    errorMsg: "First name is required",
  });
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [userGroup, setUserGroup] = useState(null);
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState();

  // FUNCTION TO SHOW PASSWORD
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleClickShowPassword3 = () => setShowPassword3((show) => !show);
  const [firstPass, setFirstPass] = useState("");
  const [secondPass, setSecondPass] = useState("");
  const [thirdPass, setThirdPass] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const rightHeight = document.querySelector(".gridProfile");

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword3 = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (event) => {
    // const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    const regex = /^(?=.*[A-Za-z])(?=.*\d)^.{8,}$/;
    if (regex.test(event.target.value) || event.target.value === "") {
      setSecondPass(event.target.value);
      setError(false);
      setPasswordsMatch(event.target.value === thirdPass);
    } else {
      setError(true);
      setPasswordsMatch(false);
    }
  };

  const handlePasswordChange2 = (event) => {
    // const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    const regex = /^(?=.*[A-Za-z])(?=.*\d)^.{8,}$/;
    if (regex.test(event.target.value) || event.target.value === "") {
      setThirdPass(event.target.value);
      setError2(false);
      setPasswordsMatch(event.target.value === secondPass);
    } else {
      setError2(true);
      setPasswordsMatch(false);
    }
  };
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const callProfile = async () => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/profile`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      if (res.status === 200) {
        var data = res.data.data;
        setFirstName(data.firstname);
        setLastName(data.lastname);
        setEmail(data.username);
        setPhone(data.phone);
        setPreviewUrl(data.avatar);
        setFirstNameTextField({ ...firstNameTextField, isError: false });
        setLastNameTextField({ ...lastNameTextField, isError: false });
        setError3("");
        const stringArray = data.usergroup_id[0].split(", ");
        setUserGroup(stringArray);
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  useEffect(() => {
    callProfile();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (rightHeight) {
      var secondClass = document.querySelector(".profileBox");
      if (windowWidth > 900)
        secondClass.style.height =
          rightHeight.getBoundingClientRect().height + "px";
      else secondClass.style.height = "auto";
    }
  }, [userGroup, windowWidth]);

  useEffect(() => {
    setFirstNameTextField({ ...firstNameTextField, value: firstName });
    setLastNameTextField({ ...lastNameTextField, value: lastName });
  }, [firstName, lastName]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data.get("firstName"), data.get("lastName"));
    if (
      data.get("contactNumber").length >= 7 &&
      data.get("firstName") &&
      data.get("lastName")
    ) {
      try {
        const res = await axios.post(
          `${apiUrl}/api/profile/update`,
          {
            firstname: firstNameTextField?.value,
            lastname: lastNameTextField?.value,
            phone: data.get("contactNumber"),
          },
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
            },
          }
        );

        if (res.data.status === 200) {
          Swal.fire({
            icon: "success",
            title: newObj.SuccessWord[language],
            text: "Update profile successfull!",
          });
          callProfile();
        }
        if (res.data.status === 401) {
          destroyCookie(null, "token");
          navigate("/login");
        }
        return res.data;
      } catch (e) {
        console.log(e);
      }
    } else {
      if (!data.get("firstName"))
        setFirstNameTextField({ ...firstNameTextField, isError: true });
      if (!data.get("lastName"))
        setLastNameTextField({ ...lastNameTextField, isError: true });
      if (data.get("contactNumber").length < 7)
        setError3("Phone number must contain at least 7 digits");
    }
  };
  const handleSubmitPass = async (event) => {
    event.preventDefault();

    const bodyFormData = new FormData();
    bodyFormData.append("current_password", firstPass);
    bodyFormData.append("new_password", secondPass);

    if (passwordsMatch) {
      try {
        const res = await axios.post(
          `${apiUrl}/api/profile/change-password`,
          bodyFormData,
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
            },
          }
        );

        if (res.data.status === 200) {
          Swal.fire({
            icon: "success",
            title: newObj.SuccessWord[language],
            text: newObj.PasswordHasBeenChangedWord[language],
          });
          handleClose();
          setFirstPass();
          setSecondPass();
          setThirdPass();
          setPasswordsMatch(false);
        }
        if (res.data.status === 401) {
          destroyCookie(null, "token");
          navigate("/login");
        }
        if (res.data.status === 500) {
          Swal.fire({
            icon: "error",
            title: newObj.ErrorWord[language],
            text: res.data.message,
            customClass: {
              container: "customSwalError",
            },
          });
        }
        return res.data;
      } catch (e) {
        console.log(e);
      }
    } else {
      alert("Password Didn't match");
    }
  };
  const handleChangeAvatar = async (event) => {
    document.getElementById("contained-button-file").click();
    event.preventDefault();
  };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setFile(file);
    setPreviewUrl(URL.createObjectURL(file));

    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await axios.post(
        `${apiUrl}/api/profile/change-profile-picture`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      console.log(res);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    p: 4,
  };

  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMdScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLgScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        maxWidth="100%"
        sx={{
          backgroundImage: `url(${macWP})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          marginTop: "60px",
          display: "flex",
          justifyContent: "center",
          paddingTop: isXsScreen || isSmScreen ? "0px" : "10px",
        }}
      >
        <PageNav title={newObj.ProfileWord[language]} href="/" />
        <Grid
          container
          component="main"
          sx={{
            minHeight: "calc(100vh - 110px)",
            marginTop: "50px",
          }}
          p={"10px"}
        >
          <Grid xs={12} md={3} className="profileBox">
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                "& > *": {
                  margin: theme.spacing(1),
                },
                position: "relative",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Avatar src={previewUrl} sx={{ width: 150, height: 150 }} />
              <form enctype="multipart/form-data" onChange={handleFileChange}>
                <Input
                  accept="image/*"
                  type="file"
                  sx={{ display: "none" }}
                  id="contained-button-file"
                />
              </form>
              <div
                variant="contained"
                onClick={handleChangeAvatar}
                style={{
                  padding: "5px",
                  borderRadius: "50%",
                  width: "35px",
                  height: "35px",
                  cursor: "pointer",
                  position: "absolute",
                  top: 130,
                  backgroundColor: "rgba(255,255,255,0.6)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PhotoCameraIcon style={{ color: "#036ed5" }} />
              </div>
              {/* user data */}
              <div
                style={{
                  padding: "10px 0",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ textAlign: "center" }}
                  pt={2}
                >
                  {firstName} {lastName}
                </Typography>
                <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                  {email}
                </Typography>
              </div>
            </div>

            <Box style={{ padding: "20px", width: "100%" }}>
              <div onClick={handleOpen} className="buttonProfile">
                {newObj.ChangePasswordWord[language]}
              </div>
            </Box>
          </Grid>
          <Grid sm={12} md={9} sx={{}}>
            <div className=" gridProfile">
              <Box
                style={{
                  padding: "15px 20px",
                  borderBottom: "1px solid #d9d9d9",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: "black" }}
                  fontWeight="bold"
                >
                  {newObj.MyProfileWord[language]}
                </Typography>
              </Box>
              <Divider light />
              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Box
                  sx={{
                    padding: "20px",
                  }}
                >
                  <TextField
                    margin="normal"
                    variant="filled"
                    required
                    fullWidth
                    id="firstName"
                    label={newObj.FirstNameWord[language]}
                    name="firstName"
                    autoComplete="first name"
                    autoFocus
                    value={firstNameTextField["value"]}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) =>
                      setFirstNameTextField({
                        ...firstNameTextField,
                        value: event.target.value,
                      })
                    }
                  />
                  {firstNameTextField?.isError && (
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                      {firstNameTextField?.errorMsg}
                    </Typography>
                  )}
                  {/* <br /> */}
                  <TextField
                    margin="normal"
                    required
                    variant="filled"
                    fullWidth
                    id="lastName"
                    label={newObj.LastNameWord[language]}
                    name="lastName"
                    autoComplete="last name"
                    autoFocus
                    value={lastNameTextField?.value}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) =>
                      setLastNameTextField({
                        ...lastNameTextField,
                        value: event.target.value,
                      })
                    }
                  />
                  {lastNameTextField?.isError && (
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                      {lastNameTextField?.errorMsg}
                    </Typography>
                  )}
                  {/* <br /> */}
                  <TextField
                    margin="normal"
                    required
                    variant="filled"
                    fullWidth
                    id="email"
                    label={newObj.EmailWord[language]}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                  {/* <br /> */}
                  <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="contactNumber"
                    label={newObj.ContactNumberWord[language]}
                    name="contactNumber"
                    autoComplete="contact number"
                    autoFocus
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  {error3 && (
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                      {error3}
                    </Typography>
                  )}
                </Box>
                <Grid container component="main">
                  <Box
                    style={{
                      padding: "15px 20px",
                      borderBottom: "1px solid #d9d9d9",
                      borderTop: "1px solid #d9d9d9",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "black" }}
                      fontWeight="bold"
                    >
                      {newObj.UserGroupWord[language]}
                    </Typography>
                  </Box>
                  <Box style={{ padding: "20px", width: "100%" }}>
                    {" "}
                    {userGroup?.map((data) => (
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id={data}
                        autoFocus
                        value={data}
                        disabled
                        InputLabelProps={{ shrink: true }}
                      />
                    ))}
                  </Box>
                </Grid>

                <Box
                  style={{
                    padding: "20px",
                    borderTop: "1px solid #d9d9d9",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    sx={{
                      width: "40% !important",
                    }}
                    className="buttonProfile"
                    type="submit"
                  >
                    {newObj.SaveWord[language]}
                  </Button>
                </Box>
              </Box>
            </div>
          </Grid>
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            zIndex: "101",
          }}
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ marginBottom: "20px" }}
            >
              {newObj.ChangePasswordWord[language]}
            </Typography>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                flexDirection: "column",
              }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmitPass}
            >
              <FormControl sx={{ my: 1, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="newPass1">
                  {newObj.CurrentPasswordWord[language]}
                </InputLabel>
                <OutlinedInput
                  id="newPass1"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  onChange={(event) => setFirstPass(event.target.value)}
                  autoComplete={showPassword ? "off" : "new-password"}
                />
              </FormControl>
              <FormControl sx={{ my: 1, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="newPass2">
                  {newObj.NewPasswordWord[language]}
                </InputLabel>
                <OutlinedInput
                  id="newPass2"
                  type={showPassword2 ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword2}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  onChange={handlePasswordChange}
                  autoComplete={showPassword2 ? "off" : "new-password"}
                />
                {error && (
                  <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                    {newObj.ErrorPasswordWord[language]}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ my: 1, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="newPass3">
                  {newObj.ConfirmNewPasswordWord[language]}
                </InputLabel>
                <OutlinedInput
                  id="newPass3"
                  type={showPassword3 ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword3}
                        onMouseDown={handleMouseDownPassword3}
                        edge="end"
                      >
                        {showPassword3 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  onChange={handlePasswordChange2}
                  autoComplete={showPassword3 ? "off" : "new-password"}
                />
                {error2 && (
                  <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                    {newObj.ErrorPasswordWord[language]}
                  </Typography>
                )}
              </FormControl>
              {!passwordsMatch && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {newObj.NewPasswordMustMatchConfirmPasswordWord[language]}
                </Typography>
              )}

              <Button
                type="submit"
                fullWidth
                style={{
                  width: "50%",
                  padding: 15,
                  // backgroundColor: "rgb(43, 51, 125)",
                  // cursor: "pointer",
                  color: "white",
                  // marginTop: "20px",
                  borderRadius: "10px",
                  ...(passwordsMatch
                    ? {
                        backgroundColor: "rgb(43, 51, 125)",
                        cursor: "pointer",
                      }
                    : {
                        backgroundColor: "grey",
                        cursor: "not-allowed",
                      }),
                }}
              >
                {newObj.ChangePasswordWord[language]}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
};

export default Profile;
