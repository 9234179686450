import React, {useState, useEffect, useRef} from 'react';
import L, {map} from 'leaflet';
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  ZoomControl,
  useMapEvents,
  useMap,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import Doughnutchart from '../../components/Doughnutchart';
import IconMenu from '../../components/IconMenu';
import IconMenuDigitalMapping from '../../components/IconMenuDigitalMapping';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import palmOil from '../../assets/images/palmOil.svg';
import getah from '../../assets/images/getah.svg';
import padi from '../../assets/images/padi.svg';
import axios from 'axios';
import nookies, {destroyCookie} from 'nookies';

import DetailData from '../../components/DetailData';
import DataContainerTab from '../../components/DataContainerTab';

import './index.scss';

import uploadMapGenerated from '../../assets/images/uploadMapGenerated.svg';
import mapFiles from '../../assets/images/mapFiles.svg';
import locationLogo from '../../assets/images/location.svg';
import iotLogo from '../../assets/images/iotlogo.png';
import {Loader} from '../../components/Loader';
import {Language} from '../../context/LanguageContext';
import {useNavigate, Link} from 'react-router-dom';
import {numberWithCommas} from '../../Helpers/stringFormat';
import {useMediaQuery, useTheme} from '@mui/material';
import {DigMap} from '../../context/DigMapContext';

const MapGenerated = (props) => {
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const {language, newObj} = Language();
  const {resetDigMapCtx} = DigMap();
  const cookies = nookies.get();
  const theme = useTheme();
  const tokens_session = cookies.token;
  const navigate = useNavigate();
  const [zoomer, setZoomer] = useState(6);
  const [resLuasLadangHa, setResLuasLadangHa] = useState();
  const [resLuasLadangAc, setResLuasLadangAc] = useState();
  const [resLuasBerhasil, setResLuasBerhasil] = useState();
  const [resLuasBertanam, setResLuasBertanam] = useState();
  const [mapData, setMapData] = useState([]);
  const [geoJsonLayer, setGeoJsonLayer] = useState(null);
  const [groupName, setGroupName] = useState();
  const [useGroupName, setUseGroupName] = useState(false);
  const [tempNumber, setTempNumber] = useState();
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [highlightLayer, setHighlightLayer] = useState(null);
  const [tempWeather, setTempWeather] = useState();
  const [pkCode, setPkCode] = useState();
  const [negeriCode, setNegeriCode] = useState();
  const [iotDevice, setIotDevice] = useState();

  const emptyFilter = {
    switch1: false,
    switch2: false,
    switch3: false,
    switch4: false,
    switch5: false,
    switch6: false,
    switch7: false,
  };
  const [selectedFilter, setSelectedFilter] = useState({
    switch7: true,
    switch6: false,
    switch5: false,
    switch4: false,
    switch3: true,
    switch2: false,
    switch1: false,
  });

  const [filter, setFilter] = useState(0);

  const [tempDataPokok, setTempDataPokok] = useState();
  const [tempJaringanJalan, setTempJaringanJalan] = useState();
  const [tempBlokAgronomi, setTempBlokAgronomi] = useState();
  const [tempPerimeterLadang, setTempPerimeterLadang] = useState();
  const [tempGunaTanah, setTempGunaTanah] = useState();
  const [tempTerlebihTanam, setTempTerlebihTanam] = useState();
  const [tempIoT, setTempIoT] = useState();
  const [rightData, setRightData] = useState();

  const [centeredLatLong, setCenteredLatLong] = useState();
  const [timeoutId, setTimeoutId] = useState(null);
  const [isLoad, setLoad] = useState(false);
  const [bounds, setBounds] = useState(false);

  const role = props.role;
  const adminRoleDigitalMap = ['digital_mapping_admin'];
  const adminRoleLintramax = ['lintramax_admin'];
  const adminRoleIot = ['iot_admin'];
  const viewerRoleDigitalMap = ['digital_mapping_viewer'];
  const viewerRoleLintramax = ['lintramax_viewer'];
  const viewerRoleIot = ['iot_viewer'];

  const adminFlag = role?.some((value) => adminRoleDigitalMap.includes(value));
  const adminIotFlag = role?.some((value) => adminRoleIot.includes(value));
  const adminLintramaxFlag = role?.some((value) =>
    adminRoleLintramax.includes(value)
  );
  const viewerDigitalMapFlag = role?.some((value) =>
    viewerRoleDigitalMap.includes(value)
  );
  const viewerLintramaxFlag = role?.some((value) =>
    viewerRoleLintramax.includes(value)
  );
  const viewerIotFlag = role?.some((value) => viewerRoleIot.includes(value));

  const [loopFlag, setLoopFlag] = useState(0);
  const [flag, setFlag] = useState();
  const [tempArr, setTempArr] = useState([]);
  const [selectedFilterClone, setSelectedFilterClone] = useState({
    switch7: true,
    switch6: true,
    switch5: false,
    switch4: true,
    switch3: true,
    switch2: false,
    switch1: false,
  });
  const [initialized, setInitialized] = useState(false);
  const [isMarkerClick, setMarkerClick] = useState(false);
  const [isPolyExist, setPolyExist] = useState(false);
  const [displayCode, setDisplayCode] = useState(false);
  const iotRef = useRef();
  const blokAgronomiRef = useRef();
  const jaringanJalanRef = useRef();
  const dirianPokokRef = useRef();
  const mapRef = useRef();

  useEffect(() => {
    if (zoomer >= 16) setLoopFlag(1);
  }, [zoomer]);

  useEffect(() => {
    let arr = {};
    if (loopFlag === 1) {
      arr = {
        switch7: true,
        switch6: true,
        switch5: false,
        switch4: true,
        switch3: true,
        switch2: false,
        switch1: false, //ini perubahan
      };

      setSelectedFilter(arr);
    } else if (loopFlag === 2) {
      arr = {
        switch7: true,
        switch6: false,
        switch5: true,
        switch4: false,
        switch3: true,
        switch2: true,
        switch1: false,
      };
      setSelectedFilter(arr);
    } else if (loopFlag === 3) {
      arr = {
        switch7: true,
        switch6: false,
        switch5: true,
        switch4: true,
        switch3: true,
        switch2: false,
        switch1: false,
      };
      setSelectedFilter(arr);
    }
    setFlag(-1);
  }, [loopFlag]);
  useEffect(() => {
    if (flag === -1) {
      setSelectedFilterClone(emptyFilter);
      setFlag(flag + 1);
    } else if (tempArr?.length > flag) {
      const obj = {...selectedFilterClone, [tempArr[flag]]: true};
      setSelectedFilterClone(obj);
      setFlag(flag + 1);
    } else {
      setFlag(100);
      setTempArr();
    }
  }, [flag]);

  useEffect(() => {
    if (flag === -1) {
      const indexArray = [];
      for (const key in selectedFilter) {
        if (selectedFilter.hasOwnProperty(key) && selectedFilter[key]) {
          indexArray.push(key);
        }
      }

      const revArr = indexArray.sort((a, b) => b.localeCompare(a));
      setTempArr(revArr);
    }
  }, [selectedFilter, flag]);

  function handleTabSwitch(data) {
    setTempNumber(data);
    getRightSideData(data, centeredLatLong, bounds);

    setFilter(data);
    setLoopFlag(data + 1);
  }
  // const handleMoveEnd = (e) => {
  //   if (timeoutId) {
  //     clearTimeout(timeoutId);
  //   }
  //   setNoMove(false);
  //   const newTimeoutId = setTimeout(() => {
  //     setEventMoveEnd(e);
  //     setNoMove(true);
  //   }, 1000);

  //   setTimeoutId(newTimeoutId);
  // };

  const resetCtx = () => {
    resetDigMapCtx();
  };

  const handleSwitchChange = async (switchValues) => {
    setFlag(-1);
    setSelectedFilter(switchValues);
    if (switchValues.switch1 === true) {
      const findKey = await findKeyByProjectName(
        groupName,
        rightData.detail.kodKawasa
      );
      downloadDirianPokok(findKey);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);
  // useEffect(() => {
  //   if (noMove) {
  //     const e = eventMoveEnd;

  //     setCenteredLatLong(newCenterHandler(e.target));
  //     const centerNew =
  //       window.innerWidth < 576
  //         ? e.target.getBounds().getCenter()
  //         : newCenterHandler(e.target);
  //     setBounds(e.target.getBounds());

  //     const zoomCheck = zoomer >= 17;
  //     const bounds = e.target.getBounds();
  //     // const filteredMarkers = L.marker.filter(marker => bounds.contains(marker.position));

  //     if (zoomCheck && lastMarkerLoc) {
  //       if (
  //         lastMarkerLoc.lat > bounds._southWest.lat &&
  //         lastMarkerLoc.lat < bounds._northEast.lat &&
  //         lastMarkerLoc.long > bounds._southWest.lng &&
  //         lastMarkerLoc.long < bounds._northEast.lng
  //       )
  //         console.log("ga load");
  //       else {
  //         getMapData(bounds, e.target.getZoom(), centerNew);
  //       }
  //     } else {
  //       getMapData(bounds, e.target.getZoom(), centerNew);
  //     }
  //   }
  // }, [noMove]);
  const newCenterHandler = (map) => {
    const newWidth = window.innerWidth;
    const container =
      document
        .getElementsByClassName('dataContainer')[0]
        .getBoundingClientRect().width + 10;

    const perbandingan = parseFloat((newWidth - container) / newWidth);
    const newCenterX =
      ((map.getBounds()._northEast.lng - map.getBounds()._southWest.lng) *
        perbandingan) /
      2;

    let newCenterLng = map.getBounds().getCenter();

    newCenterLng.lng = newCenterX + map.getBounds()._southWest.lng;

    return newCenterLng;
  };

  function MapFunctionality() {
    const map = useMapEvents({
      click: (e) => {
        map.locate();
      },
      zoomend: (e) => {
        const currentZoom = e.target.getZoom();
        setZoomer(e.target.getZoom());
        // console.log(isMarkerClick, currentZoom, zoomer);
        if (currentZoom >= 10 && currentZoom < 12) {
          setPkCode();
          setIotDevice();
          setUseGroupName(false);
        } else if (currentZoom < 10) {
          setNegeriCode();
          setUseGroupName(false);
        }
        if (
          (currentZoom === 17 && zoomer < currentZoom && isPolyExist) ||
          currentZoom < 15 ||
          // (currentZoom === 15 && zoomer > currentZoom) ||
          (currentZoom === 16 && zoomer > currentZoom && isPolyExist) ||
          isMarkerClick
        ) {
          getMapData(
            e.target.getBounds(),
            e.target.getZoom(),
            e.target.getBounds().getCenter()
          );
        }

        setMarkerClick(false);
      },
      moveend: (e) => {
        // handleMoveEnd(e);
        // console.log("getBounds", e.target.getBounds());
      },
    });

    const earlyBounds = map.getBounds();
    useEffect(() => {
      if (initialized === false) {
        const centerNew =
          window.innerWidth < 576
            ? map.getBounds().getCenter()
            : newCenterHandler(map);
        getMapData(earlyBounds, map.getZoom(), centerNew);
        setInitialized(true);
      }
    }, []);
    return null;
  }

  useEffect(() => {
    if (tempBlokAgronomi) setPolyExist(true);
    else setPolyExist(false);
  }, [tempBlokAgronomi]);

  useEffect(() => {
    if (
      iotRef.current &&
      blokAgronomiRef.current &&
      (tempBlokAgronomi ||
        tempJaringanJalan ||
        tempPerimeterLadang ||
        tempGunaTanah ||
        tempDataPokok)
    ) {
      blokAgronomiRef.current.bringToFront();
      jaringanJalanRef.current?.bringToFront();
      iotRef.current?.bringToFront();
      dirianPokokRef.current?.bringToFront();
    }
  }, [
    iotRef.current,
    blokAgronomiRef.current,
    jaringanJalanRef.current,
    dirianPokokRef.current,
  ]);

  const callWeather = async (data) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/mapping/open-weather`,
        {
          latitude: data.nearest_latitude,
          longitude: data.nearest_longitude,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      if (res.status === 200) {
        setTempWeather(res?.data?.data);
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  const OnFeature = (feature, layer) => {
    const map = useMap();

    layer.on('click', (e) => {
      setMarkerClick(true);
      let zoomPlus;
      // console.log(map.getZoom(), "getZoom");
      if (map.getZoom() >= 14) {
        // callIotDevice(feature?.properties?.code, "project_code");
        zoomPlus = 17;
      } else if (map.getZoom() === 18) zoomPlus = 18;
      else {
        zoomPlus = map.getZoom() + 2;
      }
      if (zoomPlus >= 12 && zoomPlus < 14) {
        setPkCode(feature?.properties?.code);
        callIotDevice(feature?.properties?.code, 'pk_code');
      } else if (zoomPlus >= 10 && zoomPlus < 12) {
        // console.log(feature?.properties?.code);
        setPkCode();
        setNegeriCode(feature?.properties?.code);
      }

      map.flyTo(
        [feature.properties.latitude, feature.properties.longitude],
        zoomPlus
      );
      if (map.getZoom() >= 14) {
        setGroupName(feature.properties.name);
        setUseGroupName(true);
      } else {
        // for when zoom to lvl 13 the marker not removed
        if (map.getZoom < 13) {
          map.eachLayer((layer) => {
            if (layer instanceof L.Marker) {
              map.removeLayer(layer);
            }
          });
        }
        setTempDataPokok();
        setTempJaringanJalan();
        setTempBlokAgronomi();
        setTempPerimeterLadang();
        setTempGunaTanah();
        setTempTerlebihTanam();
        setTempIoT();
      }
    });
  };

  const onFeatureIoTDigitalMap = (feature, layer) => {
    layer.on('click', (e) => {
      navigate(`/iotgraph?id=${feature.properties.id}`);
    });
  };

  const [doughnutData, setDoughnutData] = useState({
    labels: ['Luas Berhasil', 'Luas Bertanam'],
    datasets: [
      {
        label: 'Data',
        data: [1, 1],
        backgroundColor: ['rgba(43, 51, 125, 1)', 'rgba(226, 31, 38, 1)'],
        borderColor: ['rgba(43, 51, 125, 1)', 'rgba(226, 31, 38, 1)'],
        borderWidth: 1,
      },
    ],
  });

  // DOUGHNUT OPTIONS
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 0,
    aspectRatio: 1,
    width: '100%',
    plugins: {
      legend: {
        display: false, // set this to false to hide the legend
      },
    },
  };

  // MAP LOADER FUNCTION
  const getMapData = async (data, zoom, center) => {
    setLoad(true);
    var url = '';
    var properties = {
      flag: 'drag',
    };
    if (zoom >= 6) {
      url = `${apiUrl}/api/area/wilayah/get`;
      properties = {
        flag: 'drag',
        latitude_x: data._southWest.lat,
        longitude_x: data._southWest.lng,
        latitude_y: data._northEast.lat,
        longitude_y: data._northEast.lng,
      };
      setDisplayCode(false);
    }
    if (zoom >= 8) {
      url = `${apiUrl}/api/area/negeri/get-by-wilayah`;
      properties = {
        flag: 'drag',
        latitude_x: data._southWest.lat,
        longitude_x: data._southWest.lng,
        latitude_y: data._northEast.lng,
        longitude_y: data._northEast.lng,
      };
      setDisplayCode(true);
    }
    if (zoom >= 10) {
      url = `${apiUrl}/api/mapping/pk-code/get-by-negeri-new`;
      if (negeriCode)
        properties = {
          flag: 'click',
          code: negeriCode,
          status: 'publish',
        };
      else
        properties = {
          flag: 'drag',
          // code: "06",
          latitude_x: data._southWest.lat,
          longitude_x: data._southWest.lng,
          latitude_y: data._northEast.lng,
          longitude_y: data._northEast.lng,
          status: 'publish',
        };
      setDisplayCode(false);
    }
    if (zoom >= 12) {
      url = `${apiUrl}/api/mapping/project-code/get-by-pk-code-new`;
      if (pkCode)
        properties = {
          flag: 'click',
          code: pkCode,
          status: 'publish',
        };
      else
        properties = {
          flag: 'drag',
          latitude_x: data._southWest.lat,
          longitude_x: data._southWest.lng,
          latitude_y: data._northEast.lng,
          longitude_y: data._northEast.lng,
          status: 'publish',
        };
      setDisplayCode(false);
    }
    if (zoom > 14 || (zoom === 14 && isPolyExist)) {
      url = `${apiUrl}/api/mapping/collect-map-new`;
      properties = {
        input_params: 'null',
        target: 'all',
        latitude_x: data._southWest.lat,
        longitude_x: data._southWest.lng,
        latitude_y: data._northEast.lat,
        longitude_y: data._northEast.lng,
        groupname: useGroupName ? groupName : null,
        center_lat: center.lat,
        center_long: center.lng,
        status: 'publish',
        only_blok_agronomi: zoom >= 17 ? false : true,
      };
      setDisplayCode(false);
    }

    try {
      const res = await axios.post(url, properties, {
        headers: {
          Authorization: `Bearer ${tokens_session}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      if (res.data.status === 200) {
        if (res.data.polyphia === true) {
          setTempDataPokok();
          setTempJaringanJalan();
          setTempBlokAgronomi();
          setTempPerimeterLadang();
          setTempGunaTanah();
          setTempTerlebihTanam();
          setTempIoT();
          setGroupName(res.data.data.groupname);
          setUseGroupName(false);
          getRightSideData(tempNumber, center, data);
          setCenteredLatLong(center);
          setBounds(data);
          setRightData(res?.data?.data);
          // const findKey = await findKeyByProjectName(
          //   res.data.data.groupname,
          //   res.data.data.detail.kodKawasa
          // );
          // downloadDirianPokok(findKey);

          for (let i = 0; i < res?.data?.data?.data?.length; i++) {
            const data = res?.data?.data?.data[i];

            if (data?.name === 'Jaringan Jalan') {
              setTempJaringanJalan(data);
            }

            if (data?.name === 'Blok Agronomi') {
              setTempBlokAgronomi(data);
            }

            if (data?.name === 'Perimeter Ladang') {
              setTempPerimeterLadang(data);
            }

            if (data?.name === 'Gunatanah') {
              setTempGunaTanah(data);
            }

            if (data?.name === 'Terlebih Tanam') {
              setTempTerlebihTanam(data);
            }

            if (data?.name === 'IoT') {
              setTempIoT(data);
            }
          }
        } else {
          setGeoJsonLayer();
          setMapData(res?.data?.data);
        }
      } else {
        console.log('no data');
      }
      if (res.data.status === 401) {
        destroyCookie(null, 'token');
        navigate('/login');
      }

      if (zoom > 14) {
        setFlag(-1);
      }
      setLoad(false);
      return res.data;
    } catch (e) {
      setLoad(false);
      console.log(e);
    }
  };

  const findKeyByProjectName = async (name, pk) => {
    const jsonFile = await getDirianJSONFile(pk);
    const project = jsonFile.find((proj) => proj.namaProjek === name);
    return project ? project.key : null;
  };

  const callIotDevice = async (code, flag) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/device/list`,
        {
          flag: flag,
          value: code,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const filteredDataFeature = res.data.data.map((obj) => {
        return {
          type: 'Feature',
          properties: {
            name: obj.title,
            id: obj.id,
            latitude: obj.latitude,
            longitude: obj.longitude,
            pk_code: obj.pk_code,
            project_code: obj.project_code,
            total_area_ha: obj.total_area_ha,
            type: obj.type,
          },
          geometry: {
            type: 'Point',
            coordinates: [obj.longitude, obj.latitude],
          },
        };
      });
      const result = {
        type: 'FeatureCollection',
        features: filteredDataFeature,
      };

      setIotDevice(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getRightSideData = async (number, center, bounds) => {
    var target = '';
    if (number === 0 || number === undefined || number === null) {
      target = 'Dirian Pokok';
    }
    if (number === 1) {
      target = 'Jalan';
    }
    if (number === 2) {
      target = 'Guna Tanah';
    }
    try {
      const res = await axios.post(
        `${apiUrl}/api/mapping/collect-data-new`,
        {
          // groupname: groupname,
          target: target,
          latitude_x: bounds._southWest.lat,
          longitude_x: bounds._southWest.lng,
          latitude_y: bounds._northEast.lat,
          longitude_y: bounds._northEast.lng,
          center_lat: center.lat,
          center_long: center.lng,
          status: 'publish',
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      if (res.status === 200) {
        setMapData(res.data);
        callWeather(res.data?.data?.groupname_detail);
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  const getDirianJSONFile = async (pkcode) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/mapping/project_code/get-all-by-pk-code-new`,
        {
          pk_code: pkcode,
          status: 'publish',
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };
  const downloadDirianPokok = async (key) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/mapping/geojson/download`,
        {
          key: key,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (res.status === 200) {
        setTempDataPokok(res.data);
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (mapData.length > 0) {
      const geoJsonFeatures = mapData.map((item) => ({
        type: 'Feature',
        properties: {
          name: item.title,
          id: item.id,
          latitude: item.latitude,
          longitude: item.longitude,
          code: item.code,
          wilayah_id: item.wilayah_id,
        },
        geometry: {
          type: 'Point',
          coordinates: [item.longitude, item.latitude],
        },
      }));

      const geoJson = {
        type: 'FeatureCollection',
        features: geoJsonFeatures,
      };
      setGeoJsonLayer(geoJson);
      setResLuasLadangHa(
        mapData.reduce((sum, obj) => sum + obj.luas_ladang_ha, 0).toFixed(2)
      );
      setResLuasLadangAc(
        mapData.reduce((sum, obj) => sum + obj.luas_ladang_ac, 0).toFixed(2)
      );
      setResLuasBerhasil(
        mapData.reduce((sum, obj) => sum + obj.luas_berhasil, 0).toFixed(2)
      );
      setResLuasBertanam(
        mapData.reduce((sum, obj) => sum + obj.luas_bertanam, 0).toFixed(2)
      );

      setDoughnutData({
        labels: ['Luas Berhasil', 'Luas Bertanam'],
        datasets: [
          {
            label: 'Data',
            data: [
              mapData.reduce((sum, obj) => sum + obj.luas_berhasil, 0),
              mapData.reduce((sum, obj) => sum + obj.luas_bertanam, 0),
            ],
            backgroundColor: ['rgba(43, 51, 125, 1)', 'rgba(226, 31, 38, 1)'],
            borderColor: ['rgba(43, 51, 125, 1)', 'rgba(226, 31, 38, 1)'],
            borderWidth: 1,
          },
        ],
      });
    }
  }, [mapData]);

  useEffect(() => {
    if (zoomer <= 14) {
      setTempDataPokok();
      setTempJaringanJalan();
      setTempBlokAgronomi();
      setTempPerimeterLadang();
      setTempGunaTanah();
      setTempTerlebihTanam();
      setTempIoT();
    }
  }, [zoomer]);

  const pointToLayer = (feature, latlng) => {
    const displayTooltipCode =
      displayCode === true ? feature.properties.code : '';
    const icon = L.icon({
      iconUrl: locationLogo,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [10, -20],
    });
    const tooltip = L.tooltip({
      direction: 'right',
      permanent: 'true',
      opacity: 1,
      arrow: false,
      interactive: true,
      className: 'custom-tooltip',
      riseOffset: 800,
      riseOnHover: true,
    }).setContent(displayTooltipCode + ' ' + feature.properties.name);
    const marker = L.marker(latlng, {
      icon,
      className: 'custom-tooltip',
      riseOnHover: true,
    }).bindTooltip(tooltip);

    return marker;
  };

  const handleFeatureClick = (event, layer) => {
    setSelectedFeature(layer.feature);

    // Remove the existing highlight layer if it exists
    if (highlightLayer) {
      map.removeLayer(highlightLayer);
    }

    // Create a new highlight layer for the clicked feature
    let newHighlightLayer;
    if (
      layer.feature.geometry.type === 'Polygon' ||
      layer.feature.geometry.type === 'MultiPolygon'
    ) {
      newHighlightLayer = L.geoJSON(layer.feature, {
        style: {
          fillColor: 'green',
          fillOpacity: 0.6,
          stroke: false,
        },
      }).addTo(map);
    } else if (layer.feature.geometry.type === 'Point') {
      const latlng = layer.getLatLng();
      const radius = 10; // set the radius of the buffer zone in pixels
      newHighlightLayer = L.circle(latlng, {
        radius,
        fillColor: 'green',
        fillOpacity: 0.6,
        stroke: false,
      }).addTo(map);
    }

    // Store the new highlight layer in the state
    setHighlightLayer(newHighlightLayer);

    // Unhighlight the features in the other layers
    map.eachLayer(function (layer) {
      if (layer instanceof L.GeoJSON && layer !== newHighlightLayer) {
        layer.resetStyle();
      }
    });
  };

  const mapMarginTop = document.getElementsByClassName('headerFelcra')[0]
    ? document.getElementsByClassName('headerFelcra')[0].getBoundingClientRect()
        .height
    : 60;

  const isXsScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isSmScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMdScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLgScreen = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isXlScreen = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <div>
      {isLoad && (
        <div style={{position: 'absolute'}}>
          <Loader />
        </div>
      )}
      <div
        className='MapGeneratedMain'
        style={{
          marginTop: mapMarginTop,
          height: `calc(100vh - ${mapMarginTop})`,
        }}
      >
        <MapContainer
          center={[2.208543218591131, 108.03955078125]}
          zoom={zoomer}
          className='mapContainer'
          zoomControl={false}
          ref={mapRef}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          <MapFunctionality />
          <div className='mapWrapper'>
            <div className='zoom-control-container'>
              <ZoomControl position='bottomright' />
            </div>

            {geoJsonLayer && (
              <GeoJSON
                data={geoJsonLayer}
                pointToLayer={pointToLayer}
                onEachFeature={OnFeature}
              ></GeoJSON>
            )}

            {selectedFilterClone?.switch7 && (
              <>
                {iotDevice && (
                  <>
                    <GeoJSON
                      data={iotDevice}
                      pointToLayer={(feature, latlng) => {
                        const icon = L.icon({
                          iconUrl: iotLogo,
                          iconSize: [25, 25],
                          iconAnchor: [14, 33],
                          popupAnchor: [1, -34],
                          tooltipAnchor: [-10, -20],
                        });
                        const tooltip = L.tooltip({
                          direction: 'left',
                          permanent: 'true',
                          opacity: 1,
                          arrow: false,
                          interactive: true,
                          className: 'custom-tooltip',
                          riseOffset: 800,
                          riseOnHover: true,
                        }).setContent(feature.properties.name);
                        const marker = L.marker(latlng, {
                          icon,
                          className: 'custom-tooltip',
                          riseOnHover: true,
                        }).bindTooltip(tooltip);

                        return marker;
                      }}
                      onEachFeature={onFeatureIoTDigitalMap}
                    ></GeoJSON>
                    <GeoJSON
                      data={iotDevice}
                      pointToLayer={(feature, latlng) => {
                        return L.circle(latlng, {
                          color: '#008AFF',
                          fillColor: '#008AFF',
                          fillOpacity: 0.2,
                          radius: Math.sqrt(
                            (feature?.properties?.total_area_ha * 10000) / 3.14
                          ),
                        });
                      }}
                      ref={iotRef}
                    ></GeoJSON>
                  </>
                )}
              </>
            )}

            {zoomer < 17 ? (
              <>
                {selectedFilterClone?.switch3 && (
                  <>
                    {tempBlokAgronomi && (
                      <>
                        <GeoJSON
                          ref={blokAgronomiRef}
                          data={tempBlokAgronomi}
                          style={(feature) => ({
                            fillColor: `rgba(${feature.color})`,
                            fillOpacity: 1,
                            lineCap: 'butt',
                            lineJoin: 'miter',
                            // color: `rgba(${feature.color})`,
                            color:
                              selectedFeature === feature
                                ? '#FF0000'
                                : `rgba(${feature.color})`,
                            weight: 1,
                          })}
                          onEachFeature={(feature, layer) => {
                            layer.options.layerName = 'B2';
                            layer.on({
                              click: (event) =>
                                handleFeatureClick(event, layer),
                            });
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {selectedFilterClone?.switch1 && (
                  <>
                    {tempDataPokok && (
                      <GeoJSON
                        data={tempDataPokok}
                        pointToLayer={(feature, latlng) => {
                          return L.circleMarker(latlng, {
                            radius: feature.size,
                            fillColor: `rgba(${feature.outline_color})`,
                            color: `rgba(${feature.color})`,
                            opacity: feature.alpha,
                          });
                        }}
                        onEachFeature={(feature, layer) => {
                          layer.on({
                            click: (event) => handleFeatureClick(event, layer),
                          });
                        }}
                        ref={dirianPokokRef}
                      />
                    )}
                  </>
                )}
                {selectedFilterClone?.switch2 && (
                  <>
                    {tempJaringanJalan && (
                      <>
                        <GeoJSON
                          ref={jaringanJalanRef}
                          data={tempJaringanJalan}
                          style={(feature) => ({
                            color: '#000000',
                            weight: 2,
                            opacity: feature.alpha,
                            dashArray: [5, 10],
                            dashOffset: 0,
                            lineCap: 'round',
                            lineJoin: 'round',
                          })}
                        />
                      </>
                    )}
                  </>
                )}
                {selectedFilterClone?.switch3 && (
                  <>
                    {tempBlokAgronomi && (
                      <>
                        <GeoJSON
                          ref={blokAgronomiRef}
                          data={tempBlokAgronomi}
                          style={(feature) => ({
                            fillColor: `rgba(${feature.color})`,
                            fillOpacity: 1,
                            lineCap: 'butt',
                            lineJoin: 'miter',
                            // color: `rgba(${feature.color})`,
                            color:
                              selectedFeature === feature
                                ? '#FF0000'
                                : `rgba(${feature.color})`,
                            weight: 1,
                          })}
                          onEachFeature={(feature, layer) => {
                            layer.options.layerName = 'B2';
                            layer.on({
                              click: (event) =>
                                handleFeatureClick(event, layer),
                            });
                          }}
                        />
                      </>
                    )}
                  </>
                )}
                {selectedFilterClone?.switch4 && (
                  <>
                    {tempPerimeterLadang && (
                      <>
                        <GeoJSON
                          data={tempPerimeterLadang}
                          style={(feature) => ({
                            fillColor: `rgba(${feature.color})`,
                            fillOpacity: feature.alpha,
                            lineCap: 'butt',
                            lineJoin: 'miter',
                            color: `rgba(${feature.outline_color})`,
                            weight: 1,
                          })}
                        />
                      </>
                    )}
                  </>
                )}
                {selectedFilterClone?.switch5 && (
                  <>
                    {tempGunaTanah && (
                      <>
                        <GeoJSON
                          data={tempGunaTanah}
                          style={(feature) => ({
                            fillColor: `rgba(${feature.color})`,
                            fillOpacity: feature.alpha,
                            lineCap: 'butt',
                            lineJoin: 'miter',
                            color: `rgba(${feature.outline_color})`,
                            weight: 1,
                          })}
                        />
                      </>
                    )}
                  </>
                )}
                {selectedFilterClone?.switch6 && (
                  <>
                    {tempTerlebihTanam && tempTerlebihTanam.features && (
                      <>
                        <GeoJSON
                          data={tempTerlebihTanam}
                          style={(feature) => ({
                            fillColor: `rgba(${feature.color})`,
                            fillOpacity: feature.alpha,
                            lineCap: 'butt',
                            lineJoin: 'miter',
                            color: `rgba(${feature.outline_color})`,
                            weight: 1,
                          })}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </MapContainer>
        <div
          style={{
            position: 'static',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box className='topHeader' px={2}>
            <Box display='flex' style={{padding: '10px'}}>
              <Link
                to='/'
                style={{
                  textDecoration: 'none',
                  alignItems: 'center',
                  display: 'flex',
                }}
                onClick={() => resetCtx()}
              >
                <ChevronLeftIcon style={{fontSize: '28px', color: '#2B337D'}} />
                <div
                  style={{
                    color: '#2B337D',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '24px',
                    fontWeight: 'bold',
                  }}
                >
                  {newObj.DigitalMappingWord[language]}
                </div>
              </Link>
            </Box>

            <Box style={{display: 'flex', alignItems: 'center'}}>
              {adminFlag && (
                <div>
                  <div className='adminMenu'>
                    <div>
                      <Link
                        to='/uploadmap'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          color: 'black !important',
                          marginRight: '10px !important',
                        }}
                      >
                        <Avatar
                          alt='Upload Map'
                          sx={{
                            width: 24,
                            height: 24,
                            marginRight: '10px',
                          }}
                          src={uploadMapGenerated}
                        />

                        {newObj.UploadMapWord[language]}
                      </Link>
                    </div>
                    <div>
                      <Link
                        to='/mapfiles'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          color: 'black !important',
                          marginRight: '10px !important',
                        }}
                      >
                        <img
                          alt='Map Files'
                          style={{
                            width: 24,
                            height: 24,
                            marginRight: '10px',
                          }}
                          src={mapFiles}
                        />
                        {newObj.MapFilesWord[language]}
                      </Link>
                    </div>
                  </div>

                  <div className='adminMenuSmall'>
                    <IconMenuDigitalMapping />
                  </div>
                </div>
              )}
              <div style={{borderLeft: '1px solid lightgrey'}}>
                <IconMenu
                  onSwitchChange={handleSwitchChange}
                  zoom={zoomer}
                  changeSwitchValue={selectedFilter}
                  filter={filter}
                />
              </div>
            </Box>
          </Box>
        </div>
        {mapData.polyphia !== true ? (
          <Grid className='dataContainer'>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignCenter: 'center',
                height: '100%',
                flexDirection: 'column',
              }}
            >
              <div style={{backgroundColor: '#ceb889'}}>
                <div style={{padding: '20px'}}>
                  <div style={{fontWeight: 'bold'}}>
                    {newObj.JumlahKeluasanKawasanWord[language]}
                  </div>
                  {/* Top Data */}
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{xs: 1, sm: 2, md: 2}}
                    my={2}
                    sx={{
                      height: '200px',
                      marginTop: '-15px',
                      marginBottom: '-15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Grid
                      item
                      xs={5}
                      px={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: isSmScreen || isXsScreen ? '120px' : '100%',
                          // padding: isSmScreen || isXsScreen ? "30px" : "0px",
                        }}
                      >
                        <Doughnutchart data={doughnutData} options={options} />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <div style={{fontSize: '12px'}}>
                        {newObj.LuasLadangWord[language]}
                        <br />
                        <b style={{fontSize: '12px'}}>
                          {numberWithCommas(resLuasLadangHa)} ha /{' '}
                          {numberWithCommas(resLuasLadangAc)} ac
                        </b>
                      </div>
                      <br />
                      <Grid container>
                        <Grid item xs={1}>
                          <div className='border red'></div>
                        </Grid>
                        <Grid item xs={11} pl={1}>
                          <div variant='caption' style={{fontSize: '12px'}}>
                            {newObj.LuasBertanamWord[language]} <br />
                            <b className='red' style={{fontSize: '12px'}}>
                              {numberWithCommas(resLuasBertanam)} ha
                            </b>
                          </div>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container component='main'>
                        <Grid item xs={1}>
                          <div className='border blue'></div>
                        </Grid>
                        <Grid item xs={11} pl={1}>
                          <div variant='caption' style={{fontSize: '12px'}}>
                            {newObj.LuasBerhasilWord[language]} <br />
                            <b className='blue' style={{fontSize: '12px'}}>
                              {numberWithCommas(resLuasBerhasil)} ha
                            </b>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>

              {/* Data Loop */}
              <Grid
                className='removeScrollbar'
                pb={5}
                sx={{
                  background: 'rgba(255, 255, 255, 0.6)',
                  backdropFilter: 'blur(10px)',
                  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                  overflowY: 'scroll',
                  flex: '1 1 auto',
                }}
              >
                <DetailData
                  data={mapData}
                  zoom={zoomer}
                  groupName={groupName}
                />
              </Grid>
            </Box>
          </Grid>
        ) : (
          <Grid
            className='dataContainer'
            style={{
              background: 'rgba(255, 255, 255, 0.6)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Grid
              container
              sx={{
                padding: 2,
                backgroundColor: '#ceb889',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Grid item xs={10}>
                <Box style={{display: 'flex', alignItems: 'center', gap: 5}}>
                  <AvatarGroup max={3}>
                    {mapData?.data?.janis_tanaman?.sawit === true && (
                      <Avatar alt='Travis Howard' src={palmOil} />
                    )}
                    {mapData?.data?.janis_tanaman?.getah === true && (
                      <Avatar alt='Travis Howard' src={getah} />
                    )}
                    {mapData?.data?.janis_tanaman?.padi === true && (
                      <Avatar alt='Travis Howard' src={padi} />
                    )}
                  </AvatarGroup>
                  <div>
                    <div style={{fontWeight: 'bold'}}>
                      {rightData?.detail?.namaProjek}
                    </div>
                    <div style={{fontSize: '12px'}}>
                      {rightData?.detail?.kodProjek}
                      <span> | </span>
                      {rightData?.detail?.namaKawasa}
                    </div>
                  </div>
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Avatar alt='Travis Howard' src={tempWeather?.link} />
              </Grid>
            </Grid>

            <DataContainerTab
              adminDigitalMapFlag={adminFlag}
              adminIotFlag={adminIotFlag}
              adminLintramaxFlag={adminLintramaxFlag}
              viewerDigitalMapFlag={viewerDigitalMapFlag}
              viewerLintramaxFlag={viewerLintramaxFlag}
              viewerIotFlag={viewerIotFlag}
              data={mapData?.data}
              onData={handleTabSwitch}
              rightData={rightData}
              selectedGroupName={groupName}
              kawasan={rightData.detail.kodKawasa}
              status={'publish'}
            />
          </Grid>
        )}
      </div>
    </div>
  );
};

export default MapGenerated;
