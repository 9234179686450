import React, {useEffect, useState} from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import './index.css';
import macWP from '../../assets/images/macWP.jpg';
import iotLogo from '../../assets/images/iotlogo.png';
import locationLogo from '../../assets/images/location.svg';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import {
  Box,
  Button,
  Grid,
  useMediaQuery,
  createTheme,
  ThemeProvider,
  Typography,
  Modal,
  Switch,
  styled,
} from '@material-ui/core';
import PageNav from '../../components/PageNav';
import download from '../../assets/images/download.svg';
import {Language} from '../../context/LanguageContext';
import axios from 'axios';
import stationBg from '../../assets/images/iot_Station_bg.jpg';
import portableBg from '../../assets/images/iot_portable_bg.jpg';
import nookies, {destroyCookie} from 'nookies';
import {Loader} from '../../components/Loader';
import {Link, useNavigate} from 'react-router-dom';
import {DataGrid} from '@mui/x-data-grid';
import IOTChart from '../../components/IOTChart';
import moment from 'moment';
import {IoT} from '../../context/IoTContext';
export const IoTGraph = () => {
  let apiUrl = process.env.REACT_APP_MAIN_URL;

  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const navigate = useNavigate();
  const {language, newObj} = Language();
  const {startDate, endDate, isLoad, resetIoTCtx} = IoT();
  const weatherObj = [
    {id: 1, name: 'Temperature (C)', checked: true, key: 'temperature'},
    {id: 2, name: 'Dew Point (C)', checked: false, key: 'dewPoint'},
    {id: 3, name: 'Heat Index (C)', checked: false, key: 'heatIndex'},
    {id: 4, name: 'Wind chill', checked: false, key: 'windChill'},
    {id: 5, name: 'Rainfall (mm)', checked: false, key: 'rainfall'},
    {
      id: 6,
      name: 'Baromatic Pressure (mm Hg)',
      checked: false,
      key: 'barometricPressure',
    },
    {id: 7, name: 'Humidity (%)', checked: false, key: 'humidity'},
    {id: 8, name: 'Wind speed', checked: false, key: 'windSpeed'},
  ];
  const columns = [
    {field: 'index', headerName: '#', width: 20, checked: true},
    {
      field: 'time',
      headerName: newObj.DateWord[language],
      width: 100,
      checked: true,
    },
    {field: 'time', headerName: 'Masa', width: 80, checked: true},
    {field: 'name', headerName: 'Peranti', width: 250, checked: true},
    {
      field: 'category',
      headerName: 'Jenis Peranti',
      width: 130,
      checked: true,
    },
    {
      field: 'temperature',
      headerName: 'Temperature (C)',
      width: 130,
      checked: true,
    },
    {
      field: 'barometricPressure',
      headerName: 'Baromatic Pressure (mm Hg)',
      width: 130,
      checked: false,
    },
    {
      field: 'dewPoint',
      headerName: 'Dew Point (C)',
      width: 130,
      checked: false,
    },
    {
      field: 'heatIndex',
      headerName: 'Heat Index (C)',
      width: 130,
      checked: false,
    },
    {
      field: 'humidity',
      headerName: 'Humidity (%)',
      width: 130,
      checked: false,
    },
    {
      field: 'rainfall',
      headerName: 'Rainfall (mm)',
      width: 130,
      checked: false,
    },
    {
      field: 'windChill',
      headerName: 'Wind chill',
      width: 130,
      checked: false,
    },
    {
      field: 'windSpeed',
      headerName: 'Wind speed',
      width: 130,
      checked: false,
    },
  ];
  const [weatherObject, setWeatherObject] = useState(weatherObj);
  const [columnChecked, setColumnChecked] = useState(columns);
  const [deviceDetail, setDeviceDetail] = useState();
  const [windowWidth, setWindowWidth] = useState();
  const [firstLoad, setFirstLoad] = useState(false);
  const [tempData, setTempData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState({
    downloadLoading: false,
  });
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 100,
    page: 1,
    pageSize: 10,
  });
  const today = moment().format('YYYY/MM/DD');
  const date31DaysAgo = moment(today, 'YYYY/MM/DD')
    .subtract(31, 'days')
    .format('YYYY/MM/DD');
  const [loading, setLoading] = useState(true);
  const [latestFileArchive, setLatestFileArchive] = useState();
  const id = window.location.href.split('=')[1];

  const checkedWeather = weatherObject.filter((item) => {
    return item.checked === true;
  });

  const columnCheckedTrue = columnChecked.filter((item) => {
    return item.checked === true;
  });

  const checkedCount = weatherObject.filter(
    (item) => item.checked === true
  ).length;

  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const theme = createTheme({
    typography: {
      fontFamily: ['Montserrat'],
    },
  });
  const isXsScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isSmScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    p: 4,
    borderRadius: '12px',
  };

  const handleChecked = (event, index) => {
    const newArr = weatherObject.map((item, currentIndex) =>
      currentIndex === index ? {...item, checked: !item.checked} : item
    );
    setWeatherObject(newArr);
  };

  // const MaterialUISwitch = styled(Switch)(({ checked }) => ({
  //   "& .MuiSwitch-thumb": {
  //     color: checked ? "#68aaff" : "dimgray",
  //   },
  //   "& .Mui-checked": {
  //     color: checked ? "#5a7ba6 !important" : "dimgray",
  //   },
  //   "& .MuiSwitch-track": {
  //     backgroundColor: checked ? "#5a7ba6 !important" : "dimgray",
  //   },
  // }));

  const resetCtx = () => {
    resetIoTCtx();
  };

  const handleDownloadReport = async (payload) => {
    setIsLoading({...isLoading, downloadLoading: true});

    const filter = checkedWeather
      .map((el) => {
        return el.label;
      })
      .join(',');
    const res = await axios
      .get(
        `${apiUrl}/api/iot/download-data-terperinci?device=${
          deviceDetail.name
        }&category=${deviceDetail.category}&first_load=${
          !firstLoad ? true : false
        }&start=${startDate}&end=${endDate}&filter=${filter}`,
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            // "Content-Type": "multipart/form-data",
          },
          responseType: 'arraybuffer',
        }
      )
      .then(({data, headers}) => {
        const fileName = headers['content-disposition'].split('=')[1];
        const uint8Array = new Uint8Array(data);
        const blob = new Blob([uint8Array], {
          type: `application/vnd.ms-excel`,
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.setAttribute('target', '_blank');
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setIsLoading({...isLoading, downloadLoading: false});
      })
      .catch(() => {
        setIsLoading({...isLoading, downloadLoading: false});
      });
  };

  const handleDataReturn = (data) => {
    setTempData(data);
  };

  useEffect(() => {
    const getDeviceDetail = async (id) => {
      try {
        const res = await axios.post(
          `${apiUrl}/api/device/detail`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (res.data.status === 200) {
          setDeviceDetail(res.data.data);
        }
        if (res.data.status === 401) {
          destroyCookie(null, 'token');
          navigate('/login');
        }
        return res.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    if (id) {
      getDeviceDetail(id);
    }
  }, []);

  useEffect(() => {
    const getDataTypeList = async () => {
      try {
        const res = await axios.post(
          `${apiUrl}/api/data-type/list`,
          {
            category: deviceDetail.category,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (res.data.status === 200) {
          let tempDataTypeList = res.data.data.map((el) => {
            if (
              !el.checked &&
              el.label === 'temperature'
              // || el.label === "time"
            )
              el.checked = true;
            else if (!el.checked) el.checked = false;

            if (el.label === 'time') {
              el.headerName = 'Masa';
              el.width = 80;
            } else {
              el.headerName = el.name;
              el.width = 130;
            }
            el.field = el.label;
            return el;
          });
          const dataTypeList = tempDataTypeList.filter(
            (el) => el.label !== 'time'
          );
          const dataColumns = [
            {field: 'index', headerName: '#', width: 20, checked: true},
            {
              field: 'date',
              headerName: newObj.DateAndTimeWord[language],
              width: 180,
              checked: true,
              type: 'dateTime',
              valueGetter: (params) => {
                const date = moment(
                  params.row.date + ' ' + params.row.time
                ).format('DD-MM-YYYY HH:mm');
                return date;
              },
              sortComparator: (v1, v2, cellParams1, cellParams2) => {
                // Implement your custom date comparison logic here
                const date1 = moment(v1, 'DD-MM-YYYY HH:mm');
                const date2 = moment(v2, 'DD-MM-YYYY HH:mm');
                return date1 - date2;
              },
            },
            // {
            //   field: "time",
            //   headerName: newObj.TimeWord[language],
            //   width: 100,
            //   checked: true,
            //   valueGetter: (params) => {
            //     const hour = String(
            //       moment(params.row.time, "hh:mm").hour()
            //     ).padStart(2, "0");
            //     const minute = String(
            //       moment(params.row.time, "hh:mm").minute()
            //     ).padStart(2, "0");
            //     return `${hour}:${minute}`;
            //   },
            // },
            {
              field: 'name',
              headerName: newObj.DeviceWord[language],
              width: 250,
              checked: true,
            },
            {
              field: 'category',
              headerName: newObj.DeviceTypeIoTWord[language],
              width: 130,
              checked: true,
            },
            ...dataTypeList,
            deviceDetail.category === 'soil' && deviceDetail.type === 'portable'
              ? {
                  field: 'action',
                  headerName: newObj.ActionWord[language],
                  sortable: false,
                  renderCell: (params) => {
                    const lat = params.row.latitude;
                    const long = params.row.longitude;
                    const locName = params.row.locationName;
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <Link
                          style={{
                            backgroundColor: '#2C347F',
                            borderRadius: '12px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '50px',
                          }}
                          to={`/iotgraph/detail?id=${id}&lat=${lat}&long=${long}&locName=${locName}`}
                        >
                          <img
                            src={locationLogo}
                            alt='location'
                            style={{height: '2rem', width: '2rem'}}
                          />
                        </Link>
                      </div>
                    );
                  },
                  checked: true,
                }
              : {},
          ];
          setWeatherObject(dataTypeList);
          setColumnChecked(dataColumns);
        }
        if (res.data.status === 401) {
          destroyCookie(null, 'token');
          navigate('/login');
        }
      } catch (error) {
        console.log(error);
      }
    };
    const getLatestArchiveFileIoT = async (device_id) => {
      try {
        const res = await axios.post(
          `${apiUrl}/api/archive/iot/latest`,
          {
            device_id,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (res.data.status === 200) setLatestFileArchive(res?.data?.data);
        if (res.data.status === 401) {
          destroyCookie(null, 'token');
          navigate('/login');
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (deviceDetail) {
      getDataTypeList();
      getLatestArchiveFileIoT(deviceDetail?.id);
    }
  }, [deviceDetail]);

  useEffect(() => {
    // const { startDate, endDate } = tempData;
    const getDataTerperinci = async (startDate, endDate) => {
      try {
        setPageState((old) => ({...old, isLoading: true}));
        const res = await axios.post(
          `${apiUrl}/api/iot/get-data-terperinci`,
          {
            device: deviceDetail.name,
            category: deviceDetail.category,
            // page: pageState.page,
            // item_per_page: 20,
            first_load: !firstLoad ? true : false,
            start: !firstLoad ? today : startDate,
            end: !firstLoad ? date31DaysAgo : endDate,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        if (res.data.status === 200) {
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: res.data.data.result,
            // total: res.data.data.total_data,
          }));
          if (!firstLoad) setFirstLoad(true);
        }
        if (res.data.status === 401) {
          destroyCookie(null, 'token');
          navigate('/login');
        }
        setLoading(false);
        return res.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    if (deviceDetail) {
      getDataTerperinci(startDate, endDate);
      setLoading(false);
    }
  }, [
    deviceDetail,
    pageState.page,
    startDate,
    endDate,
    // tempData
  ]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // const columnNew = columnChecked.map((item) =>
    //   item.headerName === weatherObject[0].name
    //     ? { ...item, hidden: !item.hidden }
    //     : item
    // );
    const updatedColumns = columnChecked.map((c) => {
      const obj = weatherObject.filter(
        (o) => o.label === c.label && o.checked !== c.checked
      );
      return obj.length > 0 ? {...c, checked: !c.checked} : c;
    });
    setColumnChecked(updatedColumns);
  }, [weatherObject]);

  if (loading) return <Loader />;
  else
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Box
            style={{
              backgroundImage: `url(${macWP})`,
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light'
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              justifyContent: 'center',
              padding: isXsScreen || isSmScreen ? '10px' : '20px 10px 0px 10px',
            }}
          >
            <PageNav title={newObj.IoTWord[language]} href='/iot' />

            <Grid
              container
              columnSpacing={{xs: 1, sm: 2, md: 1}}
              style={{paddingBottom: '10px', marginTop: '50px'}}
            >
              <Grid item xs={12} md={3} className='relativeParent2'>
                <Box className='sideMenu2'>
                  <div
                    style={{
                      width: '100%',
                      borderBottom: '1px solid grey',
                      flex: '0 0 auto',
                    }}
                  >
                    {/* image */}
                    <div style={{height: '150px', position: 'relative'}}>
                      <img
                        src={
                          deviceDetail.type === 'portable'
                            ? portableBg
                            : stationBg
                        }
                        alt='imageIot'
                        style={{
                          backgroundColor: 'gray',
                          position: 'absolute',
                          height: '100%',
                          width: '100%',
                        }}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          width: '100%',
                          padding: '10px',
                          backgroundImage:
                            'linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
                        }}
                      >
                        <Link to='/iot'>
                          <ChevronLeftIcon style={{fontSize: '30px'}} />
                        </Link>
                      </div>
                      <div
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          width: '100%',
                          padding: '10px 20px',
                          ...(deviceDetail.type !== 'portable'
                            ? {backgroundColor: 'rgba(255,255,255,0.3)'}
                            : {backgroundColor: 'rgba(0,0,0,0.6)'}),
                          fontWeight: '500',
                          alignItems: 'center',
                          display: 'flex',
                          gap: '10px',
                          ...(deviceDetail.type === 'portable'
                            ? {color: 'white'}
                            : {color: 'black'}),
                        }}
                      >
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <img src={iotLogo} width={'30px'} alt='logo' />
                          {deviceDetail.active === false && (
                            <DoNotDisturbOnIcon
                              style={{
                                color: 'red',
                                position: 'absolute',
                                right: '-5px',
                                bottom: '3px',
                                width: '15px',
                                height: '15px',
                              }}
                            />
                          )}
                        </div>
                        {deviceDetail.name}
                      </div>
                    </div>

                    <div
                      style={{
                        padding: '20px 20px',
                      }}
                    >
                      <Grid container>
                        <Grid xs={6}>{newObj.DeviceTypeWord[language]}</Grid>
                        <Grid xs={6}>{deviceDetail.category}</Grid>
                      </Grid>
                      {deviceDetail.pkCode && (
                        <>
                          {' '}
                          <Grid container style={{margin: '20px 0'}}>
                            <Grid xs={6}>{newObj.PKKodWord[language]}</Grid>
                            <Grid xs={6}>{deviceDetail.pkCode}</Grid>
                          </Grid>
                          <Grid container>
                            <Grid xs={6}>{newObj.ProjekKodWord[language]}</Grid>
                            <Grid xs={6}>{deviceDetail.projectCode}</Grid>
                          </Grid>
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      overflowY: 'auto',
                      flex: '1 1 auto',
                    }}
                  >
                    <div style={{fontWeight: 'bold', padding: '10px 20px'}}>
                      {newObj.DataTypesWord[language]}
                    </div>
                    <Box>
                      {weatherObject.map((obj, index) => (
                        <div style={{padding: '8px'}} key={obj.id}>
                          <Box
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              ...(obj.checked && {
                                backgroundColor: '#003d6d',
                              }),
                              borderRadius: '10px',
                              padding: '10px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <img
                                src={obj.icon ? obj.icon : iotLogo}
                                alt={'title'}
                                style={{width: '30px'}}
                              />

                              <span
                                style={{
                                  fontWeight: 'bold',
                                  ...(obj.checked
                                    ? {color: 'white'}
                                    : {color: 'black'}),
                                }}
                              >
                                {obj.name}
                              </span>
                            </div>

                            <input
                              type='checkbox'
                              disabled={checkedCount >= 3 && !obj.checked}
                              id={'check' + obj.id}
                              checked={obj.checked}
                              onChange={(e) => handleChecked(e, index)}
                            />
                          </Box>
                        </div>
                      ))}
                    </Box>
                  </div>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={9}
                style={{
                  backgroundColor: 'rgba(255,255,255,0.8)',
                  borderRadius: '5px',
                  marginTop: '20px',
                  overflow: 'hidden',
                }}
              >
                <Box
                  style={{
                    padding: ' 20px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '40px',
                    backgroundColor: '#4D4D4D',
                    color: 'white',
                    flexWrap: 'wrap',
                  }}
                >
                  {/* item */}
                  <div
                    style={{
                      display: 'flex',
                      width: 'max-content',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <img src={iotLogo} alt='logo' style={{width: '30px'}} />
                    <div>
                      <div style={{fontSize: '10px', color: 'lightgrey'}}>
                        {newObj.DeviceWord[language]}
                      </div>
                      <div>{deviceDetail.name}</div>
                    </div>
                  </div>

                  <div>
                    {/* checked weather */}
                    <div style={{fontSize: '10px', color: 'lightgrey'}}>
                      {newObj.DataTypesWord[language]}
                    </div>
                    <div style={{display: 'flex', gap: '40px'}}>
                      {checkedWeather.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              width: '10px',
                              aspectRatio: '1/1',
                              borderRadius: '50%',
                              border: '1px solid white',
                            }}
                            className={`${item.label}Circle`}
                          ></div>
                          <div>{item.name}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* <div>
                    <MaterialUISwitch></MaterialUISwitch>
                  </div> */}
                </Box>

                {/* graph */}
                <Box
                  style={{
                    backgroundColor: '#4D4D4D',
                    // height: "350px",
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: "center",
                    width: '100%',
                    justifyContent: 'center',
                    color: 'white',
                  }}
                >
                  <IOTChart
                    deviceDetail={deviceDetail}
                    woi={columnChecked}
                    checkedWeather={checkedWeather}
                    windowWidth={windowWidth}
                    columnCheckedTrue={columnCheckedTrue}
                    dataReturn={handleDataReturn}
                    handleOpen={handleOpen}
                  />
                </Box>

                {/* table */}
                <div
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  <Box
                    style={{
                      padding: '20px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{fontSize: '15px', fontWeight: 'bold'}}>
                      {newObj.DataTerperinciWord[language]}
                    </div>

                    <button
                      style={{
                        padding: '10px 20px',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '5px',
                        gap: '10px',
                        cursor: 'pointer',
                      }}
                      disabled={isLoading.downloadLoading || isLoad}
                      onClick={() => {
                        if (deviceDetail.id) handleDownloadReport();
                      }}
                    >
                      <img src={download} alt='download' />{' '}
                      <span>Download Report</span>
                    </button>
                  </Box>

                  <div style={{height: 500, width: '100%', marginTop: '10px'}}>
                    {/* table */}
                    <DataGrid
                      width='100%'
                      columns={columnCheckedTrue}
                      rows={pageState.data}
                      localeText={{noRowsLabel: newObj.NoDataWord[language]}}
                      pageSize={20}
                      // rowsPerPageOptions={[5]}
                      // rowCount={pageState.total}
                      loading={pageState.isLoading}
                      // pagination
                      // page={pageState.page - 1}
                      // pageSize={20}
                      // onPageChange={(newPage) => {
                      //   setPageState((old) => ({
                      //     ...old,
                      //     page: newPage + 1,
                      //   }));
                      // }}
                      // paginationMode={"server"}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>
        <Modal
          open={showModal}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '400px',
              background: '#fff',
              border: '2px solid #000',
              boxShadow: '24',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '32px',
              borderRadius: '12px',
            }}
          >
            <Typography
              id='modal-modal-title'
              variant='h6'
              component='h2'
              style={{marginBottom: '20px'}}
            >
              {newObj.WarningWord[language]}
            </Typography>
            {/* <IconButton
             style={{ position: "absolute", top: "10px", right: "15px" }}
             onClick={handleClose}
           >
             <CloseIcon />
           </IconButton> */}
            <Box
              component='form'
              sx={{
                '& > :not(style)': {m: 1, width: '100%'},
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                flexDirection: 'column',
              }}
              noValidate
              autoComplete='off'
              onSubmit={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                {newObj.ExceedDaysIotWord[language]}
              </Box>

              <Button
                type='submit'
                fullWidth
                style={{
                  width: '100%',
                  padding: 15,
                  backgroundColor: 'rgb(43, 51, 125)',
                  cursor: 'pointer',
                  color: 'white',
                  marginTop: '20px',
                  borderRadius: '10px',
                }}
              >
                {newObj.CloseWord[language]}
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    );
};
