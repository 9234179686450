import React, { useState, useEffect } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Language } from "../context/LanguageContext";

const IconLanguage = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { language, changeLanguage } = Language();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {language === "1" ? <b>En</b> : <b>My</b>}
          <ExpandMoreIcon />
        </div>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        style={{ marginTop: "40px" }}
      >
        <MenuItem
          onClick={() => {
            changeLanguage("1");
            handleClose();
          }}
          style={{
            padding: "10px 20px",
          }}
        >
          <div>English</div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeLanguage("2");
            handleClose();
          }}
          style={{
            padding: "10px 20px",
          }}
        >
          <div>Melayu</div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default IconLanguage;
