import React from "react";
import { Language } from "../context/LanguageContext";

function Greeting() {
  const { language, newObj } = Language();
  const currentTime = new Date().getUTCHours() + 8; // UTC+8 timezone offset
  let greeting;

  if (currentTime < 12) {
    greeting = newObj.GoodMorningWord[language];
  } else if (currentTime < 18) {
    greeting = newObj.GoodAfternoonWord[language];
  } else {
    greeting = newObj.GoodEveningWord[language];
  }

  return <>{greeting}</>;
}

export default Greeting;
