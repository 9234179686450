import React, {useEffect, useState} from 'react';
import nookies, {destroyCookie} from 'nookies';
import axios from 'axios';
import moment from 'moment';
import './index.scss';

import {
  AvatarGroup,
  Box,
  createTheme,
  ThemeProvider,
  useMediaQuery,
  Modal,
  Button,
  styled,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TuneIcon from '@mui/icons-material/Tune';
import Close from '@mui/icons-material/Close';
import {DataGrid} from '@mui/x-data-grid';

import macWP from '../../assets/images/macWP.jpg';
import Sawit from '../../assets/images/Sawit.svg';
import Getah from '../../assets/images/Getah.png';
import Padi from '../../assets/images/Padi.png';
import Download from '../../assets/images/download.svg';
import {SearchKawasan} from '../../components/SearchKawasan';
import {Avatar, Switch} from '@material-ui/core';

import LintraChart from '../../components/LintraChart';
import {Language} from '../../context/LanguageContext';
import {Loader} from '../../components/Loader';
import Swal from 'sweetalert2';
import {useNavigate, useSearchParams, Link} from 'react-router-dom';
import {DigMap} from '../../context/DigMapContext';
import {Lintramax} from '../../context/LintramaxContext';
const TimeSeriesChart = () => {
  const {language, newObj} = Language();
  const {plantType} = DigMap();
  const {
    hasData,
    isLimitIot,
    setIsLimitIot,
    resetLintramaxCtx,
    setFromDigitalMap,
    fromDigitalMap,
  } = Lintramax();
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const navigate = useNavigate();
  const accountInit = [
    {name: 'Komersial (1000)', value: 'FB', selected: true},
    {name: 'PnP (1200)', value: 'PK', selected: true},
    {name: 'JV (1300)', value: 'JV', selected: true},
  ];

  const jenisTanamanInit = [
    {
      name: newObj.SawitWord[language],
      selected: true,
      image: Sawit,
      value: 'Sawit',
      key: 'sawit',
    },
    {
      name: newObj.GetahWord[language],
      selected: true,
      image: Getah,
      value: 'Getah',
      key: 'getah',
    },
    {
      name: newObj.PadiWord[language],
      selected: true,
      image: Padi,
      value: 'Padi',
      key: 'padi',
    },
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const [PKCode, setPKCode] = useState('');
  const [wilayahKod, setWilayahKod] = useState('');
  const [dataDashboardPenuaian, setDataDashboardPenuaian] = useState([]);
  const [dataDashboardPenjualan, setDataDashboardPenjualan] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [projectCode, setProjectCode] = useState('');
  const [count, setCount] = useState(0);
  const [selectedWilayah, setSelectedWilayah] = useState(['All Wilayah']);
  const [wilayahObj, setWilayahObject] = useState([]);
  const [kodNegeri, setKodNegeri] = useState('');
  const [account, setAccount] = useState(accountInit);
  const [jenisTanaman, setJenisTanaman] = useState(jenisTanamanInit);
  const [accountClick, setAccountClick] = useState(false);
  const [jenisTanamanClick, setJenisTanamanClick] = useState(false);
  const [accountUI, setAccountUI] = useState();
  const [jenisTanamanUI, setJenisTanamanUI] = useState();
  const [tableToggle, setTableToggle] = useState(0); //0 = penjualan, 1 = penuaian
  const [isLoad, setLoad] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSearch, setSearch] = useState(false);
  const [IoTDevices, setIoTDevices] = useState();
  const [isLoading, setIsLoading] = useState({
    downloadLoading: false,
    tableLoading: false,
  });

  const [tempData, setTempData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [flag, setFlag] = useState(true);
  const [selectedDevice, setSelectedDevice] = useState();
  const [callIoTData, setCallIoTData] = useState(false);
  const [dataTypeIoT, setDataTypeIoT] = useState([]);
  const theme = createTheme({
    typography: {
      fontFamily: ['Montserrat'],
    },
  });

  const MaterialUISwitch = styled(Switch)(({checked}) => ({
    '& .MuiSwitch-thumb': {
      color: checked ? '#68aaff' : 'dimgray',
    },
    '& .Mui-checked': {
      color: checked ? '#5a7ba6 !important' : 'dimgray',
    },
    '& .MuiSwitch-track': {
      backgroundColor: checked ? '#5a7ba6 !important' : 'dimgray',
    },
  }));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    p: 4,
    borderRadius: '12px',
  };

  const handleOpen = () => setShowModal(true);
  const handleClose = () => {
    if (!isLimitIot) {
      emptyObject();
      getWilayahObject();
    } else setIsLimitIot(false);
    setShowModal(false);
  };
  const handleLoadingChart = (bool) => {
    setIsLoading({...isLoading, downloadLoading: bool});
  };

  const isXsScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isSmScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDataReturn = (data) => {
    setTempData(data);
  };

  const handleSetDataTypeIoT = (data) => {
    setDataTypeIoT(data);
  };

  const handleCallIoTData = (bool) => {
    setCallIoTData(bool);
  };

  const handleSelectDeviceIoT = (data) => {
    setSelectedDevice(data);
  };

  const resetCtx = () => {
    resetLintramaxCtx();
  };

  const downloadReportFunction = async () => {
    try {
      const {startDate, endDate, periodeState, selectedPembeli} = tempData;

      setIsLoading({...isLoading, downloadLoading: true});

      const formData = new FormData();
      const splitDateStart = startDate.split('/');
      const splitDateEnd = endDate.split('/');
      const resSplitDateStart = `${splitDateStart[0]}/${splitDateStart[1]}/01`;
      const lastDate = function (year, month) {
        return new Date(year, Number(month), 0).getDate();
      };
      const resSplitDateEnd = `${splitDateEnd[0]}/${splitDateEnd[1]}/${lastDate(
        splitDateEnd[0],
        splitDateEnd[1]
      )}`;

      const start_era =
        periodeState.value === 'all' ? startDate : resSplitDateStart;
      const end_era = periodeState.value === 'all' ? endDate : resSplitDateEnd;
      const pk_code = PKCode === '' ? 'all' : PKCode;
      const kod_wilayah = wilayahKod === '' ? 'all' : wilayahKod;
      const kod_negeri = kodNegeri === '' ? 'all' : kodNegeri;
      const jenis_tanaman = jenisTanaman
        .filter((item) => item.selected)
        .map((item) => item.value)
        .join(',');

      const acc = account
        .filter((item) => item.selected)
        .map((item) => {
          if (item.name === 'Komersial') return 'Komersial';
          else if (item.name === 'PnP') return 'PnP';
          else return item.value;
        })
        .join(',');
      const kod_projek =
        displayIndex === 4
          ? selectedWilayah[displayIndex]?.split('$')[1]
          : 'all';
      const pembeli =
        selectedPembeli.id === 'all'
          ? 'all'
          : `${selectedPembeli.name}(${selectedPembeli.id})`;
      const res = await axios
        .get(
          `${apiUrl}/api/dashboard-lintramax/download?start_date=${start_era}&end_date=${end_era}&pk_code=${pk_code}&kod_wilayah=${kod_wilayah}&kod_negeri=${kod_negeri}&jenis_tanaman=${jenis_tanaman}&account=${acc}&kod_projek=${kod_projek}&pembeli=${pembeli}&historical=${
            periodeState.value === 'all' ? 'daily' : periodeState.value
          }`,
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
              // "Content-Type": "multipart/form-data",
            },
            responseType: 'arraybuffer',
          }
        )
        .then(({data, headers, config}) => {
          const fileName = headers['content-disposition'].split('=')[1];
          const uint8Array = new Uint8Array(data);
          const blob = new Blob([uint8Array], {
            type: `application/vnd.ms-excel`,
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.setAttribute('target', '_blank');
          a.setAttribute('download', fileName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          setIsLoading({...isLoading, downloadLoading: false});
        })
        .catch((e) => {
          console.log(e);
          setIsLoading({...isLoading, downloadLoading: false});
        });
    } catch (error) {
      console.log(error);
      setIsLoading({...isLoading, downloadLoading: false});
    }
  };
  const columnsPenjualan = [
    {field: 'index', headerName: '#', width: 80},
    {
      field: 'date',
      headerName: newObj.DateWord[language],
      width: 400,
      type: 'date',
      valueFormatter: (params) => {
        const date = moment(params.value).format('DD-MM-YYYY');
        return date;
      },
    },
    // { field: "tanaman", headerName: "Tanaman", width: 100 },
    // { field: "lokasi", headerName: newObj.LokasiWord[language], width: 130 },
    // {
    //   field: "pkCode",
    //   headerName: newObj.PKKodWord[language],
    //   width: 90,
    // },
    {
      field: 'netWeightInMt',
      headerName: newObj.NetWeightWord[language],
      valueGetter: (params) => (params.value ? params?.value?.toFixed(2) : 0),
      width: 400,
    },

    // {
    //   field: "kodPembeli",
    //   headerName: "Kod Pembeli",
    //   width: 120,
    // },
    // {
    //   field: "namaPembeli",
    //   headerName: "Nama Pembeli",
    //   width: 300,
    // },
  ];

  const columnsPenuaian = [
    {field: 'index', headerName: '#', width: 20},
    {
      field: 'date',
      headerName: newObj.DateWord[language],
      width: 130,
      type: 'date',
      valueFormatter: (params) => {
        const date = moment(params.value).format('DD-MM-YYYY');
        return date;
      },
    },
    // {
    //   field: "minWorker",
    //   headerName: `${newObj.TotalWorkerWord[language]} (Min)`,
    //   width: 150,
    // },
    {
      field: 'totalWorker',
      headerName: `${newObj.TotalWorkerWord[language]}`,
      width: 180,
    },
    // {
    //   field: "pkCode",
    //   headerName: newObj.PKKodWord[language],
    //   width: 90,
    // },
    {
      field: 'ffbWeightInMt',
      headerName: 'FFB Weight (MT)',
      width: 150,
      valueGetter: (params) => (params.value ? params?.value?.toFixed(2) : 0),
    },
    {
      field: 'areaHarvestInHa',
      headerName: 'Luas Berhasil (ha)',
      width: 180,
      valueGetter: (params) => (params.value ? params?.value?.toFixed(2) : 0),
    },
    {
      field: 'mt_ha',
      headerName: 'MT/ha',
      width: 180,
      valueGetter: (params) => (params.value ? params?.value?.toFixed(2) : 0),
    },

    // {
    //   field: `minWorker`,
    //   headerName: newObj.TotalWorkerWord[language],
    //   width: 300,
    //   renderCell: (params) => {
    //     const minWorker = params.row.minWorker;
    //     const maxWorker = params.row.maxWorker;
    //     return `${minWorker} - ${maxWorker}`;
    //   },
    // },
  ];
  const rowsPenjualan = dataDashboardPenjualan.map((row, index) => ({
    ...row,
    index: index + 1,
    id: index + 1,
  }));

  const rowsPenuaian = dataDashboardPenuaian.map((row, index) => ({
    ...row,
    index: index + 1,
    id: index + 1,
  }));
  //set UI account
  useEffect(() => {
    let count = 0;

    let selectedNames = '';
    account.map((obj, index) => {
      if (obj.selected) {
        selectedNames += `${obj.name} `;
        count++;
      }
    });

    if (count === account.length) {
      setAccountUI('All');
    } else if (count === 0) setAccountUI('None');
    else {
      setAccountUI(selectedNames);
    }
  }, [account]);

  //set UI jenistanaman
  useEffect(() => {
    let count = 0;

    let selectedNames = '';
    jenisTanaman.map((obj, index) => {
      if (obj.selected) {
        selectedNames += `${obj.name} `;
        count++;
      }
    });

    if (count === jenisTanaman.length) {
      setJenisTanamanUI('All');
    } else if (count === 0) setJenisTanamanUI('None');
    else {
      setJenisTanamanUI(selectedNames);
    }
  }, [jenisTanaman]);

  useEffect(() => {
    if (Object.keys(plantType).length > 0) {
      let count = 0;

      let selectedNames = '';
      const tempData = jenisTanaman.map((obj, index) => {
        if (plantType[obj.key]) {
          selectedNames += `${obj.name} `;
          count++;
        }
        obj.selected = plantType[obj.key];
        return obj;
      });

      setJenisTanaman(tempData);

      if (count === jenisTanaman.length) {
        setJenisTanamanUI('All');
      } else if (count === 0) setJenisTanamanUI('None');
      else {
        setJenisTanamanUI(selectedNames);
      }
    }
  }, [plantType]);

  const handleJenisTanamanSwitch = (event) => {
    const name = event.target.value;

    //change check
    setJenisTanaman(
      jenisTanaman.map((item) =>
        item.name === name ? {...item, selected: !item.selected} : item
      )
    );
  };

  const handleAccountSwitch = (event) => {
    const name = event.target.value;

    //change check
    setAccount(
      account.map((item) =>
        item.name === name ? {...item, selected: !item.selected} : item
      )
    );
  };

  // data and function for searchKawasan
  const displayIndex = count > 4 ? 4 : count;

  // const filteredArray = wilayahObj.filter((item) =>
  //   item.pkName
  //     ? item.pkName.toLowerCase().includes(searchTerm.toLowerCase())
  //     : item.negeri
  //     ? item.negeri.toLowerCase().includes(searchTerm.toLowerCase())
  //     : item.wilayah.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const emptyObject = () => {
    if (isSearch) getWilayahObject();
    setCount(0);
    setSelectedWilayah(['All Wilayah']);
    setWilayahKod('');
    setKodNegeri('');
    setPKCode('');
    setSearch(false);
    setProjectCode('');
    setSelectedDevice();
    setCallIoTData(false);
    setSearchParams();
    setIoTDevices([]);
    setFromDigitalMap({status: false, code: fromDigitalMap.code});
  };

  const returnClick = (index) => {
    const params = {};
    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setSelectedWilayah(selectedWilayah.slice(0, index + 2));
    setCount(index + 1);
    if (index === 0) {
      delete params.kodWilayah;
      delete params.kodProjek;
      delete params.kodKawasan;
      setKodNegeri('');
      handleSetDataTypeIoT([]);
      setSearchParams(params);
      setIoTDevices([]);
    } else if (index === 1) {
      delete params.kodProjek;
      delete params.kodKawasan;
      setPKCode('');
      handleSetDataTypeIoT([]);
      setIoTDevices([]);
      setSearchParams(params);
    } else if (index === 2) {
      delete params.kodProjek;
      handleSetDataTypeIoT([]);
      setProjectCode('');
      setSearchParams(params);
    }
    // setIoTDevices([]);
    setSelectedDevice();
    setCallIoTData(false);
    setFromDigitalMap({status: false, code: fromDigitalMap.code});
  };

  const backClick = () => {
    const returnFlag = count > 4 ? 4 : count;
    const params = {};
    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    if (returnFlag === 1) {
      delete params.idWilayah;
      setWilayahKod('');
      setSearchParams(params);
    } else if (returnFlag === 2) {
      delete params.kodWilayah;
      setKodNegeri('');
      setSearchParams(params);
    } else if (returnFlag === 3) {
      delete params.kodKawasan;
      setPKCode('');
      setSearchParams(params);
    } else {
      delete params.kodProjek;
      setProjectCode('');
      setSearchParams(params);
    }
    setWilayahObject([]);
    setIoTDevices([]);
    setSelectedWilayah(selectedWilayah.slice(0, returnFlag));
    setCount(returnFlag - 1);
    setSelectedDevice();
    setCallIoTData(false);
    setFromDigitalMap({status: false, code: fromDigitalMap.code});
  };

  function handleSearch(text) {
    setSearchTerm(text);
    searchArea(text);
    setIoTDevices([]);
  }

  const searchArea = async (word) => {
    setLoad(true);
    try {
      const res = await axios.post(
        `${apiUrl}/api/lintramax/search-area`,
        {keyword: word},
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setLoad(false);
      if (res.data.status === 200) {
        setSelectedWilayah(['All Wilayah']);
        setWilayahKod('');
        setPKCode('');
        setKodNegeri('');
        setCount(0);
        setProjectCode('');
        setSearch(true);
        setWilayahObject(res?.data?.data);
        setFromDigitalMap({status: false, code: fromDigitalMap.code});
      }
      if (res.data.status === 401) {
        destroyCookie(null, 'token');
        navigate('/login');
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const detailArea = async (flag, code) => {
    setLoad(true);
    try {
      const res = await axios.post(
        `${apiUrl}/api/lintramax/detail-area`,
        {flag: flag, code: code},
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (res.data.status === 200) {
        return res.data.data;
      }
      if (res.data.status === 401) {
        destroyCookie(null, 'token');
        navigate('/login');
      }
      return res.data;
    } catch (error) {
      onErrorApi(error);
      throw error;
    }
  };

  const onErrorApi = (msg) => {
    setLoad(false);
    Swal.fire({
      icon: 'error',
      title: newObj.ErrorWord[language],
      text: msg,
    });
  };

  const onWilayahClick = async (e, id, flag, item) => {
    const objFlag = ['', 'negeri', 'pk', 'project'];
    const index = objFlag.indexOf(flag);
    let obj = e.target.textContent + '$' + id;
    if (isSearch) {
      let returnData = '';
      if (index !== -1) {
        returnData = await detailArea(flag, id);
        const tempSearchParams = {};
        const reversedData = returnData.reverse().map((obj, i) => {
          if (i === 0) {
            setWilayahKod(obj?.id);
            tempSearchParams['idWilayah'] = obj?.id;
          } else if (i === 1) {
            setKodNegeri(obj?.code);
            tempSearchParams['kodWilayah'] = obj?.code;
          } else if (i === 2) {
            setPKCode(obj?.code);
            tempSearchParams['kodKawasan'] = obj?.code;
          }
          if (obj.code === null) {
            return `${obj.name}$${obj.id}`;
          } else {
            return `${obj.name}$${obj.code}`;
          }
        });
        if (returnData.length === 1) {
          setKodNegeri(id);
          tempSearchParams['kodWilayah'] = id;
        } else if (returnData.length === 2) {
          setPKCode(id);
          tempSearchParams['kodKawasan'] = id;
        } else if (returnData.length === 3) {
          setProjectCode(id);
          tempSearchParams['kodProjek'] = id;
        }
        setSearchParams(tempSearchParams);
        setSelectedWilayah(['All Wilayah', ...reversedData, obj]);
        setCount(index + 1);
      } else {
        setCount(1);
        setSelectedWilayah(['All Wilayah', obj]);
      }
      setSearchTerm();
      setSearch(false);
    } else {
      const params = {};

      for (let [key, value] of searchParams.entries()) {
        params[key] = value;
      }

      let name = item.title;
      if (count === 0) {
        setSearchParams({...params, idWilayah: id});
        setWilayahKod(id);
        setKodNegeri('');
        setPKCode('');
        setProjectCode('');
        name = item.wilayah;
      } else if (count === 1) {
        setSearchParams({...params, kodWilayah: id});
        name = item.negeri;
        setKodNegeri(id);
        setPKCode('');
        setProjectCode('');
      } else if (count === 2) {
        setSearchParams({...params, kodKawasan: id});
        name = item.pkName;
        setPKCode(id);
        setProjectCode('');
      } else if (count === 3) {
        setSearchParams({...params, kodProjek: id});
        name = item.namaProjek;
        setProjectCode(id);
      }

      let obj = name + '$' + id;
      setSelectedWilayah([...selectedWilayah, obj]);
      if (count < 4) {
        setWilayahObject([]);
      }
      setCount(count + 1);
    }
    setFlag(false);
  };

  const getWilayahObject = async () => {
    try {
      const res = await axios.get(
        `${apiUrl}/api/area/wilayah/get-lintramax-wilayah`,
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      setLoad(false);
      if (res.data.status === 200) {
        setWilayahObject(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, 'token');
        navigate('/login');
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getNegeriByWilayah = async (id) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/area/negeri/get-lintramax-negeri-by-wilayah`,
        {wilayah_id: id},
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setLoad(false);
      if (res.data.status === 200) {
        setWilayahObject(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, 'token');
        navigate('/login');
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getDataPKCodeByNegeri = async (id) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/lintramax/pk-code/get-data-by-negeri`,
        {kod_negeri: id},
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setLoad(false);
      if (res.data.status === 200) {
        setWilayahObject(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, 'token');
        navigate('/login');
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getDataProjectCodeByPKCode = async (id) => {
    const formData = new FormData();

    formData.append('pk_code', id);
    try {
      const res = await axios.post(
        `${apiUrl}/api/lintramax/projek-kod/get-data`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setLoad(false);
      if (res.data.status === 200) {
        setWilayahObject(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, 'token');
        navigate('/login');
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getDataDashBoardPenuaian = async (
    start_era,
    end_era,
    pk_code,
    kod_wilayah,
    jenis_tanaman,
    account,
    kod_projek,
    pembeli,
    periodeState,
    kod_negeri
  ) => {
    if (periodeState.value === 'all') {
      try {
        setIsLoading({...isLoading, tableLoading: true});
        const res = await axios.post(
          `${apiUrl}/api/dashboard-lintramax/get-data-penuaian-daily`,
          {
            start_date: start_era,
            end_date: end_era,
            pk_code: pk_code,
            kod_wilayah: kod_wilayah,
            jenis_tanaman: jenis_tanaman,
            account: account,
            kod_projek: kod_projek,
            pembeli: pembeli,
            kod_negeri: kod_negeri,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (res.data.status === 200) {
          setDataDashboardPenuaian(res?.data.data);
        }
        setIsLoading({...isLoading, tableLoading: false});
        if (res.data.status === 401) {
          destroyCookie(null, 'token');
          navigate('/login');
        }
        return res.data;
      } catch (error) {
        setIsLoading({...isLoading, tableLoading: false});
        console.error(error);
        throw error;
      }
    } else {
      try {
        setIsLoading({...isLoading, tableLoading: true});
        const res = await axios.post(
          `${apiUrl}/api/dashboard-lintramax/get-data-penuaian-using-year`,
          {
            // start_year: start_era,
            // end_year: end_era,
            start_date: start_era,
            end_date: end_era,
            pk_code: pk_code,
            kod_wilayah: kod_wilayah,
            jenis_tanaman: jenis_tanaman,
            account: account,
            kod_projek: kod_projek,
            pembeli: pembeli,
            historical: periodeState.value,
            kod_negeri: kod_negeri,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (res.data.status === 200) {
          setDataDashboardPenuaian(res?.data?.data?.penuaian);
        }
        setIsLoading({...isLoading, tableLoading: false});
        if (res.data.status === 401) {
          destroyCookie(null, 'token');
          navigate('/login');
        }
        return res.data;
      } catch (error) {
        setIsLoading({...isLoading, tableLoading: false});
        // console.error(error);
        throw error;
      }
    }
  };

  const getDataDashBoardPenjualan = async (
    start_era,
    end_era,
    pk_code,
    kod_wilayah,
    jenis_tanaman,
    account,
    kod_projek,
    pembeli,
    periodeState,
    kod_negeri
  ) => {
    if (periodeState.value === 'all') {
      try {
        setIsLoading({...isLoading, tableLoading: true});
        const res = await axios.post(
          `${apiUrl}/api/dashboard-lintramax/get-data-penjualan-daily`,
          {
            start_date: start_era,
            end_date: end_era,
            pk_code: pk_code,
            kod_wilayah: kod_wilayah,
            jenis_tanaman: jenis_tanaman,
            account: account,
            kod_projek: kod_projek,
            pembeli: pembeli,
            kod_negeri: kod_negeri,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (res.data.status === 200) {
          setDataDashboardPenjualan(res?.data?.data);
        }
        setIsLoading({...isLoading, tableLoading: false});
        if (res.data.status === 401) {
          destroyCookie(null, 'token');
          navigate('/login');
        }
        return res.data;
      } catch (error) {
        console.error(error);
        setIsLoading({...isLoading, tableLoading: false});

        throw error;
      }
    } else {
      try {
        setIsLoading({...isLoading, tableLoading: true});
        const res = await axios.post(
          `${apiUrl}/api/dashboard-lintramax/get-data-penjualan-using-year`,
          {
            // start_year: start_era,
            // end_year: end_era,
            start_date: start_era,
            end_date: end_era,
            pk_code: pk_code,
            kod_wilayah: kod_wilayah,
            jenis_tanaman: jenis_tanaman,
            account: account,
            kod_projek: kod_projek,
            pembeli: pembeli,
            historical: periodeState.value,
            kod_negeri: kod_negeri,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (res.data.status === 200) {
          setDataDashboardPenjualan(res?.data?.data?.penjualan);
        }
        setIsLoading({...isLoading, tableLoading: false});
        if (res.data.status === 401) {
          destroyCookie(null, 'token');
          navigate('/login');
        }
        return res.data;
      } catch (error) {
        // console.error(error);
        setIsLoading({...isLoading, tableLoading: false});
        throw error;
      }
    }
  };

  const getDeviceIdByProjectCode = async (payload) => {
    try {
      const res = await axios.post(`${apiUrl}/api/device/list`, payload, {
        headers: {
          Authorization: `Bearer ${tokens_session}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoad(false);
      if (res.data.status === 200) {
        if (payload.flag === 'pk_code') {
          const result = res.data.data.filter(
            (el) => el.category === 'weather'
          );
          setIoTDevices(result);
        } else setIoTDevices(res.data.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, 'token');
        navigate('/login');
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    setLoad(true);
    if (count === 0) {
      if (!isSearch) getWilayahObject();
      setWilayahKod('');
      setKodNegeri('');
      setPKCode('');
      setLoad(false);
      setProjectCode('');
    } else {
      const id = selectedWilayah[count].split('$')[1];
      if (count === 1) {
        setWilayahKod(id);
        getNegeriByWilayah(id);
        setPKCode('');
        setKodNegeri('');
        setProjectCode('');
      } else if (count === 2) {
        setKodNegeri(id);
        getDataPKCodeByNegeri(id);
      } else if (count === 3) {
        setPKCode(id);
        getDataProjectCodeByPKCode(id);
        getDeviceIdByProjectCode({value: id, flag: 'pk_code'});
      } else {
        setProjectCode(id);
        getDeviceIdByProjectCode({value: id, flag: 'project_code'});
        setLoad(false);
      }
    }
  }, [count]);

  useEffect(() => {
    if (isLimitIot) handleOpen();
  }, [isLimitIot]);

  useEffect(() => {
    if (hasData) {
      setIsLoading({...isLoading, downloadLoading: false});
    } else {
      setIsLoading({...isLoading, downloadLoading: true});
    }
  }, [hasData]);

  useEffect(() => {
    if (
      searchParams.getAll('idWilayah')[0] &&
      searchParams.getAll('kodWilayah')[0] &&
      searchParams.getAll('kodKawasan')[0] &&
      searchParams.getAll('kodProjek')[0] &&
      flag === true
    ) {
      const fetchData = async () => {
        const res1 = await getWilayahObject();
        const res2 = await getNegeriByWilayah(
          searchParams.getAll('idWilayah')[0]
        );
        const res3 = await getDataPKCodeByNegeri(
          searchParams.getAll('kodWilayah')[0]
        );
        const res4 = await getDataProjectCodeByPKCode(
          searchParams.getAll('kodKawasan')[0]
        );
        const found1 = res1?.data?.find(
          (el) => el.id === searchParams.getAll('idWilayah')[0]
        );
        const found2 = res2?.data.find(
          (el) => el.code === searchParams.getAll('kodWilayah')[0]
        );
        const found3 = res3?.data.find(
          (el) => el.pkCode === searchParams.getAll('kodKawasan')[0]
        );
        const found4 = res4?.data.find(
          (el) => el.kodProjek === searchParams.getAll('kodProjek')[0]
        );
        if (!found1 || !found2 || !found3 || !found4) {
          // emptyObject();
          handleOpen();
          return;
        }
        setSelectedWilayah([
          ...selectedWilayah,
          `${found1?.wilayah}$${found1?.id}`,
          `${found2?.negeri}$${found2?.code}`,
          `${found3?.pkName}$${found3?.pkCode}`,
          `${found4?.namaProjek}$${found4?.kodProjek}`,
        ]);
        setCount(4);
        setWilayahKod(found1?.id);
        setKodNegeri(found2?.code);
        setPKCode(found3?.pkCode);
        setProjectCode(found4?.kodProjek);
        setWilayahObject(res4?.data);
      };
      fetchData();
    }
  }, [
    searchParams.getAll('idWilayah')[0],
    searchParams.getAll('kodWilayah')[0],
    searchParams.getAll('kodKawasan')[0],
    searchParams.getAll('kodProjek')[0],
  ]);

  useEffect(() => {
    if (tempData !== null) {
      const {startDate, endDate, periodeState, selectedPembeli} = tempData;

      if (periodeState?.value === 'all') {
        getDataDashBoardPenjualan(
          startDate,
          endDate,
          PKCode === '' ? 'all' : PKCode,
          wilayahKod === '' ? 'all' : wilayahKod,
          jenisTanaman
            .filter((item) => item.selected)
            .map((item) => item.value)
            .join(', '),
          account
            .filter((item) => item.selected)
            .map((item) => item.value)
            .join(', '),
          projectCode === '' ? 'all' : projectCode,
          selectedPembeli.id,
          periodeState,
          kodNegeri === '' ? 'all' : kodNegeri
        );
        getDataDashBoardPenuaian(
          startDate,
          endDate,
          PKCode === '' ? 'all' : PKCode,
          wilayahKod === '' ? 'all' : wilayahKod,
          jenisTanaman
            .filter((item) => item.selected)
            .map((item) => item.value)
            .join(', '),
          account
            .filter((item) => item.selected)
            .map((item) => item.value)
            .join(', '),
          projectCode === '' ? 'all' : projectCode,
          selectedPembeli.id,
          periodeState,
          kodNegeri === '' ? 'all' : kodNegeri
        );
      } else {
        const splitDateStart = startDate.split('/');
        const splitDateEnd = endDate.split('/');
        const resSplitDateStart = `${splitDateStart[0]}/${splitDateStart[1]}/01`;
        const lastDate = function (year, month) {
          return new Date(year, Number(month), 0).getDate();
        };
        const resSplitDateEnd = `${splitDateEnd[0]}/${
          splitDateEnd[1]
        }/${lastDate(splitDateEnd[0], splitDateEnd[1])}`;

        getDataDashBoardPenjualan(
          resSplitDateStart,
          resSplitDateEnd,
          PKCode === '' ? 'all' : PKCode,
          wilayahKod === '' ? 'all' : wilayahKod,
          jenisTanaman
            .filter((item) => item.selected)
            .map((item) => item.value)
            .join(', '),
          account
            .filter((item) => item.selected)
            .map((item) => item.value)
            .join(', '),
          projectCode === '' ? 'all' : projectCode,
          selectedPembeli.id,
          periodeState,
          kodNegeri === '' ? 'all' : kodNegeri
        );
        getDataDashBoardPenuaian(
          resSplitDateStart,
          resSplitDateEnd,
          PKCode === '' ? 'all' : PKCode,
          wilayahKod === '' ? 'all' : wilayahKod,
          jenisTanaman
            .filter((item) => item.selected)
            .map((item) => item.value)
            .join(', '),
          account
            .filter((item) => item.selected)
            .map((item) => item.value)
            .join(', '),
          projectCode === '' ? 'all' : projectCode,
          selectedPembeli.id,
          periodeState,
          kodNegeri === '' ? 'all' : kodNegeri
        );
      }
    } else {
      console.log('Data hasnt loaded');
    }
  }, [tempData, wilayahKod, kodNegeri, PKCode, projectCode]);

  return (
    <div>
      {isLoad && (
        <div style={{position: 'absolute'}}>
          <Loader />
        </div>
      )}
      <ThemeProvider theme={theme}>
        <Box
          style={{
            backgroundImage: `url(${macWP})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
            paddingTop: isXsScreen || isSmScreen ? '10px' : '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {/* nav */}
          <Box className='headerWithButton'>
            {' '}
            <Box
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
              py={1}
              px={2}
            >
              <Box display='flex'>
                <Link
                  to='/'
                  style={{
                    textDecoration: 'none',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  onClick={() => resetCtx()}
                >
                  <ChevronLeftIcon
                    style={{fontSize: '28px', color: '#2B337D'}}
                  />
                  <Box
                    variant='body1'
                    sx={{
                      color: '#2B337D',
                      display: 'flex',
                      alignItems: 'center',
                      fontFamily: 'Montserrat, sans-serif ',
                    }}
                    fontWeight='bold'
                    p={0}
                    ml={3}
                    mb={0}
                  >
                    {newObj.LintraMaxWord[language]}
                  </Box>
                </Link>
              </Box>
              <Box style={{display: 'flex'}}>
                <Box
                  style={{
                    padding: '0 20px 0 20px ',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    borderLeft: '1px solid #cfd8dc',
                    borderRight: '1px solid #cfd8dc',
                    position: 'relative',
                  }}
                >
                  <div>
                    <Typography style={{color: 'gray', fontSize: '11px'}}>
                      Account
                    </Typography>
                    <Typography>{accountUI}</Typography>
                  </div>

                  <div
                    onClick={() => setAccountClick(!accountClick)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    {!accountClick ? (
                      <TuneIcon style={{rotate: '90deg'}} />
                    ) : (
                      <Close />
                    )}
                  </div>
                  {accountClick && (
                    <Box
                      style={{
                        position: 'absolute',
                        borderRadius: '5px',
                        backgroundColor: '#E6E6E6',
                        zIndex: '1000',
                        right: '0',
                        top: '60px',
                        padding: '10px',
                      }}
                    >
                      {account.map((obj, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            width: '240px',
                            justifyContent: 'space-between',
                            padding: '4px',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: '50px',
                            }}
                          >
                            {obj.name}
                          </div>
                          <MaterialUISwitch
                            checked={obj.selected}
                            value={obj.name}
                            control={<MaterialUISwitch />}
                            onClick={handleAccountSwitch}
                          />
                        </div>
                      ))}
                    </Box>
                  )}
                </Box>
                <Box
                  className='avatarLintramax'
                  style={{
                    padding: '0 20px 0 20px ',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    position: 'relative',
                  }}
                >
                  <AvatarGroup
                    max={3}
                    style={{...(windowWidth < 900 && {display: 'none'})}}
                  >
                    {jenisTanaman.map((el) => {
                      if (el.selected && el.value === 'Padi')
                        return <Avatar alt='Remy Sharp' src={Padi} />;
                      else if (el.selected && el.value === 'Sawit')
                        return <Avatar alt='Remy Sharp' src={Sawit} />;
                      else if (el.selected && el.value === 'Getah')
                        return <Avatar alt='Remy Sharp' src={Getah} />;
                    })}
                  </AvatarGroup>
                  <div>
                    <Typography style={{color: 'gray', fontSize: '11px'}}>
                      Jenis Tanaman
                    </Typography>
                    <Typography>{jenisTanamanUI}</Typography>
                  </div>

                  <div
                    onClick={() => setJenisTanamanClick(!jenisTanamanClick)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    {!jenisTanamanClick ? (
                      <TuneIcon style={{rotate: '90deg'}} />
                    ) : (
                      <Close />
                    )}
                  </div>
                  {jenisTanamanClick && (
                    <Box
                      style={{
                        position: 'absolute',
                        zIndex: '1000',
                        borderRadius: '5px',
                        backgroundColor: '#E6E6E6',
                        right: '0',
                        top: '60px',
                        padding: '10px',
                      }}
                    >
                      {jenisTanaman.map((obj, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '4px',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                              marginRight: '50px',
                            }}
                          >
                            <img
                              src={obj.image}
                              alt={obj.name}
                              width={'30px'}
                            />
                            {obj.name}
                          </div>
                          <MaterialUISwitch
                            checked={obj.selected}
                            value={obj.name}
                            onChange={handleJenisTanamanSwitch}
                            control={<MaterialUISwitch />}
                          />
                        </div>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Grid
            container
            columnSpacing={{xs: 1, sm: 2, md: 3}}
            style={{
              minHeight: '100vh',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
              width: '100%',
              ...(windowWidth > 429
                ? {marginTop: '40px'}
                : {
                    marginTop: '60px',
                  }),
            }}
            py={3}
          >
            <Grid
              item
              xs={12}
              md={3}
              style={
                {
                  // position: "sticky",
                  // zIndex: "1",
                  // top: "20px",
                }
              }
            >
              <SearchKawasan
                count={count}
                filteredArray={wilayahObj}
                displayIndex={displayIndex}
                selectedWilayah={selectedWilayah}
                DeviceIdByProjectCode={IoTDevices}
                returnClick={returnClick}
                onWilayahClick={onWilayahClick}
                handleSearch={handleSearch}
                backClick={backClick}
                emptyObject={emptyObject}
                isSearch={isSearch}
                isLoad={isLoad}
                isLintra={true}
                handleSelectDeviceIoT={handleSelectDeviceIoT}
                selectedDevice={selectedDevice}
                handleSetDataTypeIoT={handleSetDataTypeIoT}
                windowWidth={windowWidth}
                iotLoading={isLoading.iotLoading}
                callIoTData={callIoTData}
              />
            </Grid>
            <Grid item xs={12} md={9} sx={{}}>
              <Box
                sx={{
                  backgroundColor: 'rgba(255,255,255,0.8)',
                  borderRadius: '10px',
                  height: '100%',
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                <LintraChart
                  PKCode={PKCode}
                  projectCode={projectCode}
                  kodNegeri={kodNegeri}
                  selectedWilayah={selectedWilayah}
                  displayIndex={displayIndex}
                  account={account}
                  jenisTanaman={jenisTanaman}
                  wilayahKod={wilayahKod}
                  isLoad={isLoad}
                  dataReturn={handleDataReturn}
                  windowWidth={windowWidth}
                  selectedDevice={selectedDevice}
                  callIoTData={callIoTData}
                  dataTypeIoT={dataTypeIoT}
                  handleLoadingChart={handleLoadingChart}
                  handleCallIoTData={handleCallIoTData}
                />
                <Box
                  style={{
                    display: 'flex',
                    padding: '20px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {/* toggle */}
                  <Box
                    style={{
                      backgroundColor: 'lightgray',
                      borderRadius: '5px',
                      display: 'flex',
                    }}
                  >
                    <Box
                      style={{
                        padding: '10px 20px 10px 20px',
                        borderRadius: '5px',
                        backgroundColor: tableToggle === 0 && '#2B5180',
                        cursor: 'pointer',
                        color: tableToggle === 0 && 'white',
                      }}
                      onClick={() => setTableToggle(0)}
                    >
                      {newObj.PenjualanWord[language]}
                    </Box>
                    <Box
                      style={{
                        padding: '10px 20px 10px 20px',
                        borderRadius: '5px',
                        backgroundColor: tableToggle === 1 && '#2B5180',
                        cursor: 'pointer',
                        color: tableToggle === 1 && 'white',
                      }}
                      onClick={() => setTableToggle(1)}
                    >
                      {newObj.PenuaianWord[language]}
                    </Box>
                  </Box>

                  <Button
                    style={{
                      padding: '10px 20px 10px 20px',
                      borderRadius: '5px',
                      backgroundColor: 'lightgray',
                      gap: '10px',
                      display: 'flex',
                      cursor: 'pointer',
                      alignItems: 'center',
                    }}
                    onClick={() => downloadReportFunction()}
                    disabled={isLoading.downloadLoading}
                  >
                    <img src={Download} alt={'download'} />

                    <span
                      style={{
                        ...(windowWidth < 900 && {display: 'none'}),
                        textTransform: 'capitalize',
                      }}
                    >
                      {newObj.DownloadReportWord[language]}
                    </span>
                  </Button>
                </Box>
                <div style={{height: 400, width: '100%', marginTop: '10px'}}>
                  {/* table */}
                  <DataGrid
                    width='100%'
                    columns={
                      tableToggle === 0 ? columnsPenjualan : columnsPenuaian
                    }
                    localeText={{noRowsLabel: newObj.NoDataWord[language]}}
                    pageSize={5}
                    rows={
                      hasData
                        ? tableToggle === 0
                          ? rowsPenjualan
                          : rowsPenuaian
                        : []
                    }
                    loading={isLoading.tableLoading}
                    // rows={[]}
                    rowsPerPageOptions={[5]}
                    // className="dataGrid-menuList"
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            style={{marginBottom: '20px'}}
          >
            {isLimitIot
              ? newObj.WarningWord[language]
              : newObj.NotFoundLintramaxWord[language]}
          </Typography>
          {/* <IconButton
            style={{ position: "absolute", top: "10px", right: "15px" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton> */}
          <Box
            component='form'
            sx={{
              '& > :not(style)': {m: 1, width: '100%'},
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              flexDirection: 'column',
            }}
            noValidate
            autoComplete='off'
            onSubmit={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              {isLimitIot
                ? newObj.ExceedDaysIotWord[language]
                : newObj.NotFoundLocationLintramaxWord[language]}
            </Box>

            <Button
              type='submit'
              fullWidth
              style={{
                width: '100%',
                padding: 15,
                backgroundColor: 'rgb(43, 51, 125)',
                cursor: 'pointer',
                color: 'white',
                marginTop: '20px',
                borderRadius: '10px',
              }}
            >
              {newObj.CloseWord[language]}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default TimeSeriesChart;
