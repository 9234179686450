import React from "react";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Language } from "../context/LanguageContext";
export const ProtectedRoute = (props) => {
  const role = props.user.authority;
  const permission = props.permission;
  const hasCommon = role.some((element) => permission.includes(element));
  const { language, newObj } = Language();
  if (!hasCommon) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: newObj.CannotAccessPageWord[language],
    });
    return <Navigate to={"/"} />;
  } else return props.children;
};
