import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { LanguangeContextProvider } from "./context/LanguageContext";
import { DigMapContextProvider } from "./context/DigMapContext";
import { LintramaxContextProvider } from "./context/LintramaxContext";
import { IoTContextProvider } from "./context/IoTContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LanguangeContextProvider>
        <LintramaxContextProvider>
          <IoTContextProvider>
            <DigMapContextProvider>
              <App />
            </DigMapContextProvider>
          </IoTContextProvider>
        </LintramaxContextProvider>
      </LanguangeContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
