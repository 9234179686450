import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";

const IoTContext = createContext({
  // hasData: false,
  // setHasData: () => {},
  // isLimitIot: false,
  // setIsLimitIot: () => {},
  isLoad: true,
  setIsLoad: () => {},
  startDate: "",
  setStartDate: () => {},
  endDate: "",
  setEndDate: () => {},
  resetIoTCtx: () => {},
});

export function IoTContextProvider(props) {
  // COUNT DAY
  const today = moment().format("YYYY/MM/DD");
  const date90DaysAgo = moment(today, "YYYY/MM/DD")
    .subtract(90, "days")
    .format("YYYY/MM/DD");
  const resetIoTCtx = () => {
    setIsLoad(true);
    setStartDate(date90DaysAgo);
    setEndDate(today);
  };
  // const [hasData, setHasData] = useState(false);
  // const [isLimitIot, setIsLimitIot] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [startDate, setStartDate] = useState(date90DaysAgo);
  const [endDate, setEndDate] = useState(today);

  const context = {
    // hasData,
    // setHasData,
    // isLimitIot,
    // setIsLimitIot,
    isLoad,
    setIsLoad,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    resetIoTCtx,
  };

  return (
    <IoTContext.Provider value={context}>{props.children}</IoTContext.Provider>
  );
}

export function IoT() {
  return useContext(IoTContext);
}
