import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import nookies, { destroyCookie } from "nookies";
import { Link, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import dragndrop from "../assets/images/dragndrop.png";
import { Language } from "../context/LanguageContext";
import Swal from "sweetalert2";

export default function MyDropzone({ onChange }) {
  const { language, newObj } = Language();
  const [progress, setProgress] = useState(0);
  const [steps, setSteps] = useState(0);
  const [center, setCenter] = useState();
  const [profile, setProfile] = useState({});
  const [isStartUpload, setIsStartUpload] = useState(false);
  const [uploadStatus, setUploadStatus] = useState();
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const parts = currentUrl.split("/");
  const lastPart = parts[parts.length - 1];
  const lang = useRef(language);
  const getProfile = async () => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/profile`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      if (res.data.status === 200) {
        setProfile(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    lang.current = language;
  }, [language]);

  const urlObj = [
    {
      url: "uploadmap",
      apiUrl: "/mapping/upload",
      apiProgress: "/mapping/progress",
      // id: "GUI_ID",
    },
    {
      url: "uploadiotevent",
      apiUrl: "/iot/upload-event",
      apiProgress: "/iot/upload-event-progress",
    },
    {
      url: "uploadpalm",
      apiUrl: "/lintramax/upload",
      param: "Sawit",
      apiProgress: `/lintramax/progress`,
      // id: profile?.username,
    },
    {
      url: "uploadrubber",
      apiUrl: "/lintramax/upload",
      param: "Getah",
      apiProgress: `/lintramax/progress`,
      // id: profile?.username,
    },
    {
      url: "uploadpaddy",
      apiUrl: "/lintramax/upload",
      param: "Padi",
      apiProgress: `/lintramax/progress`,
      // id: profile?.username,
    },
  ];

  function getApiUrl(urlObj, currentUrl) {
    for (let i = 0; i < urlObj.length; i++) {
      if (urlObj[i].url === currentUrl) {
        return urlObj[i].apiUrl;
      }
    }
    return null; // Return null if no matching URL is found
  }

  function getUrlObj(urlObj, currentUrl) {
    for (let i = 0; i < urlObj.length; i++) {
      if (urlObj[i].url === currentUrl) {
        return urlObj[i];
      }
    }
    return null; // Return null if no matching URL is found
  }

  function getApiParam(urlObj, currentUrl) {
    for (let i = 0; i < urlObj.length; i++) {
      if (urlObj[i].url === currentUrl) {
        return urlObj[i].param;
      }
    }
    return null; // Return null if no matching URL is found
  }

  const uploadFile = async (file) => {
    setIsStartUpload(true);
    const formData = new FormData();
    // const currentUrl = window.location.href;
    // const parts = currentUrl.split("/");
    // const lastPart = parts[parts.length - 1];
    const apiUpload = getApiUrl(urlObj, lastPart);
    const obj = getUrlObj(urlObj, lastPart);
    const options = {
      headers: {
        Authorization: `Bearer ${tokens_session}`,
        "Content-Type": "multipart/form-data",
      },
      // onUploadProgress: (progressEvent) => {
      //   console.log(progressEvent, "progressEvent");
      //   setProgress(
      //     Math.round((progressEvent.loaded * 100) / progressEvent.total)
      //   );
      // },
    };
    setSteps(1);
    onChange(1);
    formData.append("file", file);
    formData.append("lang", lang.current == 1 ? "en" : "my");
    setUploadStatus(newObj.UploadingWord[lang.current]);

    let guidValue = null;
    let id = "";
    let progressUrl = "";
    if (obj.url === "uploadmap") {
      id = "GUI_ID";
      progressUrl = obj.apiProgress;
    } else {
      const res = await getProfile();
      id = res.username;
      progressUrl = `${obj.apiProgress}?username=${id}`;
    }
    let eventSource = new EventSource(`${apiUrl}/api${progressUrl}`);

    eventSource.addEventListener("GUI_ID", (event) => {
      // console.log(`Guid from server: ${guidValue}`);
      if (obj.url === "uploadmap") {
        guidValue = JSON.parse(event.data);
        formData.append("guid", guidValue);
        eventSource.addEventListener(guidValue, (event) => {
          const result = JSON.parse(event.data);
          if (progress !== result.progress) {
            setProgress(result.progress);
          }
          if (uploadStatus !== result.status) {
            setUploadStatus(result.status);
          }
          if (result.status === "Done!") {
            eventSource.close();
          }
        });
      } else {
        eventSource.addEventListener(id, (event) => {
          const result = JSON.parse(event.data);
          if (progress !== result.progress) {
            setProgress(result.progress);
          }
          if (uploadStatus !== result.status) {
            setUploadStatus(result.status);
          }
          if (result.status === "Done!") {
            eventSource.close();
          } else if (result.status === "Error!") {
            setProgress(0);
            onChange(0);
            eventSource.close();
          }
        });
      }
      if (apiUpload === "/lintramax/upload") {
        const apiParam = getApiParam(urlObj, lastPart);
        formData.append("jenis_tanaman", apiParam);
      }
      uploadMap(formData, options, apiUpload);
    });

    eventSource.onerror = (event) => {
      if (event.target.readyState === EventSource.CLOSED) {
        console.log("SSE closed (" + event.target.readyState + ")");
      }
      setProgress(0);
      onChange(0);
      eventSource.close();
    };

    eventSource.onopen = () => {
      console.log("connection opened");
    };

    // if (apiUpload === "/lintramax/upload") {
    //   const apiParam = getApiParam(urlObj, lastPart);
    //   formData.append("jenis_tanaman", apiParam);
    // }
    // uploadMap(formData, options, apiUpload);
  };

  const uploadMap = (formData, options, apiUpload) => {
    setIsStartUpload(true);
    axios
      .post(`${apiUrl}/api${apiUpload}`, formData, options)
      .then((response) => {
        if (response.data.status === 200) {
          setCenter([
            response.data.data.centerLatitude,
            response.data.data.centerLongitude,
          ]);
          setProgress(100);
          setSteps(2);
          onChange(2);
        } else {
          onFailedDeleteOrPublish(response.data.message);
        }
      })
      .catch((error) => {
        // console.error(error);
        // setSteps(0);
        // onChange(0);
        // setIsStartUpload(false);
        onFailedDeleteOrPublish(error.response.data.message);
      });
  };

  const onFailedDeleteOrPublish = (msg) => {
    Swal.fire({
      icon: "error",
      title: newObj.ErrorWord[language],
      text: msg,
    }).then((result) => {
      if (result.isConfirmed || result.isDismissed) {
        // window.location.href = "/uploadmap";
        setSteps(0);
        onChange(0);
        setIsStartUpload(false);
      }
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Handle the dropped files here
    acceptedFiles.forEach((file) => {
      uploadFile(file);
    });
  }, []);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    rejectedFiles,
    isDragReject,
  } = useDropzone({
    onDrop,
    multiple: false,
    minSize: 0,
    accept: {
      "application/zip": [".zip"],
      // "image/png": [".png"],
      // "image/jpeg": [".jpeg", ".jpg"],
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });

  return (
    <>
      <div
        {...(steps === 0 && getRootProps())}
        style={{
          height: "100%",
          ...(steps === 0 && { cursor: "pointer" }),
        }}
      >
        {steps === 0 && <input {...getInputProps()} />}
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {isStartUpload ? (
            progress === 100 && steps === 2 ? (
              <>
                {lastPart === "uploadmap" && (
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{
                        // marginBottom: "50px",
                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <CheckCircleOutlineIcon
                        style={{ color: "lightgreen", fontSize: "100px" }}
                      />
                      {newObj.YourMapWasSuccessfullyAddedWord[language]}
                    </div>

                    <Link to="/mapfiles" style={{ width: "100%" }}>
                      <button
                        style={{
                          color: "white",
                          backgroundColor: "transparent",
                          border: "1px solid white",
                          padding: "15px",
                          width: "100%",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                      >
                        {newObj.GoToDocumentsFiles[language]}
                      </button>
                    </Link>
                    <Link
                      to={`/mappreview?center=${center[0] + "&" + center[1]}`}
                      style={{ width: "100%" }}
                    >
                      <button
                        style={{
                          color: "black",
                          backgroundColor: "white",
                          border: "1px solid white",
                          padding: "15px",
                          width: "100%",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                      >
                        {newObj.PreviewThisMapWord[language]}
                      </button>
                    </Link>

                    <Link to="/adminpage">
                      {" "}
                      <div
                        style={{
                          color: "white",
                          textUnderlineOffset: "2px",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {newObj.ReturnToTheAdministratorPageWord[language]}
                      </div>
                    </Link>
                  </div>
                )}
                {lastPart !== "uploadmap" && lastPart !== "uploadiotevent" && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "15px",
                      }}
                    >
                      <div
                        style={{
                          // marginBottom: "50px",
                          color: "white",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <CheckCircleOutlineIcon
                          style={{ color: "lightgreen", fontSize: "100px" }}
                        />
                        {newObj.YourFileWasSuccessfullyAddedWord[language]}
                      </div>

                      <Link to={`/graph`} style={{ width: "100%" }}>
                        <button
                          style={{
                            color: "black",
                            backgroundColor: "white",
                            border: "1px solid white",
                            padding: "15px",
                            width: "100%",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                        >
                          {newObj.ViewTheChartUploadLintramaxWord[language]}
                        </button>
                      </Link>

                      <Link to="/adminpage">
                        {" "}
                        <div
                          style={{
                            color: "white",
                            textUnderlineOffset: "2px",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          {newObj.ReturnToTheAdministratorPageWord[language]}
                        </div>
                      </Link>
                    </div>
                  </>
                )}
                {lastPart === "uploadiotevent" && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "15px",
                      }}
                    >
                      <div
                        style={{
                          // marginBottom: "50px",
                          color: "white",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <CheckCircleOutlineIcon
                          style={{ color: "lightgreen", fontSize: "100px" }}
                        />
                        File uploaded successfully
                      </div>
                      <Link to={`/iot`} style={{ width: "100%" }}>
                        <button
                          style={{
                            color: "black",
                            backgroundColor: "white",
                            border: "1px solid white",
                            padding: "15px",
                            width: "100%",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                        >
                          Go To IOT
                        </button>
                      </Link>
                      <Link to="/adminpage" style={{ width: "100%" }}>
                        <button
                          style={{
                            color: "white",
                            backgroundColor: "transparent",
                            border: "1px solid white",
                            padding: "15px",
                            width: "100%",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                        >
                          Administrator
                        </button>
                      </Link>
                    </div>
                  </>
                )}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {uploadStatus}
                  </p>

                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      height: "10px",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  />
                </div>
              </div>
            )
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                gap: "20px",
              }}
            >
              <div>
                <img src={dragndrop} alt="drag" style={{ width: "200px" }} />
              </div>
              <p
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {!isDragActive &&
                  newObj.DragdropsomefileshereorclicktoselectfilesWord[
                    language
                  ]}
                {isDragActive && !isDragReject && newObj.DropHereWord[language]}
                {isDragReject && `File type not accepted, sorry!`}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
