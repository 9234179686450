import { Grid } from "@material-ui/core";
import React from "react";
import iotLogo from "../assets/images/iotlogo.png";
import stationBg from "../assets/images/iot_Station_bg.jpg";
import portableBg from "../assets/images/iot_portable_bg.jpg";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import { Language } from "../context/LanguageContext";
import { Link } from "react-router-dom";

export const IoTCard = (props) => {
  const { language, newObj } = Language();
  const title = props.item.title;
  const type = props.item.type;
  const pkCode = props.item.pk_code;
  const projectCode = props.item.project_code;
  const id = props.item.id;
  const status = props.item.active;
  const image = props.item.type === "station" ? stationBg : portableBg;
  return (
    <div style={{ padding: "10px", width: "100%" }}>
      {" "}
      <Link to={`/iotgraph?id=${id}`}>
        <div
          style={{
            width: "100%",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          {/* image */}
          <div style={{ height: "200px", position: "relative" }}>
            <div>
              <img
                src={image}
                alt="imageIot"
                style={{
                  backgroundColor: "gray",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                padding: "10px 20px",
                ...(props.tableToggle === 0
                  ? {
                      backgroundColor: "rgba(255,255,255,0.3)",
                      color: "black",
                    }
                  : {
                      backgroundColor: "rgba(0,0,0,0.6)",
                      color: "white",
                    }),
                fontWeight: "500",
                alignItems: "center",
                display: "flex",
                gap: "10px",
              }}
            >
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <img src={iotLogo} width={"30px"} alt="logo" />
                {status === false && (
                  <DoNotDisturbOnIcon
                    style={{
                      color: "red",
                      marginLeft: "-9px",
                      width: "14px",
                      height: "max-content",
                    }}
                  />
                )}
              </div>
              {title}
            </div>
          </div>

          <div
            style={{
              backgroundColor: "white",
              padding: "30px 50px",
            }}
          >
            <Grid container>
              <Grid xs={6}>{newObj.DeviceTypeIoTWord[language]}</Grid>
              <Grid xs={6}>{type}</Grid>
            </Grid>
            <Grid container style={{ margin: "20px 0" }}>
              <Grid
                container
                style={{ ...(!pkCode && { color: "transparent" }) }}
              >
                <Grid xs={6}>{newObj.PKKodWord[language]}</Grid>
                <Grid xs={6}>{pkCode}</Grid>
              </Grid>
            </Grid>
            <Grid container style={{ margin: "20px 0" }}>
              <Grid
                container
                style={{ ...(!projectCode && { color: "transparent" }) }}
              >
                <Grid xs={6}>{newObj.ProjekKodWord[language]}</Grid>
                <Grid xs={6}>{projectCode}</Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Link>
    </div>
  );
};
