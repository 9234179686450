import React, { useState, useEffect } from "react";
import { Box, IconButton, Link, Menu, MenuItem } from "@material-ui/core";
import profile from "../assets/images/profile.svg";
import profileNo from "../assets/images/profile.jpg";
import admin from "../assets/images/admin.svg";
import nookies, { destroyCookie, setCookie } from "nookies";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import signsout from "../assets/images/signout.svg";

import axios from "axios";
import { Language } from "../context/LanguageContext";
import { useNavigate } from "react-router-dom";

function IconProfile() {
  const { language, newObj } = Language();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const cookies = nookies.get();
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const tokens_session = cookies.token;
  const [previewUrl, setPreviewUrl] = useState();

  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
  });

  useEffect(() => {
    const callProfile = async () => {
      try {
        const res = await axios.post(
          `${apiUrl}/api/profile`,
          {},
          {
            headers: {
              Authorization: `Bearer ${tokens_session}`,
            },
          }
        );
        if (res.status === 200) {
          var data = res.data.data;
          setPreviewUrl(data.avatar);
        }
        return res.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    callProfile();
  }, [apiUrl, tokens_session]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };
  async function signout() {
    await destroyCookie(null, "token");
    navigate("/login");
  }

  return (
    <ThemeProvider theme={theme}>
      <div>
        <IconButton onClick={handleClick}>
          {previewUrl ? (
            <img
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                objectFit: "cover",
              }}
              alt="Dummy Avatar"
              src={previewUrl}
            />
          ) : (
            <img
              src={profileNo}
              alt="Dummy Avatar"
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                objectFit: "cover",
                backgroundColor: "grey",
              }}
            />
          )}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleClose}
          style={{ marginTop: "40px" }}
        >
          <MenuItem
            style={{
              padding: "10px 20px",
            }}
          >
            <Link href="/adminpage">
              {" "}
              <div
                style={{ display: "flex", gap: "15px", alignItems: "center" }}
              >
                <img src={admin} alt="gbr" />
                <div>{newObj.AdministratorWord[language]}</div>
              </div>
            </Link>
          </MenuItem>
          <MenuItem
            style={{
              padding: "10px 20px",
            }}
          >
            <Link href="/profile">
              {" "}
              <div
                style={{ display: "flex", gap: "15px", alignItems: "center" }}
              >
                <img src={profile} alt="gbr" />
                <div>{newObj.ProfileWord[language]}</div>
              </div>
            </Link>
          </MenuItem>
          <MenuItem
            style={{
              borderTop: "1px solid lightgrey",
              padding: "10px 20px",
            }}
          >
            <Box onClick={() => signout()}>
              {" "}
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  alignItems: "center",
                }}
              >
                <img src={signsout} alt="gbr" />
                <div>{newObj.SignOutWord[language]}</div>
              </div>
            </Box>
          </MenuItem>
        </Menu>
      </div>
    </ThemeProvider>
  );
}

export default IconProfile;
