import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useFindPath from "../../Helpers/useFindPath";
import Stepper from "../../components/Stepper";
import MyDropzone from "../../components/EditDocReportCard";
import PageNav from "../../components/PageNav";
// import "./UploadPage.scss";

import macWP from "../../assets/images/macWP.jpg";
import docsReport from "../../assets/images/docsReport.png";
import { Language } from "../../context/LanguageContext";

export const EditDocReport = () => {
  const { language, newObj } = Language();
  const title = "Edit Report";
  const imageSelected = docsReport;
  const bgSize = "cover";
  const [steps, setSteps] = useState(0);
  const path = useFindPath();
  const handleChange = (newValue) => {
    setSteps(newValue);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${macWP})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: (t) =>
          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingTop: "20px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <PageNav title={title} href="/documentreport" />
      <Grid
        component="main"
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <Box
          sx={{
            background: "#EFEFEF",
            backgroundImage: `url(${imageSelected})`,
            borderRadius: "10px",
            backgroundRepeat: "no-repeat",
            backgroundSize: `${bgSize}`,
            backgroundPosition: "left",
          }}
          className="boxContainer"
        >
          <Box
            sx={{
              background: "#2B5180",
              justifyContent: "space-between    ",
            }}
            className="boxUploadPage"
          >
            <Stepper active={steps} type={path.currentPath} totalSteps={2} />
            <MyDropzone
              onChange={handleChange}
              sx={{
                borderRadius: "5px",
                height: "80%",
                width: "100%",
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};
