import { createContext, useContext, useState } from "react";
import moment from "moment";
const LintramaxContext = createContext({
  hasData: false,
  setHasData: () => {},
  fromDigitalMap: false,
  setFromDigitalMap: () => {},
  isLimitIot: false,
  setIsLimitIot: () => {},
  startDate: "",
  setStartDate: () => {},
  endDate: "",
  setEndDate: () => {},
  resetLintramaxCtx: () => {},
});

export function LintramaxContextProvider(props) {
  // COUNT DAY
  const today = moment().format("YYYY/MM/DD");
  const date90DaysAgo = moment(today, "YYYY/MM/DD")
    .subtract(90, "days")
    .format("YYYY/MM/DD");
  const resetLintramaxCtx = () => {
    setHasData(false);
    setIsLimitIot(false);
    setFromDigitalMap({
      status: false,
      code: {
        projectCode: "",
        pkCode: "",
        kodNegeri: "",
        kodWilayah: "",
      },
    });
    setStartDate(date90DaysAgo);
    setEndDate(today);
  };
  const [hasData, setHasData] = useState(false);
  const [isLimitIot, setIsLimitIot] = useState(false);
  const [fromDigitalMap, setFromDigitalMap] = useState({
    status: false,
    code: {
      projectCode: "",
      pkCode: "",
      kodNegeri: "",
      kodWilayah: "",
    },
  });
  const [startDate, setStartDate] = useState(date90DaysAgo);
  const [endDate, setEndDate] = useState(today);

  const context = {
    hasData,
    setHasData,
    fromDigitalMap,
    setFromDigitalMap,
    isLimitIot,
    setIsLimitIot,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    resetLintramaxCtx,
  };

  return (
    <LintramaxContext.Provider value={context}>
      {props.children}
    </LintramaxContext.Provider>
  );
}

export function Lintramax() {
  return useContext(LintramaxContext);
}
