import React, { useState, useEffect, useRef } from "react";
import { Container, useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  MenuItem,
  Select,
  Tooltip,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  TextField,
  FormControl,
  Box,
  Grid,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import axios from "axios";
import nookies, { destroyCookie } from "nookies";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.scss";

import PageNav from "../../components/PageNav";

// ICON LIST
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import macWP from "../../assets/images/macWP.jpg";
import { Language } from "../../context/LanguageContext";
import Swal from "sweetalert2";

const AddNewUser = () => {
  const { language, newObj } = Language();
  let apiUrl = process.env.REACT_APP_MAIN_URL;

  const navigate = useNavigate();
  let { userId } = useParams();
  const cookies = nookies.get();
  const tokens_session = cookies.token;

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const [error, setError] = useState("");
  const [title, setTitle] = useState("Add New User");
  const [formTitle, setFormTitle] = useState(newObj.AddNewUserWord[language]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [email, setEmail] = useState("");
  const [firstPass, setFirstPass] = useState("");
  const [secondPass, setSecondPass] = useState("");
  const [group, setGroup] = useState([]);
  const [groupSelected, setGroupSelected] = useState([]);
  const [status, setStatus] = useState(true);
  const [tempInputVal, setTempInputVal] = useState([]);
  const lang = useRef();
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
  });

  useEffect(() => {
    lang.current = language;
  }, [language]);

  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (userId) {
      if (phoneNum.length >= 7) {
        try {
          const res = await axios.post(
            `${apiUrl}/api/admin/user/update`,
            {
              id: userId,
              username: email,
              firstname: firstName,
              lastname: lastName,
              phone: phoneNum,
              group: tempInputVal,
              status: status,
              language: lang.current == 1 ? "en" : "my",
            },
            {
              headers: {
                Authorization: `Bearer ${tokens_session}`,
              },
            }
          );
          if (res.data.status === 200) {
            Swal.fire({
              icon: "success",
              title: newObj.SuccessWord[language],
              text: res.data.message,
            }).then((result) => {
              if (result.isConfirmed || result.isDismissed) {
                navigate("/adminpage");
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: newObj.ErrorWord[language],
              text: res.data.message,
            });
          }
          if (res.data.status === 401) {
            destroyCookie(null, "token");
            navigate("/login");
          }
          return res.data;
        } catch (e) {
          console.log(e);
        }
      } else {
        setError("Phone number must contain at least 7 digits");
      }
    } else {
      if (firstPass === secondPass) {
        if (phoneNum.length >= 7) {
          try {
            const res = await axios.post(
              `${apiUrl}/api/admin/user/new`,
              {
                username: email,
                firstname: firstName,
                lastname: lastName,
                password: firstPass,
                avatar: "",
                phone: phoneNum,
                group: groupSelected,
              },
              {
                headers: {
                  Authorization: `Bearer ${tokens_session}`,
                },
              }
            );
            if (res.data.status === 200) {
              Swal.fire({
                icon: "success",
                title: newObj.SuccessWord[language],
                text: res.data.message,
              }).then((result) => {
                if (result.isConfirmed || result.isDismissed) {
                  navigate("/adminpage");
                }
              });
            } else {
              Swal.fire({
                icon: "error",
                title: newObj.ErrorWord[language],
                text: res.data.message,
              });
            }
            if (res.data.status === 401) {
              destroyCookie(null, "token");
              navigate("/login");
            }
            return res.data;
          } catch (e) {
            console.log(e);
          }
        } else {
          setError("Phone number must contain at least 7 digits");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: newObj.ErrorWord[language],
          text: newObj.PasswordNotMatch[language],
        });
      }
    }
  };
  const getUserGroups = async () => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/admin/usergroup/list`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      if (res.status === 200) {
        setGroup(res.data.data);
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };
  const handleInputChange = (event) => {
    event.persist();
    const { value, checked } = event.target;
    if (checked) {
      console.log(value);
      setGroupSelected((groupSelected) => [
        ...groupSelected,
        event.target.value,
      ]);
      setTempInputVal((tempInputVal) => [...tempInputVal, event.target.value]);
    } else {
      setGroupSelected(groupSelected.filter((val) => val !== value));
      setTempInputVal(tempInputVal.filter((val) => val !== value));
    }
  };
  const getExistedData = async (id) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/admin/user/details`,
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      console.log(res);
      if (res.data.status === 200) {
        console.log(res.data.data, "tes");
        setFirstName(res.data.data.firstname);
        setLastName(res.data.data.lastname);
        setPhoneNum(res.data.data.phone);
        setEmail(res.data.data.username);
        setStatus(res.data.data.status);
        setTempInputVal(res.data.data.usergroup_id);
        setTitle(`Edit User ${res.data.data.username}`);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (userId) {
      getExistedData(userId);
      setFormTitle("Edit User");
    }
    getUserGroups();
    console.log("tempInputVal", tempInputVal);
    console.log("groupSelected", groupSelected);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundImage: `url(${macWP})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: isXsScreen || isSmScreen ? "10px" : "20px 10px",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        <PageNav
          title={
            userId
              ? newObj.EditUserWord[language]
              : newObj.AddNewUserWord[language]
          }
          href="/adminpage"
        />
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            padding: "60px 0",
            width: "100%",
          }}
        >
          <Box
            sx={{
              background: "white",
              height: "100%",
              borderRadius: "10px",
              width: "100%",
            }}
            component="form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Grid container component="main">
              <Grid item xs={12} lg={8} className="boxUser">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  marginBottom={"20px"}
                >
                  {userId
                    ? newObj.EditUserWord[language]
                    : newObj.AddNewUserWord[language]}
                </Typography>
                <TextField
                  margin="dense"
                  required
                  id="firstName"
                  label={newObj.FirstNameWord[language]}
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  required
                  id="lastName"
                  label={newObj.LastNameWord[language]}
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="outlined-number"
                  label={newObj.ContactNumberWord[language]}
                  value={phoneNum}
                  onChange={(event) => setPhoneNum(event.target.value)}
                  fullWidth
                />
                {error && (
                  <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                    {error}
                  </Typography>
                )}
                <TextField
                  margin="dense"
                  required
                  id="email_new"
                  label={newObj.EmailWord[language]}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  fullWidth
                  type="email"
                  autoComplete="off"
                />
                {!userId && (
                  <>
                    <FormControl
                      sx={{ my: 1, width: "100%" }}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="newPass1">
                        {newObj.PasswordWord[language]}
                      </InputLabel>
                      <OutlinedInput
                        id="newPass1"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        value={firstPass}
                        onChange={(event) => setFirstPass(event.target.value)}
                        autoComplete={showPassword ? "off" : "new-password"}
                      />
                    </FormControl>
                    <FormControl
                      sx={{ my: 1, width: "100%" }}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="newPass2">
                        {newObj.PasswordWord[language]}
                      </InputLabel>
                      <OutlinedInput
                        id="newPass2"
                        type={showPassword2 ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword2}
                              onMouseDown={handleMouseDownPassword2}
                              edge="end"
                            >
                              {showPassword2 ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label={newObj.PasswordWord[language]}
                        value={secondPass}
                        onChange={(event) => setSecondPass(event.target.value)}
                        autoComplete={showPassword2 ? "off" : "new-password"}
                      />
                    </FormControl>
                  </>
                )}
                {userId && (
                  <>
                    <FormControl
                      sx={{
                        my: 1,
                        width: "100%",
                      }}
                      variant="outlined"
                    >
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={status}
                        label="Status"
                        id="userStatus"
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                      >
                        <MenuItem value={true}>
                          <Box
                            sx={{
                              textAlign: "start",
                            }}
                          >
                            Active
                          </Box>
                        </MenuItem>
                        <MenuItem value={false}>
                          <Box
                            sx={{
                              textAlign: "start",
                            }}
                          >
                            Inactive
                          </Box>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
                sx={{ textAlign: "start", padding: "20px" }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {newObj.AssignUserGroupWord[language]}
                </Typography>
                <FormGroup
                  aria-label="position"
                  row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "5%",
                    height: 400,
                    overflow: "hidden",
                    overflowY: "auto",
                  }}
                  onChange={handleInputChange}
                >
                  {group && (
                    <>
                      {group
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((data) => (
                          <Grid
                            key={data.id}
                            xs={12}
                            sx={{ marginLeft: "10px" }}
                          >
                            <Tooltip title={data.description}>
                              <FormControlLabel
                                control={<Checkbox />}
                                label={data.name}
                                value={data.id}
                                labelPlacement="right"
                                checked={tempInputVal.includes(data.id)}
                              />
                            </Tooltip>
                          </Grid>
                        ))}
                    </>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTop: "2px solid #b6b6b6",
                p: 4,
              }}
            >
              <Button
                type="submit"
                fullWidth
                style={{
                  width: "50%",
                  padding: 15,
                  backgroundColor: "rgb(43, 51, 125)",
                  cursor: "pointer",
                  color: "white",
                  borderRadius: "10px",
                }}
              >
                {newObj.SaveWord[language]}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AddNewUser;
