import React, { useState, useCallback, useEffect, useRef } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import axios from "axios";
import nookies, { destroyCookie } from "nookies";
import { Link, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import iotdropzoneicon from "../assets/images/iotdropzoneicon.png";
import dragndrop from "../assets/images/dragndrop.png";
import Swal from "sweetalert2";
import { Box, FormControlLabel, Modal, Radio, RadioGroup } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Loader } from "./Loader";
import word from "../assets/images/word.png";
import excel from "../assets/images/excel.png";
import acrobat from "../assets/images/Icon.png";
import zip from "../assets/images/qgisZip.png";
import { Language } from "../context/LanguageContext";
import { ModalAddIotDevice } from "./ModalAddIotDevice";

export default function DocumentReportDropzone({ onChange }) {
  const { language, newObj } = Language();
  const [progress, setProgress] = useState(0);
  const [steps, setSteps] = useState(0);
  const [pkCode, setPkCode] = useState();
  const [listDevice, setListDevice] = useState();
  const [deviceClick, setDeviceClick] = useState(false);
  const [selectedDeviceList, setSelectedDeviceList] = useState();
  const [selectDevice, setSelectedDevice] = useState(); //0 -> weather stations, 1 -> soil sensors
  const [openAddDeviceModal, setOpenAddDeviceModal] = useState(false);
  const [file, setFile] = useState();
  const [profile, setProfile] = useState();
  const [uploadStatus, setUploadStatus] = useState("Uploading...");
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const navigate = useNavigate();
  const lang = useRef(language);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    onChange(steps);
  }, [steps]);

  useEffect(() => {
    if (file) {
      const fileExtension = getFileExtension(file.path);
      let image = "";
      if (fileExtension === "docx" || fileExtension === "doc") {
        image = word;
      } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        image = excel;
      } else if (fileExtension === "pdf") {
        image = acrobat;
      } else image = zip;
    }
  }, [file]);

  const getFileExtension = (filename) => {
    // Split the filename by dot to get the file parts
    const fileParts = filename.split(".");

    // Get the last part which should be the file extension
    const fileExtension = fileParts[fileParts.length - 1];

    // Return the file extension in lowercase
    return fileExtension.toLowerCase();
  };

  const getProfile = async () => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/profile`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      if (res.data.status === 200) {
        setProfile(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    const options = {
      headers: {
        Authorization: `Bearer ${tokens_session}`,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        // setProgress(
        //   Math.round((progressEvent.loaded * 100) / progressEvent.total)
        // );
      },
    };
    formData.append("file", file);

    setFile(file);
  };

  useEffect(() => {
    if (file && selectedDeviceList) uploadIotData();
  }, [file, selectedDeviceList]);

  useEffect(() => {
    lang.current = language;
  }, [language]);

  const uploadIotData = async () => {
    setSteps(1);
    setUploadStatus(newObj.UploadingWord[lang.current]);
    const url =
      selectDevice === 0 ? "/api/iot/upload-weather" : "/api/iot/upload-soil";
    const param =
      selectDevice === 0
        ? {
            file: file,
            device: selectedDeviceList.title,
            language: lang.current == 1 ? "en" : "my",
          }
        : {
            file: file,
            device: selectedDeviceList.title,
            type: selectedDeviceList.type,
            language: lang.current == 1 ? "en" : "my",
          };
    let guidValue = null;
    let progressUrl = "";
    const res = await getProfile();
    let id = res.username;
    if (selectDevice === 0) {
      progressUrl = `/iot/upload-weather-progress?username=${id}`;
    } else {
      progressUrl = `/iot/upload-soil-progress?username=${id}`;
    }
    let eventSource = new EventSource(`${apiUrl}/api${progressUrl}`);

    eventSource.addEventListener("GUI_ID", (event) => {
      // console.log(`Guid from server: ${guidValue}`);
      eventSource.addEventListener(id, (event) => {
        const result = JSON.parse(event.data);
        if (progress !== result.progress) {
          setProgress(result.progress);
        }
        if (uploadStatus !== result.status) {
          setUploadStatus(result.status);
        }
        if (result.status === "Done!") {
          eventSource.close();
        } else if (result.status === "Error!") {
          setSteps(0);
          setSelectedDeviceList();
          setFile();
          eventSource.close();
        }
      });

      axios
        .post(`${apiUrl}` + url, param, {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            setSteps(2);
          } else if (response.data.status === 400) {
            Swal.fire({
              icon: "error",
              title: newObj.ErrorWord[language],
              text: response.data.message,
            });
            setProgress(0);
            setSteps(0);
            setSelectedDeviceList();
            setFile();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });

    eventSource.onerror = (event) => {
      if (event.target.readyState === EventSource.CLOSED) {
        console.log("SSE closed (" + event.target.readyState + ")");
      }
      setProgress(0);
      setSteps(0);
      setSelectedDeviceList();
      setFile();
      eventSource.close();
    };

    eventSource.onopen = () => {
      console.log("connection opened");
    };
  };

  const getAllDevice = async (device) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/device/list`,
        {
          flag: "category",
          value: device,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        if (selectDevice === 0) setListDevice(res?.data?.data);
        else {
          const stationData = res?.data?.data.station.map((item) => ({
            ...item,
            type: "station",
          }));
          const portableData = res?.data?.data.portable.map((item) => ({
            ...item,
            type: "portable",
          }));
          setListDevice([...stationData, ...portableData]);
        }
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    const device = selectDevice === 0 ? "weather" : "soil";
    getAllDevice(device);
  }, [selectDevice]);

  const onDrop = useCallback((acceptedFiles) => {
    // Handle the dropped files here
    acceptedFiles.forEach((file) => {
      uploadFile(file);
    });
  }, []);
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    rejectedFiles,
    isDragReject,
  } = useDropzone({
    onDrop,
    multiple: false,
    minSize: 0,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <input {...getInputProps()} />
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {steps === 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              gap: "20px",
            }}
          >
            <div>
              <img
                src={iotdropzoneicon}
                alt="drag"
                style={{ width: "100px" }}
              />
            </div>
            <p
              style={{
                color: "white",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                width: "100%",
                fontSize: "12px",
                // width: "80%",
              }}
            >
              {newObj.ChooseCategoryUploadIoTWord[language]}
            </p>

            <button
              onClick={() => {
                setSelectedDevice(0);
                setSteps(0.5);
              }}
              style={{
                backgroundColor: "white",
                border: "1px solid white",
                padding: "15px",
                width: "80%",
                borderRadius: "10px",
                cursor: "pointer",
                fontSize: "12px",
              }}
            >
              Weather Stations
            </button>

            <button
              onClick={() => {
                setSelectedDevice(1);
                setSteps(0.5);
              }}
              style={{
                backgroundColor: "white",
                border: "1px solid white",
                padding: "15px",
                width: "80%",
                borderRadius: "10px",
                cursor: "pointer",
                fontSize: "12px",
              }}
            >
              Soil Sensors
            </button>
          </div>
        )}
        {steps === 0.5 && (
          <div
            style={{
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: "20px",
                // height: "100%",
                width: "100%",
              }}
            >
              {/* select device */}
              <Box
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  padding: "10px 20px",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setDeviceClick(!deviceClick)}
              >
                {selectedDeviceList ? (
                  // pk code name
                  <span>
                    {selectedDeviceList.title
                      ? selectedDeviceList.title
                      : selectedDeviceList.name}
                  </span>
                ) : (
                  <span>{newObj.SelectDeviceWord[language]}</span>
                )}
                <ExpandMoreIcon style={{ width: "20px" }} />
              </Box>
              {/* <div
              style={{
                position: "relative",
              }}
            /> */}
              {deviceClick && (
                <Box
                  style={{
                    margin: "10px 20px",
                    ...(windowWidth < 800
                      ? { width: "90%" }
                      : { width: "88%" }),
                    position: "absolute",
                  }}
                >
                  <Box
                    className="removeScrollbar"
                    style={{
                      borderRadius: "5px",
                      position: "absolute",
                      backgroundColor: "white",
                      width: "100%",
                      zIndex: "1000",
                      right: "0",
                      overflowX: "scroll",
                      // ...(windowWidth < 600
                      //   ? { top: "-125px" }
                      //   : { top: "-125px" }),
                      top: "-2px",
                    }}
                  >
                    {listDevice?.map((obj, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          borderBottom: "1px solid lightgray",
                          padding: "10px 20px",
                          alignItems: "center",
                          cursor: "pointer",
                          fontSize: "12px",
                        }}
                        onClick={() => {
                          setDeviceClick(!deviceClick);
                          setSelectedDeviceList(obj);
                        }}
                      >
                        {obj.title ? obj.title : obj.name}
                      </div>
                    ))}
                  </Box>
                </Box>
              )}

              {/* add new device */}
              <button
                onClick={() => setOpenAddDeviceModal(!openAddDeviceModal)}
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "transparent",
                  border: "1px solid white",
                  padding: "15px",
                  color: "white",
                  cursor: "pointer",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              >
                {newObj.AddNewDeviceWord[language]}
              </button>
            </div>
            <div
              {...(steps === 0.5 && getRootProps())}
              style={{
                // maxHeight: "100%",
                height: "70%",
                // marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                ...(steps === 0.5 && { cursor: "pointer" }),
              }}
            >
              <img src={dragndrop} alt="drag" style={{ width: "150px" }} />
              <p
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {!isDragActive &&
                  newObj.DragAndDropOrBrowseYourFileWord[language]}
                {isDragActive && !isDragReject && newObj.DropHereWord[language]}
                {isDragReject && `File type not accepted, sorry!`}
              </p>
            </div>
          </div>
        )}
        {steps === 1 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                }}
              >
                {uploadStatus}
              </p>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  height: "10px",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
            </div>
          </div>
        )}

        {steps === 2 && (
          <div
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            <div
              style={{
                // margin: "50px 0",
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <CheckCircleOutlineIcon
                style={{ color: "lightgreen", fontSize: "100px" }}
              />
              {newObj.FileUploadedSuccessfully[language]}
            </div>

            <Link to="/manageiotdevices" style={{ width: "100%" }}>
              <button
                style={{
                  backgroundColor: "white",
                  border: "1px solid white",
                  padding: "15px",
                  width: "100%",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                {newObj.ManageDevicesWord[language]}
              </button>
            </Link>

            <Link to="/adminpage" style={{ width: "100%" }}>
              {" "}
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid white",
                  color: "white",
                  padding: "15px",
                  width: "100%",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                {newObj.AdministratorWord[language]}
              </button>
            </Link>
          </div>
        )}
      </div>
      {/* add new device modal */}

      <ModalAddIotDevice
        openAddDeviceModal={openAddDeviceModal}
        setOpenAddDeviceModal={setOpenAddDeviceModal}
      />
    </div>
  );
}
