import React, {useState, useEffect} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DetailData from './DetailData';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Doughnutchart from '../components/Doughnutchart';
import Avatar from '@mui/material/Avatar';
import {LowerComponent} from './LowerComponent';
import {Language} from '../context/LanguageContext';
import {numberWithCommas} from '../Helpers/stringFormat';
import {useMediaQuery, useTheme} from '@mui/material';

function formatLabel(label) {
  const regex = /^(B|Blok|Block)\s*(\d{1,3})$/;
  const match = label.match(regex);
  if (match) {
    const digits = parseInt(match[2]);
    return match[1] === 'Blok' || match[1] === 'Block'
      ? `B${digits}`
      : `B${digits}`;
  } else {
    return label;
  }
}

function formatBlokLabel(label) {
  const regex = /^(B|Blok|Block)\s*0*(\d{1,3})$/;
  const match = label.match(regex);
  if (match) {
    const digits = parseInt(match[2], 10).toString();
    return match[1] === 'Blok' || match[1] === 'Block'
      ? `Blok ${digits}`
      : `Blok ${digits}`;
  } else {
    return label;
  }
}

export default function DataContainerTab(props) {
  const {language, newObj} = Language();
  useEffect(() => {
    setTempSelectedGroupName(props.selectedGroupName);
  }, [props]);

  const [value, setValue] = useState(0);
  const [tempSelectedGroupName, setTempSelectedGroupName] = useState(
    props.selectedGroupName
  );

  const theme = useTheme();

  const [doughnutData, setDoughnutData] = useState({
    labels: ['Luas Berhasil', 'Luas Bertanam'],
    datasets: [
      {
        label: 'Data ',
        data: [
          props?.data?.summary?.luas_berhasil,
          props?.data?.summary?.luas_bertanam,
        ],
        backgroundColor: ['rgba(43, 51, 125, 1)', 'rgba(226, 31, 38, 1)'],
        borderColor: ['rgba(43, 51, 125, 1)', 'rgba(226, 31, 38, 1)'],
        borderWidth: 1,
      },
    ],
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onData(newValue);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 0,
    aspectRatio: 1,
    width: '100%',
    plugins: {
      legend: {
        display: false, // set this to false to hide the legend
      },
    },
  };

  const isXsScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isSmScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMdScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLgScreen = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isXlScreen = useMediaQuery(theme.breakpoints.up('xl'));
  function InnerComponent(props) {
    const filteredData = props.data.data.data.filter((obj) => {
      return obj.title === tempSelectedGroupName;
    });
    return (
      <Grid
        container
        component='main'
        style={{
          margin: 0,
        }}
      >
        {filteredData &&
          filteredData[0]?.features
            .sort((a, b) => {
              const labelA = a?.properties[0]?.label;
              const labelB = b?.properties[0]?.label;
              if (labelA < labelB) {
                return -1;
              }
              if (labelA > labelB) {
                return 1;
              }
              return 0;
            })
            .map((smaller) => (
              <div
                style={{
                  padding: '25px',
                  borderBottom: '1px solid lightgrey',
                  borderTop: '1px solid lightgrey',
                }}
              >
                {/* biar ga error sementara */}
                {smaller.properties && (
                  <>
                    {' '}
                    <Grid
                      key={smaller.id}
                      sx={{
                        height: 'fit-content',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '10px',
                        flexDirection: 'row',
                        marginBottom: '15px',
                      }}
                    >
                      <div
                        style={{
                          color: 'white',
                          padding: '10px',
                          backgroundColor: 'black',
                          borderRadius: '100%',
                        }}
                      >
                        {formatLabel(smaller?.properties[0]?.label)}
                      </div>
                      <div style={{fontWeight: 'bold'}}>
                        {formatBlokLabel(smaller.properties[0]?.label)}
                      </div>
                    </Grid>
                    <Grid
                      container
                      component='main'
                      sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Grid container component='main' sx={{width: '100%'}}>
                        {/* LUAS LADANG */}
                        <Grid
                          item
                          xs={6}
                          style={{
                            fontSize: '12px',
                            padding: '4px',
                          }}
                        >
                          <div variant='caption' style={{fontSize: '12px'}}>
                            {newObj.LuasLadangWord[language]}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            fontSize: '12px',
                            padding: '4px',
                            fontWeight: 'bold',
                          }}
                        >
                          <Stack
                            direction='row'
                            spacing={2}
                            alignItems='center'
                          >
                            <div variant='caption' style={{fontSize: '12px'}}>
                              {numberWithCommas(
                                smaller?.properties[0]?.luas_ladang_ha
                              )}{' '}
                              ha /
                              {numberWithCommas(
                                smaller?.properties[0]?.luas_ladang_ac
                              )}{' '}
                              ac
                            </div>
                          </Stack>
                        </Grid>
                        {value === 0 && (
                          <>
                            {/* LUAS BERHASIL */}
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <div variant='caption' style={{fontSize: '12px'}}>
                                {newObj.LuasBerhasilWord[language]}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                                fontWeight: 'bold',
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                              >
                                <div
                                  variant='caption'
                                  style={{fontSize: '12px'}}
                                >
                                  {numberWithCommas(
                                    smaller?.properties[0]?.luas_berhasil
                                  )}{' '}
                                  ha
                                </div>
                              </Stack>
                            </Grid>
                            {/* Bilangan Pokok */}
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <div variant='caption' style={{fontSize: '12px'}}>
                                Bilangan Pokok
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                                fontWeight: 'bold',
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                              >
                                <div
                                  variant='caption'
                                  style={{fontSize: '12px'}}
                                >
                                  {numberWithCommas(
                                    smaller?.properties[0]?.bilangan_pokok
                                  )}
                                </div>
                              </Stack>
                            </Grid>
                            {/* SPH */}
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <div variant='caption' style={{fontSize: '12px'}}>
                                SPH
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                                fontWeight: 'bold',
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                              >
                                <div
                                  variant='caption'
                                  style={{fontSize: '12px'}}
                                >
                                  {smaller?.properties[0]?.sph}
                                </div>
                              </Stack>
                            </Grid>
                            {/* Jenis Tanaman */}
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <div variant='caption' style={{fontSize: '12px'}}>
                                Jenis Tanaman
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                                fontWeight: 'bold',
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                              >
                                <div
                                  variant='caption'
                                  style={{fontSize: '12px'}}
                                >
                                  {smaller?.properties[0]?.jenis_tanaman?.join(
                                    ', '
                                  )}
                                </div>
                              </Stack>
                            </Grid>
                          </>
                        )}
                        {value === 1 && (
                          <>
                            {/* LUAS JALAN */}
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <div variant='caption' style={{fontSize: '12px'}}>
                                Luas Jalan
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                              >
                                <div
                                  variant='caption'
                                  style={{
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {numberWithCommas(
                                    smaller?.properties[0]?.luas_jalan
                                  )}{' '}
                                  ha
                                </div>
                              </Stack>
                            </Grid>

                            {/* KEPADATAN JALAN */}
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <div variant='caption' style={{fontSize: '12px'}}>
                                Kepadatan Jalan
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                              >
                                <div
                                  variant='caption'
                                  style={{
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {numberWithCommas(
                                    smaller?.properties[0]?.kepadatan_jalan
                                  )}{' '}
                                  m/ha
                                </div>
                              </Stack>
                            </Grid>
                            <br />
                            <br />
                            {/* JALAN DALAM BLOK */}
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <div variant='caption' style={{fontSize: '12px'}}>
                                Jalan Dalam Blok
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                              >
                                <div
                                  variant='caption'
                                  style={{
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {numberWithCommas(
                                    smaller?.properties[0]?.jalan_dalam_blok
                                  )}{' '}
                                  m
                                </div>
                              </Stack>
                            </Grid>

                            {/* JALAN UTAMA */}
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <div variant='caption' style={{fontSize: '12px'}}>
                                Jalan Utama
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                              >
                                <div
                                  variant='caption'
                                  style={{
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {numberWithCommas(
                                    smaller?.properties[0]?.jalan_utama
                                  )}{' '}
                                  m
                                </div>
                              </Stack>
                            </Grid>

                            {/* JALAN LADANG */}
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <div variant='caption' style={{fontSize: '12px'}}>
                                Jalan Ladang
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                              >
                                <div
                                  variant='caption'
                                  style={{
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {numberWithCommas(
                                    smaller?.properties[0]?.jalan_ladang
                                  )}{' '}
                                  m
                                </div>
                              </Stack>
                            </Grid>
                          </>
                        )}
                        {value === 2 && (
                          <>
                            {/* LUAS TIDAK USAHA */}
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <div variant='caption' style={{fontSize: '12px'}}>
                                Luas Tidak Usaha
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                              >
                                <div
                                  variant='caption'
                                  style={{
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {numberWithCommas(
                                    smaller?.properties[0]?.luas_tidak_usaha
                                  )}{' '}
                                  ha
                                </div>
                              </Stack>
                            </Grid>
                            {/* JALAN */}
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <div variant='caption' style={{fontSize: '12px'}}>
                                Luas Jalan
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                                fontWeight: 'bold',
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                              >
                                <div
                                  variant='caption'
                                  style={{fontSize: '12px'}}
                                >
                                  {numberWithCommas(
                                    smaller?.properties[0]?.jalan
                                  )}{' '}
                                  ha
                                </div>
                              </Stack>
                            </Grid>
                            {/* MAJU LUAR KAWASAN */}
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <div variant='caption' style={{fontSize: '12px'}}>
                                Maju Luar Kawasan
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                                fontWeight: 'bold',
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                              >
                                <div
                                  variant='caption'
                                  style={{fontSize: '12px'}}
                                >
                                  {numberWithCommas(
                                    smaller?.properties[0]?.maju_luar_kawasan
                                  )}{' '}
                                  ha
                                </div>
                              </Stack>
                            </Grid>
                            {/* LUAS BERHASIL */}
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                              }}
                            >
                              <div variant='caption' style={{fontSize: '12px'}}>
                                {newObj.LuasBerhasilWord[language]}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                fontSize: '12px',
                                padding: '4px',
                                fontWeight: 'bold',
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                              >
                                <div
                                  variant='caption'
                                  style={{fontSize: '12px'}}
                                >
                                  {numberWithCommas(
                                    smaller?.properties[0]?.luas_berhasil
                                  )}{' '}
                                  ha
                                </div>
                              </Stack>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </div>
            ))}
      </Grid>
    );
  }

  function formatNumber(input) {
    const str = input.toString();
    const parts = [];
    let i = str.length;
    while (i > 0) {
      parts.unshift(str.substring(Math.max(0, i - 3), i));
      i -= 3;
    }
    return parts.join('.');
  }

  useEffect(() => {
    if (value === 0) {
      setDoughnutData({
        labels: ['Luas Berhasil', 'Luas Bertanam'],
        datasets: [
          {
            label: 'Data ',
            data: [
              props?.data?.summary?.luas_berhasil,
              props?.data?.summary?.luas_bertanam,
            ],
            backgroundColor: ['rgba(43, 51, 125, 1)', 'rgba(226, 31, 38, 1)'],
            borderColor: ['rgba(43, 51, 125, 1)', 'rgba(226, 31, 38, 1)'],
            borderWidth: 1,
          },
        ],
      });
    } else if (value === 1) {
      setDoughnutData({
        labels: ['Luas Jalan', 'Luas Ladang'],
        datasets: [
          {
            label: 'Data ',
            data: [
              props?.data?.summary?.luas_jalan,
              props?.data?.summary?.luas_ladang_ha,
            ],
            backgroundColor: ['rgba(43, 51, 125, 1)', 'rgb(199,199,199)'],
            borderColor: ['rgba(43, 51, 125, 1)', 'rgb(199,199,199)'],
            borderWidth: 1,
          },
        ],
      });
    } else {
      setDoughnutData({
        labels: ['Luas Tidak Usaha', 'Jalan', 'Luas Ladang'],
        datasets: [
          {
            label: 'Data ',
            data: [
              props?.data?.summary?.luas_tidak_usaha,
              props?.data?.summary?.jalan,
              props?.data?.summary?.luas_ladang_ha,
            ],
            backgroundColor: [
              'rgba(43, 51, 125, 1)',
              'rgba(226, 31, 38, 1)',
              'rgb(199,199,199)',
            ],
            borderColor: [
              'rgba(43, 51, 125, 1)',
              'rgba(226, 31, 38, 1)',
              'rgb(199,199,199)',
            ],
            borderWidth: 1,
          },
        ],
      });
    }
  }, [props.data]);

  return (
    <Box
      style={{
        // background: "rgba(255, 255, 255, 0.6)",
        // backdropFilter: "blur(10px)",
        // webkitBackdropFilter: "blur(10px)",
        // overflow: "scroll",
        height: '100%',
      }}
    >
      <Tabs
        centered
        value={value}
        onChange={handleChange}
        style={{
          borderBottom: '1px solid lightgrey',
        }}
      >
        <Tab
          label='Dirian Pokok'
          value={0}
          sx={{
            background: 'transparent',
          }}
        />
        <Tab
          label='Jalan'
          value={1}
          sx={{
            background: 'transparent',
          }}
        />
        <Tab
          label='Guna Tanah'
          value={2}
          sx={{
            background: 'transparent',
          }}
        />
      </Tabs>
      <div
        style={{
          // position: "relative",
          display: 'flex',
          justifyContent: 'center',
          alignCenter: 'center',
          flexDirection: 'column',
          height: 'calc(100% - 80px)', // 80px because there are when position fixed top 70px amd bottom 10px
        }}
      >
        {value === 0 && (
          <>
            <Grid
              container
              component='main'
              rowSpacing={1}
              columnSpacing={{xs: 1, sm: 2, md: 2}}
              mt={2}
              px={2}
              sx={{
                height: '200px',
                borderBottom: '1px solid #BBBBBB',
                margin: '0 !important',
              }}
            >
              <Grid
                item
                xs={6}
                px={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingBottom: '8px',
                }}
              >
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: isSmScreen || isXsScreen ? '120px' : '100%',
                  }}
                >
                  <Doughnutchart data={doughnutData} options={options} />
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  fontSize: '12px',
                  padding: '4px',
                  fontWeight: 'bold',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <div
                  variant='caption'
                  style={{
                    fontSize: '12px',
                  }}
                >
                  {newObj.LuasLadangWord[language]} <br />
                  <b style={{fontSize: '12px'}}>
                    {numberWithCommas(props?.data?.summary?.luas_ladang_ha)} ha
                    / {numberWithCommas(props?.data?.summary?.luas_ladang_ac)}{' '}
                    ac
                  </b>
                </div>

                <br />
                <Grid container component='main'>
                  <Grid item xs={1}>
                    <div className='border red'></div>
                  </Grid>
                  <Grid item xs={11} pl={1}>
                    <div variant='caption' style={{fontSize: '12px'}}>
                      {newObj.LuasBertanamWord[language]} <br />
                      <b className='red' style={{fontSize: '12px'}}>
                        {numberWithCommas(props?.data?.summary?.luas_bertanam)}{' '}
                        ha
                      </b>
                    </div>
                  </Grid>
                </Grid>
                <br />
                <Grid container component='main'>
                  <Grid item xs={1}>
                    <div className='border blue'></div>
                  </Grid>
                  <Grid item xs={11} pl={1}>
                    <div variant='caption' style={{fontSize: '12px'}}>
                      {newObj.LuasBerhasilWord[language]} <br />
                      <b className='blue' style={{fontSize: '12px'}}>
                        {numberWithCommas(props?.data?.summary?.luas_berhasil)}{' '}
                        ha
                      </b>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              mb={5}
              sx={{
                overflowY: 'scroll',
              }}
            >
              <Grid
                container
                component='main'
                sx={{
                  padding: '25px',
                  borderBottom: '1px solid #BBBBBB',
                }}
              >
                <Grid item xs={6} style={{fontSize: '12px', padding: '4px'}}>
                  Bilangan Pokok
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                    fontWeight: 'bold',
                  }}
                >
                  {numberWithCommas(props?.data?.summary?.bilangan_pokok)}
                </Grid>

                <Grid item xs={6} style={{fontSize: '12px', padding: '4px'}}>
                  SPH
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                    fontWeight: 'bold',
                  }}
                >
                  {props?.data?.summary?.sph_sawit &&
                    props?.data?.summary?.sph_sawit}
                </Grid>
              </Grid>
              <InnerComponent data={props} />
              <LowerComponent
                adminIotFlag={props.adminIotFlag}
                rightData={props.rightData}
                adminLintramaxFlag={props.adminLintramaxFlag}
                viewerLintramaxFlag={props.viewerLintramaxFlag}
                viewerIotFlag={props.viewerIotFlag}
                adminDigitalMapFlag={props.adminDigitalMapFlag}
                viewerDigitalMapFlag={props.viewerDigitalMapFlag}
                kawasan={props.kawasan}
                status={props.status}
                data={props.data}
              />
            </Grid>
          </>
        )}
        {value === 1 && (
          <>
            <Grid
              container
              component='main'
              rowSpacing={1}
              columnSpacing={{xs: 1, sm: 2, md: 2}}
              mt={2}
              px={2}
              sx={{
                height: '200px',
                borderBottom: '1px solid #BBBBBB',
                margin: '0 !important',
              }}
            >
              <Grid
                item
                xs={6}
                px={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingBottom: '8px',
                }}
              >
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: isSmScreen || isXsScreen ? '120px' : '100%',
                  }}
                >
                  <Doughnutchart data={doughnutData} options={options} />
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  fontSize: '12px',
                  padding: '4px',
                  fontWeight: 'bold',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <div variant='caption' style={{fontSize: '12px'}}>
                  {newObj.LuasLadangWord[language]} <br />
                  <b style={{fontSize: '12px'}}>
                    {numberWithCommas(props?.data?.summary?.luas_ladang_ha)} ha
                    / {numberWithCommas(props?.data?.summary?.luas_ladang_ac)}{' '}
                    ac
                  </b>
                </div>

                <br />
                <Grid container component='main'>
                  <Grid item xs={1}>
                    <div className='border blue'></div>
                  </Grid>
                  <Grid item xs={11} pl={1}>
                    <div variant='caption' style={{fontSize: '12px'}}>
                      Luas Jalan <br />
                      <b className='blue' style={{fontSize: '12px'}}>
                        {numberWithCommas(props?.data?.summary?.luas_jalan)} ha
                      </b>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              pb={5}
              sx={{
                overflowY: 'scroll',
                // height: "45vh",
              }}
            >
              <Grid
                container
                component='main'
                sx={{
                  borderBottom: '1px solid #BBBBBB',
                  padding: '25px',
                }}
              >
                {/* LUAS LADANG */}
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                  }}
                >
                  {newObj.LuasLadangWord[language]}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                    fontWeight: 'bold',
                  }}
                >
                  {numberWithCommas(props?.data?.summary?.luas_ladang_ha)} ha /{' '}
                  {numberWithCommas(props?.data?.summary?.luas_ladang_ac)} ac
                </Grid>
                {/* LUAS JALAN */}
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                  }}
                >
                  Luas Jalan
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                    fontWeight: 'bold',
                  }}
                >
                  {numberWithCommas(props?.data?.summary?.luas_jalan)} ha
                </Grid>
                {/* KEPADATAN JALAN */}
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                  }}
                >
                  Kepadatan Jalan
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    padding: '4px',
                  }}
                >
                  {numberWithCommas(props?.data?.summary?.kepadatan_jalan)} m/ha
                </Grid>
                <br />
                <br />
                {/*  JALAN DALAM BLOK */}
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                  }}
                >
                  Jalan Dalam Blok
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    padding: '4px',
                  }}
                >
                  {numberWithCommas(props?.data?.summary?.jalan_dalam_block)} m
                </Grid>
                {/*  JALAN UTAMA */}
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                  }}
                >
                  Jalan Utama
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    padding: '4px',
                  }}
                >
                  {numberWithCommas(props?.data?.summary?.jalan_utama)} m
                </Grid>
                {/*  JALAN LADANG */}
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                  }}
                >
                  Jalan Ladang
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    padding: '4px',
                  }}
                >
                  {numberWithCommas(props?.data?.summary?.jalan_ladang)} m
                </Grid>
              </Grid>
              <InnerComponent data={props} />

              <LowerComponent
                adminIotFlag={props.adminIotFlag}
                rightData={props.rightData}
                adminLintramaxFlag={props.adminLintramaxFlag}
                viewerLintramaxFlag={props.viewerLintramaxFlag}
                viewerIotFlag={props.viewerIotFlag}
                adminDigitalMapFlag={props.adminDigitalMapFlag}
                viewerDigitalMapFlag={props.viewerDigitalMapFlag}
                kawasan={props.kawasan}
                status={props.status}
              />
            </Grid>
          </>
        )}
        {value === 2 && (
          <>
            <Grid
              container
              component='main'
              rowSpacing={1}
              columnSpacing={{xs: 1, sm: 2, md: 2}}
              mt={2}
              px={2}
              sx={{
                height: '200px',
                borderBottom: '1px solid #BBBBBB',
                margin: '0 !important',
              }}
            >
              <Grid
                item
                xs={6}
                px={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBottom: '8px',
                }}
              >
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: isSmScreen || isXsScreen ? '120px' : '100%',
                  }}
                >
                  <Doughnutchart data={doughnutData} options={options} />
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  fontSize: '12px',
                  padding: '4px',
                  fontWeight: 'bold',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <div variant='caption' style={{fontSize: '12px'}}>
                  {newObj.LuasLadangWord[language]} <br />
                  <b style={{fontSize: '12px'}}>
                    {numberWithCommas(props?.data?.summary?.luas_ladang_ha)} ha
                    /{numberWithCommas(props?.data?.summary?.luas_ladang_ac)} ac
                  </b>
                </div>

                <br />
                <Grid container component='main'>
                  <Grid item xs={1}>
                    <div className='border blue'></div>
                  </Grid>
                  <Grid item xs={11} pl={1}>
                    <div variant='caption' style={{fontSize: '12px'}}>
                      Luas Tidak Usaha <br />
                      <b
                        className='blue'
                        style={{fontSize: '12px', fontWeight: 'bold'}}
                      >
                        {numberWithCommas(
                          props?.data?.summary?.luas_tidak_usaha
                        )}{' '}
                        ha
                      </b>
                    </div>
                  </Grid>
                </Grid>
                <br />
                <Grid container component='main'>
                  <Grid item xs={1}>
                    <div className='border red'></div>
                  </Grid>
                  <Grid item xs={11} pl={1}>
                    <div variant='caption' style={{fontSize: '12px'}}>
                      Luas Jalan <br />
                      <b className='red' style={{fontSize: '12px'}}>
                        {numberWithCommas(props?.data?.summary?.jalan)} ha
                      </b>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              pb={5}
              sx={{
                overflowY: 'scroll',
                // height: "45vh",
              }}
            >
              <Grid
                container
                component='main'
                sx={{
                  height: 'fit-content',
                  borderBottom: '1px solid #BBBBBB',
                  fontSize: '12px',
                  padding: '25px',
                }}
              >
                {/* LUAS LADANG */}
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                  }}
                >
                  {newObj.LuasLadangWord[language]}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                    fontWeight: 'bold',
                  }}
                >
                  {numberWithCommas(props?.data?.summary?.luas_ladang_ha)} ha /{' '}
                  {numberWithCommas(props?.data?.summary?.luas_ladang_ac)} ac
                </Grid>
                {/* LUAS TIDAK USAHA */}
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                  }}
                >
                  Luas Tidak Usaha
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                    fontWeight: 'bold',
                  }}
                >
                  {numberWithCommas(props?.data?.summary?.luas_tidak_usaha)} ha
                </Grid>
                {/* Jalan */}
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                  }}
                >
                  Luas Jalan
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    padding: '4px',
                  }}
                >
                  {numberWithCommas(props?.data?.summary?.jalan)} ha
                </Grid>
                {/* MAJU LUAR KAWASAN */}
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                  }}
                >
                  Maju Luar Kawasan
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                    fontWeight: 'bold',
                  }}
                >
                  {numberWithCommas(props?.data?.summary?.maju_luar_kawasan)} ha
                </Grid>
                {/* LUAS BERHASIL */}
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                  }}
                >
                  {newObj.LuasBerhasilWord[language]}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    fontSize: '12px',
                    padding: '4px',
                    fontWeight: 'bold',
                  }}
                >
                  {numberWithCommas(props?.data?.summary?.luas_berhasil)} ha
                </Grid>
              </Grid>
              <InnerComponent data={props} />

              <LowerComponent
                adminIotFlag={props.adminIotFlag}
                rightData={props.rightData}
                adminLintramaxFlag={props.adminLintramaxFlag}
                viewerLintramaxFlag={props.viewerLintramaxFlag}
                viewerIotFlag={props.viewerIotFlag}
                adminDigitalMapFlag={props.adminDigitalMapFlag}
                viewerDigitalMapFlag={props.viewerDigitalMapFlag}
                kawasan={props.kawasan}
                status={props.status}
              />
            </Grid>
          </>
        )}
      </div>
    </Box>
  );
}
