import React, { useEffect, useState } from "react";
import { Container, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import axios from "axios";
import nookies, { destroyCookie } from "nookies";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SearchInput from "../../components/SearchInput";
import MapFilesSelection from "../../components/MapFilesSelection";
import MapFilesSmall from "../../components/MapFiles";

import macWP from "../../assets/images/macWP.jpg";
import iconDelete from "../../assets/images/icon-delete.svg";
import iconDownload from "../../assets/images/download.svg";
import PageNav from "../../components/PageNav";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import { Language } from "../../context/LanguageContext";
import { Loader } from "../../components/Loader";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const IotArchiveFiles = () => {
  const { language, newObj } = Language();
  const cookies = nookies.get();
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const tokens_session = cookies.token;
  const navigate = useNavigate();
  const [mapFileData, setMapFileData] = useState([]);
  const [archiveData, setArchiveData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState(0);
  const [tableToggle, setTableToggle] = useState(0); //0 = IoT, 1 = Event
  const [isLoad, setLoad] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
  });
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const countCheckedObjects = archiveData.reduce(
      (acc, obj) => (obj.checked ? acc + 1 : acc),
      0
    );

    setSelectedOption(countCheckedObjects);
  }, [archiveData]);

  const getAllMapArchive = async (type) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/archive/iot/list`,
        {
          type: type,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.data.status === 200) {
        const data = res?.data?.data;
        const dataWithCheck = data.map((item) => ({
          ...item,
          checked: false,
        }));
        setArchiveData(dataWithCheck);
      } else if (res.data.status === 500) {
        setArchiveData([]);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }

      setLoad(false);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const postArchivedDownload = async (files, filename, fileExt) => {
    const fetchFile = await axios
      .post(
        `${apiUrl}/api/archive/iot/download`,
        {
          filename: files,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer",
        }
      )
      .then(({ data }) => {
        const fileName = filename;
        const uint8Array = new Uint8Array(data);
        const blob = new Blob([uint8Array], {
          type:
            fileExt === "csv"
              ? `text/csv;charset=utf-8`
              : fileExt === "xlsx"
              ? `application/vnd.ms-excel`
              : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.setAttribute("target", "_blank");
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch(() => null);
  };

  const postArchivedDelete = async (files) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/archive/iot/delete`,
        {
          files: files,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status === 200) {
        // const data = res?.data?.data;
        // const dataWithCheck = data.map((item) => ({
        //   ...item,
        //   checked: false,
        // }));
        // setArchiveData(dataWithCheck);
        Swal.fire({
          icon: "success",
          title: newObj.SuccessWord[language],
          text: newObj.DeleteArchiveFileWord[language],
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            window.location.reload();
          }
        });
      } else if (res.data.status === 500) {
        setArchiveData([]);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getMapKawasanList = async (status) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/mapping/kawasan/list`,
        { status: status },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,

            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        const data = res?.data?.data;
        const dataWithCheck = data.map((item) => ({
          ...item,
          checked: false,
        }));
        setMapFileData(dataWithCheck);
      } else if (res.data.status === 500) {
        setMapFileData([]);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      setLoad(false);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDownload = () => {
    const filteredObjects = archiveData
      .filter((obj) => obj.checked)
      .map(({ key, url, name, fileExt }) => ({ key, url, name, fileExt }));
    filteredObjects.forEach(async (element) => {
      await postArchivedDownload(element.key, element.name, element.fileExt);
    });
  };

  const handleDeleteArchive = () => {
    const filteredObjects = archiveData
      .filter((obj) => obj.checked)
      .map(({ id, key }) => ({ id, filename: key }));
    postArchivedDelete(filteredObjects);
  };

  const handleDeleteMapFile = () => {
    const filteredObjects = mapFileData
      .filter((obj) => obj.checked)
      .map(({ id, name }) => ({ id, name }));

    const objectString = filteredObjects;
    // postArchivedDelete(objectString)
  };

  const handleSelectionChange = (data) => {
    setArchiveData(data);
  };

  const handleSelectionMapFilesChange = (event) => {
    const { id } = event.target;

    setMapFileData(
      mapFileData.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const filteredArray = mapFileData.filter((item) =>
    item.namaKawasa.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  useEffect(() => {
    setLoad(true);
    if (tableToggle === 0) getAllMapArchive("data");
    else getAllMapArchive("event");
  }, [tableToggle]);

  if (isLoad) {
    return <Loader />;
  } else
    return (
      <ThemeProvider theme={theme}>
        <Box
          style={{
            backgroundImage: `url(${macWP})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            padding: isXsScreen || isSmScreen ? "10px 10px" : "20px 10px",
            minHeight: "100vh",
          }}
        >
          {/* nav */}

          <PageNav
            title={newObj.IoTArchiveFilesWord[language]}
            href="/adminpage"
          />

          <Box
            style={{
              minHeight: "80vh",
              borderRadius: "5px",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              marginTop: "60px",
              width: "100%",
            }}
          >
            <Box style={{ width: "100%" }}>
              <Box
                style={{
                  borderBottom: "1px solid lightgrey",
                  padding: "0 15px",
                  borderColor: "divider",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="tabNav">
                    {" "}
                    <div className="leftBar">
                      <Box className="leftBar1">
                        <Box
                          style={{
                            padding: "10px 20px 10px 20px",
                            borderRadius: "5px",
                            backgroundColor: tableToggle === 0 && "#2B5180",
                            cursor: "pointer",
                            color: tableToggle === 0 && "white",
                            width: "max-content",
                            alignItems: "center",
                            gap: "5px",
                            display: "flex",
                            flex: "1 1 auto",
                            justifyContent: "center",
                          }}
                          onClick={() => setTableToggle(0)}
                        >
                          <FolderCopyOutlinedIcon />
                          {windowWidth > 600 && (
                            <>{newObj.ArchiveWord[language]}</>
                          )}{" "}
                          {newObj.IoTDataWord[language]}
                        </Box>
                        <Box
                          style={{
                            padding: "10px 20px 10px 20px",
                            borderRadius: "5px",
                            backgroundColor: tableToggle === 1 && "#2B5180",
                            cursor: "pointer",
                            color: tableToggle === 1 && "white",
                            width: "max-content",
                            alignItems: "center",
                            gap: "5px",
                            display: "flex",
                            flex: "1 1 auto",
                            justifyContent: "center",
                          }}
                          onClick={() => setTableToggle(1)}
                        >
                          <FolderCopyOutlinedIcon />
                          {windowWidth > 600 && (
                            <> {newObj.ArchiveWord[language]} </>
                          )}{" "}
                          {newObj.EventDataWord[language]}
                        </Box>
                      </Box>
                    </div>
                    <div className="rightBar">
                      <div className="rightSearchBar">
                        {" "}
                        <SearchInput
                          style={{ width: "100%" }}
                          value={searchTerm}
                          onChange={handleSearchChange}
                        />
                      </div>

                      <div
                        className="buttonContainer"
                        style={{
                          marginLeft: "20px",
                          borderLeft: "1px solid lightgrey",
                          display: "flex",
                          alignItems: "center",
                          padding: "20px",
                          gap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            gap: "8px",
                          }}
                          onClick={handleDeleteArchive}
                        >
                          <img src={iconDelete} alt="download" width={"20px"} />
                          {windowWidth > 600 && (
                            <>{newObj.DeleteWord[language]}</>
                          )}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            // selectedOption > 1 ? "not-allowed" : "pointer",
                            gap: "8px",
                          }}
                          onClick={() => {
                            // if (selectedOption === 1) {
                            handleDownload();
                            // }
                          }}
                        >
                          <img
                            src={iconDownload}
                            alt="download"
                            width={"20px"}
                          />
                          {windowWidth > 600 && (
                            <>{newObj.DownloadWord[language]}</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* tabs */}
                </div>
              </Box>
              <div style={{ padding: "15px" }}>
                {tableToggle === 0 ? (
                  <div>
                    <Grid
                      container
                      spacing={0}
                      display="flex"
                      alignItems="center"
                      textAlign="center"
                      padding="0"
                    >
                      <MapFilesSelection
                        data={archiveData}
                        search={searchTerm}
                        onSelectionChange={handleSelectionChange}
                      />
                    </Grid>
                  </div>
                ) : (
                  <div>
                    <Grid
                      container
                      spacing={0}
                      display="flex"
                      alignItems="center"
                      textAlign="center"
                      padding="0"
                    >
                      <MapFilesSelection
                        data={archiveData}
                        search={searchTerm}
                        onSelectionChange={handleSelectionChange}
                      />
                    </Grid>
                  </div>
                )}
              </div>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
};

export default IotArchiveFiles;
