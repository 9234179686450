import React from "react";
import { InputBase, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@mui/icons-material/Search";
import { Language } from "../context/LanguageContext";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "4px",
    paddingLeft: "8px",
    paddingRight: "8px",
    width: "100%",
  },
  input: {
    flex: 1,
  },
});

function SearchInput(props) {
  const { language, newObj } = Language();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <InputBase
        {...props}
        className={classes.input}
        placeholder={newObj.SearchWord[language]}
      />
      <IconButton type="submit" aria-label="search">
        <SearchIcon />
      </IconButton>
    </div>
  );
}

export default SearchInput;
