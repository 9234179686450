import React, { useState, useCallback, useEffect } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import axios from "axios";
import nookies, { destroyCookie } from "nookies";
import { Link, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Language } from "../context/LanguageContext";
import dragndrop from "../assets/images/dragndrop.png";
import { Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Loader } from "./Loader";
import word from "../assets/images/word.png";
import excel from "../assets/images/excel.png";
import acrobat from "../assets/images/Icon.png";
import zip from "../assets/images/qgisZip.png";

export default function DocumentReportDropzone({ onChange }) {
  const { language, newObj } = Language();
  const [progress, setProgress] = useState(0);
  const [steps, setSteps] = useState(0);
  const [pkCodeClick, setPKCodeClick] = useState(false);
  const [selectedPK, setSelectedPK] = useState();
  const [pkCode, setPkCode] = useState();
  const [projectCodeClick, setProjectCodeClick] = useState(false);
  const [selectedProjectCode, setSelectedProjectCode] = useState();
  const [projectCode, setProjectCode] = useState();
  const [load, setLoad] = useState(true);
  const [src, setSrc] = useState();
  const [file, setFile] = useState();
  const [uploadStatus, setUploadStatus] = useState("");
  const [profile, setProfile] = useState();

  const navigate = useNavigate();
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const apiUrl = process.env.REACT_APP_MAIN_URL;

  const now = new Date();

  // Extract the individual parts of the date and time
  const year = now.getFullYear();
  const month = now.toLocaleString("en-US", { month: "long" });
  const date = now.getDate();
  const hours = now.getHours();
  const minutes = now.getMinutes();

  const dateNow =
    date + " " + month + " " + year + ", " + hours + ":" + minutes;

  useEffect(() => {
    onChange(steps);
  }, [steps]);

  useEffect(() => {
    if (file) {
      const fileExtension = getFileExtension(file.path);
      let image = "";
      if (fileExtension === "docx" || fileExtension === "doc") {
        image = word;
      } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        image = excel;
      } else if (fileExtension === "pdf") {
        image = acrobat;
      } else image = zip;
      setSrc(image);
    }
  }, [file]);

  const getFileExtension = (filename) => {
    // Split the filename by dot to get the file parts
    const fileParts = filename.split(".");

    // Get the last part which should be the file extension
    const fileExtension = fileParts[fileParts.length - 1];

    // Return the file extension in lowercase
    return fileExtension.toLowerCase();
  };
  const uploadFile = async (file) => {
    const formData = new FormData();
    const options = {
      headers: {
        Authorization: `Bearer ${tokens_session}`,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        // setSteps(1);
        // setProgress(
        //   Math.round((progressEvent.loaded * 100) / progressEvent.total)
        // );
      },
    };
    formData.append("file", file);

    setFile(file);
    setSteps(1);
  };

  const getAllPkCode = async () => {
    try {
      const res = await axios.get(`${apiUrl}/api/mapping/pk_code/get-all`, {
        headers: {
          Authorization: `Bearer ${tokens_session}`,
        },
      });
      if (res.data.status === 200) {
        setPkCode(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      setLoad(false);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getProjectCodeByPkCode = async (pkCode) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/mapping/project_code/get-all-by-pk-code`,
        { pk_code: pkCode },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        setProjectCode(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      setLoad(false);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const getProfile = async () => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/profile`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );
      if (res.data.status === 200) {
        setProfile(res?.data?.data);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitReportToApi = async () => {
    try {
      setSteps(2);

      const res = await getProfile();
      let id = res.username;

      let eventSource = new EventSource(
        `${apiUrl}/api/archive/report/progress?username=${id}`
      );
      eventSource.addEventListener("GUI_ID", (event) => {
        const guidValue = event.data;
        // formData.append("guid", guidValue);
        eventSource.addEventListener(id, (event) => {
          const result = JSON.parse(event.data);
          if (progress !== result.progress) {
            setProgress(result.progress);
          }
          if (uploadStatus !== result.status) {
            setUploadStatus(result.status);
          }
          if (result.status === "Done!") {
            eventSource.close();
          }
        });
        submitReportToApi();

        // uploadMap(formData, options, apiUpload);
      });

      eventSource.onerror = (event) => {
        if (event.target.readyState === EventSource.CLOSED) {
          // console.log("SSE closed (" + event.target.readyState + ")");
        }
        setProgress(0);
        onChange(0);
        eventSource.close();
      };

      eventSource.onopen = () => {
        // console.log("connection opened");
      };
    } catch (error) {
      console.log(error);
    }
  };

  const submitReportToApi = async () => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/archive/report/upload`,
        {
          file: file,
          pk_kod: selectedPK.kodKawasa,
          projek_kod: selectedProjectCode.kodProjek,
          negeri_kod: selectedPK.kodNegeri,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        setSteps(3);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    if (!pkCode) {
      getAllPkCode();
      setLoad(false);
    }
  }, []);

  useEffect(() => {
    if (selectedPK) {
      setLoad(true);
      getProjectCodeByPkCode(selectedPK?.kodKawasa);
    }
  }, [selectedPK]);

  const onDrop = useCallback((acceptedFiles) => {
    // Handle the dropped files here
    acceptedFiles.forEach((file) => {
      uploadFile(file);
    });
  }, []);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    rejectedFiles,
    isDragReject,
  } = useDropzone({
    onDrop,
    multiple: false,
    minSize: 0,
  });
  if (load) {
    <Loader />;
  } else
    return (
      <>
        <div
          {...(steps === 0 && getRootProps())}
          style={{
            height: "100%",
            ...(steps === 0 && { cursor: "pointer" }),
          }}
        >
          <input {...getInputProps()} />
          <div style={{ width: "100%", height: "90%" }}>
            {steps === 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  gap: "20px",
                }}
              >
                <div>
                  <img src={dragndrop} alt="drag" style={{ width: "200px" }} />
                </div>
                <p
                  style={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {!isDragActive &&
                    newObj.DragAndDropOrBrowseYourFileWord[language]}
                  {isDragActive && !isDragReject && `Drop Here`}
                  {isDragReject && `File type not accepted, sorry!`}
                </p>
              </div>
            )}
            {steps === 1 && (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "end",
                }}
              >
                <div
                  style={{
                    marginBottom: "100px",
                    width: "100%",
                  }}
                >
                  {/* file */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      justifyContent: "start",
                    }}
                  >
                    <img src={src} alt="file" style={{ width: "50px" }} />
                    <div>
                      <div style={{ color: "white", fontWeight: "bold" }}>
                        {file.path}
                      </div>
                      <div style={{ color: "white", fontSize: "12px" }}>
                        {dateNow}
                      </div>
                    </div>
                  </div>

                  <div style={{ margin: "10px 0 5px 0", color: "white" }}>
                    Please fill up information below
                  </div>

                  {/* select pk code */}
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    {" "}
                    <Box
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px 20px",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        position: "relative",
                      }}
                      onClick={() => setPKCodeClick(!pkCodeClick)}
                    >
                      {selectedPK ? (
                        // pk code name
                        <span>
                          <span
                            style={{
                              fontWeight: "bold",
                              marginRight: "10px",
                              fontSize: "12px",
                            }}
                          >
                            {selectedPK.kodKawasa}
                          </span>
                          {selectedPK.namaKawasa}
                        </span>
                      ) : (
                        <span>PK Code</span>
                      )}
                      <ExpandMoreIcon style={{ width: "20px" }} />
                    </Box>
                    {pkCodeClick && (
                      <Box
                        style={{
                          padding: "3% 0",
                          position: "absolute",
                          width: "100%",
                          zIndex: 100,
                        }}
                      >
                        <Box
                          className="removeScrollbar"
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "white",
                            zIndex: "1000",
                            right: "0",
                            maxHeight: "200px",
                            overflowY: "scroll",
                            bottom: "-25%",
                          }}
                        >
                          {pkCode.map((obj, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                borderBottom: "1px solid lightgray",
                                padding: "10px 20px",
                                alignItems: "center",
                                cursor: "pointer",
                                fontSize: "12px",
                              }}
                              onClick={() => {
                                setPKCodeClick(!pkCodeClick);
                                setSelectedPK({
                                  kodKawasa: obj.kodKawasa,
                                  namaKawasa: obj.namaKawasa,
                                  kodNegeri: obj.kodWilaya,
                                });
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "10px",
                                  fontSize: "12px",
                                }}
                              >
                                PK{obj.kodKawasa}
                              </span>
                              {obj.namaKawasa}
                            </div>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </div>
                  {/* select project code */}
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <Box
                      style={{
                        borderRadius: "5px",
                        display: "flex",
                        marginTop: "10px",
                        alignItems: "center",
                        padding: "10px 20px",
                        justifyContent: "space-between",

                        ...(!selectedPK
                          ? {
                              backgroundColor: "grey",
                            }
                          : {
                              backgroundColor: "white",
                            }),
                        ...(!selectedPK
                          ? { cursor: "not-allowed" }
                          : { cursor: "pointer" }),
                      }}
                      onClick={() =>
                        selectedPK && setProjectCodeClick(!projectCodeClick)
                      }
                    >
                      {selectedProjectCode ? (
                        // pk code name
                        <span>
                          <span
                            style={{
                              fontWeight: "bold",
                              marginRight: "10px",
                              fontSize: "12px",
                            }}
                          >
                            {selectedProjectCode.kodProjek}
                          </span>
                          {selectedProjectCode.namaProjek}
                        </span>
                      ) : (
                        <span>Project Code</span>
                      )}
                      <ExpandMoreIcon style={{ width: "20px" }} />
                    </Box>
                    {projectCodeClick && (
                      <Box
                        style={{
                          padding: "3% 0",
                          position: "absolute",
                          width: "100%",
                        }}
                      >
                        <Box
                          className="removeScrollbar"
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "white",
                            zIndex: "1000",
                            right: "0",
                            maxHeight: "200px",
                            overflowY: "scroll",
                            bottom: "-25%",
                          }}
                        >
                          {projectCode.map((obj, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                borderBottom: "1px solid lightgray",
                                padding: "10px 20px",
                                alignItems: "center",
                                cursor: "pointer",
                                fontSize: "12px",
                              }}
                              onClick={() => {
                                setProjectCodeClick(!projectCodeClick);
                                setSelectedProjectCode({
                                  kodProjek: obj.kodProjek,
                                  namaProjek: obj.namaProjek,
                                });
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "10px",
                                  fontSize: "12px",
                                }}
                              >
                                {obj.kodProjek}
                              </span>
                              {obj.namaProjek}
                            </div>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </div>
                </div>

                <button
                  onClick={handleSubmitReportToApi}
                  style={{
                    ...(!(selectedPK && selectedProjectCode)
                      ? {
                          backgroundColor: "grey",
                        }
                      : {
                          backgroundColor: "white",
                        }),
                    ...(!(selectedPK && selectedProjectCode)
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }),
                    color: "black",
                    padding: "15px",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  Save
                </button>
              </div>
            )}
            {steps === 2 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {uploadStatus}
                  </p>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      height: "10px",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  />
                </div>
              </div>
            )}

            {steps === 3 && (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: "15px",
                }}
              >
                <div
                  style={{
                    marginBottom: "50px",
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <CheckCircleOutlineIcon
                    style={{ color: "lightgreen", fontSize: "100px" }}
                  />
                  {newObj.YourFileWasSuccessfullyAddedWord[language]}
                </div>

                <Link to="/documentreport" style={{ width: "100%" }}>
                  <button
                    style={{
                      backgroundColor: "white",
                      border: "1px solid white",
                      padding: "15px",
                      width: "100%",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  >
                    {newObj.ViewTheDocumentAndReportWord[language]}
                  </button>
                </Link>

                <Link to="/adminpage">
                  {" "}
                  <div
                    style={{
                      color: "white",
                      textUnderlineOffset: "2px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {newObj.ReturnToTheAdministratorPageWord[language]}
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      </>
    );
}
