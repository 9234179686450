import React, { useState, useEffect } from "react";
import { IconButton, Menu, MenuItem, Switch } from "@material-ui/core";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import dirianPokokLogo from "../../src/assets/images/dirianPokok.png";
import jaringanJalanLogo from "../../src/assets/images/jaringanJalan.png";
import blockAgronomiLogo from "../../src/assets/images/blockAgrononomi.png";
import perimeterLadangLogo from "../../src/assets/images/perimeterLadang.png";
import gunaTanahLogo from "../../src/assets/images/gunaTanah.png";
import terlebihTanamLogo from "../../src/assets/images/terlebihTanam.png";
import iotLogo from "../../src/assets/images/iot.png";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

const theme = createTheme({
  div: {
    fontFamily: "Montserrat",
  },
  overrides: {
    MuiMenuItem: {
      root: {
        padding: 0,
      },
    },
  },
});

function IconMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [switchValues, setSwitchValues] = useState(props.changeSwitchValue);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const disableDirian = props.zoom < 17 ? true : false;
  const disableIoT = props.zoom < 12 ? true : false;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleSwitchChange = (event) => {
    setSwitchValues({
      ...switchValues,
      [event.target.name]: event.target.checked,
    });

    props.onSwitchChange({
      ...switchValues,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    setSwitchValues(props.changeSwitchValue);
  }, [props]);

  return (
    <ThemeProvider theme={theme}>
      <IconButton onClick={handleClick}>
        <TuneOutlinedIcon style={{ rotate: "90deg" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        style={{ marginTop: "70px" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem>
          <img
            src={dirianPokokLogo}
            style={{ width: "120px", margin: "0 20px" }}
            alt="Dirian Pokok"
          />
          <div style={{ width: "40%" }}>
            <div variant="body1">Dirian Pokok</div>
          </div>
          <Switch
            name="switch1"
            disabled={disableDirian}
            checked={switchValues.switch1}
            color="primary"
            onChange={handleSwitchChange}
          />
        </MenuItem>
        <MenuItem>
          <img
            src={jaringanJalanLogo}
            style={{ width: "120px", margin: "0 20px" }}
            alt="Jaringan Jalan"
          />
          <div style={{ width: "40%" }}>
            <div variant="body1">Jaringan Jalan</div>
          </div>
          <Switch
            name="switch2"
            disabled={disableDirian}
            checked={switchValues.switch2}
            onChange={handleSwitchChange}
            color="primary"
          />
        </MenuItem>
        <MenuItem>
          <img
            src={blockAgronomiLogo}
            style={{ width: "120px", margin: "0 20px" }}
            alt="Block Agronomi"
          />
          <div style={{ width: "40%" }}>
            <div variant="body1">Block Agronomi</div>
          </div>
          <Switch
            name="switch3"
            checked={switchValues.switch3}
            onChange={handleSwitchChange}
            color="primary"
          />
        </MenuItem>
        <MenuItem>
          <img
            src={perimeterLadangLogo}
            style={{ width: "120px", margin: "0 20px" }}
            alt="Block Agronomi"
          />
          <div style={{ width: "40%" }}>
            <div variant="body1">Perimeter Ladang</div>
          </div>
          <Switch
            name="switch4"
            disabled={disableDirian}
            checked={switchValues.switch4}
            onChange={handleSwitchChange}
            color="primary"
          />
        </MenuItem>
        <MenuItem>
          <img
            src={gunaTanahLogo}
            style={{ width: "120px", margin: "0 20px" }}
            alt="Block Agronomi"
          />
          <div style={{ width: "40%" }}>
            <div variant="body1">Guna Tanah</div>
          </div>
          <Switch
            name="switch5"
            disabled={disableDirian}
            checked={switchValues.switch5}
            onChange={handleSwitchChange}
            color="primary"
          />
        </MenuItem>
        <MenuItem>
          <img
            src={terlebihTanamLogo}
            style={{ width: "120px", margin: "0 20px" }}
            alt="Block Agronomi"
          />
          <div style={{ width: "40%" }}>
            <div variant="body1">Terlebih Tanam</div>
          </div>
          <Switch
            name="switch6"
            disabled={disableDirian}
            checked={switchValues.switch6}
            onChange={handleSwitchChange}
            color="primary"
          />
        </MenuItem>
        <MenuItem>
          <img
            src={iotLogo}
            style={{ width: "120px", margin: "0 20px" }}
            alt="Block Agronomi"
          />
          <div style={{ width: "40%" }}>
            <div variant="body1">IoT</div>
          </div>
          <Switch
            name="switch7"
            disabled={disableIoT}
            checked={switchValues.switch7}
            onChange={handleSwitchChange}
            color="primary"
          />
        </MenuItem>
      </Menu>
    </ThemeProvider>
  );
}

export default IconMenu;
