import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import axios from "axios";
import Swal from "sweetalert2";
import nookies, { destroyCookie } from "nookies";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import SearchInput from "../../components/SearchInput";
import MapFilesSelection from "../../components/MapFilesSelection";
import MapFilesSmall from "../../components/MapFiles";

import macWP from "../../assets/images/macWP.jpg";
import iconDelete from "../../assets/images/icon-delete.svg";
import iconDownload from "../../assets/images/download.svg";
import iconUpload from "../../assets/images/Upload-icon.svg";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import { Language } from "../../context/LanguageContext";
import { Loader } from "../../components/Loader";
import "./index.scss";
import { Link, useNavigate } from "react-router-dom";

const MapFiles = () => {
  const { language, newObj } = Language();
  const cookies = nookies.get();
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const tokens_session = cookies.token;
  const navigate = useNavigate();
  const [mapFileData, setMapFileData] = useState([]);
  const [archiveData, setArchiveData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState(0);
  const [tableToggle, setTableToggle] = useState(0); //0 = Weather, 1 = Soil
  const [isLoad, setLoad] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
  });
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const countCheckedObjects = archiveData.reduce(
      (acc, obj) => (obj.checked ? acc + 1 : acc),
      0
    );

    setSelectedOption(countCheckedObjects);
  }, [archiveData]);

  const getAllMapArchive = async () => {
    try {
      const res = await axios.get(`${apiUrl}/api/archive/mapping/list`, {
        headers: {
          Authorization: `Bearer ${tokens_session}`,
        },
      });
      if (res.data.status === 200) {
        const data = res?.data?.data;
        const dataWithCheck = data.map((item) => ({
          ...item,
          checked: false,
        }));
        setArchiveData(dataWithCheck);
      } else if (res.data.status === 500) {
        setArchiveData([]);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }

      setLoad(false);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const postArchivedDownload = async (files, filename) => {
    const fetchFile = await axios
      .post(
        `${apiUrl}/api/archive/mapping/download`,
        {
          filename: files,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer",
        }
      )
      .then(({ data }) => {
        const fileName = filename;
        const uint8Array = new Uint8Array(data);
        const blob = new Blob([uint8Array], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.setAttribute("target", "_blank");
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch(() => null);
  };

  const postArchivedDelete = async (files) => {
    setLoad(true);
    try {
      const res = await axios.post(
        `${apiUrl}/api/archive/mapping/delete`,
        { files },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );

      setLoad(false);
      if (res.data.status === 200) {
        Swal.fire({
          icon: "success",
          title: newObj.SuccessWord[language],
          text: newObj.MapHasBeenDeleted[language],
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            window.location.reload();
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Delete Archive Files",
        });
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      setLoad(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
      throw error;
    }
  };

  const deleteMultipleMap = async (files) => {
    setLoad(true);
    try {
      const res = await axios.post(
        `${apiUrl}/api/mapping/delete-multiple`,
        { files },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
          },
        }
      );

      setLoad(false);
      if (res.data.status === 200) {
        Swal.fire({
          icon: "success",
          title: newObj.SuccessWord[language],
          text: newObj.DeleteArchiveFileWord[language],
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            window.location.reload();
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Delete Archive Files",
        });
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      setLoad(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
      throw error;
    }
  };
  const getMapKawasanList = async (status) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/mapping/kawasan/list`,
        { status: status },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,

            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        const data = res?.data?.data;
        const dataWithCheck = data.map((item) => ({
          ...item,
          checked: false,
        }));
        setMapFileData(dataWithCheck);
      } else if (res.data.status === 500) {
        setMapFileData([]);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      setLoad(false);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDownload = () => {
    const filteredObjects = archiveData
      .filter((obj) => obj.checked)
      .map(({ key, url, name }) => ({ key, url, name }));

    postArchivedDownload(filteredObjects[0].key, filteredObjects[0].name);
  };

  const handleDeleteArchive = () => {
    const filteredObjects = archiveData
      .filter((obj) => obj.checked)
      .map(({ id, key }) => ({ filename: key, id }));

    postArchivedDelete(filteredObjects);
  };

  const handleDeleteMapFile = () => {
    const filteredObjects = mapFileData
      .filter((obj) => obj.checked)
      .map(({ namaKawasa, publish }) => ({
        nama_kawasan: namaKawasa,
        status: publish,
      }));

    console.log(filteredObjects);
    deleteMultipleMap(filteredObjects);
  };

  const handleSelectionChange = (data) => {
    setArchiveData(data);
  };

  const handleSelectionMapFilesChange = (event) => {
    const { id } = event.target;

    setMapFileData(
      mapFileData.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const filteredArray = mapFileData.filter((item) =>
    item.namaKawasa.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setLoad(true);
    if (tableToggle === 0) getMapKawasanList("all");
    else if (tableToggle === 1) getMapKawasanList("publish");
    else if (tableToggle === 2) getMapKawasanList("draft");
    else getAllMapArchive();
  }, [tableToggle]);

  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  if (isLoad) {
    return <Loader />;
  } else
    return (
      <ThemeProvider theme={theme}>
        <Box
          style={{
            backgroundImage: `url(${macWP})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            padding:
              isXsScreen || isSmScreen ? "10px 10px 20px 10px" : "20px 10px",
            minHeight: "100vh",
          }}
        >
          {/* nav */}
          <Box className="headerWithButton">
            <Link
              to="/adminpage"
              py={1}
              px={2}
              style={{
                textDecoration: "none",
                alignItems: "center",
                display: "flex",
                padding: "8px 16px",
              }}
            >
              <Box display="flex">
                <ChevronLeftIcon
                  style={{ fontSize: "28px", color: "#2B337D" }}
                />
                <div
                  variant="body1"
                  style={{
                    color: "#2B337D",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    marginLeft: "10px",
                  }}
                  p={0}
                  ml={3}
                  mb={0}
                >
                  {newObj.MapFilesWord[language]}
                </div>
              </Box>
            </Link>
            <Box
              style={{
                height: "100%",
              }}
            >
              <Link
                to="/uploadmap"
                style={{
                  borderLeft: "1px solid lightgrey",
                  display: "flex",
                  padding: "10px 20px",
                  height: "100%",
                  gap: "10px",
                  color: "black",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                  }}
                >
                  <img src={iconUpload} style={{ width: "20px" }} />
                </Box>
                <div
                  style={{
                    fontWeight: "500",
                  }}
                >
                  {newObj.UploadMapWord[language]}
                </div>
              </Link>
            </Box>
          </Box>

          <Box
            style={{
              minHeight: "80vh",
              borderRadius: "5px",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              marginTop: "60px",
              width: "100%",
            }}
          >
            <Box style={{ width: "100%" }}>
              <Box
                style={{
                  borderBottom: "1px solid lightgrey",
                  padding: "0 15px",
                  borderColor: "divider",
                  alignItems: "center",
                }}
              >
                <div className="tabNav">
                  {/* tabs */}
                  <div className="leftBar">
                    <Box className="leftBar1">
                      <Box
                        style={{
                          ...(windowWidth < 600
                            ? {
                                padding: "12px",
                              }
                            : {
                                padding: "10px 20px",
                              }),
                          borderRadius: "5px",
                          backgroundColor: tableToggle === 0 && "#2B5180",
                          cursor: "pointer",
                          color: tableToggle === 0 && "white",
                          width: "max-content",
                          flex: "1 1 auto",
                          textAlign: "center",
                        }}
                        onClick={() => setTableToggle(0)}
                      >
                        {windowWidth < 600
                          ? newObj.AllMapsWord[language].split(" ")[0]
                          : newObj.AllMapsWord[language]}
                      </Box>
                      <Box
                        style={{
                          ...(windowWidth < 600
                            ? {
                                padding: "12px",
                              }
                            : {
                                padding: "10px 20px",
                              }),
                          borderRadius: "5px",
                          backgroundColor: tableToggle === 1 && "#2B5180",
                          cursor: "pointer",
                          color: tableToggle === 1 && "white",
                          width: "max-content",
                          flex: "1 1 auto",
                          textAlign: "center",
                        }}
                        onClick={() => setTableToggle(1)}
                      >
                        {" "}
                        {windowWidth < 600
                          ? newObj.PublishedMapWord[language].split(" ")[0]
                          : newObj.PublishedMapWord[language]}
                      </Box>
                      <Box
                        style={{
                          ...(windowWidth < 600
                            ? {
                                padding: "12px",
                              }
                            : {
                                padding: "10px 20px",
                              }),
                          borderRadius: "5px",
                          backgroundColor: tableToggle === 2 && "#2B5180",
                          cursor: "pointer",
                          color: tableToggle === 2 && "white",
                          width: "max-content",
                          flex: "1 1 auto",
                          textAlign: "center",
                        }}
                        onClick={() => setTableToggle(2)}
                      >
                        {windowWidth < 600
                          ? newObj.DraftMapWord[language].split(" ")[0]
                          : newObj.DraftMapWord[language]}
                      </Box>
                    </Box>

                    <Box
                      style={{
                        padding: "10px 20px 10px 20px",
                        borderRadius: "5px",
                        backgroundColor:
                          tableToggle === 3 ? "#2B5180" : "white",
                        cursor: "pointer",
                        marginLeft: "10px",
                        color: tableToggle === 3 && "white",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        width: "max-content",
                      }}
                      onClick={() => setTableToggle(3)}
                    >
                      <FolderCopyOutlinedIcon />

                      <span className="archiveText">
                        {newObj.ArchiveWord[language]}
                      </span>
                    </Box>
                  </div>
                  <div className="rightBar">
                    <div className="rightSearchBar">
                      <SearchInput
                        style={{ width: "100%" }}
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                    <div
                      className="buttonContainer"
                      style={{
                        marginLeft: "20px",
                        borderLeft: "1px solid lightgrey",
                        display: "flex",
                        alignItems: "center",
                        padding: "20px",
                        gap: "20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: "8px",
                        }}
                        onClick={
                          tableToggle === 3
                            ? handleDeleteArchive
                            : handleDeleteMapFile
                        }
                      >
                        <img src={iconDelete} alt="download" width={"20px"} />
                        <span className="archiveText">Delete</span>
                      </div>

                      {tableToggle === 3 && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor:
                              selectedOption > 1 ? "not-allowed" : "pointer",
                            gap: "8px",
                          }}
                          onClick={() => {
                            if (selectedOption === 1) {
                              handleDownload();
                            }
                          }}
                        >
                          <img
                            src={iconDownload}
                            alt="download"
                            width={"20px"}
                          />
                          <span className="archiveText">Download</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Box>
              <div style={{ padding: "15px" }}>
                {tableToggle === 0 && (
                  <div>
                    <Grid
                      container
                      spacing={1}
                      display="flex"
                      alignItems="center"
                      textAlign="center"
                      padding="0"
                    >
                      {filteredArray.map((file) => (
                        <MapFilesSmall
                          key={file.id}
                          title={file.namaKawasa}
                          checked={file.checked}
                          id={file.id}
                          onSelectionChange={handleSelectionMapFilesChange}
                          file={file}
                          isPublished={file.publish}
                        />
                      ))}
                    </Grid>
                  </div>
                )}
                {tableToggle === 1 && (
                  <div>
                    <Grid
                      container
                      spacing={1}
                      display="flex"
                      alignItems="center"
                      textAlign="center"
                      padding="0"
                    >
                      {filteredArray.map((file) => (
                        <MapFilesSmall
                          key={file.id}
                          title={file.namaKawasa}
                          checked={file.checked}
                          id={file.id}
                          onSelectionChange={handleSelectionMapFilesChange}
                          file={file}
                          isPublished={file.publish}
                        />
                      ))}
                    </Grid>
                  </div>
                )}
                {tableToggle === 2 && (
                  <div>
                    <Grid
                      container
                      spacing={1}
                      display="flex"
                      alignItems="center"
                      textAlign="center"
                      padding="0"
                    >
                      {filteredArray.map((file) => (
                        <MapFilesSmall
                          key={file.id}
                          title={file.namaKawasa}
                          checked={file.checked}
                          id={file.id}
                          onSelectionChange={handleSelectionMapFilesChange}
                          file={file}
                          isPublished={file.publish}
                          centerLat={file.latitude}
                          centerLong={file.longitude}
                        />
                      ))}
                    </Grid>
                  </div>
                )}
                {tableToggle === 3 && (
                  <div>
                    <Grid
                      container
                      spacing={0}
                      display="flex"
                      alignItems="center"
                      textAlign="center"
                      padding="0"
                    >
                      <MapFilesSelection
                        data={archiveData}
                        search={searchTerm}
                        onSelectionChange={handleSelectionChange}
                      />
                    </Grid>
                  </div>
                )}
              </div>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
};

export default MapFiles;
