import React, { useEffect, useState } from "react";
import nookies, { destroyCookie } from "nookies";

import macWP from "../../assets/images/macWP.jpg";
import { Container, Grid } from "@material-ui/core";
import { IoTCard } from "../../components/IoTCard";
import PageNav from "../../components/PageNav";
import axios from "axios";
import { Loader } from "../../components/Loader";
import { Box, useMediaQuery, createTheme, ThemeProvider } from "@mui/material";
import { useNavigate } from "react-router-dom";
const IoT = () => {
  const cookies = nookies.get();
  const tokens_session = cookies.token;
  const apiUrl = process.env.REACT_APP_MAIN_URL;
  const navigate = useNavigate();
  const [tableToggle, setTableToggle] = useState(0); //0 = Weather, 1 = Soil
  const [weatherObject, setWeatherObject] = useState();
  const [deviceDetail, setDeviceDetail] = useState([]);
  const [isLoad, setLoad] = useState(true);
  const [count, setCount] = useState(-1);
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
  });
  const isXsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const getAllDevice = async (device, toggle) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/device/list`,
        {
          flag: "category",
          value: device,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        if (toggle === 0) {
          const data = res?.data?.data;
          const newData = data.map((item, index) => {
            if (index === 0) return { ...item, clicked: true };
            else return { ...item, clicked: false };
          });
          setWeatherObject(newData);
        } else {
          const dataStation = res?.data?.data?.station;
          const dataPortable = res?.data?.data?.portable;
          const dataStationNew = dataStation.map((item, index) => {
            if (index === 0) return { ...item, clicked: true };
            else return { ...item, clicked: false };
          });
          const dataPortableNew = dataPortable.map((item) => {
            return { ...item, clicked: false };
          });
          setWeatherObject([...dataStationNew, ...dataPortableNew]);
        }
        setLoad(false);
      }

      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getDeviceDetail = async (id) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/device/detail`,
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens_session}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status === 200) {
        setDeviceDetail([...deviceDetail, res.data.data]);
      }
      if (res.data.status === 401) {
        destroyCookie(null, "token");
        navigate("/login");
      }
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    setLoad(true);
    const device = tableToggle === 0 ? "weather" : "soil";
    getAllDevice(device, tableToggle);
  }, [tableToggle]);

  useEffect(() => {
    if (count === -1) {
      setDeviceDetail([]);
      setCount(count + 1);
    } else if (count < weatherObject?.length) {
      // getDeviceDetail(weatherObject[count].id);
      setCount(count + 1);
    } else {
      setCount(-1);
      setLoad(false);
    }
  }, [weatherObject, deviceDetail]);

  if (isLoad) return <Loader />;
  else
    return (
      <ThemeProvider theme={theme}>
        <Box
          style={{
            backgroundImage: `url(${macWP})`,
            backgroundRepeat: "no-repeat",
            minHeight: "100vh",
            maxHeight: "maxContent",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            paddingTop: isSmScreen || isXsScreen ? "10px" : "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PageNav title="IoT" href="/" />

          <Box
            style={{
              backgroundColor: "rgba(255,255,255,0.8)",
              borderRadius: "5px",
              margin: "70px 10px 10px 10px",
              width: "calc(100vw - 20px)",
            }}
          >
            <Box
              style={{
                padding: "15px",
                borderBottom: "1px solid gray",
                display: "flex",
                flexDirection: "column",
                gap: "70px",
              }}
            >
              {" "}
              {/* toggle */}
              <Box
                style={{
                  backgroundColor: "lightgray",
                  borderRadius: "5px",
                  display: "flex",
                  width: "max-content",
                }}
              >
                <Box
                  style={{
                    padding: "10px 20px 10px 20px",
                    borderRadius: "5px",
                    backgroundColor: tableToggle === 0 && "#2B5180",
                    cursor: "pointer",
                    color: tableToggle === 0 && "white",
                    width: "max-content",
                  }}
                  onClick={() => setTableToggle(0)}
                >
                  Weather Stations
                </Box>
                <Box
                  style={{
                    padding: "10px 20px 10px 20px",
                    borderRadius: "5px",
                    backgroundColor: tableToggle === 1 && "#2B5180",
                    cursor: "pointer",
                    color: tableToggle === 1 && "white",
                    width: "max-content",
                  }}
                  onClick={() => setTableToggle(1)}
                >
                  Soil Sensors
                </Box>
              </Box>
            </Box>

            <Box
              style={{
                padding: "15px",
              }}
            >
              <Grid container spacing={0}>
                {weatherObject?.map((item, index) => (
                  <Grid
                    xs={12}
                    lg={3}
                    md={4}
                    sx={{ padding: "5px", width: "100%" }}
                    key={index}
                  >
                    <IoTCard
                      item={item}
                      style={{ width: "100%" }}
                      tableToggle={tableToggle}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
};

export default IoT;
